export const BUTTONS ={
    save:'Save',
    cancel:'Cancel'
}

export const HEADINGS = {
    explain:'Explanation to the IRS',
    viewExpl:'View Explanation'
}

export const ERRORS = {
    errorIRS: 'Please write some explanation to IRS.'
}