import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Snackbar,
  Alert,
  Select,
  InputLabel,
  MenuItem,
  Button,
} from "@mui/material";
import { FlexContainer, TextButton } from "./styles";
import { useEffect, useState } from "react";
import { REFERENCE_STATUS, REJECTED_STATUSES } from "../../common/apis/const";
import DashboardService from "../../Services/dashboard.service";
import DashboardLinksService from "../../Services/dashboard-links.service";
import { formatDate } from "../../Utils/Constants";
import { IS_LOADING } from "../../Actions/types";
import { useAppDispatch } from "../../Store";

const PROCESS_STATE_LIST = [
  "Blocked",
  "Blocked and Credit Initiated",
  "Blocked and Refund Initiated",
];

const CREADIT_REASON_LIST = [
  "To be utilised now",
  "To be utilised in future"
];

export default function DashboardHeader({ reference, clearStateAndRefetch } : {
  reference: any;
  clearStateAndRefetch: any;
}) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [blockBox, setBlockBox] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [rejectState, setRejectState] = useState("Blocked");
  const [creditReason, setCreditReason] = useState(" ");
  const [showRetransmit, setShowRetransmit] = useState(false);
  const [reTransmitStatus, setReTransmitStatus] = useState<number>();
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const handleClose = () => setOpen(false);
  const closeBlockBox = () => setBlockBox(false);

  const handleAlertClose = () => setShowAlert(false);
  const referenceStatus = REFERENCE_STATUS.status;

  const reTransmit = () => {
    dispatch({ type: IS_LOADING });
    const { filingType, referenceNumber } = reference;
    let formType = "";
    if (filingType === "Form2290") {
      formType = "2290";
    } else if (filingType === "Form8849") {
      formType = "8849";
    }

    if (formType && referenceNumber) {
      DashboardService.csReTransmit({ filingType: formType, referenceNumber })
        .then((response) => {
          setShowAlert(true);
          setReTransmitStatus(response.status);
        })
        .catch((error) => {
          setShowAlert(true);
          setReTransmitStatus(error.code);
        })
        .finally(() => {         
          clearStateAndRefetch();
        });
    }
  };

  const saveRejectState = () => {
    dispatch({ type: IS_LOADING });
    if (rejectState === "Blocked") {
      return DashboardLinksService.csStopProcessing(reference?.referenceNumber)
        .then((res) => {
          clearStateAndRefetch();
          setBlockBox(false);
        })
        .catch((err) => console.log(err));
    } else {
      const status =
        rejectState === "Blocked and Credit Initiated" ? "BC" : "BR";
      const rejReasonCd =
        status === "BC" ? (creditReason === "To be utilised now" ? "TN" : "TF") : "";
      return DashboardLinksService.csBlockRefundAndCredit(
        reference?.referenceNumber,
        status,
        rejReasonCd
      )
        .then((res) => {
          clearStateAndRefetch();
          setBlockBox(false);
        })
        .catch((err) => console.log(err));
    }
  };

  const rejectedFix = () => {
    return (
      reference.rejection?.rejectionCode === 'R0000-922-01' &&
      reference.rejectionFixedOn !== null
    );
  };

  useEffect(() => {
    setShowRetransmit(REJECTED_STATUSES.includes(reference.status));
    setIsSaveDisabled(rejectState === "Blocked and Credit Initiated" && creditReason === " ");
  }, [rejectState, creditReason, reference]);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="Header Details">
        <TableBody>
          <TableRow>
            <TableCell
              component="th"
              scope="column"
              style={{ fontWeight: "bold" }}
            >
              Reference/Confirmation No.
            </TableCell>
            <TableCell>{reference && reference.referenceNumber}</TableCell>

            <TableCell
              component="th"
              scope="column"
              style={{ fontWeight: "bold" }}
            >
              Form Type
            </TableCell>
            <TableCell>
              {reference && reference.filingType}
              {reference?.form2290Model?.amendmentCategory &&
                reference?.form2290Model?.isAmendment &&
                `- ${reference?.form2290Model?.amendmentCategory}`}
              {(reference?.status === referenceStatus.SR || reference?.status === referenceStatus.SB || reference?.status === referenceStatus.US || reference?.status ===referenceStatus.PS) && (
                <>
                  <TextButton onClick={() => setBlockBox(true)} disableRipple>
                    Stop Processing
                  </TextButton>
                  <Dialog open={blockBox} onClose={closeBlockBox}>
                    <DialogTitle style={{ fontWeight: 'bold' , fontSize:'18px' }}>
                      Block Reference
                    </DialogTitle>
                    <DialogContent style={{width:'305px'}}>
                      <DialogContentText>
                        {/* <div
                          style={{
                            marginBottom: "16px",
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                        >
                          {rejectState}
                        </div> */}
                        <InputLabel
                          id="stateName-label"
                          style={{ marginBottom: "10px" , fontWeight: "bold" , fontSize:'14px' , color: 'black'}}
                        >
                          Please Select from below
                        </InputLabel>
                        <Select
                          labelId="stateName-label"
                          id="stateName"
                          style={{fontSize:'12px', width: '307px', height: '40px' ,fontWeight: rejectState==="Blocked" ? 'normal' : 'bold'}}
                          value={rejectState}
                          onChange={(e) => {
                            setRejectState(e.target.value);
                          }}
                        >
                          {PROCESS_STATE_LIST.map((state, index) => (
                            <MenuItem style={{fontWeight:'bold'}} key={index} value={state}>
                              {state}
                            </MenuItem>
                          ))}
                        </Select>
                        {rejectState === "Blocked and Credit Initiated" && (
                            <div style={{
                              marginBottom: "16px",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}>
                              {/* Additional dropdown or content specific to "Blocked and Credit Initiated" state */}
                              <InputLabel
                                id="reasonDropdown-label"
                                style={{ marginTop: "20px" , marginBottom: "10px" , fontWeight: "bold" , fontSize:'14px' , color: 'black' }}
                              >
                                Reason
                              </InputLabel>
                              <Select
                                labelId="reasonDropdown-label"
                                id="reasonDropdown"
                                value={creditReason}
                                style={{fontSize:'12px', width: '307px', height: '40px', fontWeight: creditReason===" " ? 'normal' : 'bold'}}
                                onChange={(e) => {
                                  setCreditReason(e.target.value);
                                }}
                              >
                                {/* Placeholder */}
                                <MenuItem value=" ">
                                  Select Reason
                                </MenuItem>
                                {/* Other options */}
                                {CREADIT_REASON_LIST.map((option, index) => (
                                  <MenuItem style={{fontWeight:'bold'}} key={index} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>
                            )}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions >
                      <Button disabled={isSaveDisabled} style={{height:'30px' , width:'100px', margin:'0px 0 16px 5px', fontSize: '12px'}} color="primary" variant="contained" onClick={saveRejectState}>Save</Button>
                      <Button style={{fontWeight :'bold' ,height:'30px' , width:'100px', margin:'0px 0 10px 5px' ,fontSize: '12px'}}onClick={closeBlockBox}>Close</Button>                    
                    </DialogActions>
                  </Dialog>
                </>
              )}
            </TableCell>

            <TableCell
              component="th"
              scope="column"
              style={{ fontWeight: "bold" }}
            >
              <FlexContainer>Status</FlexContainer>
            </TableCell>
            <TableCell>
              <FlexContainer>
                {reference && reference?.status === "Silvermine Rejected"
                  ? "Rejected by the IRS"
                  : reference?.status}
                {reference?.rejection && (
                  <div>
                    {reference.rejection?.rejectionCode}
                    {reference.rejection?.rejectionCode === 'R0000-922-01' && reference.rejectionFixedOn !== null ? ", EIN recently assigned" : "" }
                  </div>
                )}
                {reference?.rejection && (
                  <>
                    <TextButton onClick={() => setOpen(true)} disableRipple>
                      Rejection message 
                    </TextButton>
                    {reference.rejection?.rejectionCode === 'R0000-922-01' && reference.rejectionFixedOn !== null ? (
                        <Dialog open={open} onClose={handleClose}>
                          <DialogTitle style={{fontSize: "14px"}}>Rejection Message</DialogTitle>
                          <Table style={{border: '1px solid black', padding: '10px'}}>
                            <TableBody>
                              <TableRow>
                                <TableCell style={{border: '1px solid black',fontSize: "12px"}}>Original Issue</TableCell>
                                <TableCell style={{border: '1px solid black',fontSize: "12px"}}>{reference.rejection?.rejectionMessage}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell style={{border: '1px solid black',fontSize: "12px"}}>Update</TableCell>
                                <TableCell style={{border: '1px solid black',fontSize: "12px"}}>{reference.rejectionUpdate}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell style={{border: '1px solid black',fontSize: "12px"}}>Filing Fixed On</TableCell>
                                <TableCell style={{border: '1px solid black',fontSize: "12px"}}>{reference.rejectionFixedOn}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                          <DialogActions>
                            <TextButton onClick={handleClose}>Close</TextButton>
                          </DialogActions>
                        </Dialog>
                      ) : (
                        <Dialog open={open} onClose={handleClose}>
                          <DialogTitle>Rejection Message</DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              {reference?.rejectionMsg || ""}
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <TextButton onClick={handleClose}>Close</TextButton>
                          </DialogActions>
                        </Dialog>
                      )}
                  </>
                )}
              </FlexContainer>
            </TableCell>

            <TableCell
              component="th"
              scope="column"
              style={{ fontWeight: "bold" }}
            >
              <FlexContainer>
                <div>Submitted on</div>
                {showRetransmit && (
                  <>
                    <TextButton onClick={reTransmit} disableRipple>
                      Retransmit
                    </TextButton>
                    <Snackbar
                      open={showAlert}
                      autoHideDuration={6000}
                      onClose={handleAlertClose}
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    >
                      <Alert
                        onClose={handleAlertClose}
                        severity={
                          reTransmitStatus === 200 ? "success" : "error"
                        }
                        sx={{ width: "100%" }}
                      >
                        {reTransmitStatus === 200
                          ? "Retransmit success"
                          : "Retransmit failed. Please try again."}
                      </Alert>
                    </Snackbar>
                  </>
                )}
              </FlexContainer>
            </TableCell>
            <TableCell>
              {reference &&
                reference.submittedOn &&
                formatDate(reference.submittedOn)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
