import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Dialog,
  DialogContent,
  TextField,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import DashboardService from "../../Services/dashboard.service";
import { useAppDispatch } from "../../Store";

import EditIcon from "@mui/icons-material/Edit";
import {
  SET_ERROR_MESSAGE,
  IS_LOADING,
  STOP_LOADING,
} from "../../Actions/types";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect, useState } from "react";

export default function ThirdPartyDesignee({
  tpd,
  formType,
  businessSubmissionId,
  clearStateAndRefetch,
}: any) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const [errorState, setErrorState] = useState<any>({});
  const [masterRecordUpdate, setMasterRecordUpdate] = useState<any>(false);

  const methods = useForm({});
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    setValue("tpdDesignee", tpd.tpdName);
    setValue("tpdPin", tpd.tpdPin);
    setValue("tpdPhone", tpd.tpdPhone);
    setValue("remarks", tpd?.remarks);
  }, [tpd]);

  const handleOpen = () => {
    setErrorState({});
    setOpen(true);
  };

  const handleClose = () => {
    setErrorState({});
    setOpen(false);
  };

  const onSubmit = (data: any) => {
    return DashboardService.csListTPDUpdate({
      ...data,
      formType,
      businessSubmissionId,
      masterRecordUpdate,
    })
      .then((response) => {
        dispatch({ type: IS_LOADING });
        clearStateAndRefetch();
        setOpen(false);
      })
      .catch((error) => {
        console.log("errrrr", error?.response?.data?.errors);
        setErrorState((prev: any) => ({
          ...prev,
          ...error?.response?.data?.errors,
        }));
      });
  };
  return (
    <TableContainer component={Paper}>
      <Table aria-label="Third Party Designee" size="small">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              TP Designee
            </TableCell>
            <TableCell>{tpd.tpdName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              TPD PIN
            </TableCell>
            <TableCell>{tpd.tpdPin}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              TPD Phone
            </TableCell>
            <TableCell>
              {tpd.tpdPhone}
              {tpd?.status !== "Retrieved Schedule 1" && tpd?.status !== 'User Submitted' && (
                <IconButton color="primary" aria-label="Edit">
                  <EditIcon onClick={handleOpen} />
                </IconButton>
              )}
              <Button size="small"></Button>
              <Dialog open={open} onClose={handleClose}>
                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle>Edit Thid Party Assignee</DialogTitle>
                    <DialogContent>
                      <TextField
                        {...(errors.tpdDesignee && {
                          helperText: errors.tpdDesignee.message as string,
                        })}
                        {...(errorState?.designee && {
                          helperText: errorState?.designee[0] as string,
                        })}
                        {...(errors?.tpdDesignee || errorState?.designee
                          ? { error: true }
                          : {})}
                        {...register("tpdDesignee", {
                          required: "TP Designee is required",
                        })}
                        margin="dense"
                        id="tpdDesignee"
                        label="TP Designee"
                        type="text"
                        fullWidth
                        variant="standard"
                      />
                      <TextField
                        {...(errors.tpdPin && {
                          helperText: errors.tpdPin.message as string,
                        })}
                        {...(errorState?.pin && {
                          helperText: errorState?.pin[0] as string,
                        })}
                        {...(errors?.tpdPin || errorState?.pin
                          ? { error: true }
                          : {})}
                        {...register("tpdPin", {
                          required: "TPD PIN is required",
                          pattern: {
                            value: /^\d{5}$/g,
                            message: "Please enter a TPD Pin",
                          },
                        })}
                        margin="dense"
                        id="tpdPin"
                        label="TPD PIN"
                        type="text"
                        fullWidth
                        inputProps={{ maxLength: 5, minLength: 5 }}
                        variant="standard"
                      />
                      <TextField
                        {...(errors.tpdPhone && {
                          helperText: errors.tpdPhone.message as string,
                        })}
                        {...(errorState?.phone && {
                          helperText: errorState?.phone[0] as string,
                        })}
                        {...(errors?.tpdPhone || errorState?.phone
                          ? { error: true }
                          : {})}
                        {...register("tpdPhone", {
                          required: "TPD Phone is required",
                          pattern: {
                            value: /^[1-9]{1}[0-9]{9}$/g,
                            message: "Please enter a valid phone number",
                          },
                        })}
                        margin="dense"
                        id="tpdPhone"
                        label="TPD Phone"
                        type="text"
                        fullWidth
                        variant="standard"
                      />
                      <TextField
                        {...(errors.remarks && {
                          helperText: errors.remarks.message as string,
                        })}
                        {...(errors?.remarks ? { error: true } : {})}
                        {...register("remarks", {
                          required: "Remark is required",
                        })}
                        {...(errors?.remarks ? { error: true } : {})}
                        margin="dense"
                        id="remarks"
                        label="Remark"
                        type="text"
                        fullWidth
                        variant="standard"
                        multiline
                      />
                    </DialogContent>
                    <DialogActions>
                      <FormControlLabel
                        control={<Checkbox value="true" color="primary" />}
                        label="Also update master business record"
                        id="masterRecordUpdate"
                        onChange={(e: any) => {
                          setMasterRecordUpdate(e.target.checked);
                        }}
                      />
                      <Button onClick={handleClose}>Cancel</Button>
                      <Button type="submit">Save</Button>
                    </DialogActions>
                  </form>
                </FormProvider>
              </Dialog>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
