import React, {useEffect, useState} from "react";
import ClosedCasesFilterForm from './Components/ClosedCasesFilterForm';
import ClosedCasesTable from "./Components/ClosedCasesTable";
import dayjs from "dayjs";
import {styled, Box, Typography} from "@mui/material";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined';
import {getAgentsList, getCustomerInFunnelReports} from "../../Services/reports.service";
import Agent from '../../Utils/Models/Agent';

export interface ClosedCase {
  id: number;
  user: string;
  email: string;
  mobileNumber: string;
  assignedTo: string;
  wasConversion: boolean;
  referenceNumber: string;
  filingInitiated: Date;
  filingCompleted: Date;
  disposition: string;
  agentsLog: string;
}

function createClosedCase(
  id: number,
  user: string,
  email: string,
  mobileNumber: string,
  assignedTo: string,
  wasConversion: boolean,
  referenceNumber: string,
  filingInitiated: Date ,
  filingCompleted: Date,
  disposition: string,
  agentsLog: string,
): ClosedCase {
  return {
    id,
    user,
    email,
    mobileNumber,
    assignedTo,
    wasConversion,
    referenceNumber,
    filingInitiated:filingInitiated,
    filingCompleted: filingCompleted,
    disposition,
    agentsLog,
  };
}

interface Filters {
  conversion: boolean | null;
  email: string | null;
  disposition: string | null;
  assignedTo: string | null;
}

let databaseClosedCases: ClosedCase[] = [];

export default function ClosedCases() {

  // Data from API
  const [closedCasesFetched, setClosedCasesFetched] = useState<boolean>(false);
  const [agentsList, setAgentsList] = useState<Agent[]>([]);
  const [emailsList, setEmailsList] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  const [filteredClosedCases, setFilteredClosedCases] = useState<ClosedCase[]>([]);
  const [filters, setFilters] = useState<Filters>({
    conversion: null,
    email: null,
    disposition: null,
    assignedTo: null,
  });

  async function onSearchPressed(fromDate: Date, toDate: Date , clearFilters: boolean) {
    setStartDate(dayjs(fromDate).format('YYYY-MM-DD'));
    setEndDate(dayjs(toDate).format('YYYY-MM-DD'));
    databaseClosedCases = await fetchAPICustomerInFunnelReports(fromDate, toDate);
    setClosedCasesFetched(true);

    // Fill the emails list
    const emails = databaseClosedCases.map(row => row.email);
    setEmailsList(emails.filter((email, index) => emails.indexOf(email) === index));
    if(clearFilters)
    {
        setFilters({
          conversion: null,
          email: null,
          disposition: null,
          assignedTo: null,
        })
        setFilteredClosedCases(databaseClosedCases)
    }
    else
       applyFilters(filters);
  }

  function onFilterByConversion(wasConversion: string) {
    const filterValue = wasConversion === 'Yes' ? true : wasConversion === 'No' ? false : null;
    setFilters(prevFilters => ({...prevFilters, conversion: filterValue}));
    applyFilters({...filters, conversion: filterValue});
  }

  function onFilterByEmail(email: string) {
    const filterValue = email === 'none' ? null : email;
    setFilters(prevFilters => ({...prevFilters, email}));
    applyFilters({...filters, email: filterValue});
  }

  function onFilterByDisposition(disposition: string) {
    const filterValue = disposition === 'none' ? null : disposition;
    setFilters(prevFilters => ({...prevFilters, disposition: filterValue}));
    applyFilters({...filters, disposition: filterValue});
  }

  function onFilterByAssignedTo(assignedTo: string) {
    const filterValue = assignedTo === 'none' ? null : assignedTo;
    setFilters(prevFilters => ({...prevFilters, assignedTo: filterValue}));
    applyFilters({...filters, assignedTo: filterValue});
  }

  function applyFilters(newFilters: typeof filters) {

    let filteredCases = databaseClosedCases;

    if (newFilters.conversion !== null) {
      filteredCases = filteredCases.filter(row => row.wasConversion === newFilters.conversion);
    }

    if (newFilters.email) {
      filteredCases = filteredCases.filter(row => row.email === newFilters.email);
    }

    if (newFilters.disposition) {
      filteredCases = filteredCases.filter(row => row.disposition === newFilters.disposition);
    }

    if (newFilters.assignedTo) {
      filteredCases = filteredCases.filter(row => row.assignedTo === newFilters.assignedTo);
    }

    setFilteredClosedCases(filteredCases);
  }

  const CustomizedEmptyBox = styled(Box)(() => ({
    border: '1px solid #707070',
    borderRadius: '4px',
    height: 450,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#f4f4f4',
    margin: '24px 66px 24px 50px'
  }));

  async function fetchAPICustomerInFunnelReports(fromDate: Date, toDate: Date): Promise<ClosedCase[]> {
    const response = await getCustomerInFunnelReports(dayjs(fromDate).format('YYYY-MM-DD'), dayjs(toDate).format('YYYY-MM-DD'), 'CLOSED');
    if (response.error) {
      console.error(response.error);
      return [];
    }

    const closedCases: ClosedCase[] = [];
    for (const closedCase of response.data) {
      let executiveName = '';
      if (closedCase.executivesList && closedCase.executivesList.length > 0) {
        executiveName = closedCase.executivesList[0].name;
      }
      closedCases.push(createClosedCase(
        closedCase.id,
        closedCase.fullName,
        closedCase.email,
        closedCase.phone,
        executiveName,
        closedCase.isConverted,
        closedCase.refNo,
        closedCase.filingIntiatedAtIst,
        closedCase.filingCompletedAtIst,
        closedCase.disposition,
        closedCase.agentLog
      ));
    }
    return closedCases;
  }

  useEffect(() => {
    getAgentsList().then((resp) => {
      if (resp.data) {
        const sortedAgents = resp.data.sort((a: { name: string; }, b: { name: string; }) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        return nameA.localeCompare(nameB);
      });
        setAgentsList(sortedAgents);
      }
    });
  }, []);

  return <>
    <ClosedCasesFilterForm
      onFilterByConversion={onFilterByConversion}
      onSearchPressed={onSearchPressed}
      onFilterByEmail={onFilterByEmail}
      onFilterByDisposition={onFilterByDisposition}
      onFilterByAssignedTo={onFilterByAssignedTo}
      agentsList={agentsList}
      closedCases={databaseClosedCases}
    />
    {!closedCasesFetched ? (
      <CustomizedEmptyBox mt={3}>
        <CalendarMonthOutlinedIcon sx={{color: '#c7c5c5', fontSize: 130}}/>
        <Typography mt={1}>Select the time frame to search for the closed cases.</Typography>
      </CustomizedEmptyBox>
    ) : filteredClosedCases.length === 0 ? (
      <CustomizedEmptyBox mt={3}>
        <DoNotDisturbAltOutlinedIcon sx={{color: '#c7c5c5', fontSize: 130}}/>
        <Typography mt={1}>No closed cases found in the selected time frame.</Typography>
      </CustomizedEmptyBox>
    ) : (
      <div style={{margin: '24px 66px 24px 50px'}}>
        <ClosedCasesTable 
          closedCases={filteredClosedCases}
          agentsList={agentsList}
          fromDate ={startDate}
          toDate={endDate}
        />
      </div>
    )}
  </>;
}
