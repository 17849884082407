import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";

import { DASHES } from "../../Utils/Constants";

export default function TaxDetails({ taxDetails }: { taxDetails: any }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="Profile Details" size="small">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Tax Amount
            </TableCell>
            <TableCell>{taxDetails && taxDetails.taxAmount.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Additional Tax Amount
            </TableCell>
            <TableCell>{taxDetails && taxDetails.additionalAmount.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Credit Amount
            </TableCell>
            <TableCell>{taxDetails && taxDetails.creditAmount.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Total Tax
            </TableCell>
            <TableCell>
              {taxDetails &&
                (taxDetails.taxAmount +
                  taxDetails.additionalAmount -
                  taxDetails.creditAmount).toFixed(2)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Payment Mode
            </TableCell>
            <TableCell>
              {taxDetails && taxDetails.paymentMode
                ? taxDetails.paymentMode
                : DASHES}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
