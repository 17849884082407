import { DataGrid, GridColDef } from "@mui/x-data-grid";
import * as React from "react";
import { formatDate } from "../../Utils/Constants";

export default function NotSubmitted({
  notSubmittedList,
  isForm2290,
}: {
  notSubmittedList: any;
  isForm2290: boolean;
}) {
  const columns: GridColDef[] = [
    // {
    //   field: "vehicles",
    //   headerName: "Vehicles",
    //   width: 150,
    //   valueGetter: () => {
    //     return "VEHICLES";
    //   },
    // },
    {
      field: "formSubmittedDate",
      headerName: "Form Submitted Date",
      width: 150,
      valueGetter: (params: any) => {
        return formatDate(params?.row?.creationDate);
      },
    },
    {
      field: isForm2290 ? "firstUsedMonth" : "taxYearEndMonth",
      headerName: isForm2290 ? "First Used Month" : "Tax year Ending Month",
      width: 150,
    },
    {
      field: "taxPeriod",
      headerName: "Tax Period",
      width: 150,
    },
    {
      field: isForm2290 ? "taxAmount" : "claimAmount",
      headerName: "Tax Amount",
      width: 150,
    },

    { field: "isFinalReturn", headerName: "Final Return", width: 150 },
    {
      field: "phone",
      headerName: "Phone Number",
      width: 150,
      valueGetter: (params: any) => {
        return params?.row?.business?.signingAuthority?.phone;
      },
    },
    {
      field: "VIN",
      headerName: "VIN",
      width: 200,
      renderCell: (params: any) => {
        return isForm2290 ? (
          <div
            style={{ padding: "8px 0", maxHeight: "120px", overflow: "auto" }}
          >
            {params?.row?.vehicles?.map((vehicle: any, index: any) => (
              <div key={index}>{`${vehicle?.vin} (${vehicle?.category})`}</div>
            ))}
          </div>
        ) : (
          params?.row?.vehicles[0]?.vin
        );
      },
    },
    {
      field: "weightCurrent",
      headerName: "Gross Weight",
      width: 150,
      renderCell: (params: any) => {
        return isForm2290 ? (
          <div
            style={{ padding: "8px 0", maxHeight: "120px", overflow: "auto" }}
          >
            {params?.row?.vehicles?.map((vehicle: any, index: any) => (
              <div key={index}>{`${vehicle?.weightCurrent}`}</div>
            ))}
          </div>
        ) : (
          params?.row?.vehicles[0]?.weightCurrent
        );
      },
    },
    {
      field: "isLoggingNew",
      headerName: "Logging",
      width: 150,
      renderCell: (params: any) => {
        return isForm2290 ? (
          <div
            style={{ padding: "8px 0", maxHeight: "120px", overflow: "auto" }}
          >
            {params?.row?.vehicles?.map((vehicle: any, index: any) => (
              <div key={index}>{`${vehicle?.isLoggingNew ? 'YES' : 'No'}`}</div>
            ))}
          </div>
        ) : (
          params?.row?.vehicles[0]?.weightCurrent
        );
      },
    },
    {
      field: "EIN",
      headerName: "EIN",
      width: 150,
      valueGetter: (params: any) => {
        return params?.row?.business?.ein;
      },
    },
    { field: "businessName", headerName: "Business", width: 150 },
    {
      field: "isAddressChanged",
      headerName: "Address changed",
      width: 150,
      type: "boolean",
      valueGetter: (params: any) => {
        return params?.row?.business?.isAddressChanged;
      },
    },
    {
      field: "address",
      headerName: "Address",
      width: 250,
      valueGetter: (params: any) => {
        return `${
          params?.row?.business?.address?.addLine1 !== null
            ? params?.row?.business?.address?.addLine1
            : ""
        } ${
          params?.row?.business?.address?.addLine2 !== null
            ? params?.row?.business?.address?.addLine2
            : ""
        }`;
      },
    },
    // {
    //   field: "City",
    //   headerName: "City",
    //   width: 150,
    //   valueGetter: (params: any) => {
    //     return params?.row?.business?.address?.city;
    //   },
    // },
    // {
    //   field: "state",
    //   headerName: "State",
    //   width: 200,
    //   valueGetter: (params: any) => {
    //     return params.row.business.address.state.stateName;
    //   },
    // },
    // {
    //   field: "country",
    //   headerName: "Country",
    //   width: 150,
    //   valueGetter: (params: any) => {
    //     return params?.row?.business?.address?.country?.countryName;
    //   },
    // },
    {
      field: "zip",
      headerName: "Zip",
      width: 150,
      valueGetter: (params: any) => {
        return params?.row?.business?.address?.zip;
      },
    },
    {
      field: "saName",
      headerName: "SA Name",
      width: 200,
      valueGetter: (params: any) => {
        return params?.row?.business?.signingAuthority?.name;
      },
    },
    {
      field: "saTitle",
      headerName: "SA Title",
      width: 150,
      valueGetter: (params: any) => {
        return params?.row?.business?.signingAuthority?.title;
      },
    },
    {
      field: "saPin",
      headerName: "SA Pin",
      width: 150,
      valueGetter: (params: any) => {
        return params?.row?.business?.signingAuthority?.pin;
      },
    },
    {
      field: "payment option",
      headerName: "Payment Option",
      width: 150,
      valueGetter: (params: any) => {
        return params?.row?.paymentMode;
      },
    },
  ];

  return (
    <>
      <DataGrid
        getRowId={(row) => row.formKey}
        rows={
          isForm2290 ? notSubmittedList?.form2290 : notSubmittedList?.form8849
        }
        getRowHeight={() => "auto"}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        density="compact"
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 200 },
          },
        }}
      />
    </>
  );
}
