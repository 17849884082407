import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Input,
  Link,
  styled,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import DashboardLinkService from "../../Services/dashboard-links.service";
import AdminRefundTable from "../AdminRefundTable";
import NotSubmitted from "../NotSubmitted";
import store from "../../Store";
import EFilingCredits from "../EFilingCredits";
import SendFax from "../SendFax";
import SendScheduleEmail from "../SendScheduleEmail";
import { useEffect } from "react";
import SendScheduleSMS from "../SendScheduleSMS";


export default function FilingActions({
  reference,
  selectedRefId,
}: {
  reference: any;
  selectedRefId: any;
}) {
  const handleClose = () => setOpen(false);
  const handleNSClose = () => setNSOpen(false);
  const handleNSOpen = () => setNSOpen(true);
  const [open, setOpen] = React.useState(false);
  const [nsOpen, setNSOpen] = React.useState(false);
  const [openCreditDetails, setOpenCreditDetails] = React.useState(false);
  const [adminPendingList, setAdminPendingList] = React.useState([]);
  const [notSubmittedList, setNotSubmittedList] = React.useState([]);
  const [sendScheduleEmail, setSendScheduleEmail] = React.useState(false);
  const [sendScheduleSms, setSendScheduleSms] = React.useState(false);
  const [sendFax, setSendFax] = React.useState(false);
  const [creditAdded, setCreditAdded] = React.useState("");
  const [amountPending, setAmountPending] = React.useState("");
  const[creditedDetails,setCreditedDetails]=React.useState({});
  const[userIsAdmin,setIsUserIsAdmin]=React.useState(false);

  const handleCloseSMS = () => {
    setSendScheduleSms(false);
  };
  const handleCloseEmail = () => {
    setSendScheduleEmail(false);
  };
  
  useEffect(() => {
    const itemUser:any = localStorage.getItem("user") || {};
    if(JSON.parse(itemUser)?.data?.profile?.roles.includes('admin')){
      setIsUserIsAdmin(true)
    }else{
      setIsUserIsAdmin(false)
    }
  }, []);

  const handleOpenCreditDetails = () => {
    setOpenCreditDetails(true);
    // return DashboardLinkService?.csCreditDetails(
    //   store.getState()?.message?.message[0]?.user?.email?.toLowerCase()
    // )
    //   .then((data: any) => {
    //     setOpenCreditDetails(true);
    //     setAmountPending(data.data.amountPending);
    //     setCreditedDetails(data.data);
    //     console.log('asdasd2', data.data)
    //   })
    //   .catch(() => {
    //     setOpenCreditDetails(false);
    //   });
    // setOpenCreditDetails(val)
  };

  

  const handleSendEmail = () =>{
    setSendScheduleEmail(true);
   
  }

  const submitCredits = async (e: any) => {
    const payload = {
      amountPending: creditAdded,
      comments: "",
      userEmail: store.getState().message.message[0].user?.email?.toLowerCase(),
    };
     await DashboardLinkService.csAddDetails(payload).then((response) => {
      setAmountPending(response?.data.amount+parseFloat(amountPending));
      setCreditAdded("");
    });
    const customEvent = new CustomEvent('updateTransactionTable');
    document.dispatchEvent(customEvent);
  };
  const handleAdminPending = () => {
    return DashboardLinkService.csAdminRefundPendingList()
      .then((data: any) => {
        setOpen(true);
        setAdminPendingList(data.data);
      })
      .catch(() => {
        setOpen(false);
      });
  };

  const handleNotSubmittedForms = () => {
    const userKey = store.getState().message.message[0].user.userKey;
    return DashboardLinkService.csNotSubmittedFormsList(userKey)
      .then((data: any) => {
        if(data?.status === 400){
          throw new Error(data?.message);
        }
        handleNSOpen();
        setNotSubmittedList(data.data); //csNotSubmittedFormsList
      })
      .catch((error) => {
        console.log("error", error)
        handleNSClose();
      });
  };

  const handleSendFax = () => {
    setSendFax(true);
  };

  const handleDownloadFiles = (docType: string) => {
    if(docType == "COPY_REFERENCE") {
      return DashboardLinkService.csDownloadRefCopy(reference)
      .then((data: any) => {
        downloadFile(data);
      })
      .catch((error: any) => {
        console.log("download files", error);
      });
    } else {
    return DashboardLinkService.csDownloadFiles(reference, docType)
      .then((data: any) => {
        downloadFile(data);
      })
      .catch((error: any) => {
        console.log("download files", error);
      });
    }
  };
  const paymentInvoiceDownload = () => {
    return DashboardLinkService.piDownload(reference);
  }
  const downloadFile = (response: any) => {
    const file = new Blob([response], { type: "application/pdf" });
    const fileURL = window.URL.createObjectURL(file);
    const a = document.createElement("a");
    a.target = "_blank";
    document.body.appendChild(a);
    a.href = fileURL;
    // if (fileName) {
    //   a.download = fileName;
    // }
    a.click();
    // document.body.removeChild(a);
  };

  const [updatedRefundData, setUpdatedRefundData] = React.useState({
    referenceNumber: "",
    formKey: "",
    customerEmail: "",
    refundAmount: "",
    refundTransactionId: "",
    refundReason: "",
    refundStatus: "",
    comments: "",
    refund_id: "",
    invoice_number:"",
  });
  const saveChangesHandler = () => {
    setOpen(false);
    if(updatedRefundData?.refundStatus == "Approved"){
      return DashboardLinkService.csStatusUpdate(
        {
          invoice_number: updatedRefundData.invoice_number,
          refund_id: updatedRefundData.refund_id,
          refund_amount: updatedRefundData.refundAmount,
        },
        "Approved"
      ).then((res) => {
        if(res.status == 200){
          // handleAdminPending();
        }
      });
    }
    else{
      return DashboardLinkService.csRefundUpdate(
        updatedRefundData,
        updatedRefundData.refund_id
    );
    }
  };
  const closeHandler = () => {
    setOpen(false);
  };
  const scheduleText =
    selectedRefId?.form8849 && typeof selectedRefId?.form8849 === "object"
      ? 6
      : 1;

 const cellTextStyle: React.CSSProperties = {
    cursor: "pointer",
    textDecoration: "none",
    border: 'solid 1px #1876d1',
    borderRadius: "4px",
    padding: "8px 4px",
    background: "#fff",
    textAlign: "center",
    display: "block",
  };

  const scheduleStyle : React.CSSProperties = {
    fontSize: '21px',
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'center',
  }

 const menuItemStyle = {
    fontSize: '14px',
  };
  
const CommonStyledTable = styled(Table)({
  "& .MuiTableCell-root": {
    padding: '6px 10px',
    border:'none'
    },
  });
const option=`Schedule ${scheduleText}`;

  return (
    <div>
    <TableContainer component={Paper}>
      <CommonStyledTable aria-label="Filing Actions" size="small">
        <TableBody>
          <TableRow style={{ border: 'none' }}>
            <TableCell style={{ padding:'6px 10px' }}>
              <Link
                style={{...cellTextStyle, width: "90px" }}
                onClick={handleOpenCreditDetails}
              >
                Credit Details
              </Link>

              <Dialog fullWidth maxWidth="md" 
                open={openCreditDetails}
                onClose={() => setOpenCreditDetails(false)}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <DialogTitle style={{fontSize: '18px', fontWeight:'bold'}}>Credit Details</DialogTitle>
                <DialogActions>
                  <Button style={{fontSize: '15px', fontWeight:'bold'}} onClick={() => setOpenCreditDetails(false)}>
                    Close
                  </Button>
                </DialogActions>
                </div>
                <DialogTitle style={{fontSize: '16px', fontWeight:'bold', margin:'-25px 0 -5px 0px'}}>
                  Available e-Filing Credits  :{" "}
                  <span style={{ color: '#358f80' }}>
                  $ {amountPending}
                  </span>
                </DialogTitle>              
                <DialogContent >
                  <EFilingCredits amount={creditedDetails} setAmountPending={setAmountPending} amountPending={amountPending} setOpenCreditDetails={setOpenCreditDetails} openCreditDetails={openCreditDetails}/>
                </DialogContent>
                {
                 userIsAdmin ?
                  <DialogActions>
                  <Input
                      name="credit-amount"
                      value={creditAdded}
                      placeholder="Enter Credits"
                      fullWidth
                      onChange={(e:any) => setCreditAdded(e.target.value)}
                      style={{ width :'180px',height:'40px' ,margin: '16px', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }}
                    />
                  <Button style={{margin: '16px'}} color="primary" variant="contained" onClick={submitCredits}>Add Credits</Button>
                </DialogActions>
                :

                <DialogActions></DialogActions>
                }
               
              </Dialog>
            </TableCell>
            <TableCell>
              <Link
                style={{...cellTextStyle, width: "140px" }}
                onClick={handleNotSubmittedForms}
              >
                Not Submitted Forms
              </Link>
              <Dialog
                fullWidth
                maxWidth="md"
                open={nsOpen}
                onClose={handleNSClose}
              >
                <DialogTitle>Not Submitted</DialogTitle>
                <DialogContent style={{ height: "700px" }}>
                  form 2290
                  <NotSubmitted
                    notSubmittedList={notSubmittedList}
                    isForm2290={true}
                  />
                  <br />
                  form 8849
                  <NotSubmitted
                    notSubmittedList={notSubmittedList}
                    isForm2290={false}
                  />
                </DialogContent>
                <DialogActions></DialogActions>
              </Dialog>
            </TableCell>
            <TableCell>
              <Link style={{...cellTextStyle, width: "100px" }} onClick={handleAdminPending}>
                Admin refund
              </Link>
              <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
                <DialogTitle>Pending Refunds</DialogTitle>
                <DialogContent style={{ height: "600px" }}>
                  <AdminRefundTable
                    adminPendingList={adminPendingList}
                    handleAdminPending={handleAdminPending}
                    setUpdatedRefundData={setUpdatedRefundData}
                  />
                </DialogContent>
                <DialogActions>
                  <div
                    style={{
                      display: "flex",
                      gap: "40px",
                      padding: "0px 40px 12px",
                    }}
                  >
                    <Button onClick={saveChangesHandler}>Save</Button>
                    <Button onClick={closeHandler}>Close</Button>
                  </div>
                </DialogActions>
              </Dialog>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link style={{...cellTextStyle, width: "90px" }} onClick={handleSendFax}>
                Send Fax
              </Link>

              <Dialog open={sendFax} onClose={() => setSendFax(false)}>
                <DialogTitle>Send Fax</DialogTitle>
                <DialogContent>
                  <SendFax />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setSendFax(false)}>Close</Button>
                </DialogActions>
              </Dialog>
            </TableCell>
            <TableCell>
              <Link
                style={{...cellTextStyle, width: "140px" }}
                onClick={() => handleDownloadFiles("COPY_REFERENCE")}
              >
                Download Ref Copy
              </Link>
            </TableCell>
            <TableCell>
            <Link
                style={{...cellTextStyle, width: "110px" }}
                onClick={() =>paymentInvoiceDownload()}
              >
                Generate Invoice
              </Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link style={{...cellTextStyle, width: "110px" }}>Executive Report</Link>
            </TableCell>
            <TableCell>
              <Link style={{...cellTextStyle, width: "145px" }}>Executive Report New</Link>
            </TableCell>
          </TableRow>
        </TableBody>
      </CommonStyledTable>
    </TableContainer>
    <TableContainer component={Paper}>
    <CommonStyledTable aria-label="Filing Actions" size="small">
      <TableBody>
        <TableRow>
          <TableCell>
          <FormControl variant="outlined" sx={{ width: '180px' }}>
            <Select
              sx={{
                height: '2.5rem',
                fontSize:'14px',
                color: '#1876d1',
                border: 'none',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#1876d1',
                  borderRadius: '4px',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#1876d1',
                  borderRadius: '4px',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderRadius: '4px', 
                },
                '& .MuiSvgIcon-root': {
                  color: '#1876d1',
                },
              }}
              autoWidth={false}
              value={option}
              onChange={(e) => {
                const selectedOption = e.target.value;
                if (selectedOption === `Download ${scheduleText}`) {
                  handleDownloadFiles(`SCH${scheduleText}`);
                }
                else if (selectedOption === 'SendEmail') {
                  handleSendEmail()
                }
                else if(selectedOption==='SendSMS'){
                  setSendScheduleSms(true);
                }
              }}  
              MenuProps={{
                PaperProps: {
                  sx: {
                    border: '1px solid #1876d1', 
                    borderRadius: '4px',        
                  },
                },
              }}      
             >
              <MenuItem value={`Schedule ${scheduleText}`} style={{ display: 'none' }}>
                  Schedule {scheduleText}
                </MenuItem>
              <MenuItem  
                sx={{
                    borderBottom: '1px solid #ccc', 
                    color: '#1876d1'
                  }} 
                value={`Download ${scheduleText}`} style={menuItemStyle}>Download Schedule {scheduleText}</MenuItem>
              <MenuItem  
                sx={{
                  borderBottom: '1px solid #ccc', 
                  color: '#1876d1'
                }} 
                value="SendSMS" style={menuItemStyle}>Send as SMS</MenuItem>
              <MenuItem  sx={{
                  color: '#1876d1'
                }} 
                value="SendEmail" style={menuItemStyle}>Send through email</MenuItem>
            </Select>
            </FormControl>
          </TableCell>
        </TableRow>
      </TableBody>
    </CommonStyledTable>
  </TableContainer>
  {/* Dialog for Send through sms */}
  <Dialog 
      open={sendScheduleSms}
      onClose={() => setSendScheduleSms(false)}
    >
      <div  style={{ width: '500px'}}>
        <DialogTitle
            sx={scheduleStyle}
        >
            Send Schedule {scheduleText} SMS</DialogTitle>
        <DialogContent>
        <DialogContent style={{padding:'0px 0px 0px 0px'}}>
              <SendScheduleSMS
                emailId ={store.getState()?.message?.message?.[0]?.user?.email?.toLowerCase()}
                referenceNo={selectedRefId?.referenceNumber}
                userKey={store.getState()?.message?.message?.[0]?.user?.userKey}
                filingType={selectedRefId?.filingType}
                onClose={handleCloseSMS}
              />
        </DialogContent>
        </DialogContent>
      </div>
  </Dialog>
  {/* Dialog for Send through email */}
  <Dialog 
      open={sendScheduleEmail}
      onClose={() => setSendScheduleEmail(false)}
    >
      <div  style={{ width: '500px'}}>
        <DialogTitle
            sx={scheduleStyle} >
            Send Schedule {scheduleText} Email</DialogTitle>
        <DialogContent style={{padding:'10px 30px'}}>
              <SendScheduleEmail
                emailId ={store.getState()?.message?.message?.[0]?.user?.email?.toLowerCase()}
                referenceNo={selectedRefId?.referenceNumber}
                userKey={store.getState()?.message?.message?.[0]?.user?.userKey}
                filingType={selectedRefId?.filingType}
                onClose={handleCloseEmail}
              />
        </DialogContent>
      </div>
  </Dialog>
  </div>
  );
}
