import {
    Dialog,
    DialogContent,
    TextField,
    DialogTitle,
    DialogActions,
    Button,
  } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import DashboardService from "../../Services/dashboard.service";

const ChangePassword = ({open, handleClose, user} : any) => {
    const methods = useForm({});
    const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
      setError
    } = methods;

    const onSubmit = (data: any) => {
        console.log('Submit')

        console.log('data', data)
        if(data.password !== data.confirmPassword){
            setError('confirmPassword', { type: 'custom', message: 'Password and Confirm password should be same' });
        }else{
            const payload = {
                ...data,
                email : user?.email
            }
            return DashboardService.csChangePassword(payload)
                .then(() => {
                    reset()
                    closeModal()
            })
            .catch((e) => {
                console.log(e)
            });
        }
    };

    const closeModal = () => {
        reset()
        handleClose()
    }

    return(
        <Dialog open={open} onClose={closeModal}>
            <DialogTitle>Change Password</DialogTitle>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <TextField
                            {...(errors.password && {
                                helperText: errors.password.message as string,
                            })}
                            {...(errors?.password ? { error: true } : {})}
                            {...register("password", {
                                required: "Password is required",
                            })}
                            margin="dense"
                            label="Password"
                            type="password"
                            fullWidth
                            variant="standard"
                            inputProps={{
                                minLength : 6,
                                maxLength : 15
                            }}
                        />

                        <TextField
                            {...(errors.confirmPassword && {
                                helperText: errors.confirmPassword.message as string,
                            })}
                            {...(errors?.confirmPassword ? { error: true } : {})}
                            {...register("confirmPassword", {
                                required: "Confirm Password is required",
                            })}
                            margin="dense"
                            label="Confirm Password"
                            type="password"
                            fullWidth
                            variant="standard"
                            inputProps={{
                                minLength : 6,
                                maxLength : 15
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeModal}>Cancel</Button>
                        <Button type="submit">Save</Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    )
}

export default ChangePassword;