import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import EditIRSExplain from "./editIRSExplanation";
import { HEADINGS } from "./constants";

export default function TexableVehicleDetails({
  vehicleResponse,clearStateAndRefetch
}: {
  vehicleResponse: any;clearStateAndRefetch:any;
}) {
  const [open, setOpen] = React.useState(false);
  const [IRSExplain,setIRSExplain] = React.useState('');
  const [vin, setVin] = React.useState<string>('')
  const handleClose = () => setOpen(false);
  const viewExplaination = (value:string , vin: string) => {
    setIRSExplain(value)
    setVin(vin)
    setOpen(true);
  }

  return (
    <>
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>VIN</strong>
            </TableCell>
            <TableCell>
              <strong>Logging</strong>
            </TableCell>
            <TableCell>
              <strong>Weight</strong>
            </TableCell>
            <TableCell>
              <strong>Tax</strong>
            </TableCell>
            {vehicleResponse.form2290Model &&
            vehicleResponse.form2290Model.isVinCorrection && 
              <TableCell>
                <strong>{HEADINGS.explain}</strong>
              </TableCell>
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {vehicleResponse.form2290Model &&
            vehicleResponse.form2290Model.vehicleResponse &&
            vehicleResponse.form2290Model.vehicleResponse?.taxableVehicles?.map(
              (row: any) => (
                <TableRow
                  key={row.vin}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.vin}
                  </TableCell>
                  <TableCell align="left">
                    {row.isLoggingNew ? "Yes" : "No"}
                  </TableCell>
                  <TableCell align="left">{row.weightCurrent}</TableCell>
                  <TableCell align="left">{row.taxAmount.toFixed(2)}</TableCell>
                  {row.irsExplanation && 
                     <TableCell align="left"
                        style={{
                            color: '#1876d1',
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }} 
                          onClick={()=>{viewExplaination(row?.irsExplanation, row?.vin)}}>{HEADINGS.viewExpl}
                      </TableCell>
                  }
                </TableRow>
              )
            )}
        </TableBody>
      </Table>
    </TableContainer>
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ textAlign: 'center',fontSize: '21px' , fontFamily: 'unset' , marginTop:'4px'}}>{HEADINGS.explain}</DialogTitle>
        <DialogContent style={{padding:'0px 54px'}}>
        <EditIRSExplain 
            status={IRSExplain}
            vin={vin}
            vehicleType='T'
            formKey={vehicleResponse?.form2290Model?.formKey}
            filingStatus = {vehicleResponse?.status}
            onClose={handleClose}
            clearStateAndRefetch={clearStateAndRefetch}
        />
        </DialogContent>
    </Dialog>
    </>
  );
}
