import * as React from 'react';
import {useEffect, useState} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
  Stack,
  Button,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Backdrop,
  CircularProgress,
  FormControl,
  Autocomplete,
  TextField,
  Link
} from '@mui/material';
import {FirstPage as FirstPageIcon, KeyboardArrowLeft, KeyboardArrowRight, LastPage as LastPageIcon} from '@mui/icons-material';
import SendAsEmail from './sendAsEmail';
import { downloadFile, downloadReportAsExcel } from '../../../Services/reports.service';
import { UserData } from '../FollowUpRequests';
import { convertToMinutes, StatusEnum} from '../../../Utils/Constants';
import { handleAgentSelectionChange } from '../../../Utils/Constants/commonCustomerInFunnelFunction';
import SearchIcon from '@mui/icons-material/Search';
import { BlinkingTableRow , autocompleteStyles } from '../../../Utils/Constants/commonCssCustomerInFunnel';
import { useNavigate } from 'react-router-dom';
import { BUTTONS, CASE_STATUS, FollowUpTableCells, FUNNEL_ERRORS } from './constants';

export const styles = {
  paginationIcons: {
    borderRadius: '1px',
    backgroundColor: '#bddbf5',
    width: '28px',
    height: '28px',
    marginRight: '4px',
    color: '#1876d1'
  },
  sorting : {
    position: 'relative',cursor: 'pointer', minWidth:'78px'
  } as any,
  sortIcons : {
    position: 'absolute', right: '0px', top: '50%', marginLeft:'2px', transform: 'translate(-50%, -50%)', lineHeight: '0.8'
  } as any,
}
export default function FollowUpRequestsTable({closedCases ,agentList ,agentListEmail ,agentNameToIdMap ,agentSelectedIndex ,setAgentSelectedIndex ,   handleRefresh}: { closedCases: UserData[], agentList: any; agentListEmail :any; agentNameToIdMap :any; agentSelectedIndex: any ; setAgentSelectedIndex: any ; handleRefresh: () => void; }) {
  const ROWS_PER_PAGE = 10;
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [sendEmail, setSendEmail] = React.useState(false);  
  const [agentSelections, setAgentSelections] = useState<{ [key: number]: string }>({}); 
  const navigateTo = useNavigate();

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const CustomizedTableCell = styled(TableCell)(() => ({
    border: '1px solid white',
    fontWeight: 'bold',
    fontSize: '14px',
    whiteSpace: 'normal', 
    wordBreak: 'break-word', 
}));

  const handleCloseSMS = () =>{
    setSendEmail(false);
  }
  
  const downloadReport = async () => {
    setLoading(true);
    const response = await downloadReportAsExcel('', '', StatusEnum.FOLLOW_UP, []);
    if(response.data) {
      downloadFile(response.data)
      setLoading(false)
    }
    else {
      setLoading(false)
    }
  }

  const searchByEmailORReference = (value : any , searchtype: any) => {
    navigateTo('/dashboard', { state: { [searchtype]: value } });
  };

  const handleSelectionChange = (event: React.SyntheticEvent, newValue: string | null, id: number ,userDataItem : any) => {
    handleAgentSelectionChange(
        event,
        newValue,
        id,
        userDataItem,
        agentNameToIdMap,
        agentSelectedIndex,
        setAgentSelections,
        handleRefresh
    );
  };

  return (
    <>
    {loading && (
      <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
      >
          <CircularProgress color="inherit" />
      </Backdrop>
    )}
    <Box marginTop={3} marginBottom={3}>
      <TableContainer sx={{border: '1px solid #707070', borderRadius: '4px'}}>
        <Table>
          <TableHead sx={{backgroundColor: '#d9d9d9'}}>
            <TableRow>
              <CustomizedTableCell>{FollowUpTableCells.email}</CustomizedTableCell>
              <CustomizedTableCell>{FollowUpTableCells.mobileNumber}</CustomizedTableCell>
              <CustomizedTableCell>{FollowUpTableCells.agentContacted}</CustomizedTableCell>
              <CustomizedTableCell>{FollowUpTableCells.wasConversion} <span style={{whiteSpace: 'nowrap'}}>{FollowUpTableCells.conversion}</span></CustomizedTableCell>
              <CustomizedTableCell>{FollowUpTableCells.referenceNumber}</CustomizedTableCell>
              <CustomizedTableCell>
                  {FollowUpTableCells.contacted}<br />{FollowUpTableCells.onIST}
              </CustomizedTableCell>
              <CustomizedTableCell>{FollowUpTableCells.AgenComment}</CustomizedTableCell>
              <CustomizedTableCell style={{maxWidth:'50px'}}>{FollowUpTableCells.followUp}<br /><span style={{whiteSpace: 'nowrap'}}>{FollowUpTableCells.requestedOn}</span></CustomizedTableCell>
              <CustomizedTableCell>{FollowUpTableCells.assignedFollow}<br />{FollowUpTableCells.upTo}</CustomizedTableCell>
              <CustomizedTableCell  style={{minWidth:'52px'}}> {FollowUpTableCells.agent}<br />{FollowUpTableCells.status}</CustomizedTableCell>
            </TableRow>
          </TableHead>
          
          <TableBody>
            {closedCases
              .slice(page * ROWS_PER_PAGE, page * ROWS_PER_PAGE + ROWS_PER_PAGE)
              .map((closedCase,rowIndex) => (
                <BlinkingTableRow
                  key={closedCase.id}
                  style={{ backgroundColor: '#f4f4f4'  }}
                  className={closedCase.status === CASE_STATUS.Awaiting && parseInt(closedCase.duration)==0 ? 'blink' : ''}
                >
                  <CustomizedTableCell><Link style={{color: 'inherit', cursor: 'pointer'}}  onClick={() => searchByEmailORReference(closedCase.email, 'email')}>{closedCase?.email}</Link></CustomizedTableCell>
                  <CustomizedTableCell>{closedCase.mobileNumber}</CustomizedTableCell>
                  <CustomizedTableCell>{closedCase.agentContacted}</CustomizedTableCell>
                  <CustomizedTableCell>{closedCase.isConverted ? 'Yes' : 'No'}</CustomizedTableCell>
                  <CustomizedTableCell><Link style={{color: 'inherit', cursor: 'pointer'}}  onClick={() => searchByEmailORReference(closedCase.refNo, 'ref_no')}>{closedCase?.refNo}</Link></CustomizedTableCell>
                  <CustomizedTableCell>{closedCase.contactedAt}</CustomizedTableCell>
                  <CustomizedTableCell>{closedCase.agentLog?.toString()}</CustomizedTableCell>
                  <CustomizedTableCell>{closedCase.requestedAt} ({closedCase.timezone})</CustomizedTableCell>
                  <CustomizedTableCell >
                    <FormControl variant="outlined" sx={{ width: '155px' }}>    
                    <Autocomplete
                        options={agentList}
                        value={agentSelections[rowIndex] || closedCase.assignedTo || null} 
                        onChange={(event, newValue) => handleSelectionChange(event, newValue, rowIndex, closedCase)}
                        noOptionsText={<span style={autocompleteStyles.noOptionsText}>{FUNNEL_ERRORS.validAgent}</span>}
                        renderOption={(props, option) => (
                            <li {...props} style={autocompleteStyles.option}>
                              {option}
                            </li>
                          )}
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            placeholder={'Select Agent'}
                            sx={autocompleteStyles.textField}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                <React.Fragment>
                                    <SearchIcon sx={autocompleteStyles.searchIcon} />
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                                ),
                            }}
                            />
                        )}
                    />
                    </FormControl>
                  </CustomizedTableCell>
                  <CustomizedTableCell>
                    <span style={{whiteSpace: 'nowrap', }}> {closedCase.status} {closedCase.status === CASE_STATUS.Awaiting && (<span>{convertToMinutes(parseInt(closedCase.duration))}</span>)}</span>
                    {closedCase.status === CASE_STATUS.Awaiting && (
                        <span style={{ fontSize: '10px', display: 'block', color:'#1771c9' , textDecoration: 'underline', cursor: 'pointer',whiteSpace: 'nowrap', }} onClick={handleRefresh}>
                            {BUTTONS.refreshToUpdate}
                        </span>
                    )}
                </CustomizedTableCell>
                </BlinkingTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{marginTop: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center',}}>
        <div style={{margin: '0px 50px'}}></div>
        <Stack spacing={3} direction="row" justifyContent="center">
          <Button variant="contained" sx={{textTransform: 'none', width: '160px'}} onClick={() => downloadReport()}>{BUTTONS.exportExcel}</Button>
          <Button variant="contained" sx={{textTransform: 'none', width: '160px'}}  onClick={() => setSendEmail(true)}>{BUTTONS.senAsEmail}</Button>
        </Stack>
        <TablePagination
          component="div"
          count={closedCases.length}
          rowsPerPage={ROWS_PER_PAGE}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[]}
          ActionsComponent={(subProps) => {
            const {onPageChange, page} = subProps;
            const currentPage = page + 1; 
            const totalPages = Math.ceil(closedCases.length / ROWS_PER_PAGE);
            return (
              <div style={{display: 'flex', alignItems: 'center'}}>
                <IconButton style={styles.paginationIcons} onClick={() => onPageChange(null, 0)} disabled={page === 0} aria-label="first page">
                  <FirstPageIcon/>
                </IconButton>
                <IconButton style={styles.paginationIcons} onClick={() => onPageChange(null, page - 1)} disabled={page === 0} aria-label="previous page">
                  <KeyboardArrowLeft/>
                </IconButton>
                <span style={{whiteSpace: 'nowrap', margin: '0 8px'}}>{currentPage} of {totalPages}</span>
                <IconButton style={styles.paginationIcons} onClick={() => onPageChange(null, page + 1)} disabled={page >= Math.ceil(closedCases.length / ROWS_PER_PAGE) - 1}
                            aria-label="next page">
                  <KeyboardArrowRight/>
                </IconButton>
                <IconButton style={styles.paginationIcons} onClick={() => onPageChange(null, Math.ceil(closedCases.length / ROWS_PER_PAGE) - 1)}
                            disabled={page >= Math.ceil(closedCases.length / ROWS_PER_PAGE) - 1} aria-label="last page">
                  <LastPageIcon/>
                </IconButton>
              </div>
            );
          }}
          labelDisplayedRows={() => null}
        />
        <Dialog 
          open={sendEmail}
          onClose={() => setSendEmail(false)}>
          <div  style={{ width: '500px'}}>
            <DialogTitle sx={{ textAlign: 'center',fontSize: '21px', fontFamily: 'unset', marginTop:'4px' }}>{BUTTONS.sendReportOnEmail}</DialogTitle>
            <DialogContent>
            <DialogContent style={{padding:'0px 0px 0px 0px'}}>
                  <SendAsEmail
                    emailIds ={agentListEmail}
                    onClose={handleCloseSMS}
                    fromDate=''
                    toDate=''
                    status={StatusEnum.FOLLOW_UP}
                  />
            </DialogContent>
            </DialogContent>
          </div>
      </Dialog>
      </div>
    </Box>
    </>
  );
}

