import { Button, CircularProgress, InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import DashboardLinkService from "../../Services/dashboard-links.service";
import {BUTTONS, CONSTANTS ,ERRORMSG} from './constants';

const SendScheduleEmail = ({emailId ,referenceNo, userKey,filingType, onClose} :{ emailId: string; referenceNo: string; userKey: string;filingType:string; onClose: () => void }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [email, setEmail] = useState<string>(emailId);
    const [emailError, setEmailError] = useState<string>("");
    const [otpVerified , setOtpVerified] = useState<boolean>(false);
    const [otpError, setotpError] = useState<string>("");
    const [emailVerified, setEmailVerified] = useState<boolean>(false);
    const [otp, setOtp]=useState<string>('');
    const [scheduleText, setScheduleText] = useState<number>(1);
    const [isOTPSent,setIsOTPSent] =useState<boolean>(false);
    const [smsSent,setSmsSent] =useState<boolean>(false);
    const [smsSentSuccess,setSmsSentSuccess] =useState<boolean>(false);
    const [smsSentFailed,setSmsSentFailed] =useState<boolean>(false);
    const [alternateEmail, setAlternateEmail]=useState<boolean>(true);
    const [sentToAlternateEmail, setSendToAlternateEmail]=useState<boolean>(false);

    const smsSentStyle: React.CSSProperties = {
        width: '360px', 
        fontSize: '14px', 
        fontWeight: 600 ,
        textAlign:'center' ,
    };
    const okButton: React.CSSProperties = {
        width: '160px',
        height: '40px',
        marginBottom: smsSentFailed ? '30px': '10px',
        borderRadius: '4px',
        border: 'solid 1px #1876d1',
        backgroundColor: '#fff'
    };
    const textField: React.CSSProperties = {
        fontSize:'14px',
        fontWeight:600 , 
        marginTop:'10px'
    }; 
    const errorField: React.CSSProperties = {
        fontSize:'12px',
        color: 'red',
        marginTop: '5px'
    }; 
    const text: React.CSSProperties = {
        fontSize:'14px',
        fontWeight:'bold' ,
         marginTop:'10px',
    }
    const textFieldInside: React.CSSProperties = {
        width: '366px',
        padding:'8px 0px 2px 8px',
        borderRadius: '4px',
        backgroundColor: '#fff',
        border: '1px solid #707070', 
    }; 
    const loadingStyle: React.CSSProperties = {
        textAlign: 'center', 
        marginTop: '20px'
    };  
    const verified: React.CSSProperties = {
        color: '#006b89', 
        margin: ' 0px 5px 4px 5px', 
        fontSize: '14px', 
        fontWeight: 'bold'
    }; 
    const okButtonDiv: React.CSSProperties = {
        display: 'flex', 
        justifyContent: 'center' , 
        marginTop:'25px'
    }; 
    const buttonDiv: React.CSSProperties = {
        display: 'flex', 
        justifyContent: 'center', 
    }; 
    const button1: React.CSSProperties = {
        fontWeight: 500,
        height: '40px',
        width: '175px',
        margin: '20px 25px 10px 0px',
        fontSize: '15px',
        borderRadius: '4px',
        border: 'solid 1px #1876d1',
        marginBottom: sentToAlternateEmail ? '30px': '10px',
        textTransform: 'none'
    }; 
    const button2: React.CSSProperties = {
        fontWeight: 500,
        height: '40px',
        width: '175px',
        margin: '20px 15px 10px 0px',
        fontSize: '15px',
        marginBottom: (sentToAlternateEmail || (isOTPSent && !otpVerified)) ? '30px': '10px',
        textTransform: 'none'
    }; 
    const alternateButton: React.CSSProperties = {
        fontWeight: 500,
        height: '40px',
        width: '374px',
        margin: '10px 0px 20px 25px',
        fontSize: '15px',
        borderRadius: '4px',
        border: 'solid 1px #1876d1',
        textTransform: 'none'
    }; 
    
    const handleEmailChange = (value: string) => {
        let errorMsg = ERRORMSG.emailEmpty;
        if(value !== '') {
            errorMsg = ''
        }
        setEmailError(errorMsg)
        setEmail(value)
    }

    const handleOTPChange = (value :string) =>{
        let errorMsg = ERRORMSG.otpEmpty;
        if(value !== ''){
            errorMsg =''
        }
        setotpError(errorMsg);
        setOtp(value);
    }

    const sendOTP=()=>{
        const mailformat = /\S+@\S+\.\S+/;
        if(email === ''){
            setEmailError(ERRORMSG.emailEmpty);
            return false;
        }
        if(!email.match(mailformat)){
            setEmailError(ERRORMSG.emailInvalid);
            return false;
        }
        DashboardLinkService.csSendOTPEmail(email)
            .then((response: any) => {
                setIsOTPSent(true);
                setAlternateEmail(false);
            })
            .catch((error:any) => {
                setEmailError(error.message);
            });
    }
    
    const verifyOTP=()=>{
        let errorMsg = ERRORMSG.otpEmpty;
        if(otp !== ''){
            errorMsg =''
        }
        setotpError(errorMsg);
        DashboardLinkService.verifyOTPEmail(email,otp)
        .then((response: any) => {
            setOtpVerified(true)
            setAlternateEmail(true);
        })
        .catch((error:any) => {
            setotpError(ERRORMSG.otpInvalid);
        });
    }

    const sendSMS=()=> {
        setLoading(true)
        setSmsSent(true)
        DashboardLinkService.csSendScheduleEmail({
            referenceNumber: referenceNo,
            filingType,
            email,
        })
        .then((response: any) => {
            setSmsSentSuccess(true)
            setLoading(false); 

        })
        .catch(() => {
            setSmsSentFailed(true)
            setLoading(false); 
        });
    }
    const sendToAlternateEmail=()=>{
        setEmail('');
        setSendToAlternateEmail(true);
        setAlternateEmail(false);
    }

    const verifiedIcon = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="20.004" height="19.962" viewBox="0 0 20.004 19.962">
                <path data-name="Path 96676" d="M18.844 8.178q-.622-.6-1.246-1.213-.018-.846-.044-1.692a3.021 3.021 0 0 0-.91-2.035 3.019 3.019 0 0 0-2.038-.908q-.847-.026-1.695-.044-.611-.621-1.211-1.241a2.719 2.719 0 0 0-3.977 0q-.6.622-1.215 1.245-.847.017-1.695.044a3.122 3.122 0 0 0-2.948 2.941q-.026.846-.044 1.692Q1.2 7.578.575 8.18a2.706 2.706 0 0 0 0 3.965q.622.6 1.249 1.212.017.846.044 1.693a3.116 3.116 0 0 0 2.948 2.942q.847.027 1.695.044.611.622 1.214 1.242a2.723 2.723 0 0 0 3.98 0q.6-.621 1.214-1.244.847-.018 1.695-.044a3.122 3.122 0 0 0 2.948-2.942q.027-.846.044-1.691.624-.611 1.247-1.213a2.7 2.7 0 0 0-.006-3.966z" transform="translate(.289 -.18)" fill="#006b89" />
                <path data-name="Path 96677" d="M12.911 2.287Q12.3 1.665 11.7 1.045a2.719 2.719 0 0 0-3.977 0q-.6.622-1.215 1.245-.847.017-1.695.044a3.122 3.122 0 0 0-2.948 2.941q-.026.846-.044 1.692Q1.2 7.578.575 8.18a2.706 2.706 0 0 0 0 3.965q.622.6 1.249 1.212.015.731.039 1.462a10.938 10.938 0 0 0 2.083.2A10.873 10.873 0 0 0 14.818 4.146a10.941 10.941 0 0 0-.153-1.812h-.057q-.849-.026-1.7-.044z" transform="translate(.289 -.18)" fill="#006b89" />
                <path data-name="Path 96678" d="M128.589 161.873c-1.685 1.751-3.4 3.54-5.08 5.316a1.465 1.465 0 0 1-1.063.46h-.007a1.492 1.492 0 0 1-1.065-.457q-1.35-1.394-2.709-2.778a1.458 1.458 0 0 1 .03-2.085 1.5 1.5 0 0 1 2.107.026l1.617 1.66c1.336-1.4 2.679-2.809 4-4.2a1.483 1.483 0 0 1 2.1-.03 1.462 1.462 0 0 1 .07 2.085z" transform="translate(-113.617 -153.157)" fill="#fff" />
            </svg>
        );
      };

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await DashboardLinkService.csPhoneNumberVerified(emailId);
            const isverified = response?.data?.userResponseList[0]?.isEmailVerified;
            setEmail(response?.data?.userResponseList[0]?.email);
            if(isverified){
                setEmailVerified(true);
                setOtpVerified(true);
                setLoading(false); 
            } 
            else{
              setLoading(false); 
            }         
          } catch (error) {
            setLoading(false);
          } 
          
        };     
        fetchData();
        const scheduleText =
        filingType === "Form8849"
            ? 6
            : 1;
        setScheduleText(scheduleText)
        setIsOTPSent(false)
      }, []); 

  return (  
          <div> 
            {loading ? ( 
                <div style={loadingStyle}>
                <CircularProgress />
                </div>
            ) : (
                <div>      
                {smsSent ? (
                    <div>
                        {smsSentSuccess && ( <div style={{ ...smsSentStyle, marginLeft: '35px' }}>
                                Schedule {scheduleText} for reference number {referenceNo} sent on email {email} successfully!
                            </div> )}
                        {smsSentFailed && (<div style={{ ...smsSentStyle,width: '400px',marginLeft:'20px'}}>
                                Sending Schedule {scheduleText} for reference number {referenceNo} sent on email {email} failed! <br></br>Please try some other method.
                            </div> )}
                        <div style={okButtonDiv}> 
                            <Button
                                onClick={() => {
                                    onClose();
                                }}
                                style={okButton}
                            >
                                OK
                            </Button>
                        </div>
                    </div>
                ):(
                    <div>
                        <div style={{marginLeft:'25px'}}>
                            {!sentToAlternateEmail && (
                            <div>
                                <div style={textField}>{CONSTANTS.email}</div>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="faxno"
                                    value={email}
                                    onChange={(e) => {
                                        handleEmailChange(e.target.value);
                                    }}
                                    required
                                    variant="standard"
                                    error={emailError !== ''}
                                    sx={textFieldInside}
                                    InputProps={{
                                        endAdornment: (
                                            otpVerified && (
                                                <InputAdornment position="end">
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div>
                                                        {verifiedIcon()}
                                                        </div>
                                                    <span style={verified}>{CONSTANTS.verified}</span>
                                                    </div>
                                                </InputAdornment>
                                            )
                                        ),
                                        readOnly: true,
                                        placeholder:CONSTANTS.emailPlaceholder,
                                        disableUnderline: true ,
                                        style: { fontSize: '14px' },
                                    }} 
                                />
                                {emailError && <div style={errorField}>{emailError}</div>}
                                {isOTPSent && !otpVerified && ( <div style={textField}>{CONSTANTS.otp}</div> )}
                                {isOTPSent && !otpVerified && (
                                    <div>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            id="faxno"
                                            value={otp}
                                            onChange={(e) => {
                                                handleOTPChange(e.target.value);
                                            }}
                                            required
                                            variant="standard"
                                            sx={textFieldInside}
                                            InputProps={{
                                                inputProps: { maxLength: 6 },
                                                placeholder:CONSTANTS.otpPlaceholder,
                                                style: { fontSize: '14px' },
                                                disableUnderline: true 
                                            }}
                                            
                                        />
                                            {otpError && <div style={errorField}>{otpError}</div>}
                                    </div>                           
                                    )}
                             </div>
                            )}
                             {sentToAlternateEmail && (
                                <div>
                                    <div style={textField}>{CONSTANTS.alternateEmail}</div>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="faxno"
                                        type="email"
                                        value={email}
                                        onChange={(e) => {
                                            handleEmailChange(e.target.value);
                                        }}
                                        required
                                        variant="standard"
                                        sx={textFieldInside}
                                        InputProps={{
                                            placeholder:CONSTANTS.emailPlaceholder,
                                            style: { fontSize: '14px' },
                                            disableUnderline: true 
                                        }}
                                        
                                    />
                                    {emailError && <div style={errorField}>{emailError}</div>}
                                </div>                           
                             )}
                        </div>
                        {!isOTPSent && !otpVerified && (
                            <div style={{...text, marginLeft:'25px'}}>{CONSTANTS.verifyOTPEmail}</div>
                        )}
                        {otpVerified && !sentToAlternateEmail && (
                            <div style={{...text, marginLeft:'18px'}}>{CONSTANTS.verifiedEmail}</div>
                        )}                  
                    <div style={buttonDiv}>
                        <Button
                            onClick={() => {
                                onClose();
                            }}
                            style={button1}
                        >
                            {BUTTONS.cancel}
                        </Button>
                        {!isOTPSent && !emailVerified && !sentToAlternateEmail && (
                            <Button
                            style={button2}
                            color="primary"
                            variant="contained"
                            onClick={() => sendOTP()}
                            >
                           {BUTTONS.send}
                            </Button>
                            ) }
                            {isOTPSent && !otpVerified &&  (
                                <Button
                                style={button2}
                                color="primary"
                                variant="contained"
                                onClick={() => verifyOTP()}
                                >
                                {BUTTONS.verify}
                                </Button>
                                
                            )}
                            { (otpVerified || sentToAlternateEmail) && (
                                <Button
                                    style={button2}
                                    color="primary"
                                    variant="contained"
                                    onClick={() => sendSMS()}
                                    >
                                     {BUTTONS.sendEmail}
                                </Button>
                            )}
                    </div>
                        {alternateEmail && (
                        <Button
                                onClick={() => {
                                    sendToAlternateEmail();
                                }}
                                style={alternateButton}
                            >
                                {BUTTONS.alternateEmail}
                            </Button>
                    )}
                </div>
            )}
            </div> 
            )}
        </div>
    );
};

export default SendScheduleEmail;
