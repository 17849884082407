import dayjs from "dayjs";

export const API_ENDPOINT = process.env.REACT_APP_API_URL;
export const REPORT_API_ENDPOINT = process.env.REACT_APP_REPORT_API_URL;
export const CUSTOMER_IN_FUNNEL_LIST_ENV = process.env.REACT_APP_CUSTOMER_IN_FUNNEL_LIST_ENV
export const DASHES = "------";
export const REJECTED = "Rejected";
export const APPROVED = "Approved";
export const PROD_URL = 'https://api.eform2290.com/'
export const API_ENDPOINT_MCS = process.env.REACT_APP_MCS_API_URL;

export const getTableValue = (value: any) =>
  value && value !== undefined ? parseFloat(value).toFixed(2) : DASHES;

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const US_STATES_LIST = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AS", label: "American Samoa" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "AE", label: "Armed Forces (Europe,Canada,Middle East,Africa)" },
  { value: "AA", label: "Armed Forces Americas" },
  { value: "AP", label: "Armed Forces Pacific" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "DC", label: "District of Columbia" },
  { value: "FM", label: "Federated States of Micronesia" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "GU", label: "Guam" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PW", label: "Palau" },
  { value: "PA", label: "Pennsylvania" },
  { value: "PR", label: "Puerto Rico" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "VI", label: "U.S. Virgin Islands" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
];

export const CANADA_STATES_LIST = [
  {
    label: "Alberta",
    value: "AB",
  },
  {
    label: "British Columbia",
    value: "BC",
  },
  {
    label: "Manitoba",
    value: "MB",
  },
  {
    label: "New Brunswick",
    value: "NB",
  },
  {
    label: "Newfoundland and Labrador",
    value: "NL",
  },
  {
    label: "Northwest Territories",
    value: "NT",
  },
  {
    label: "Nova Scotia",
    value: "NS",
  },
  {
    label: "Nunavut",
    value: "NU",
  },
  {
    label: "Ontario",
    value: "ON",
  },
  {
    label: "Prince Edward Island",
    value: "PE",
  },
  {
    label: "Quebec",
    value: "QC",
  },
  {
    label: "Saskatchewan",
    value: "SK",
  },
  {
    label: "Yukon Territory",
    value: "YT",
  },
];

export const MEXICO_STATES_LIST = [
  {
    label: "Aguascalientes",
    value: "AG",
  },
  {
    label: "Baja California",
    value: "BC",
  },
  {
    label: "Baja California Sur",
    value: "BS",
  },
  {
    label: "Campeche",
    value: "CM",
  },
  {
    label: "Chiapas",
    value: "CS",
  },
  {
    label: "Chihuahua",
    value: "CH",
  },
  {
    label: "Coahuila",
    value: "CO",
  },
  {
    label: "Colima",
    value: "CL",
  },
  {
    label: "Mexico City",
    value: "DF",
  },
  {
    label: "Durango",
    value: "DG",
  },
  {
    label: "Guanajuato",
    value: "GT",
  },
  {
    label: "Guerrero",
    value: "GR",
  },
  {
    label: "Hidalgo",
    value: "HG",
  },
  {
    label: "Jalisco",
    value: "JA",
  },
  {
    label: "México",
    value: "EM",
  },
  {
    label: "Michoacán",
    value: "MI",
  },
  {
    label: "Morelos",
    value: "MO",
  },
  {
    label: "Nayarit",
    value: "NA",
  },
  {
    label: "Nuevo León",
    value: "NL",
  },
  {
    label: "Oaxaca",
    value: "OA",
  },
  {
    label: "Puebla",
    value: "PU",
  },
  {
    label: "Querétaro",
    value: "QT",
  },
  {
    label: "Quintana Roo",
    value: "QR",
  },
  {
    label: "San Luis Potosí",
    value: "SL",
  },
  {
    label: "Sinaloa",
    value: "SI",
  },
  {
    label: "Sonora",
    value: "SO",
  },
  {
    label: "Tabasco",
    value: "TB",
  },
  {
    label: "Tamaulipas",
    value: "TM",
  },
  {
    label: "Tlaxcala",
    value: "TL",
  },
  {
    label: "Veracruz",
    value: "VE",
  },
  {
    label: "Yucatán",
    value: "YU",
  },
  {
    label: "Zacatecas",
    value: "ZA",
  },
];

export const COUNTRY_LIST = [
  { label: "United States of America", value: "US" },
  { label: "Mexico", value: "MX" },
  { label: "Canada", value: "CN" },
];

export const DISPOSITION_LIST = [
  "8849 Filing",
  "Block and Credited",
  "Business List Query",
  "CMO - Payment Query",
  "Call back request",
  "DND",
  "Discount Request",
  "Don't need any assistance",
  "Duplicate Rejection",
  "EFTPS Payment Query",
  "EFW Payment Query",
  "EIN Rejection",
  "Filed with Competitor",
  "Filed with IRS",
  "Filing Assistance Form2290",
  "GWI Amendment",
  "Ghost Call",
  "How to make CDC Payment",
  "Hung Up",
  "Letter From The IRS",
  "Marketing Email Complaint",
  "Multiple Invoice",
  "New EIN",
  "No answer",
  "Not Listed",
  "Not Listed",
  "Password Reset",
  "Profile update",
  "RTN Rejection",
  "Refund Request",
  "Registered User - Completed Filing",
  "Schedule 1 Request",
  "Service fee query",
  "Spanish Transfer",
  "Tax Amount Query",
  "Technical Issue",
  "Unable to login",
  "VIN Correction",
  "Voicemail",
  "Low Mileage credit",
  "No response / Navigated away",
  "Already Taken Care",
  "Wrong number",
  "Other Rejections",
  "Refund Status",
  "Correction on Schedule-1",
  "No Longer in business",
  "Customer is on Live chat"
];

export enum StatusEnum {
  OPEN = 'OPEN',
  FOLLOW_UP = 'FOLLOW_UP',
  CLOSED = 'CLOSED',
} 

export const ReferenceStatus = {
  scheduleRetrieved:`Retrieved Schedule 1`,
  userSubmitted:`User Submitted`,
}


export const formatDate = (date: any) => {
  if (date !== "") {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month?.length < 2) month = "0" + month;
    if (day?.length < 2) day = "0" + day;
    return [month, day, year].join("/");
  }
  return "";
};

export const maskString = (string: any, length: number) => {
  return "*".repeat(string?.length - length) + string?.slice(-length);
};

export const getMonth = (monthNum: number) => {
  const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  return month[monthNum];
}

export const getMOIString = (month: string, year:number) => {
  return `${getMonth(parseInt(month) - 1)} ${ parseInt(month) > 6 && parseInt(month) <= 12 ? year : year + 1 }`
}

//Tax Year End
export const getTaxYearEndDate = () => {
  const date = new Date();
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth();
  const endYear = currentMonth < 6 ? currentYear : currentYear + 1;
  return dayjs(new Date(endYear, 5, 30));
};
export const TAX_YEAR_END = getTaxYearEndDate();

//Time Zones
const generateTimeOptions = () => {
  const timeOptions = [];
  for (let hour = 0; hour <= 23; hour++) {
    for (let minutes = 0; minutes < 60; minutes += 15) {
      const timeString = `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
      timeOptions.push(timeString);
    }
  }
  timeOptions.push('24:00');
  return timeOptions;
};
export const timeOptions = generateTimeOptions();
export const timeZones = ['PST', 'MST', 'CST', 'EST'];
export { generateTimeOptions };


export const convertToMinutes = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
  return `${minutes}:${formattedSeconds}`;
};
