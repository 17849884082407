import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Dialog,
  DialogContent,
  TextField,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
  Link,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import { getTableValue } from "../../Utils/Constants";

export default function FeeDetails({ reference, feeDetails, invoiceNumber, status, getServiceFeeBreakUp, viewBreakUpData, setViewBreakUpData }: any) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [viewBreakUpShow, setViewBreakUpShow] = React.useState(false);

  const openViewBreakUpDialog = () => {
    getServiceFeeBreakUp();
    setViewBreakUpShow(true);
  };

  const closeViewBreakUpDialog = () => {
    setViewBreakUpShow(false);
    setViewBreakUpData(null);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="Profile Details" size="small">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Service Fee
            </TableCell>
            <TableCell>
              {feeDetails && getTableValue(feeDetails.serviceFee)}
             { (Object.keys(reference).length > 0) && <Link
              style={{cursor:'pointer', marginLeft: '10px'}}
              onClick={openViewBreakUpDialog}>
              View Breakup
            </Link>}
            <Dialog open={viewBreakUpShow}>
            <DialogTitle></DialogTitle>
            <DialogContent style={{paddingBottom: '5px'}}>
            <TableContainer sx={{border: '1px solid rgba(224, 224, 224, 1)', borderRadius: '4px'}}>
            <Table aria-label="View Breakup" size="small" sx={{ minWidth: 420 }}>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                  Total Vehicles: 
                  </TableCell>
                  <TableCell>
                  {reference && reference?.form2290Model && reference?.form2290Model?.noOfVehicles || reference && reference?.form8849 && reference?.form8849?.noOfVehicles}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                  Service Fee: 
                  </TableCell>
                  <TableCell>
                    $ {viewBreakUpData ? viewBreakUpData?.serviceFee : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                  Service Fee Discount ({viewBreakUpData?.serviceFeeDiscountPercentage}%):
                  </TableCell>
                  <TableCell>
                   $ {viewBreakUpData ? '-' + viewBreakUpData?.serviceFeeDiscountAmount : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                  Subtotal:
                  </TableCell>
                  <TableCell>
                    $ {viewBreakUpData ? viewBreakUpData?.subTotal : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                  Sales Tax({viewBreakUpData?.salesTaxPercentage}%):
                  </TableCell>
                  <TableCell>
                    $ {viewBreakUpData ? viewBreakUpData?.salesTaxAmount : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                  Total: 
                  </TableCell>
                  <TableCell>
                    $ {viewBreakUpData ? viewBreakUpData?.total : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                  Credit Applied:
                  </TableCell>
                  <TableCell>
                   $ {viewBreakUpData ? '-' + viewBreakUpData?.creditApplied : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                  Bill to Card: 
                  </TableCell>
                  <TableCell>
                    $ {viewBreakUpData ? viewBreakUpData?.billToCard : '-'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button style={{marginRight: '15px'}} onClick={closeViewBreakUpDialog}>Close</Button>
            </DialogActions>
          </Dialog>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Card
            </TableCell>
            <TableCell>{feeDetails && feeDetails.cardNumber}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Braintree ID
            </TableCell>
            <TableCell>{feeDetails && feeDetails.braintreeId || '-'}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell component="th" scope="row">
              Invoice(s)
            </TableCell>
            <TableCell>
              {invoiceNumber}
              </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {reference?.status !== "Retrieved Schedule 1" && reference?.status !== 'User Submitted' && (
                <IconButton color="primary" aria-label="Edit">
                  <EditIcon onClick={handleOpen} />
                </IconButton>
              )}
              <Button size="small"></Button>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit Profile</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="email"
                    label="Routing No"
                    type="email"
                    required
                    fullWidth
                    variant="standard"
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Account No"
                    type="text"
                    required
                    fullWidth
                    variant="standard"
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="category"
                    label="Account Type"
                    type="text"
                    required
                    fullWidth
                    variant="standard"
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="regDate"
                    label="Taxpayer Phone"
                    type="text"
                    required
                    fullWidth
                    variant="standard"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={handleClose}>Save</Button>
                </DialogActions>
              </Dialog>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
