import { Backdrop, Button, Checkbox, CircularProgress, FormControl, FormHelperText, InputAdornment, InputLabel, ListItemText, MenuItem, Select, TextField, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { ERRORMSG , BUTTONS , CONSTANTS } from "../../../Components/SendScheduleEmail/constants";
import dayjs from "dayjs";
import { downloadFile, downloadReportAsExcel, sendReportAsEmail } from "../../../Services/reports.service";
import { StatusEnum } from "../../../Utils/Constants";

const SendAsEmail = ({emailIds , onClose , fromDate , toDate , status} :{ emailIds: string[]; onClose: () => void ; fromDate: string ; toDate: string , status: string}) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [emailError, setEmailError] = useState<string>("");
    const [emailFailed, setEmailFailed] = useState<boolean>(false);
    const [error, setError]=useState<boolean>(false);
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]); 
    const [options , setOptions] = useState<string[]>([]); 
    const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
        borderBottom: '1px solid #E5E5E5',
        height: '31px',
        padding:8,
        '&:last-child': {
          borderBottom: 'none',
        },
        '& .MuiListItemText-primary': {
            fontSize: '14px', 
        },
        '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: '#F5F5F5', 
        },
        '&:hover': {
            backgroundColor: '#F5F5F5', 
          },
    }));    
    const textField: React.CSSProperties = {
        fontSize:'14px',
        fontWeight:600 , 
        marginTop:'10px',
        marginLeft: '48px'
    }; 
    const errorField: React.CSSProperties = {
        fontSize:'14px',
        color: '#e83d3d',
        marginTop: '7px',
        marginBottom:'5px',
        fontWeight:'500',
    }; 
    const errorField2: React.CSSProperties = {
        fontSize:'12px',
        color: '#e83d3d',
    }; 
    const text: React.CSSProperties = {
        fontSize:'14px',
        fontWeight:'bold' ,
        marginTop:'10px',
    }
    const textFieldInside: React.CSSProperties = {
        width: '346px',
        padding:'8px 0px 2px 8px',
        borderRadius: '4px',
        backgroundColor: '#fff',
        border: '1px solid #707070', 
    }; 
    const loadingStyle: React.CSSProperties = {
        textAlign: 'center', 
        marginTop: '20px'
    };  
    const buttonDiv: React.CSSProperties = {
        display: 'flex', 
        justifyContent: 'center', 
    }; 
    const button1: React.CSSProperties = {
        fontWeight: 500,
        height: '40px',
        width: '163px',
        margin: '20px 24px 10px 0px',
        fontSize: '15px',
        borderRadius: '4px',
        border: 'solid 1px #1876d1',
        marginBottom: emailFailed ? '15px': '22px',
        textTransform: 'none'
    }; 
    const button2: React.CSSProperties = {
        fontWeight: 500,
        height: '40px',
        width: '163px',
        margin: '20px 0px 10px 9px',
        fontSize: '15px',
        marginBottom: emailFailed ? '15px': '22px',
        textTransform: 'none'
    }; 

    const sendEmail = async () => {
        if (selectedOptions.length < 1) {
            setError(true);
            return;
        }
        setLoading(true);
        let response: any;  
        try {
            if (status === StatusEnum.OPEN || status === StatusEnum.FOLLOW_UP) {
                response = await sendReportAsEmail('', '', status, selectedOptions);
            } else {
                response = await sendReportAsEmail(fromDate, toDate, StatusEnum.CLOSED, selectedOptions);
            }
    
            if (response.data) {
                onClose();
            } else {
                setEmailFailed(true);
            }
        } catch (error) {
            setEmailFailed(true);
        } finally {
            setLoading(false);
        }
    };
    

    const downloadReport = async () => {
        let response: any;
        setLoading(true);
        try {
            if (status === StatusEnum.OPEN || status === StatusEnum.FOLLOW_UP) {
                response = await downloadReportAsExcel('', '', status, []);
            } else {
                response = await downloadReportAsExcel(fromDate, toDate, StatusEnum.CLOSED, []);
            }
    
            if (response.data) {
                downloadFile(response.data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };   

    const renderSelectedValues = (selected: string[]) => {
        if (selected.length === 0) {
          return 'Select Email';
        }
        return (
            <span style={{ color: '#000', fontWeight: 500 }}>
              {selected.join(', ')}
            </span>
          );
    };

    const handleChange = (event: { target: { value: any; }; }) => {
        const { target: { value } } = event;
        setError(false);
        setSelectedOptions(value);
    };

    useEffect(() => {
        const fetchData = () => {
            setOptions( emailIds)
            setLoading(false)
          };     
          fetchData();
      }, []); 

    return (  
          <div> 
                {loading && (
                    <Backdrop
                        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={true}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
                <div>                                 
                    {!emailFailed && (
                    <>
                        <div style={textField}>{CONSTANTS.email}</div>
                        <div style={buttonDiv} >
                            <FormControl sx={{ m:1, width: 360 }}>
                            <Select 
                                multiple
                                id="multiple-select"
                                className="dropdown"
                                value={selectedOptions} 
                                onChange={handleChange}
                                renderValue={(selected) => renderSelectedValues(selected as string[])}
                                displayEmpty
                                sx={{
                                    height: '2.5rem',
                                    fontSize:'14px',
                                    color: '#909090',
                                    border: 'none',
                                    marginLeft:0,
                                    marginRight:0,
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#707070',
                                        borderRadius: '4px',
                                        borderWidth: '1px',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#707070',
                                        borderRadius: '4px',
                                        borderWidth:  '1px'
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#707070',
                                        borderRadius: '4px', 
                                        borderWidth: '1px',
                                    },
                                    '& .MuiSvgIcon-root': {
                                        borderColor: '#707070',
                                    },
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor:'#F5F5F5',
                                            '& .MuiMenuItem-root': {
                                            paddingRight: 0,
                                            backgroundColor:'#F5F5F5'
                                            },
                                        },
                                        style: {
                                        //maxHeight: 200,
                                        maxWidth: 355,
                                        border: 'solid 1px #707070',
                                        backgroundColor: '#F8F8F8',
                                        borderBottomLeftRadius: '4px',
                                        borderBottomRightRadius: '4px',
                                        },
                                    },
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    
                                    }}
                            >
                                {options.map((option)=>(
                                <CustomMenuItem key={option} value={option}>
                                    <ListItemText primary={option} />
                                    <Checkbox 
                                    sx={{
                                        color: '#707070;', 
                                        borderWidth: 1,
                                        '& .MuiIconButton-root': {
                                            borderRadius: 0,
                                            padding: '4px', 
                                        },
                                    }}
                                        checked={selectedOptions.indexOf(option) > -1} ></Checkbox>
                                </CustomMenuItem>
                            ))}
                            </Select> 
                            {error && 
                            <FormHelperText
                                sx={{
                                    color: '#e83d3d', 
                                    fontSize: '12px', 
                                    marginLeft: '2px',
                                }}
                            >{ERRORMSG.emailValidationList}</FormHelperText>}                     
                        </FormControl>
                        </div>
                    </>
                    )}
                    {emailFailed && (
                        <div style={buttonDiv} >
                            <div style={errorField}>{ERRORMSG.emailNotSent}</div>
                        </div>
                    )}
                    <div style={buttonDiv}>
                        <Button
                            onClick={() => {
                                onClose();
                            }}
                            style={button1}
                        >
                            {BUTTONS.cancel}
                        </Button>
                        {!emailFailed && (
                            <Button
                                style={button2}
                                color="primary"
                                variant="contained"
                                onClick={() =>sendEmail()}
                                >
                                {BUTTONS.sendEmail}
                            </Button>
                        )}
                        {emailFailed && (
                            <Button
                                style={button2}
                                color="primary"
                                variant="contained"
                                onClick={() => downloadReport()}
                                >
                                {BUTTONS.exportExcel}
                            </Button>
                        )}
                    </div>
            </div> 
        </div>
    );
};

export default SendAsEmail;
