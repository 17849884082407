import * as React from "react";
import {
  Button,
  Grid,
  InputLabel,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect } from "react";
// import dayjs, { Dayjs } from 'dayjs';
import DashboardService from "../../Services/dashboard.service";
import FetchData from "./fetchData";
import { GridColDef } from '@mui/x-data-grid';
import GlobalWrapper from "../../Components/GlobalComponentWrapper/GlobalWrapper";
import * as XLSX from 'xlsx';
import axios from "axios";
import { REPORT_API_ENDPOINT } from "../../Utils/Constants";
import { useNavigate } from "react-router-dom";

export default function Reports({ reference }: { reference: any }) {
  const [clickedButtonName, setClickedButtonName] = React.useState('');
  // const [fromDate, setFromDate] = React.useState<Dayjs | null>(dayjs(''));
  const [fromDate, setFromDate] = React.useState('');
  const [toDate, setToDate] = React.useState('');
  const [isPagination, setIsPagination] = React.useState(false);
  const [response, setResponse] = React.useState([]);
  const [isdata, setIsData] = React.useState(false)
  const [columns, setColumns] = React.useState<GridColDef[]>([])
  const [keyObj, setKeyObj] = React.useState<any>([])
  const [isLoading, setIsLoading] = React.useState(false);
  const [controller, setController] = React.useState({
    pageNo: 0,
    pageSize: 10
  });
  const [passengersCount, setPassengersCount] = React.useState(0);
  const dataButtons = {
    'Customer In Funnel': 'api/report/filing/customer-in-funnel', 'Hourly Rejection': 'api/report/filing/hourly-rejection', 'Not Field Current Year': 'api/report/filing/not-filed-current-year', 
    'Rejection List': 'api/report/filing/rejection', 'Different Category': 'api/report/filing/category', 'First Used Month': 'api/report/filing/first-used-month', 
    'Different Logging': 'api/report/filing/islogging', 'Final Filing': 'api/report/filing/final-filing', 'Hourly Rejection 8849': 'api/report/filing/hourly-rejection-8849', 'Rejection List 8849': 'api/report/filing/rejection-8849',
    'CouponUsage': 'api/report/filing/weekly-coupon-usage', 'User With Zero Filing': 'api/report/filing/user-with-zero-filing', 'Login Reattempt': 'api/report/user/login-reattempt',
     'Daily PaymentType List': 'api/report/filing/daily-payment-type-list', 'Daily MultipleSubmission': 'api/report/filing/daily-multiple-submission ', 'Daily Submission List' :'api/report/filing/daily-submission-list', 
     'Customer InActivity Last 5 Min' :'api/report/analytics/customer-inactivity-last-5-min', 'Refund Report' :'api/report/filing/refund-report'
  }

  const fetchData = (name: string, method: string, pagi?: string) => {
    setIsLoading(true)
    setIsPagination(false)

    let fromD: any = '';
    let toD: any = '';

    if (fromDate !== '') {
      const firstDate = new Date(fromDate);
      const fromDateFormated = [firstDate.getFullYear(), String(firstDate.getMonth() + 1).padStart(2, '0'), String(firstDate.getDate()).padStart(2, '0')]
      fromD = fromDateFormated.join('-')
    }

    if (toDate !== '') {
      const firstDate = new Date(toDate);
      const fromDateFormated = [firstDate.getFullYear(), String(firstDate.getMonth() + 1).padStart(2, '0'), String(firstDate.getDate()).padStart(2, '0')]
      toD = fromDateFormated.join('-')
    }

    setClickedButtonName(name)
    const keysOfDataButton = Object.keys(dataButtons).indexOf(name)
    const apiurl = Object.values(dataButtons)[keysOfDataButton]
    const postData = {
      fromDate: fromD,
      toDate: toD,
    };

    if (method == 'post') {
      if (postData.fromDate == '' || postData.toDate == '') {
        alert('please select date')
        setIsLoading(false)
        return
      }
      return DashboardService.csReportDetails(apiurl, postData)
        .then((resp) => {
          setIsLoading(false)
          setResponse(resp.data)
          setIsData(true)
          const asdad = Object.keys(resp.data[0])
          setKeyObj(asdad)
          const col = Object.keys(resp.data[0]).map((x) => {
            return { field: x, headerName: x, width: 70 }
          })
          setColumns(col)
        })
        .catch((err) => {
          console.log('error', err)
        });
    }

    if (method == 'get') {
      return DashboardService.csReportGet(apiurl)
        .then((resp) => {
          setIsLoading(false)
          setResponse(resp?.data)
          setIsData(true)         
          const asdad = Object.keys(resp?.data[0])
          setKeyObj(asdad)
          const col = Object.keys(resp?.data[0]).map((x) => {
            return { field: x, headerName: x, width: 70 }
          })
          setColumns(col)
        })
        .catch((err) => {
          console.log('error', err)
        });
    }

  }

  const csRowPrPage = (rowPrPage: number) => {
    setController({ ...controller, pageSize: rowPrPage });
  }

  const csPageNumber = (rowPrPage: number) => {
    setController({ ...controller, pageNo: rowPrPage });
  }

  const fetchDataPage = (name: string) => {
    setIsLoading(true)
    setIsPagination(true)
    setClickedButtonName(name)
    const apiurl = "api/report/filing/not-filed-current-year"

    return DashboardService.csReportDetails(apiurl, controller)
      .then((resp) => {
        setIsLoading(false)
        setResponse(resp.data)
        setPassengersCount(resp?.count)
        setIsData(true)
        const asdad = Object.keys(resp.data[0])
        setKeyObj(asdad)
        const col = Object.keys(resp.data[0]).map((x) => {
          return { field: x, headerName: x, width: 70 }
        })
        setColumns(col)
      })
      .catch((err) => {
        console.log('error', err)
      });
  }

  useEffect(() => {
    fetchDataPage('Not Field Current Year')
  }, [controller])

  const downloadFile = (response: any) => {
    const worksheet = XLSX.utils.json_to_sheet(response);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "DataSheet.xlsx");
  };

  const fetchDataDownload = () => {
    setIsLoading(true)

    axios.get(REPORT_API_ENDPOINT + 'filing/not-filed-current-year-excel',{responseType:'arraybuffer'}).then((response) => {
      setIsLoading(false)
      const file = new Blob([response.data] , { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const fileURL = window.URL.createObjectURL(file);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = fileURL;
      a.click();

    }).catch(error => {
      console.log('error:',error);
    });
  }

  const handleCustomerInFunnelReport = () => {
    window.open("/customer-in-funnel-report", "_blank");
  };

  const eMCS150Navigation = () => {
    window.open("/emcs150/emcs-customers", "_blank");
  };

  return (
    <GlobalWrapper isLoading={isLoading}>
      <>
        <Grid container spacing={2} sx={{ pl: 4, mt: 2 }} >
        <Grid item xs={6} sm={4} md={3}>
            <Button variant="outlined" onClick={() => fetchData('Customer In Funnel', 'get')}>Customer In Funnel Old</Button>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Button variant="outlined" onClick={handleCustomerInFunnelReport}>Customer In Funnel New</Button>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Button variant="outlined" onClick={() => fetchData('Hourly Rejection', 'get')}>Hourly Rejection</Button>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Button variant="outlined" onClick={() => fetchData('Hourly Rejection 8849', 'get')}>Hourly Rejection 8849</Button>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Button variant="outlined" onClick={() => fetchData('Rejection List', 'post')}>Rejection List</Button>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Button variant="outlined" onClick={() => fetchData('Rejection List 8849', 'post')}>Rejection List 8849</Button>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Button variant="outlined" onClick={() => fetchDataPage('Not Field Current Year')}>Not Filed Current Year</Button>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Button variant="outlined" onClick={() => fetchData('Different Category', 'post')}>Different Category</Button>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Button variant="outlined" onClick={() => fetchData('First Used Month', 'post')}>First Used Month</Button>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Button variant="outlined" onClick={() => fetchData('Different Logging', 'post')}>Different Logging</Button>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Button variant="outlined" onClick={() => fetchData('Final Filing', 'post')}>Final Filing</Button>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Button variant="outlined" onClick={() => fetchDataDownload()}>Not Filed Current Year Excel</Button>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Button variant="outlined" onClick={() => fetchData('CouponUsage', 'post')}>Coupon Usage</Button>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Button variant="outlined" onClick={() => fetchData('User With Zero Filing', 'post')}>User With Zero Filing</Button>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Button variant="outlined" onClick={() => fetchData('Login Reattempt', 'post')}>Login Reattempt</Button>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Button variant="outlined" onClick={() => fetchData('Daily PaymentType List', 'get')}>Daily Payment Type List</Button>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Button variant="outlined" onClick={() => fetchData('Daily MultipleSubmission', 'get')}>Daily Multiple Submission</Button>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Button variant="outlined" onClick={() => fetchData('Daily Submission List', 'get')}>Daily Submission List</Button>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Button variant="outlined" onClick={() => fetchData('Customer InActivity Last 5 Min', 'get')}>Customer InActivity Last 5 Min</Button>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Button variant="outlined" onClick={() => fetchData('Refund Report', 'post')}>Refund Report</Button>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Button variant="outlined" onClick={eMCS150Navigation}>eMCS - 150</Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ pl: 4, mt: 2 }} >
          <Grid item xs={6} sm={4} md={3}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <InputLabel>From Date : &nbsp;&nbsp;&nbsp;</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="YYYY-MM-DD"
                  format="YYYY-MM-DD"
                  value={fromDate}
                  onChange={(e: any) => setFromDate(e)}
                // onChange={(e:any)=>setAa(e)}
                />
              </LocalizationProvider>
            </div>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <InputLabel>To Date : &nbsp;&nbsp;&nbsp;</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="YYYY-MM-DD"
                  format="YYYY-MM-DD"
                  value={toDate}
                  onChange={(e: any) => setToDate(e)}
                />
              </LocalizationProvider>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ pl: 4, mt: 2 }} >
          <Grid item xs={6} sm={4} md={3}>
            <Button variant="contained" onClick={()=>downloadFile(response)} >Export to Excel</Button>
          </Grid>
        </Grid>

        {/*  data grid start */}
        {
          isdata && <FetchData response={response} columns={columns} keyObj={keyObj} isPagination={isPagination} csRowPrPage={csRowPrPage} csPageNumber={csPageNumber} passengersCount={passengersCount} setController={setController} controller={controller} />
        }
      </>
    </GlobalWrapper>
  );
}
