import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './Pages/Dashboard';
import Login from './Pages/Login';
import Register from './Pages/Register';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Reports from './Pages/Reports';
import CustomerInFunnelReport from './Pages/CustomerInFunnelReport/Index';
import EmcsForm from './Pages/Emcs150';
import FileMCS150 from './Pages/Emcs150/FileMCS150';

function App() {
  return (
    <div className="App">
      <GoogleOAuthProvider
        clientId={
          '639962496765-o35se1jm5i1vcrbq388n4o697p3pv3d9.apps.googleusercontent.com'
        }
      >
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/reports" element={<Reports reference={undefined} />} />
            <Route path="/customer-in-funnel-report" element={<CustomerInFunnelReport />} />
            <Route path="/emcs150" element={<Navigate to="/emcs150/emcs-customers" />} />
            <Route path="/emcs150/emcs-customers" element={<EmcsForm />} />
            <Route path="/emcs150/file-mcs150" element={<FileMCS150 />} />
          </Routes>
        </Router>
      </GoogleOAuthProvider>
    </div>
  );
}
export default App;
