import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, keyframes, makeStyles, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import dayjs from "dayjs";
import dashboardLinksService from '../../Services/dashboard-links.service';
import { FirstPage as FirstPageIcon, KeyboardArrowLeft, KeyboardArrowRight, LastPage as LastPageIcon } from '@mui/icons-material';
import { API_ENDPOINT, PROD_URL } from '../../Utils/Constants';
import { agentAssignment, downloadFile, downloadReportAsExcel } from '../../Services/reports.service';
import SendAsEmail from './Components/sendAsEmail';
import SearchIcon from '@mui/icons-material/Search';
import { CSSProperties } from 'react';
import { BlinkingTableRow } from '../../Utils/Constants/commonCssCustomerInFunnel';
import { agentListmapping, handleAgentSelectionChange } from '../../Utils/Constants/commonCustomerInFunnelFunction';

interface UserData {
    filingType: string;
    user: string;
    email: string;
    mobileNumber: string;
    currentPage :string,
    previousPage :string ,
    timeSpent : string,
    isNewUser: boolean;
    filingInitiated: Date;
    efilingCredits : number ;
    status: string;
    assignedTo: string;
    userKey: string;
    id: number;
    currentUrl: string;
    previousUrl: string;
    duration: string;
    lastFilingInfo : string;
    lastFilingInfoTaxYear : string;
}
interface DuplicateUser{
    userKey: string;
    email:string;
}
export const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',     
      height: '401px',    
      borderRadius: '4px',
      border: 'solid 1px #707070',
      backgroundColor: '#f4f4f4',     
    },
    header : {
        display: 'flex', 
        fontWeight: 500,   
        justifyContent: 'space-between', 
        alignItems: 'center', 
        marginBottom: '30px'
    },
    svg: {
        width: '100px',
        height: '100px',
        marginBottom: '20px',
    },
    refreshButton: {
        width: '120px',
        height: '40px',
        borderRadius: '4px',
        border: 'solid 1px #1876d1',
        backgroundColor: '#fff',
        fontSize: "16px",  
        fontWeight: 500,   
        color: "#1876d1",
        textTransform: 'none'
    },
    markCasesAsDuplicate: {
        width: '180px',
        height: '40px',
        borderRadius: '4px',
        border: 'solid 1px #1876d1',
        backgroundColor: '#fff',
        fontSize: "16px",  
        fontWeight: 500,   
        color: "#1876d1",
        cursor: 'pointer'
    },
    emailExcel: {
        width: '180px',
        height: '40px',
        borderRadius: '4px',
        border: 'solid 1px #1876d1',
        marginLeft:'30px',
        color: '#fff',
        fontSize: "16px",  
        fontWeight: 500,   
        backgroundColor: "#1876d1",
        cursor: 'pointer'
    },
    tableContainer : {
        border: '1px solid #707070', borderRadius: '4px'
    },
    tableCell : {
       border: '1px solid white',
       fontWeight: 'bold', 
       fontSize: '14px'
    },
    paginationIcons : {      
        borderRadius: '1px',
        backgroundColor: '#bddbf5',
        width: '28px',
        height: '28px',
        marginRight: '4px' ,
        color: '#1876d1'         
    }
};

const SvgContent = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" style={styles.svg}>
    <path d="M50 100a49.975 49.975 0 1 0-35.363-14.637A50.057 50.057 0 0 0 50 100zm0-8.333a41.373 41.373 0 0 1-26.379-9.4l58.65-58.646A41.683 41.683 0 0 1 50 91.667zm0-83.334a41.368 41.368 0 0 1 26.377 9.4L17.729 76.379A41.683 41.683 0 0 1 50 8.333zm0 0" style={{ fill: '#bcbcbc' }} />
    </svg>
);


export default function CustomerInFunnelContent(){
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [refreshToggle, setRefreshToggle] = useState(false);
    const [userData, setUserData] = useState<UserData[]>([]);
    const [orderBy, setOrderBy] = useState<keyof UserData>('timeSpent');
    const [order, setOrder] = useState<'asc' | 'desc' | null>(null);
    const [agentList, setAgentList] = useState<string[]>([]); 
    const [agentListEmail, setAgentListEmail] = useState<string[]>([]);  
    const [agentSelections, setAgentSelections] = useState<{ [key: number]: string }>({});
    const [agentSelectedIndex, setAgentSelectedIndex] = useState<{ [key: string]: number | null }>({});
    const [agentNameToIdMap, setAgentNameToIdMap] = useState<{ [key: string]: string | number }>({});
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [duplicateUserList,setDuplicateUserList]=useState<DuplicateUser[]>([]);
    const [sendEmail, setSendEmail] = React.useState(false);
    const env = API_ENDPOINT;
    const prodUrl = PROD_URL;

    // Handling sorting for filing initiated and time spent
    const handleSort = (property: keyof UserData) => {
        const isAscending = orderBy === property && order === 'asc';
        setOrder(isAscending ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const sortedRows = userData.sort((a, b) => {
        const timeA = a.timeSpent.split(':').map(Number);
        const timeB = b.timeSpent.split(':').map(Number);
    
        if (timeA[0] < timeB[0]) {
            return order === 'asc' ? -1 : 1;
        }
        if (timeA[0] > timeB[0]) {
            return order === 'asc' ? 1 : -1;
        }
        if (timeA[1] < timeB[1]) {
            return order === 'asc' ? -1 : 1;
        }
        if (timeA[1] > timeB[1]) {
            return order === 'asc' ? 1 : -1;
        }
        return 0;
    });
    
    const handleRefresh = () => {
        setLoading(true); 
        dashboardLinksService.getCustomerInFunnelListOpen(false)
        .then((response) => {           
            const data = response.data;
            userDataAdd(response.data);
            setLoading(false); 
        })
        .catch((error) => {
            console.log(error);
            setLoading(false); 
        });
        setRefreshToggle(!refreshToggle);      
    };

    // Handling duplicate case scenario
    const handlemarkAsDuplicate = () => {
        const updatedUserData = userData.filter((item, index) => !selectedRows.includes(index));
        
        dashboardLinksService.addDuplicateCustomers(duplicateUserList)
        .then((response) => {
            setUserData(updatedUserData);
            console.log(response.status);
        })
        .catch((error: Error) => {
            console.log('Error occurred:', error.message);
        });
        setDuplicateUserList([]);
        setSelectedRows([]);
    }

    const handleRowSelection = (index: number,duplicateUser:DuplicateUser) => { 
        if (selectedRows.includes(index)) {
            setSelectedRows(selectedRows.filter((item) => item !== index));
            const filteredUserList = duplicateUserList.filter((user)=>user?.email!==duplicateUser?.email);
            setDuplicateUserList(filteredUserList);

        } else {
            setSelectedRows([...selectedRows, index]);
            setDuplicateUserList(prevList =>  [...prevList, duplicateUser]);
        }
    };
    
    const downloadReport = async () => {
        setLoading(true); 
        const response = await downloadReportAsExcel('', '', 'OPEN', []);
        if(response.data) {
            downloadFile(response.data)
            setLoading(false); 
        }
        else {
            setLoading(false); 
        }
    }
    
     const userDataAdd = (data: any) => {
        const agentIndexMapping: { [key: string]: number | null } = {};
        const userDataArray: UserData[] = data.map((item: any) => {
            agentIndexMapping[item.email] = item?.id ?? null;
            return {
                user: item.fullName,
                email: item.email,
                mobileNumber: item.phone,
                filingType: item.filingType,
                currentPage: item.currentPage,
                previousPage: item.previousPage,
                timeSpent: item.timeSpent,
                isNewUser: item.isNewUser,
                filingInitiated: item.filingIntiatedAtIst,
                efilingCredits: item.userCredit, 
                status: item.caseStatus, 
                assignedTo : item.executivesList && item.executivesList.length > 0? item.executivesList[0].name: null,
                userKey: item.userKey,
                id: item.id,
                previousUrl: item.previousUrl,
                currentUrl: item.currentUrl,
                duration: item.assignedCaseRemainingSeconds,
                lastFilingInfo : item.lastSubmissionDate,
                lastFilingInfoTaxYear : item.taxYear,
            };
        });
        setUserData(userDataArray);
        setAgentSelectedIndex(agentIndexMapping);
      };

    const handleSelectionChange = (event: React.SyntheticEvent, newValue: string | null, id: number ,userDataItem : any) => {
        handleAgentSelectionChange(
            event,
            newValue,
            id,
            userDataItem,
            agentNameToIdMap,
            agentSelectedIndex,
            setAgentSelections,
            handleRefresh
        );
    };
            
    const agentListEmailIdmapping = (response : any )=> {
        const { sortedList, emailList, nameToIdMap } = agentListmapping(response);
        setAgentList(sortedList);
        setAgentListEmail(emailList);
        setAgentNameToIdMap(nameToIdMap);
    }

    // Handling pagination
    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleCloseSMS = () =>{
        setSendEmail(false);
    }
    
    // Colour categorization of userdata based on time spent in the session
    const getPriorityLevelColor = (timeSpent: string) => {
        const [minutes, seconds] = timeSpent.split(':').map(Number);
        const totalMinutes = minutes + seconds / 60; 
        if( env === prodUrl )  {
            if (totalMinutes >= 4 && totalMinutes < 8) {
                return '#bddbf5'; 
            } else if (totalMinutes >= 8 && totalMinutes < 15) {
                return '#f5e2bd'; 
            } else if (totalMinutes >= 15 && totalMinutes < 30) {
                return '#f5cabd'; 
            } else if (totalMinutes >= 30) {
                return '#faa7a7'; 
            }   
            return ''; 
        }
        else {
            if (totalMinutes >= 1 && totalMinutes < 3) {
                return '#bddbf5'; 
            } else if (totalMinutes >= 3 && totalMinutes < 6) {
                return '#f5e2bd'; 
            } else if (totalMinutes >= 6 && totalMinutes < 9) {
                return '#f5cabd'; 
            } else if (totalMinutes >= 9) {
                return '#faa7a7'; 
            }   
            return ''; 
        } 
    };

    // Url mapping for current page and previous page
    const urlMappings = {
        'review': 'Review Page',
        'irs-payment': 'IRS Payment Page',
        'single-truck': 'Single Truck Form Page',
        'select': 'MT-SelectBusiness Page',
        'tax-period': 'MT-Select TaxPeriod & Category Page',
        'taxable-vehicle#no-back-button': 'MT-Taxable Form page',
        'suspended-vehicle#no-back-button': 'MT-Suspended Form page',
        'credit-vehicle#no-back-button': 'MT-Credit Form page',
        'prior-sold-vehicle#no-back-button': 'MT-Sold Suspended Form page',
    };
    const mapUrlToDescription = (url: string | string[]) => {
        for (const [key, value] of Object.entries(urlMappings)) {
            if (url.includes(key)) {
                return value;
            }
        }
        return url;
    };
    const mapDataToDescription = (data: string | string[]) => {
        switch (typeof data) {
            case 'string':
                return mapUrlToDescription(data);
            case 'boolean':
                return data ? 'Yes' : 'No';
            case 'object':
                return data?.toString() || '';
            default:
                return data;
        }
    };

    const convertToMinutes = (seconds: number): string => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
    
        return `${minutes}:${formattedSeconds}`;
    };
   
    // Table cell customization
    const CustomizedTableCell = styled(TableCell)(() => ({
        border: '1px solid white',
        fontWeight: 'bold',
        fontSize: '14px',
        maxWidth: '150px',
        wordBreak: 'break-word',
        padding: '4px 8px',
    }));
    
    const loadingStyle: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '300px'
    }; 

    useEffect(()=>{
        setLoading(true); 
        dashboardLinksService.getAgentList()
        .then((response) => { 
            if (response.data) {          
                agentListEmailIdmapping(response)
            }
        })
        .catch((error) => {
            console.log(error);
        });
        dashboardLinksService.getCustomerInFunnelListOpen(false)
        .then((response) => {           
            const data = response.data;
            userDataAdd(response.data);
            setLoading(false); 
        })
        .catch((error) => {
            console.log(error);
            setLoading(false); 
        });
        setLoading(false); 
 
    }, []); 

    const autocompleteStyles = {
        textField: {
          fontSize: '14px',
          backgroundColor: 'white',
          borderRadius: '4px',
          fontWeight: 'bold',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#707070',
              borderRadius: '4px',
            },
            '&:hover fieldset': {
              borderColor: '#707070',
              borderRadius: '4px',
              fontSize: '14px',
            },
          },
          '& .MuiInputBase-input': {
            paddingRight: '0px',
            fontSize: '14px',
            color: 'black',
            '&::placeholder': {
              color: 'black',
              opacity: 1,
            },
          },
          '& .MuiAutocomplete-clearIndicator': {
            display: 'none',
          },
          '& .MuiAutocomplete-popupIndicator': {
            display: 'none',
          },
          '& .MuiInputBase-root': {
            padding: '0 !important',
            fontSize: '14px',
            fontWeight: 'bold',
            overflow: 'hidden',
            whiteSpace: 'pre-line',
            textOverflow: 'clip',
          },
          '& .MuiInputBase-adornedEnd': {
            padding: '0 !important',
          },
        } as CSSProperties,
        listbox: {
          border: '1px solid #707070',
          borderRadius: '4px',
          overflowX: 'hidden',
          padding: 0,
        } as CSSProperties,
        option: {
            borderBottom: '1px solid #e5e5e5',  // Green bottom border for each item
            fontSize: '14px',
            padding: '8px 16px',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: '#f0f0f0',
            },
            '&:last-child': {
              borderBottom: 'none',  // Remove the bottom border for the last item
            },
          } as CSSProperties,
          searchIcon: {
            color: 'black',
            paddingRight: '5px',
          } as CSSProperties,
          noOptionsText: {
            fontSize: '12px',
            lineHeight: '0.9',
            color: '#e20707',
          } as CSSProperties,
        };
        
    return (
        <>
        {loading && (
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
        <div style={{margin:'32px 74px'}}>
            <div style={styles.header}>
                <div>The details of customers who are currently in the Form 2290 or 8849 filing funnel.</div>
                <Button variant="outlined" onClick={handleRefresh} sx={styles.refreshButton as React.CSSProperties}>Refresh</Button>
            </div>
            {!(userData.length>0) ?
                <>
                    <div className="Rectangle-5812" style={styles.container as React.CSSProperties}>
                        <SvgContent />
                    <div>No active customers in the funnel to reach out</div>
                    </div>
                </>
            :
            <div>
                <TableContainer sx={styles.tableContainer}>
                    <Table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <TableHead sx={{backgroundColor: '#d9d9d9'}}>
                            <TableRow style={{ height: '50px' }}>
                                <CustomizedTableCell ></CustomizedTableCell>
                                <CustomizedTableCell >Email</CustomizedTableCell>
                                <CustomizedTableCell >Mobile <br /> Number</CustomizedTableCell>
                                <CustomizedTableCell >Filing <br /> Type</CustomizedTableCell>
                                <CustomizedTableCell >Current <br /> Page</CustomizedTableCell>
                                <CustomizedTableCell >Previous <br /> Page</CustomizedTableCell>
                                <CustomizedTableCell style={{  width:'8%'}} >Last Filing<br />with<br/>eForm2290</CustomizedTableCell>
                                <CustomizedTableCell
                                    style={{ position: 'relative',cursor: 'pointer' , minWidth:'116px'}}
                                    onClick={() => handleSort('timeSpent')}
                                >
                                    Time spent <br />by the user in<br /> the session
                                    <span style={{position: 'absolute', right: '0px', top: '50%', transform: 'translate(-50%, -50%)', lineHeight: '0.8' }}>
                                        ▲ <br />▼
                                    </span>
                                </CustomizedTableCell>
                                <CustomizedTableCell >Is User <br /> New?</CustomizedTableCell>
                                <CustomizedTableCell
                                    style={{ position: 'relative',cursor: 'pointer', minWidth:'76px'}}
                                    onClick={() => handleSort('timeSpent')}
                                >
                                    Filing Initiated<br />at (IST)
                                    <span style={{ position: 'absolute', right: '0px', top: '50%', transform: 'translate(-50%, -50%)', lineHeight: '0.8' }}>
                                        ▲ <br />▼
                                    </span>
                                </CustomizedTableCell>
                                <CustomizedTableCell >eFiling <br /> Credits</CustomizedTableCell>
                                <CustomizedTableCell >Agent<br/>Status</CustomizedTableCell>
                                <CustomizedTableCell >Assigned <br /> To</CustomizedTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            {userData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((userDataItem, rowIndex) => (
                                <BlinkingTableRow key={rowIndex} style={{ backgroundColor: getPriorityLevelColor(userDataItem.timeSpent) }} className={userDataItem.status === 'Awaiting' && parseInt(userDataItem.duration)==0 ? 'blink' : ''}>
                                    <CustomizedTableCell >
                                        <input 
                                            type="checkbox"
                                            checked={selectedRows.includes(rowIndex)}
                                            onChange={() => handleRowSelection(rowIndex,{userKey:userDataItem.userKey,email:userDataItem.email})}
                                        />
                                    </CustomizedTableCell>
                                    <CustomizedTableCell >{userDataItem.email}</CustomizedTableCell>
                                    <CustomizedTableCell >{userDataItem.mobileNumber}</CustomizedTableCell> 
                                    <CustomizedTableCell >{userDataItem.filingType}</CustomizedTableCell> 
                                    <CustomizedTableCell >{mapDataToDescription(userDataItem.currentPage)}</CustomizedTableCell>
                                    <CustomizedTableCell >{mapDataToDescription(userDataItem.previousPage)}</CustomizedTableCell>
                                    <CustomizedTableCell>
                                        {userDataItem.lastFilingInfo ? ( 
                                            <> {userDataItem.lastFilingInfo}<br />{userDataItem.lastFilingInfoTaxYear}</>
                                        ) : (
                                            'NA'
                                        )}
                                    </CustomizedTableCell>
                                    <CustomizedTableCell >{userDataItem.timeSpent}</CustomizedTableCell>
                                    <CustomizedTableCell>{userDataItem.isNewUser ? 'Yes' : 'No'}</CustomizedTableCell>
                                    <CustomizedTableCell >{userDataItem.filingInitiated?.toString()}</CustomizedTableCell>
                                    <CustomizedTableCell style={{whiteSpace: 'nowrap', }}>$ {userDataItem.efilingCredits.toFixed(2)}</CustomizedTableCell>
                                    <CustomizedTableCell>
                                       <span style={{whiteSpace: 'nowrap', }}> {userDataItem.status} {userDataItem.status === 'Awaiting' && (<span>{convertToMinutes(parseInt(userDataItem.duration))}</span>)}</span>
                                        {userDataItem.status === 'Awaiting' && (
                                            <span style={{ fontSize: '10px', display: 'block', color:'#1771c9' , textDecoration: 'underline', cursor: 'pointer',whiteSpace: 'nowrap', }} onClick={handleRefresh}>
                                                Refresh to Update
                                            </span>
                                        )}
                                    </CustomizedTableCell>
                                    <CustomizedTableCell >
                                    <FormControl variant="outlined" sx={{ width: '155px' }}>
                                        
                                    <Autocomplete
                                        options={agentList}
                                        value={agentSelections[rowIndex] || userDataItem.assignedTo || null} 
                                        onChange={(event, newValue) => handleSelectionChange(event, newValue, rowIndex, userDataItem)}
                                        noOptionsText={<span style={autocompleteStyles.noOptionsText}>Please select a valid agent.</span>}
                                        renderOption={(props, option) => (
                                            <li {...props} style={autocompleteStyles.option}>
                                              {option}
                                            </li>
                                          )}
                                        renderInput={(params) => (
                                            <TextField
                                            {...params}
                                            placeholder={'Select Agent'}
                                            sx={autocompleteStyles.textField}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                <React.Fragment>
                                                    <SearchIcon sx={autocompleteStyles.searchIcon} />
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                                ),
                                            }}
                                            />
                                        )}
                                    />
                                    </FormControl>
                                    </CustomizedTableCell>
                                </BlinkingTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Dialog 
                    open={sendEmail}
                    onClose={() => setSendEmail(false)}
                    >
                    <div  style={{ width: '500px'}}>
                        <DialogTitle sx={{ textAlign: 'center',fontSize: '21px' , fontFamily: 'unset' , marginTop:'4px'}}>Send Report on Email</DialogTitle>
                        <DialogContent>
                        <DialogContent style={{padding:'0px 0px 0px 0px'}}>
                            <SendAsEmail
                                emailIds ={agentListEmail}
                                onClose={handleCloseSMS}
                                fromDate=''
                                toDate=''
                                status='OPEN'
                            />
                        </DialogContent>
                        </DialogContent>
                    </div>
                </Dialog>
                <div style={{marginTop:'20px',display: 'flex', justifyContent: 'space-between', alignItems: 'center',}}>
                    <button onClick={handlemarkAsDuplicate} style={styles.markCasesAsDuplicate as React.CSSProperties}>Mark Cases Duplicate</button>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <button  style={styles.emailExcel as React.CSSProperties} onClick={() => downloadReport()}>Export to Excel</button>
                        <button  style={styles.emailExcel as React.CSSProperties} onClick={() => setSendEmail(true)}>Send as Email</button>
                    </div>
                    <div>
                        <TablePagination
                            component="div"
                            count={userData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPageOptions={[]} 
                            ActionsComponent={(subProps) => {
                                const { onPageChange, page } = subProps;
                                const currentPage = page + 1;
                                const totalPages = Math.ceil(userData.length / rowsPerPage);
                                return (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <IconButton style={styles.paginationIcons} onClick={() => onPageChange(null, 0)} disabled={page === 0} aria-label="first page">
                                            <FirstPageIcon />
                                        </IconButton>
                                        <IconButton style={styles.paginationIcons} onClick={() => onPageChange(null, page - 1)} disabled={page === 0} aria-label="previous page">
                                            <KeyboardArrowLeft />
                                        </IconButton>
                                        <span style={{ whiteSpace: 'nowrap', margin: '0 8px' }}>{currentPage} of {totalPages}</span>
                                        <IconButton style={styles.paginationIcons} onClick={() => onPageChange(null, page + 1)} disabled={page >= Math.ceil(userData.length / rowsPerPage) - 1} aria-label="next page">
                                            <KeyboardArrowRight />
                                        </IconButton>
                                        <IconButton style={styles.paginationIcons} onClick={() => onPageChange(null, Math.ceil(userData.length / rowsPerPage) - 1)} disabled={page >= Math.ceil(userData.length / rowsPerPage) - 1} aria-label="last page">
                                            <LastPageIcon />
                                        </IconButton>
                                    </div>
                                );
                            }}
                            labelDisplayedRows={() => null}   
                        />
                    </div>
                </div>
            </div>
          }
          </div>
        </>
     )
}