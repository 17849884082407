import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Dialog,
  DialogContent,
  TextField,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import DashboardService from "../../Services/dashboard.service";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import { getTableValue } from "../../Utils/Constants";
import { useAppDispatch } from "../../Store";
import { IS_LOADING } from "../../Actions/types";
import { FormProvider, useForm } from "react-hook-form";

export default function SigningAuthority({
  saDetails,
  formType,
  businessSubmissionId,
  clearStateAndRefetch,
}: {
  saDetails: any;
  formType: string;
  clearStateAndRefetch: any;
  businessSubmissionId: string;
}) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [errorState, setErrorState] = useState<any>({});
  const [masterRecordUpdate, setMasterRecordUpdate] = useState<any>(false);

  const methods = useForm({});
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    setValue("saName", saDetails?.saName);
    setValue("saTitle", saDetails?.saTitle);
    setValue("saPin", saDetails?.saPin);
    setValue("saPhone", saDetails?.saPhone);
    setValue("remarks", saDetails?.remarks);
  }, [saDetails]);

  const handleOpen = () => {
    setErrorState({});
    setOpen(true);
  };
  const handleClose = () => {
    setErrorState({});
    setOpen(false);
  };
  const onSubmit = (data: any) => {
    console.log("data", data, formType);
    return DashboardService.csListSignUpdate({
      ...data,
      formType,
      businessSubmissionId,
      masterRecordUpdate,
    })
      .then((response) => {
        dispatch({ type: IS_LOADING });
        clearStateAndRefetch();
        setOpen(false);
      })
      .catch((error) => {
        console.log("errrrr", error?.response?.data?.errors);
        setErrorState((prev: any) => ({
          ...prev,
          ...error?.response?.data?.errors,
        }));
      });
  };

  console.log("errorSatee", errorState);
  return (
    <TableContainer component={Paper}>
      <Table aria-label="Signing Authority" size="small">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              SA Name
            </TableCell>
            <TableCell>{saDetails.saName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              SA Title
            </TableCell>
            <TableCell>{saDetails.saTitle}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              SA PIN
            </TableCell>
            <TableCell>{saDetails.saPin}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              SA Phone
            </TableCell>
            <TableCell>
              {saDetails.saPhone}
              {saDetails?.status !== "Retrieved Schedule 1" && saDetails?.status !== 'User Submitted' && (
                <IconButton color="primary" aria-label="Edit">
                  <EditIcon onClick={handleOpen} />
                </IconButton>
              )}
              <Button size="small"></Button>
              <Dialog open={open} onClose={handleClose}>
                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle>Edit Signing Authority</DialogTitle>
                    <DialogContent>
                      <TextField
                        {...(errors.saName && {
                          helperText: errors.saName.message as string,
                        })}
                        {...(errorState?.name && {
                          helperText: errorState?.name[0] as string,
                        })}
                        {...(errors?.saName || errorState?.name
                          ? { error: true }
                          : {})}
                        {...register("saName", {
                          required: "SA Name is required",
                        })}
                        margin="dense"
                        id="saName"
                        label="SA Name"
                        type="text"
                        fullWidth
                        variant="standard"
                      />
                      <TextField
                        {...(errors.saTitle && {
                          helperText: errors.saTitle.message as string,
                        })}
                        {...(errorState?.title && {
                          helperText: errorState?.title[0] as string,
                        })}
                        {...(errors?.saTitle || errorState?.title
                          ? { error: true }
                          : {})}
                        {...register("saTitle", {
                          required: "SA Title is required",
                        })}
                        margin="dense"
                        id="saTitle"
                        label="SA Title"
                        type="text"
                        fullWidth
                        variant="standard"
                      />
                      <TextField
                        {...(errors.saPin && {
                          helperText: errors.saPin.message as string,
                        })}
                        {...(errorState?.pin && {
                          helperText: errorState?.pin[0] as string,
                        })}
                        {...(errors?.saPin || errorState?.pin
                          ? { error: true }
                          : {})}
                        {...register("saPin", {
                          required: "SA Pin is required",
                          pattern: {
                            value: /^\d{5}$/g,
                            message: "Please enter a SA Pin",
                          },
                        })}
                        margin="dense"
                        id="saPin"
                        label="SA PIN"
                        type="text"
                        fullWidth
                        variant="standard"
                        inputProps={{
                          maxLength: 5,
                          minLength: 5,
                        }}
                      />
                      <TextField
                        {...(errors.saPhone && {
                          helperText: errors.saPhone.message as string,
                        })}
                        {...(errorState?.phone && {
                          helperText: errorState?.phone[0] as string,
                        })}
                        {...(errors?.saPhone || errorState?.phone
                          ? { error: true }
                          : {})}
                        {...register("saPhone", {
                          required: "SA Phone is required",
                          pattern: {
                            value: /^[1-9]{1}[0-9]{9}$/g,
                            message: "Please enter a valid phone number",
                          },
                        })}
                        margin="dense"
                        id="saPhone"
                        label="SA Phone"
                        type="text"
                        fullWidth
                        variant="standard"
                      />
                      <TextField
                        {...(errors.remarks && {
                          helperText: errors.remarks.message as string,
                        })}
                        {...(errors?.remarks ? { error: true } : {})}
                        {...register("remarks", {
                          required: "Remark is required",
                        })}
                        margin="dense"
                        id="remarks"
                        label="Remark"
                        type="text"
                        fullWidth
                        variant="standard"
                        multiline
                      />
                    </DialogContent>
                    <DialogActions>
                      <FormControlLabel
                        control={<Checkbox value="true" color="primary" />}
                        label="Also update master business record"
                        id="masterRecordUpdate"
                        onChange={(e: any) => {
                          setMasterRecordUpdate(e.target.checked);
                        }}
                      />
                      <Button onClick={handleClose}>Cancel</Button>
                      <Button type="submit">Save</Button>
                    </DialogActions>
                  </form>
                </FormProvider>
              </Dialog>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
