import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Tooltip, IconButton } from '@mui/material';
import pdfImg from "../../../Assets/eMCS/pdf.svg";

interface FileUploadDialogProps {
  open: boolean;
  onClose: () => void;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectFile: File | null;
  handleUploadMCSFile: () => void;
  selectFileError:string;
  selectedFileSize:any;
  usdot:any
}

const UploadEMCS150: React.FC<FileUploadDialogProps> = ({ open, onClose, handleFileChange, selectFile, handleUploadMCSFile, selectFileError, selectedFileSize, usdot }) => {
  return (
    <Dialog open={open} maxWidth={false}
      sx={{
        '& .MuiDialog-paper': {
          width: '600px',
          backgroundColor: '#edf2f8',
        },
      }}>
      <DialogTitle id="upload-dialog-title" sx={{fontWeight:'bold', textAlign:'center'}}>Upload eMCS150</DialogTitle>
      <label style={{textAlign:'center', fontWeight:'bold', fontSize:'16px'}}>USDOT - <span style={{color:'#036a89', textDecoration:'underline', cursor:'pointer'}}>{usdot}</span></label>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            border: '2px dashed #cccccc',
            padding: '20px',
            textAlign: 'center',
            backgroundColor: '#ffffff',
          }}
        >
          <img src={pdfImg} alt="PDF" width="50" /><br />
          <label>Drag and Drop file here</label><br />
          <label>Or</label><br />
          <Button variant="contained" component="label"
            sx={{
              backgroundColor: '#f05728',
              color: '#fff',
              textTransform: 'none',
              padding: '8px 16px',
              marginRight: '8px',
            }}>
            Choose File
            <input
              type="file"
              id="fileInput"
              onChange={handleFileChange}
              accept=".pdf"
              style={{ display: 'none' }}
            />
          </Button>

          {selectFile && (
            <Box sx={{ mt: 2, textAlign: 'center' }}>
              <p>Selected File: {selectFile.name}</p>
            </Box>
          )}
        </Box>
        <p style={{color:'red'}}>{selectFileError}</p>
        <p style={{color:'red'}}>{selectedFileSize}</p>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', mb: 3, padding: '10px 20px' }}>
        <Box sx={{ fontSize: '14px', color: '#6c757d' }}>
          <label>Supports only .pdf <br /> Maximum file size: 25MB</label>
        </Box>
        <Button onClick={handleUploadMCSFile} variant="outlined"
          sx={{ width: '125px', border: 'solid 1px #036a89', color: '#036a89', backgroundColor: '#ffffff' }}>
          Save
        </Button>
        <Button onClick={onClose} variant="outlined"
          sx={{ width: '125px', border: 'solid 1px #036a89', color: '#036a89', backgroundColor: '#ffffff' }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadEMCS150;
