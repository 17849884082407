import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  styled,
  InputLabel,
  TableHead,
  TablePagination,
} from "@mui/material";
// import dayjs, { Dayjs } from 'dayjs';
import { tableCellClasses } from '@mui/material/TableCell';

export default function FetchData(props: any) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#1976d2',
      color: theme.palette.common.white,
      fontWeight: 'bold',
      fontSize: '16px'

    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPagePagi = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    props.csRowPrPage(+event.target.value)
    // console.log('fetchData page RowPrPage ::', +event.target.value)
  };

  const handlePageChange = (event: any, newPage: any) => {
    // console.log('fetchData page newPage ::', newPage)
    props.setController({
      ...props.controller,
      pageNo: newPage
    });
    props.csPageNumber(newPage)
  };
  return (
    <>

      <div style={{ height: 400, width: '100%' }}>
        {
          props.response && props.response.length > 0 ?
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700, mt: 2 }} aria-label="customized table">
                <TableHead>
                  <TableRow key={props.passengersCount}>
                    {props.keyObj.map((data: any, index: number) =>
                      <StyledTableCell align="left" key={index}>{data}</StyledTableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.response?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any, index: number) => (
                    <StyledTableRow key={index}>
                      {props.keyObj.map((data: any, index: number) =>
                        <StyledTableCell component="th" align="left" scope="row" key={index}>{row[data]}</StyledTableCell>
                      )}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            :
            <InputLabel style={{ alignItems: "center" }}>No Data Found</InputLabel>
        }


        {
          (props.response && props.isPagination == false) &&

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={props.response?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }

        {
          (props.response && props.isPagination == true) &&
          <TablePagination
            component="div"
            rowsPerPageOptions={[10]}
            onPageChange={handlePageChange}
            page={props.controller.pageNo}
            count={props.passengersCount}
            rowsPerPage={props.controller.pageSize}
            onRowsPerPageChange={handleChangeRowsPerPagePagi}
          />
        }

      </div>

    </>
  );
}
