import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextButton = styled(Button)`
  &.MuiButton-root{
    padding: 0px;
    text-transform: none;
    justify-content: flex-start;
    width: fit-content;
  }
`;
