import * as React from "react";
import {
  TableContainer,
  Paper,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Select,
  InputLabel,
  MenuItem,
  Button,
  TextField,
  Autocomplete,
  DialogTitle,
  Backdrop,
  CircularProgress,
  TextFieldProps,
  FormControl,
  Grid,
  Box,
} from "@mui/material";
import { useEffect, useState } from "react";
import DashboardLinksService from "../../Services/dashboard-links.service";
import DashboardService from "../../Services/dashboard.service";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { TAX_YEAR_END, timeOptions, timeZones } from "../../Utils/Constants";

interface CountryCode {
  id: number;
  name: string;
  isoCode: string;
  phoneCode: string;
}

interface InputState {
  mobileNumber: string;
  wasConversion: string;
  refNumber: string;
  disposition: string;
  remarks: string;
  followUpDate: string;
  followUpTime: string;
  followUpTimeZone: string;
}

export default function NotifyCsAgent({ selectedRef, customerInFunnelId, customerEmail,customerCaseStatus,setNotificationsData ,removeClosedCase , isOutBoundCall }: { selectedRef: any; customerInFunnelId:any; customerEmail:any, customerCaseStatus:any,setNotificationsData:any, removeClosedCase:any, isOutBoundCall:boolean}) {
  const styles = {
    textFieldInside: {
      width: '300px',
      padding: '8px 0px 2px 8px',
      borderRadius: '4px',
      backgroundColor: '#fff',
      border: '1px solid #707070',
    },
    fieldStyle: {
      height: '40px',
      width: '400px',
      borderColor: '1px solid #707070',
    },
    halfFieldStyle: {
      height: '40px',
      width: '200px',
    },
    countryStyle: {
      height: '43px',
      width: '106px',
      fontSize: '14px',
      marginTop: '7px',
    },
    btnStyle: {
      fontSize: '14px',
      height: '40px',
      marginTop: '16px',
      width: '140px',
      fontWeight: 'bold',
    },
  };

  const [errors, setErrors] = useState({
    mobileNumber: '',
    wasConversion: '',
    disposition: '',
    remarks: '',
    refNumber: '',
    followUpDate: '',
    followUpTime: '',
    followUpTimeZone: '',
  });

  const [closeCaseDD, setCloseCaseDD] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(true);
  const [isFormIsValid, setIsFormIsValid] = useState(false);
  const [inputState, setInputState] = useState<InputState>({
    mobileNumber: "",
    wasConversion: "",
    refNumber: "",
    disposition: "",
    remarks: "",
    followUpTime: "",
    followUpTimeZone: "",
    followUpDate: dayjs().format('YYYY-MM-DD'),
  });
  const [countryCodes, setCountryCodes] = useState<CountryCode[]>([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>("");
  const [respDisposition, setRespDisposition] = useState<string[]>([]);
  const [options, setOptions] = useState<string[]>([]);
  const [acceptClicked, setAcceptClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

const gerRefNumber=async()=>{
    setLoading(true);
    try {
        const response = await DashboardService.csList('email', customerEmail);
        const { data } = response;
        if(data){
            const filteredData = data.map((code:any) => code.referenceNumber);
            setIsPhoneVerified(data[0].user.isMobileVerified)
            setInputState({
                mobileNumber: data[0].user.isMobileVerified ? "" : data[0].user.mobileNum,
                wasConversion: "",
                refNumber: "",
                disposition: "",
                remarks: "",
                followUpDate: "",
                followUpTimeZone: "",
                followUpTime: "",
              });
            setOptions(filteredData)
        }
        setLoading(false);
      } catch (err) {
        alert('Something went wrong, Please try again');
        setLoading(false);
      }
}
  const getDisposition = async () => {
    try {
      const resp = await DashboardService.csDisposition();
      setRespDisposition(resp?.data);
    } catch (err) {
        alert('Something went wrong, Please try again');
    }
  };

  const getCountryCode = async () => {
    try {
      const response = await DashboardLinksService.getCountryCodes();
      const { data } = response;
      if (Array.isArray(data) && data.length > 0) {
        const filteredData = data.filter(code => code.isoCode !== "CA");
        setCountryCodes(filteredData);
        setSelectedCountryCode(filteredData[0]?.phoneCode || '');
      }
    } catch (error) {
      console.error("Error fetching country codes:", error);
    }
  };

  const openCloseCaseDialog = () => {
    gerRefNumber()
    setCloseCaseDD(true);
    getDisposition();
    getCountryCode();
  };

  const validateField = (name: string, value: any) => {
    let error = '';
    switch (name) {
      case 'mobileNumber':
        if (value && !/^((?!(0))[0-9]{10})$/.test(value)) {
          error = 'Please enter a valid mobile number';
        }
        break;
      case 'wasConversion':
      case 'disposition':
      case 'remarks':
      case 'followUpDate':  
      case 'followUpTimeZone':
      case 'followUpTime':  
        if (!value) {
          error = 'This field is required';
        }
        break;
      case 'refNumber':
        if (!value || !/^([0-9]{10})$/.test(value)) {
          error = 'Please enter a valid reference number';
        }
        if (value && !options.includes(value)) {
            error = 'Please select the valid reference number from the dropdown.';
          }
        break;
      default:
        break;
    }
    setErrors(prevErrors => ({ ...prevErrors, [name]: error }));
    return error === '';
  };

  const handleInputChange = (name: string, value: any) => {
    setInputState(prevState => ({
      ...prevState,
      [name]: value,
    }));
    validateField(name, value);
  };

  const validateForm = () => {
    const mobileNumberValid = !isPhoneVerified ? validateField('mobileNumber', inputState.mobileNumber) : true;
    const wasConversionValid = validateField('wasConversion', inputState.wasConversion);
    const refNumberValid = inputState.wasConversion === 'Yes' ? validateField('refNumber', inputState.refNumber) : true;
    const dispositionValid = validateField('disposition', inputState.disposition);
    const remarksValid = validateField('remarks', inputState.remarks);
    const followUpDate = inputState.disposition === 'Follow Up Request' ? validateField('followUpDate', inputState.followUpDate) : true;
    const followUpTime = inputState.disposition === 'Follow Up Request' ? validateField('followUpTime', inputState.followUpTime): true;
    const followUpTimeZone = inputState.disposition === 'Follow Up Request' ? validateField('followUpTimeZone', inputState.followUpTimeZone): true;

    const isValid = mobileNumberValid && wasConversionValid && refNumberValid && dispositionValid && remarksValid && followUpDate && followUpTime && followUpTimeZone;
    setIsFormIsValid(isValid);
    return isValid;
  };

  const handleSave = async () => {
    if (validateForm()) {
      const formattedDate = inputState.followUpDate;
        const caseClosureDetail = {
            isConversion: inputState.wasConversion == 'Yes' ? true : false,
            // referenceNumber: inputState.refNumber,
            dispositionText: inputState.disposition,
            remarks: inputState.remarks,
            ...(isPhoneVerified ? {} : {
              customerPhoneNumber: inputState.mobileNumber,
              regionCode: selectedCountryCode
            }),
            ...(inputState.wasConversion == 'Yes' ?  {
                referenceNumber: inputState.refNumber,
              } : {}),
            ...(inputState.disposition == 'Follow Up Request' ?  {
                timezone: inputState.followUpTimeZone,
                date: formattedDate,
                time: inputState.followUpTime,
              } : {})
          };
          
          const payload = {
            customerInFunnelId: customerInFunnelId,
            caseStatus: "COMPLETED",
            caseClosureDetail: caseClosureDetail,
            pageUrl:typeof window !== 'undefined' ? window.location.pathname : '',
          };
      try {
        const resp = await DashboardService.csCloseDashboardCase(payload);
        if(resp){
            removeClosedCase(customerInFunnelId , isOutBoundCall);
            resetForm();
            setCloseCaseDD(false);
            setNotificationsData(customerInFunnelId);
            const storedOutboundCallAssignmentList = localStorage.getItem('OutboundCallAssignmentList');
            let outboundCallAssignmentList: number[] = [];
            if (storedOutboundCallAssignmentList) {
              outboundCallAssignmentList = JSON.parse(storedOutboundCallAssignmentList);
            }
            // Remove the specific closed customerInFunnelId from the array
            outboundCallAssignmentList = outboundCallAssignmentList?.filter(id => id !== customerInFunnelId);
            // Update localStorage with the modified list
            localStorage.setItem('OutboundCallAssignmentList', JSON.stringify(outboundCallAssignmentList));
            alert('Case is succesfully close');
        }
      } catch (err) {
        alert('Something went wrong, Please try again');
      }
    } else {
      console.log('Form is invalid, show errors');
    }
  };

  const handleCountryCodeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedCountryCode(event.target.value as string);
  };
  
  const acceptCase = async () => {
    if (isLoading) return;

    setIsLoading(true);
    const payload = {
      customerInFunnelId: customerInFunnelId,
      caseStatus: 'ACCEPTED',
    };

    try {
      const resp = await DashboardService.csCloseDashboardCase(payload);
      if (resp) {
        setAcceptClicked(true);
      }
    } catch (err: any) {
      alert(err.response.data.message);
    } finally {
      setIsLoading(false); 
    }
  };

  useEffect(()=>{
    if(customerCaseStatus == 'ACCEPTED'){
        setAcceptClicked(true)
    }
  },[])

  const resetForm = () => {
    setErrors({
      mobileNumber: '',
      wasConversion: '',
      disposition: '',
      remarks: '',
      refNumber: '',
      followUpDate: '',
      followUpTime: '',
      followUpTimeZone: '',
    });
    setInputState({
      mobileNumber: "",
      wasConversion: "",
      refNumber: "",
      disposition: "",
      remarks: "",
      followUpDate: "",
      followUpTime: "",
      followUpTimeZone: "",
    });
  };

   useEffect(() => {
    if (!inputState.followUpDate) {
      setInputState(prevState => ({
        ...prevState,
        followUpDate: dayjs().format('YYYY-MM-DD'), // Set to today's date
      }));
    }
  }, [inputState.followUpDate]);

  return (
    <TableContainer>
        <div style={{backgroundColor:'#f4f4f4'}}>
        {/* <Button variant="contained" style={{fontSize: '15px',padding: '6px 25px', textTransform: 'none'}} onClick={acceptCase}  >Accept Case</Button> 
        <Button variant="contained" style={{fontSize: '15px',padding: '6px 25px', textTransform: 'none', marginLeft: '24px'}} onClick={openCloseCaseDialog} >Close Case</Button>  */}
        <Button variant="contained" style={{fontSize: '15px', minWidth: '120px', textTransform: 'none', margin: '10px'}} sx={{
          "&.Mui-disabled": {
            backgroundColor: '#1976d2',
            color: '#fff',
            opacity: '0.3',
          }
        }} onClick={acceptCase} disabled={acceptClicked || isLoading}>Accept Case</Button> 
      <Button variant="contained" style={{fontSize: '15px', minWidth: '120px', textTransform: 'none', margin: '10px'}} sx={{
        "&.Mui-disabled": {
          backgroundColor: '#1976d2',
          color: '#fff',
          opacity: '0.3',
        }
      }} onClick={openCloseCaseDialog}  disabled={!acceptClicked}>Close Case</Button> 
        </div>
        {loading && (
      <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 999 }}
          open={true}
      >
          <CircularProgress color="inherit" />
      </Backdrop>
    )}
      <Dialog open={closeCaseDD}>
        <DialogContent style={{ width: '460px' }}>
        <DialogTitle style={{textAlign:'center', fontWeight:'500'}}>Close case for <br />{customerEmail}</DialogTitle>
          <DialogContentText style={{ paddingRight: '0px', paddingLeft: '30px' }}>
            {!isPhoneVerified && (
              <>
                <InputLabel id="mobileNumber-label" style={{ fontWeight: '500', fontSize: '14px', color: 'black' }}>
                  Customer’s Mobile Number
                </InputLabel>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  {countryCodes.length > 1 && (
                    <Select
                      value={selectedCountryCode}
                      onChange={(e:any)=>handleCountryCodeChange(e)}
                      style={styles.countryStyle}
                      sx={{ width: '100px' }}
                    >
                      {countryCodes.map(code => (
                        <MenuItem key={code.id} value={code.phoneCode}>
                          {`${code.phoneCode} - ${code.isoCode}`}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  <TextField
                    autoFocus
                    margin="dense"
                    id="mobileNumber"
                    style={{ width: countryCodes.length > 1 ? '274px' : '389px' }}
                    value={inputState.mobileNumber}
                    onChange={(e) => handleInputChange('mobileNumber', e.target.value)}
                    variant="standard"
                    sx={{
                      ...styles.textFieldInside,
                      border: errors.mobileNumber ? '1px solid red' : '1px solid #707070',
                    }}
                    InputProps={{
                      disableUnderline: true,
                      inputProps: {
                        maxLength: 10,
                        inputMode: 'numeric',
                      },
                      style: { fontSize: '14px' },
                    }}
                    placeholder="Enter Mobile Number"
                  />
                </div>
                {errors.mobileNumber && <div style={{ color: 'red', fontSize: '12px' }}>{errors.mobileNumber}</div>}
                <label style={{ fontSize: '12px' }}>
                  Ask for customer’s mobile number, that they would like to link to their eForm2290 account (optional)
                </label>
              </>
            )}
            <InputLabel
              id="wasConversion-label"
              style={{ fontWeight: '500', fontSize: '14px', color: 'black', marginTop: '25px' }}
            >
              Was it a conversion? *
            </InputLabel>
            <Select
              value={inputState.wasConversion}
              displayEmpty
              fullWidth
              style={styles.fieldStyle}
              sx={{ width: '180px', color: inputState.wasConversion === '' ? '#b3b3b3' : 'black' }}
              onChange={(e) => handleInputChange('wasConversion', e.target.value)}
              error={errors.wasConversion !== ''}
            >
              <MenuItem value={''} disabled>
                Select
              </MenuItem>
              <MenuItem value={'Yes'}>Yes</MenuItem>
              <MenuItem value={'No'}>No</MenuItem>
            </Select>
            {errors.wasConversion && <div style={{ color: 'red', fontSize: '12px' }}>{errors.wasConversion}</div>}
            
            {inputState.wasConversion === 'Yes' && (
              <>
                <InputLabel
                  id="refNumber-label"
                  style={{ fontWeight: '500', fontSize: '14px', color: 'black', marginTop: '25px' }}
                >
                 Enter the reference number *
                </InputLabel>
                <Autocomplete
                  options={options}
                  value={inputState.refNumber}
                  getOptionLabel={(option) => option || ''}
                  onInputChange={(event, newValue) => handleInputChange('refNumber', newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      style={{ width: '389px', marginTop: '8px' }}
                      placeholder="Enter reference number"
                      sx={{
                        ...styles.textFieldInside,
                        border: errors.refNumber ? '1px solid red' : '1px solid #707070',
                      }}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      inputProps={{
                        ...params.inputProps,
                        maxLength: 10,
                        inputMode: 'numeric',
                    }}
                    />
                  )}
                />
                {errors.refNumber && <div style={{ color: 'red', fontSize: '12px' }}>{errors.refNumber}</div>}
              </>
            )}
            
            <InputLabel
              id="disposition-label"
              style={{ fontWeight: '500', fontSize: '14px', color: 'black', marginTop: '25px' }}
            >
             Select the disposition *
            </InputLabel>
            <Select
              value={inputState.disposition}
              displayEmpty
              fullWidth
              style={styles.fieldStyle}
              sx={{ width: '400px', color: inputState.disposition === '' ? '#b3b3b3' : 'black' }}
              onChange={(e) => handleInputChange('disposition', e.target.value)}
              error={errors.disposition !== ''}
            >
              <MenuItem value={''} disabled>
                Select
              </MenuItem>
              {respDisposition.map((disposition, index) => (
                <MenuItem key={index} value={disposition}>
                  {disposition}
                </MenuItem>
              ))}
            </Select>
            {errors.disposition && <div style={{ color: 'red', fontSize: '12px' }}>{errors.disposition}</div>}

             {/* Follow Up Request */}
            {inputState.disposition === 'Follow Up Request' && (
              <>
                {/* Follow Up Select Date */}
                <InputLabel
                  id="followup-date-label"
                  style={{ fontWeight: '500', fontSize: '14px', color: 'black', marginTop: '25px' }}
                >
                  Follow up date *
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  
                <DatePicker
                  value={dayjs(inputState.followUpDate, 'YYYY-MM-DD')}
                  onChange={(newValue) => {
                    if (newValue) {
                      const formattedDate = dayjs(newValue).startOf('day').format('YYYY-MM-DD');
                      handleInputChange('followUpDate', formattedDate);
                      console.log('Date to submit:', dayjs(inputState.followUpDate));
                    }
                  }}
                  format="YYYY-MM-DD"
                  minDate={dayjs().startOf('day')} // Minimum date is today
                  maxDate={TAX_YEAR_END} // Maximum date is the end of the tax year
                  slotProps={{
                    textField: {
                      size: 'small',
                    },
                  }}
                  sx={{
                    width: { xs: '100%', md: '100%', lg: '400px' },
                    '& .MuiFormHelperText-sizeSmall': {
                      position: 'absolute',
                      top: '36px',
                      margin: '4px 0px',
                      letterSpacing: '0px',
                    },
                    
                  }}
                  disableFuture={false} // Allow selecting future dates up to maxDate
                />
                </LocalizationProvider>
                {errors.followUpDate && <div style={{ color: 'red', fontSize: '12px' }}>{errors.followUpDate}</div>}
                {/* End of Follow Up Date */}

                <InputLabel
                  id="followup-time-label"
                  style={{ fontWeight: '500', fontSize: '14px', color: 'black', marginTop: '25px' }}
                >
                  Follow up time *
                </InputLabel>
                <Box sx={{ width: '400px' }}>
                <Grid container spacing={1}>
                <Grid item xs={6}>
                  <FormControl>
                    <Select
                      value={inputState.followUpTimeZone}
                      displayEmpty
                      style={styles.halfFieldStyle}
                      sx={{ color: inputState.followUpTimeZone === '' ? '#b3b3b3' : 'black' }}
                      onChange={(e) => handleInputChange('followUpTimeZone', e.target.value)}
                      error={errors.followUpTimeZone !== ''}
                    >
                      <MenuItem value={''} disabled>
                        Select Timezone
                      </MenuItem>
                      {timeZones.map((zone, index) => (
                         <MenuItem key={index} value={zone}>
                            {zone}
                        </MenuItem>       
                      ))}
                    </Select>
                    {errors.followUpTimeZone && <div style={{ color: 'red', fontSize: '12px' }}>{errors.followUpTimeZone}</div>}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                <FormControl>
                    <Select
                      value={inputState.followUpTime}
                      displayEmpty
                      style={styles.halfFieldStyle}
                      sx={{ color: inputState.followUpTime === '' ? '#b3b3b3' : 'black' }}
                      onChange={(e) => handleInputChange('followUpTime', e.target.value)}
                      error={errors.followUpTime !== ''}
                    >
                      <MenuItem value={''} disabled>
                        Select Time
                      </MenuItem>
                      {timeOptions.map((time, index) => (
                        <MenuItem key={index} value={time}>
                          {time}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.followUpTime && <div style={{ color: 'red', fontSize: '12px' }}>{errors.followUpTime}</div>}
                  </FormControl>
                </Grid>
              </Grid>
              </Box>
              </>
            )}
          {/* End of Follow Up Request */}
            
            <InputLabel
              id="remarks-label"
              style={{ fontWeight: '500', fontSize: '14px', color: 'black', marginTop: '25px' }}
            >
             Agent&apos;s log *
            </InputLabel>
            <TextField
              variant="standard"
              fullWidth
              style={{ width: '390px', marginTop: '8px' }}
              value={inputState.remarks}
              onChange={(e) => handleInputChange('remarks', e.target.value)}
              multiline
              rows={4}
              sx={{
                ...styles.textFieldInside,
                border: errors.remarks ? '1px solid red' : '1px solid #707070',
              }}
              InputProps={{
                disableUnderline: true,
                style: { fontSize: '14px' },
              }}
              inputProps={{
                maxLength: 255,
              }}
              placeholder="Enter Remarks"
            />
            {errors.remarks && <div style={{ color: 'red', fontSize: '12px' }}>{errors.remarks}</div>}
          </DialogContentText>
          <DialogActions style={{ display: 'flex', justifyContent: 'center',paddingRight: '24px', paddingBottom: '16px' }}>
            <Button variant="outlined" fullWidth style={styles.btnStyle} sx={{ width: { lg: '180px', textTransform: 'none' } }} onClick={() => { setCloseCaseDD(false);resetForm();}}>Close</Button>
            <Button variant="contained" fullWidth style={{ ...styles.btnStyle, textTransform: 'none' }} onClick={handleSave}>
          Save
        </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </TableContainer>
  );
  }