import React, { useState } from 'react';
import { Box, Typography, Button, Link, TableBody, Grid, styled, Paper, Table, TableContainer, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import mcsService from '../../Pages/Emcs150/Services/mcs.service';
import GlobalWrapper from '../GlobalComponentWrapper/GlobalWrapper';

export const styles = {
    button:{
        fontWeight: 500,
        height: '40px',
        width: '175px',
        margin: '15px 15px 10px 0px',
        fontSize: '15px',
        textTransform: 'none'
    },
    textFieldInsideDisable : {
        '& .MuiInputBase-input': {
          backgroundColor: '#efefef',
          height: '10px'
        },
    },
    textField : {
      fontSize:'14px',
      fontWeight:600 , 
      marginBottom:'10px',
    }, 
    textFieldInside : {
      '& .MuiInputBase-input': {
        backgroundColor: 'white',
        height:'10px'
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#cdd4d9',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#cdd4d9',
        },
      },
    },
};

const CheckForMCS150 = ({customerUSDOTdata, ein, setHideMCSSection}: any) => {
    
    const CommonStyledTable = styled(Table)({
        "& .MuiTableCell-root": {
        padding: '6px 10px',
        border: 'none',
        },
    });

    const [dialogType, setDialogType] = useState<'notInterested' | 'yesInterested' | null>(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [remarks, setRemarks] = useState('');
    const [remarkError, setRemarkError] = useState('');

    const handleDialogClose = () => {
        setDialogType(null);
        setRemarks('');
        setRemarkError('');
    };

    const handleSave = (action:any) => {
        if (!remarks.trim() && action=="takeAction") {
            setRemarkError('Remarks cannot be empty');
            return;
        }
        const payload = {
            mappingKey: customerUSDOTdata.mappingKey,
            preference: dialogType === 'notInterested' ? 'NOT_INTERESTED' : 'INTERESTED',
            remarks: remarks,
            interactionType:'INBOUND_EFORM',
        };
        setIsLoading(true);

        mcsService.mcsSavePrefrence(payload)
            .then((response) => {
            if (response.status === 200) {
                setRemarks('');
                setRemarkError('');
                handleDialogClose();
                setHideMCSSection(true)
            } else {
                throw new Error('API request failed with status ' + response.status);
            }
            setIsLoading(false);
            })
            .catch(() => {
            setIsLoading(false);
            alert('Something went wrong, Please try again');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
       <>
       <GlobalWrapper isLoading={isLoading}>
        <TableContainer component={Paper} sx={{mt:2}}>
            <CommonStyledTable aria-label="Filing Actions" size="small">
            <TableBody>
            <Box borderRadius="8px" marginTop="10px">
                <Box sx={{backgroundColor:'#efefef', padding:" 10px 16px"}}>
                    <Typography variant="subtitle1" fontWeight="bold" sx={{backgroundColor:'#efefef', fontSize:'16px'}}>
                    Check for MCS150
                    </Typography>
                </Box>

                <Box padding="16px">
                    <Typography variant="body1" sx={{fontSize:'16px'}}>
                    USDOT # - <strong>{customerUSDOTdata?.usdot}</strong>
                    </Typography>
                    <Typography variant="body1" marginTop="8px" sx={{fontSize:'16px'}}>
                    Due By - <strong>{customerUSDOTdata.biennialUpdateDue}</strong>
                    </Typography>

                    <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="16px">
                        <Button
                            variant="outlined"
                            sx={{
                            borderColor: '#1976d2',
                            color: '#1976d2',
                            textTransform: 'none',
                            minWidth: '30%',
                            '&:hover': {
                                borderColor: '#115293',
                            },
                            }}
                            onClick={() => setDialogType('notInterested')}
                        >
                            Not Interested
                        </Button>

                        <Button
                            variant="contained"
                            sx={{
                            backgroundColor: '#1976d2',
                            color: '#fff',
                            textTransform: 'none',
                            minWidth: '30%',
                            '&:hover': {
                                backgroundColor: '#115293',
                            },
                            }}
                            onClick={()=>handleSave("noAction")}
                        >
                            Yes Interested
                        </Button>

                        <Link href="#" underline="always" sx={{ color: '#1976d2', marginLeft: '8px' }}  onClick={() => setDialogType('yesInterested')}>
                            Add Remarks
                        </Link>
                    </Box>
                </Box>
            </Box>
            </TableBody>
            </CommonStyledTable>
        </TableContainer>
        </GlobalWrapper>

        <Dialog
          open={dialogType !== null}
          fullWidth
          maxWidth="sm"
          sx={{
            '& .MuiDialog-paper': {
              width: '500px',
            },
          }}
        >
          <DialogTitle sx={{fontSize:'21px',  textAlign:'center'}}>
            {dialogType === 'notInterested' ? 'Mark Customer Not Interested' : `Customer ${ein} `}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              {dialogType === 'yesInterested' && (
                <>
                  <Grid item xs={12}>
                    <div style={styles.textField}>USDOT#</div>
                    <TextField
                      placeholder="USDOT#"
                      name="usdot"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      value={customerUSDOTdata?.usdot || ''}
                      sx={{
                        ...styles.textFieldInside,
                        ...styles.textFieldInsideDisable,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div style={styles.textField}>Due By</div>
                    <TextField
                      placeholder="Due By"
                      name="DueBy"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      value={customerUSDOTdata?.biennialUpdateDue || ''}
                      sx={{
                        ...styles.textFieldInside,
                        ...styles.textFieldInsideDisable,
                      }}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <div style={styles.textField}>Remarks</div>
                <TextField
                  placeholder="Add Remarks"
                  fullWidth
                  multiline
                  rows={3}
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                  error={!!remarkError}
                />
                {remarkError && (
                  <div style={{ color: 'red', marginTop: '4px' }}>
                    {remarkError}
                  </div>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ alignItems: 'center', justifyContent: 'center', mb:2 }}>
            <Button onClick={handleDialogClose} variant="outlined" sx={styles.button}>
              Cancel
            </Button>
            <Button  onClick={()=>handleSave("takeAction")} variant="contained" sx={styles.button}>
            {dialogType === 'yesInterested' ? 'Yes Interested' : 'Save'}
            </Button>
          </DialogActions>
        </Dialog>
       </>
    );
};

export default CheckForMCS150;
