import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { TextField, TableCell, styled, TableContainer, Table, TableHead, TableRow, TableBody, Paper, Dialog, DialogTitle, DialogContent, Grid, DialogActions, IconButton, Container, AppBar, InputBase, Tab, Tabs, Typography, Pagination, PaginationItem, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search'; // Search Icon
import Header from "./Header";
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import Checked from "../../Assets/eMCS/checked.svg";
import NIpast from "../../Assets/eMCS/blocked.svg";
import AddCustomerDialog from './Common/AddNewCustomer';
import mcsService from './Services/mcs.service';
import GlobalWrapper from '../../Components/GlobalComponentWrapper/GlobalWrapper';
import NotInterestedDialog from './Common/NotInterestedDialog';
import NotInterested from "../../Assets/eMCS/not-interested.svg"

export const styles = {
  tableContainer : {
      border: '1px solid #707070', borderRadius: '4px',margin: '0 auto',marginTop:'10px'
  },
  searchBar:{
    border: '1px solid #ccc',
    padding: '0 10px',
    borderRadius: '4px',
    backgroundColor: '#fff',
    width: '400px',
    height: '40px',
  },
  button:{
    height: '40px', textTransform: 'none'
  },
  textField : {
    fontSize:'14px',
    fontWeight:600 , 
    marginBottom:'10px',
}, 
textFieldInside: {
  '& .MuiInputBase-input': {
    backgroundColor: 'white',
    height: '10px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#cdd4d9',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#cdd4d9',
    },
  },
  '& .MuiFormHelperText-root': {
    marginLeft:'4px'
  },
},

textAreaBox: {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#cdd4d9',
    },
    '&:hover fieldset': {
      borderColor: '#cdd4d9',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#cdd4d9',
    },
    backgroundColor: 'white',
  },
},

};

export default function EmcsForm() {
  const CustomizedTableCell = styled(TableCell)(() => ({
    fontWeight: 'bold',
    fontSize: '14px',
    wordBreak: 'break-word',
    padding: '4px 8px',
    textAlign:'left',
    backgroundColor:'#c0d2e5',
    color:'#484848',
  }));
  const CustomizedTableData = styled(TableCell)(() => ({
    border: '1px solid #c0d2e5',
    fontSize: '14px',
    textAlign:'left',
    wordBreak: 'break-word',
    padding: '4px 8px',
  }));

  const navigate = useNavigate();
  const [notInterested, setNotInterested] = useState(false);
  const [orderBy, setOrderBy] = useState('timeSpent');
  const [order, setOrder] = useState<'asc' | 'desc' | null>();
  const [addNewCustomerModal, setAddNewCustomerModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [selectedTabValue, setSelectedTabValue] = React.useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [isLoading, setIsLoading] = React.useState(false);
  const [userClickedData, setUserClickedData] = useState<Record<string, any>>({});
  const [addedEmail, setAddedEmail] = useState('')
  const [isRegisterNewUser, setIsRegisterNewUser] = useState(false)
  const completedFile = ["PAYMENT_COMPLETED", "MARK_SUBMITTED","UPLOADED_EMCS", "MARK_PROCESSING", "MARK_COMPLETED"]

  // useEffect(()=>{
  //   getEmcsCustomerData()
  // },[ order])

  const handleDataSearch =(flow:any, value?:any)=>{
    if(flow=='search'){
    setCurrentPage(1);
    getEmcsCustomerData(1,"asc",selectedTabValue)
    }
    if(flow=='pageChange'){
      getEmcsCustomerData(value)
    }
  }

    const handlePageChange = (event: any, value: any) => {
      setCurrentPage(value);
    };
  
  const getEmcsCustomerData = async (page?: number, orders?: string, tab?: string) => {
    if(searchTerm == ""){
      return
    }
    setFilteredData([]);
    setIsLoading(true)
    if (!tab && !selectedTabValue) {
      setSelectedTabValue("ALL");
    }
    try {
      const resp = await mcsService.getEmcsData(searchTerm, page ?? currentPage, orders ?? order, tab || selectedTabValue || 'ALL');
      if (resp?.status === 200) {
        setFilteredData(resp?.data?.customers);
        setTotalPage(resp?.data?.totalPages)
        setIsLoading(false)
      }
      else {
        setIsLoading(false)
        throw new Error('API request failed with status ' + resp?.status);
      }
    
    } catch (err) {
        setIsLoading(false)
        alert('Something went wrong, Please try again');
    }
  };

  const refreshPage = () => {
    window.location.reload();
  }

const updateStateOnClick = (data:any) => {
  setIsLoading(true);
  const payload = {
    mappingKey: data?.mappingKey,
    preference: "INTERESTED",
    remarks: ''
  }
  return mcsService.mcsSavePrefrence(payload)
  .then((response) => {
    if (response.status === 200) {
      setIsLoading(false)
    }
    else {
      setIsLoading(false)
      throw new Error('API Error : ' + response.status);
    }
  })
  .catch(() => {
    setIsLoading(false)
    alert('Something went wrong, Please try again');
  });
};

const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
  setSearchTerm(e.target.value);
};



const handleSort = (property: string) => {
  const isAscending = orderBy === property && order === 'asc';
  setOrder(isAscending ? 'desc' : 'asc');
  setOrderBy(property);
  getEmcsCustomerData();
};

  const handleTabChange = (event:any, newValue:any) => {
    setCurrentPage(1);
    setOrder("asc");
    setSelectedTabValue(newValue);
    getEmcsCustomerData(1,"asc",newValue);
    
  };

  const handleClick = (row: any, e: any) => {
    const buttonText = e.target.innerText;
    setIsRegisterNewUser(false);
    setAddNewCustomerModal(true);
    setUserClickedData(row);
  
    if (buttonText !== 'Start') {
      navigate({
        pathname: '/emcs150/file-mcs150',
        search: `?mappingKey=${row?.mappingKey}&dotNumber=${row?.usdot}`,
      });
    }
  };
  

  const handleNewCustomerAdded = () => {
    setSelectedTabValue("ALL")
    getEmcsCustomerData()
  };

  const splitEmail = (email:any) => {
    if (!email) return ['', ''];
    const parts = email?.split('@');
    return parts.length === 2 ? parts : ['', ''];
  };

  const handleNotInterestedSuccess = () => {
    getEmcsCustomerData();
  };

  useEffect(() => {
    if (addedEmail) {
      getEmcsCustomerData();
    }
  }, [addedEmail]);

  return (
    <>
    <GlobalWrapper isLoading={isLoading}>
      <>
      <Header isLogoutCta={true}/>
      <Container maxWidth={false} style={{padding:'0 40px'}}>
        <Box sx={{ flexGrow: 1, ml:3, mr:3, mb:3 }}>
        <div style={{ backgroundColor: '#e8eef3' }}>
          <div style={{margin:"0px 30px"}}>
          <AppBar position="static" color="transparent" elevation={0}>
              <h1>List of Customers</h1> 
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <InputBase
                sx={styles.searchBar}
                placeholder="Search by Name, Email, USDOT or EIN"
                inputProps={{ 'aria-label': 'search' }}
                value={searchTerm}
                onChange={handleSearch}
              />
             <Button variant="contained" sx={{ ml: 1, backgroundColor: '#397188', height: '40px' }} onClick={()=>handleDataSearch('search')} >
                <SearchIcon />
              </Button>
              <Button variant="contained" sx={{ ml: 'auto', backgroundColor: '#016b89', height: '40px', textTransform: 'none' }} onClick={refreshPage}>
                <RefreshIcon /> &nbsp;&nbsp; Refresh
              </Button>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <Tabs
                    value={selectedTabValue}
                    onChange={handleTabChange}
                    aria-label="customer tabs"
                    sx={{
                      mt: 2,
                      '.MuiTabs-flexContainer': {
                        borderBottom: 'none',
                      },
                      '.MuiTab-root': {
                        textTransform: 'none',
                        fontSize: '16px',
                        fontWeight: 600,
                        color: '#397188',
                        '&.Mui-selected': {
                          color: '#fff',
                          backgroundColor: '#397188',
                          borderRadius: '5px',
                        },
                      },
                      '.MuiTabs-indicator': {
                        display: 'none',
                      },
                    }}
                  >
                    <Tab label="All" value="ALL" />
                    <Tab label="Suspended" value="SUSPENDED" />
                    <Tab label="Completed" value="PAYMENT_COMPLETED" />
                    <Tab label="Not Interested" value="NOT_INTERESTED" />
                  </Tabs>
                </Grid>  
                <Grid item xs={6} md={6}>
                  <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2, alignItems: 'center', backgroundColor: '#e8eef3', py:2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mr: 3 }}>
                        <img src={Checked} alt="Completed" style={{ width: 30, height: 30 }} />
                        <Typography variant="h6" sx={{ ml: 1, fontSize:'14px' }}>Completed</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', mr: 3 }}>
                        <img src={NIpast} alt="Not Interested in the past" style={{ width: 30, height: 30 }} />
                        <Typography variant="h6" sx={{ ml: 1, fontSize:'14px' }}>Not Interested in the past</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <img src={NotInterested} alt="Not Interested in the past" style={{ width: 30, height: 30 }} />
                        <Typography variant="h6" sx={{ ml: 1, fontSize:'14px' }}>Mark Not Interested</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

          </AppBar>
          <TableContainer component={Paper} sx={styles.tableContainer}>
            <Table aria-label="customized table" style={{ borderCollapse: 'collapse', width: '100%' }}>
              <TableHead sx={{backgroundColor: '#d9d9d9'}}>
                  <TableRow style={{ height: '50px' }}>
                      <CustomizedTableCell >Email</CustomizedTableCell>
                      <CustomizedTableCell >Name</CustomizedTableCell>
                      <CustomizedTableCell >Mobile <br /> Phone</CustomizedTableCell>
                      <CustomizedTableCell >Legal Business <br /> Name</CustomizedTableCell>
                      <CustomizedTableCell >EIN</CustomizedTableCell>
                      <CustomizedTableCell>USDOT#</CustomizedTableCell>
                      <CustomizedTableCell  style={{ position: 'relative' , minWidth:'63px'}}
                          >
                            Due By
                            <span onClick={() => handleSort('dueBy')} style={{position: 'absolute', right: 'auto', top: '50%', transform: 'translate(-50%, -50%)', marginLeft:'10px', lineHeight: '0.8', cursor: 'pointer' }}>
                              ▲ <br />▼
                          </span>
                            </CustomizedTableCell>
                      <CustomizedTableCell >Remark</CustomizedTableCell>
                      <CustomizedTableCell >Action</CustomizedTableCell>
                  </TableRow>
                </TableHead>
                { filteredData?.length  ? (
                  <TableBody>
                  {filteredData?.map((row:any, index:any) => {
                  const [username, domain] = splitEmail(row?.email);
                  return (
                    <TableRow key={index}>
                      <CustomizedTableData>
                      {username}
                      {domain && `@${domain}`}
                      </CustomizedTableData>
                      <CustomizedTableData>{row?.name}</CustomizedTableData>
                      <CustomizedTableData>{row?.phone}</CustomizedTableData>
                      <CustomizedTableData sx={{maxWidth:'150px'}}>{row?.legalBusinessName}</CustomizedTableData>
                      <CustomizedTableData>{row?.ein}</CustomizedTableData>
                      <CustomizedTableData>{row?.usdot}</CustomizedTableData>
                      <CustomizedTableData>{row?.biennialUpdateDue}</CustomizedTableData>
                      <CustomizedTableData sx={{maxWidth:'150px'}}>{row?.remarks}</CustomizedTableData>
      
                      <CustomizedTableData sx={{ width: '180px' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          {(row?.filingStatus === "SUSPENDED") && (
                            <Button
                              variant="contained"
                              onClick={(e) => handleClick(row,e)}
                              sx={{
                                backgroundColor: '#016b89',
                                marginLeft: 2,
                                ...styles.button,
                              }}
                            >
                             Continue
                            </Button>
                          )}

                          {
                            (row?.filingStatus !== "SUSPENDED") && (
                              <Button
                                variant="contained"
                                onClick={(e) => {
                                  // if (row?.filingStatus === "NEW" || row?.filingStatus === "ADDED") {
                                  //   updateStateOnClick(row);
                                  // }
                                  handleClick(row,e);
                                }}
                                sx={{
                                  backgroundColor: '#016b89',
                                  marginLeft: 2,
                                  ...styles.button,
                                }}
                              >
                                {row?.filingStatus === "NEW" || row?.filingStatus === "ADDED" || row?.filingStatus === "NOT_INTERESTED"
                                  ? 'Start' 
                                  : completedFile.includes(row?.filingStatus) 
                                  ? 'View' 
                                  : 'Continue'
                                }
                              </Button>
                            )
                          }

                            {((row?.filingStatus === "SUSPENDED")) && (
                            <Tooltip title="Mark Customer as Not Interested"><img src={NotInterested} alt="Completed" style={{ width: 30, height: 30,  marginLeft: 15, cursor:'pointer' }}  onClick={() => {setNotInterested(true); setUserClickedData(row)}} /></Tooltip>
                          )}

                          {
                            (row?.filingStatus === "NEW" || 
                            row?.filingStatus === "ADDED" || 
                            (!completedFile.includes(row?.filingStatus) && row?.filingStatus !== "SUSPENDED")) && 
                            row?.filingPreference !== "NOT_INTERESTED" && (
                              <Tooltip title="Mark Customer as Not Interested">
                                <img 
                                  src={NotInterested} 
                                  alt="Completed" 
                                  style={{ width: 30, height: 30, marginLeft: 15, cursor: 'pointer' }}  
                                  onClick={() => { setNotInterested(true); setUserClickedData(row); }} 
                                />
                              </Tooltip>
                            )
                          }

                          {completedFile.includes(row?.filingStatus) && (
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: 2,
                              }}
                            >
                             <Tooltip title="Filing completed for this customer."><img src={Checked} alt="Completed" style={{ width: 30, height: 30 }} /></Tooltip>
                            </Box>
                          )}
                          {(row?.filingPreference === "NOT_INTERESTED" && row?.filingStatus !== "SUSPENDED" && !completedFile.includes(row?.filingStatus)) && (
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: 2,
                              }}
                            >
                             <Tooltip title="Customer was marked Not Interested in the past."><img src={NIpast} alt="Completed" style={{ width: 30, height: 30 }} /></Tooltip>
                            </Box>
                          )}
                        </Box>
                      </CustomizedTableData>

                    </TableRow>
                  );
                })}
      
                  </TableBody>
                ) : (
                <TableBody>
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    <Box sx={{ textAlign: 'center', py: 3 }}>
                      <Typography variant="h6" sx={{ mb: 2, fontSize:'16px', fontWeight:'bold' }}>
                        No record found in your list.
                      </Typography>
                      <Button
                        variant="contained"
                        sx={{ backgroundColor: '#ec5928', color: '#fff' }}
                        onClick={()=>{setAddNewCustomerModal(true); setIsRegisterNewUser(true)}}
                      >
                        Add Customer To Your List
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
                </TableBody>
                )}
            </Table>
          </TableContainer>
          <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', backgroundColor: '#e8eef3', py:2 }}>
          <Pagination
            count={totalPage} 
            page={currentPage}
            siblingCount={1} 
            boundaryCount={1}
            onChange={(e,page) => {setCurrentPage(page);handleDataSearch('pageChange',page)}} 
            renderItem={(item) => (
              <PaginationItem
                {...item}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: '#397188',
                    color: '#fff',
                  },
                  '& .MuiPaginationItem-root': {
                    borderColor: '#397188',
                    color: '#397188',
                  },
                  '&.MuiPaginationItem-previousNext': {
                    color: '#397188',
                  },
                }}
              />
            )}
            shape="rounded"
          />
        </Box>
          </div>
        </div>
        </Box>
      </Container>
      </>
    </GlobalWrapper>

{/* Add new customer Dialog */}
    <AddCustomerDialog
        open={addNewCustomerModal}
        onClose={() => setAddNewCustomerModal(false)}
        styles={styles}
        onCustomerAdded={handleNewCustomerAdded}
        setIsLoading ={setIsLoading}
        setAddedEmail={setAddedEmail}
        setSearchTerm={setSearchTerm}
        setIsRegisterNewUser={setIsRegisterNewUser}
        isRegisterNewUser={isRegisterNewUser}
        userClickedData={userClickedData}
      />

{/* Not Interested Dialog */}
    <NotInterestedDialog
      open={notInterested}
      setNotInterested={setNotInterested}
      userClickedData={userClickedData}
      onSuccess={handleNotInterestedSuccess} 
      setIsLoading={setIsLoading} 
      styles={styles}
    />
    </>
  );
}
