import {
  SET_MESSAGE,
  CLEAR_MESSAGE,
  IS_LOADING,
  STOP_LOADING,
} from "../Actions/types";

const initialState = { message: "", isLoading: false };

export default function (state = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case SET_MESSAGE:
      return <any>{ message: payload, isLoading: false };

    case CLEAR_MESSAGE:
      return { ...state, message: "" };

    case IS_LOADING:
      return { ...state, isLoading: true };

    case STOP_LOADING:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}
