import { SET_ERROR_MESSAGE, CLEAR_ERROR_MESSAGE } from "../Actions/types";

const initialState = {};

export default function (state = initialState, action: any) {
    const { type, payload } = action;

    switch (type) {
        case SET_ERROR_MESSAGE:
            return <any>{ message: payload };

        case CLEAR_ERROR_MESSAGE:
            return { message: "" };

        default:
            return state;
    }
}