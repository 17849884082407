import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import DashboardService from "../../Services/dashboard.service";
import EditIcon from "@mui/icons-material/Edit";
import { ReferenceStatus, months } from "../../Utils/Constants";
import { useEffect } from "react";
import { IS_LOADING } from "../../Actions/types";
import { useAppDispatch } from "../../Store";
import { REFERENCE_STATUS } from "../../common/apis/const";
export default function FilingType({
  reference,
  clearStateAndRefetch,
}: {
  reference: any;
  clearStateAndRefetch: any;
}) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const [usedMonth, setUsedMonth] = React.useState<any>("");
  const [taxperiod, setTaxperiod] = React.useState("");
  const [radioVal, setRadioVal] = React.useState("no");
  const [isStandalonePrefiling , setIsStandalonePrefiling] = React.useState<boolean>(false)
  const referenceStatus = REFERENCE_STATUS.status;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getFillingType = () => {
    const filingTypes = [
      { key: "GWI", text: "Gross Weight Increased" },
      { key: "ME", text: "Mileage Exceeded" },
      { key: "VINC", text: "VIN Correction" },
    ];
    let filingText: any = "Normal Filing";
    if (
      reference?.form2290Model?.amendmentCategory &&
      reference?.form2290Model?.isAmendment
    ) {
      filingText = filingTypes.find(
        (filing): any =>
          filing.key === reference.form2290Model.amendmentCategory
      )?.text;
    }
   
    if ((isStandalonePrefiling && reference?.status === referenceStatus.PS) || (parseInt(reference?.taxPeriod, 10)===new Date().getFullYear()) && isStandalonePrefiling) {
      filingText = "PreFiling"
    }

    return filingText;
  };

  useEffect(() => {
    setTaxperiod(
      reference?.form2290Model
        ? reference?.form2290Model?.taxYear
        : reference?.form8849?.taxYear
    );
    setRadioVal(
      reference?.form2290Model
        ? reference?.form2290Model?.isFinalReturn
          ? "yes"
          : "no"
        : reference?.isFinalReturn
        ? "yes"
        : "no"
    );
    setUsedMonth(
      reference?.form2290Model
        ? reference?.form2290Model?.firstUsedMonth
        : reference?.fum
    );
    const storedValue = localStorage.getItem('isStandAlonePrefiling') === 'true';
    setIsStandalonePrefiling(storedValue);
  }, [reference]);

  const handleSave = () => {
    dispatch({ type: IS_LOADING });
    return DashboardService.csFormUpdate(
      radioVal,
      taxperiod,
      usedMonth,
      reference
    )
      .then(() => {
        clearStateAndRefetch();
        setOpen(false);
      })
      .catch(() => {
        setOpen(false);
      });
  };

  const radioHanlder = (e: any) => {
    setRadioVal(e.target.defaultValue);
  };

  const getCurrentFinancialYear = () => {
    return new Date().getFullYear() - 1;
  };
  const createtaxPeriodYears = (): { actualValue: string, uiValue: string }[] => {
    const date = new Date();
    let curYear = isStandalonePrefiling ? date.getFullYear() + 1 : date.getFullYear();
    const curMonth = date.getMonth();
    const year = curMonth < 6 ? date.getFullYear() - 1 : date.getFullYear();
    if (curMonth < 6) { 
      curYear = isStandalonePrefiling && !reference?.form2290Model?.isAmendment ? year + 1 : year;
    } else {
      curYear = year;
    }
    const numberOfYears = isStandalonePrefiling && !reference?.form2290Model?.isAmendment ? 4 : 3;
    const result = [];
    for (let i = 0; i < numberOfYears; i++) {
      const actualValue = (curYear - i).toString();
      const uiValue = `July ${actualValue} - June ${Number(actualValue) + 1}`;
      result.push({ actualValue, uiValue });
    }
    return result;
  };


  const currentFinancialYear = getCurrentFinancialYear();

  return (
    <TableContainer component={Paper}>
      <Table aria-label="Filing Details" size="small">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Filing Type
            </TableCell>
            <TableCell>{getFillingType()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Final Return
            </TableCell>
            <TableCell>
              {reference && reference.isFinalReturn ? "Yes" : "No"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              First Used Month
            </TableCell>
            <TableCell>
              {reference?.form2290Model
                ? months[+reference?.form2290Model?.firstUsedMonth - 1]
                : months[+reference?.fum - 1]}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Tax Period
            </TableCell>
            <TableCell>
              {reference?.form2290Model
                ? reference?.form2290Model?.taxYear &&
                  `July ${reference?.form2290Model?.taxYear} - June ${
                    reference?.form2290Model?.taxYear + 1
                  }`
                : reference?.form8849?.taxYear &&
                  `July ${reference?.form8849?.taxYear} - June ${
                    reference?.form8849?.taxYear + 1
                  }`}
                  {reference?.status !== ReferenceStatus.scheduleRetrieved && reference?.status !== ReferenceStatus.userSubmitted && !reference?.form8849 && ( 
                    <IconButton color="primary" aria-label="Edit">
                      <EditIcon onClick={handleOpen} />
                    </IconButton>
                  )}
              <Button size="small"></Button>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Form Info</DialogTitle>
                <DialogContent>
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      Final Return
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="yes"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value={"yes"}
                        control={<Radio />}
                        label="Yes"
                        checked={
                          typeof radioVal !== "undefined" && radioVal === "yes"
                        }
                        onClick={(e) => radioHanlder(e)}
                      />
                      <FormControlLabel
                        value={"no"}
                        control={<Radio />}
                        label="No"
                        checked={
                          typeof radioVal !== "undefined" && radioVal === "no"
                        }
                        onClick={(e) => radioHanlder(e)}
                      />
                    </RadioGroup>
                  </FormControl>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                      marginBottom: "16px",
                    }}
                  >
                    <InputLabel id="taxperiod-label">Tax Period</InputLabel>
                    <Select
                      labelId="taxperiod-label"
                      id="taxperiod"
                      value={taxperiod}
                      onChange={(e) => {
                        setTaxperiod(e.target.value);
                      }}
                    >
                     {createtaxPeriodYears().map((year) => (
                        <MenuItem key={year.actualValue} value={year.actualValue}>
                          {year.uiValue}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <InputLabel id="usedMonth-label">
                      First Used Month
                    </InputLabel>
                    <Select
                      disabled={true}
                      labelId="usedMonth-label"
                      id="usedMonth"
                      defaultValue="hhhh"
                      value={usedMonth}
                      onChange={(e) => {
                        setUsedMonth(e.target.value);
                      }}
                      style={{ minWidth: "40%", background: "#eaeaea" }}
                    >
                      {months.map((month, index) => (
                        <MenuItem key={month} value={index + 1}>
                          {month}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={handleSave}>Save</Button>
                </DialogActions>
              </Dialog>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
