import React from "react";
import {
  StyledCircularProgress,
  StyledLoaderOverlay,
} from "./GlobalWrapperStyles";

const AppLoader = () => {
  return (
    <StyledLoaderOverlay>
      <StyledCircularProgress />
    </StyledLoaderOverlay>
  );
};

export default AppLoader;
