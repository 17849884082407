import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
} from "@mui/material";
import { formatDate, getMOIString } from "../../Utils/Constants";

export default function GrossWeightVehicleDetails({
  vehicleResponse,
}: {
  vehicleResponse: any;
}) {
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>VIN</strong>
            </TableCell>
            <TableCell>
              <strong>Logging</strong>
            </TableCell>
            <TableCell>
              <strong>Tax</strong>
            </TableCell>
            <TableCell>
              <strong>Filing Category</strong>
            </TableCell>
            <TableCell>
              <strong>Month of Increase</strong>
            </TableCell>
            <TableCell>
              <strong>Agriculture</strong>
            </TableCell>
            <TableCell>
              <strong>New Weight</strong>
            </TableCell>
            <TableCell>
              <strong>Weight Old</strong>
            </TableCell>
            <TableCell>
              <strong>Milege Exceeded</strong>
            </TableCell>
            <TableCell>
              <strong>Credit Reason</strong>
            </TableCell>
            <TableCell>
              <strong>Buyer Name</strong>
            </TableCell>
            <TableCell>
              <strong>Creation Date</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {vehicleResponse.form2290Model &&
            vehicleResponse.form2290Model.vehicleResponse &&
            vehicleResponse.form2290Model.vehicleResponse.grossWeightIncreasedVehicles?.map(
              (row: any) => (
                <TableRow
                  key={row?.vin}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row?.vin}
                  </TableCell>
                  <TableCell align="left">
                    {row?.isLoggingNew ? "Yes" : "No"}
                  </TableCell>
                  <TableCell align="left">{row?.taxAmount}</TableCell>
                  <TableCell align="left">{row?.category}</TableCell>
                  <TableCell align="left">
                    {getMOIString(row?.weightIncreasedMonth, vehicleResponse.form2290Model.taxYear)}
                  </TableCell>
                  <TableCell align="left">
                    {row.isAgricultural ? "Yes" : "No"}
                  </TableCell>
                  <TableCell align="left">{row?.weightCurrent}</TableCell>
                  <TableCell align="left">{row?.weightOld}</TableCell>
                  <TableCell align="left">
                    {row?.isMileageExceed ? "Yes" : "No"}
                  </TableCell>
                  <TableCell align="left">{row?.creditReason}</TableCell>
                  <TableCell align="left">{row?.buyer}</TableCell>
                  <TableCell align="left">
                    {formatDate(row?.creationDate)}
                  </TableCell>
                </TableRow>
              )
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
