import { keyframes, styled, TableRow } from "@mui/material";
import { CSSProperties } from 'react';

export const blinkAnimation = keyframes`
    50% {
        background-color: #FF6A6A;
    }
`;

export const BlinkingTableRow = styled(TableRow)`
    &.blink {
        background-color: yellow;
        animation: ${blinkAnimation} 800ms step-end infinite;
    }
`;

export const autocompleteStyles = {
    textField: {
        fontSize: '14px',
        backgroundColor: 'white',
        borderRadius: '4px',
        fontWeight: 'bold',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#707070',
                borderRadius: '4px',
            },
            '&:hover fieldset': {
                borderColor: '#707070',
                borderRadius: '4px',
                fontSize: '14px',
            },
        },
        '& .MuiInputBase-input': {
            paddingRight: '0px',
            fontSize: '14px',
            color: 'black',
            '&::placeholder': {
                color: 'black',
                opacity: 1,
            },
        },
        '& .MuiAutocomplete-clearIndicator': {
            display: 'none',
        },
        '& .MuiAutocomplete-popupIndicator': {
            display: 'none',
        },
        '& .MuiInputBase-root': {
            padding: '0 !important',
            fontSize: '14px',
            fontWeight: 'bold',
            overflow: 'hidden',
            whiteSpace: 'pre-line',
            textOverflow: 'clip',
        },
        '& .MuiInputBase-adornedEnd': {
            padding: '0 !important',
        },
    } as CSSProperties,
    listbox: {
        border: '1px solid #707070',
        borderRadius: '4px',
        overflowX: 'hidden',
        padding: 0,
    } as CSSProperties,
    option: {
        borderBottom: '1px solid #e5e5e5',
        fontSize: '14px',
        padding: '8px 16px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f0f0f0',
        },
        '&:last-child': {
            borderBottom: 'none',
        },
    } as CSSProperties,
    searchIcon: {
        color: 'black',
        paddingRight: '5px',
    } as CSSProperties,
    noOptionsText: {
        fontSize: '12px',
        lineHeight: '0.9',
        color: '#e20707',
    } as CSSProperties,
};