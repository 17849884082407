import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Dialog,
  DialogContent,
  TextField,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import DashboardService from "../../Services/dashboard.service";

import EditIcon from "@mui/icons-material/Edit";
import {
  CANADA_STATES_LIST,
  COUNTRY_LIST,
  DASHES,
  MEXICO_STATES_LIST,
  US_STATES_LIST,
} from "../../Utils/Constants";
import { useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { IS_LOADING } from "../../Actions/types";
import { useAppDispatch } from "../../Store";

export default function BusinessDetails({
  reference,
  clearStateAndRefetch,
}: any) {
  const state = useSelector((state) => state);
  const methods = useForm({});
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const [errorState, setErrorState] = useState<any>({});

  const [currentState, setCurrentState] = useState<any>();
  const [stateList, setStateList] = useState(US_STATES_LIST);
  const [currentCountry, setCurrentCountry] = useState<any>();

  const onSubmit = (data: any) => {
    return DashboardService.csListUpdate(data, reference)
      .then(() => {
        dispatch({ type: IS_LOADING });
        clearStateAndRefetch();
        setOpen(false);
      })
      .catch((error) => {
        console.log("errrrr", error?.response?.data?.errors);
        setErrorState((prev: any) => ({
          ...prev,
          ...error?.response?.data?.errors,
        }));
      });
  };

  const handleOpen = () => {
    setErrorState({});
    setOpen(true);
  };
  const handleClose = () => {
    setErrorState({});
    setOpen(false);
  };
  const handleCountryChange = (e: SelectChangeEvent<unknown>) => {
    const country = e.target.value as string;
    setValue("country", country);
    setCurrentCountry(country);
    updateStateList(country);
  };
  const handleStateChange = (e: SelectChangeEvent<unknown>) => {
    const state = e.target.value as string;
    setValue("state", state);
    setCurrentState(state);
  };
  const updateStateList = (cn: string) => {
    switch (cn) {
      case "US":
        setStateList(US_STATES_LIST);
        break;
      case "MX":
        setStateList(MEXICO_STATES_LIST);
        break;
      case "CN":
        setStateList(CANADA_STATES_LIST);
        break;
    }
  };

  useEffect(() => {
    setValue("einModal", reference?.ein);
    setValue("country", reference?.country);
    setValue("state", reference?.state);
    setValue("businessModal", reference?.businessName);
    setValue("add1Modal", reference?.addressLine1);
    setValue("add2Modal", reference?.addressLine2);
    setValue("cityModal", reference?.city);
    setValue("zip", reference?.zip);
    setCurrentState(reference?.state);
    setCurrentCountry(reference?.country);
    updateStateList(reference?.country);
  }, [reference]);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="Business Details" size="small">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              EIN
            </TableCell>
            <TableCell>{reference?.ein ? reference?.ein : DASHES}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Business
            </TableCell>
            <TableCell>{reference?.businessName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Address
            </TableCell>
            <TableCell>
              {reference?.addressLine1} {reference?.addressLine2}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              City
            </TableCell>
            <TableCell>{reference?.city}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              State
            </TableCell>
            <TableCell>{reference?.state}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Country
            </TableCell>
            <TableCell>{reference?.country}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Zip/Postal Code
            </TableCell>
            <TableCell>
              {reference?.zip}
              {reference?.status !== "Retrieved Schedule 1" && reference?.status !== 'User Submitted' && (
                <IconButton color="primary" aria-label="Edit">
                  <EditIcon onClick={handleOpen} />
                </IconButton>
              )}
              <Button size="small"></Button>
              <Dialog open={open} onClose={handleClose}>
                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle>Business</DialogTitle>
                    <DialogContent>
                      <TextField
                        {...(errors.einModal && {
                          helperText: errors.einModal.message as string,
                        })}
                        {...(errors?.einModal ? { error: true } : {})}
                        {...register("einModal", {
                          required: "EIN is required",
                          pattern: {
                            value: /^\d{9}$/g,
                            message: "Please enter a valid EIN",
                          },
                        })}
                        margin="dense"
                        label="EIN"
                        type="text"
                        fullWidth
                        inputProps={{ maxLength: 9 }}
                        variant="standard"
                      />

                      <TextField
                        {...(errors.businessModal && {
                          helperText: errors.businessModal.message as string,
                        })}
                        {...(errors?.businessModal ? { error: true } : {})}
                        {...register("businessModal", {
                          required: "Business is required",
                        })}
                        margin="dense"
                        label="Business"
                        type="text"
                        fullWidth
                        variant="standard"
                      />
                      <FormControlLabel
                        control={<Checkbox value="true" color="primary" />}
                        label="Is Address changed from last filling"
                        {...register("checkValue")}
                      />
                      <TextField
                        {...(errors.add1Modal && {
                          helperText: errors.add1Modal.message as string,
                        })}
                        {...(errors?.add1Modal ? { error: true } : {})}
                        {...register("add1Modal")}
                        margin="dense"
                        label="Address1"
                        type="text"
                        fullWidth
                        variant="standard"
                      />

                      <TextField
                        {...(errors.add2Modal && {
                          helperText: errors.add2Modal.message as string,
                        })}
                        {...(errors?.add2Modal ? { error: true } : {})}
                        {...register("add2Modal")}
                        margin="dense"
                        label="Address2"
                        type="text"
                        fullWidth
                        variant="standard"
                      />

                      <TextField
                        {...(errors.cityModal && {
                          helperText: errors.cityModal.message as string,
                        })}
                        {...(errors?.cityModal ? { error: true } : {})}
                        {...register("cityModal", {
                          required: "City is required",
                        })}
                        margin="dense"
                        label="City"
                        type="text"
                        fullWidth
                        variant="standard"
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "34px",
                          padding: "16px 0",
                        }}
                      >
                        <InputLabel id="state-label">State</InputLabel>

                        {currentCountry === "US" ? <Select
                          labelId="state-label"
                          id="state"
                          style={{ minWidth: "40%" }}
                          value={currentState}
                          {...register("state", {
                            required: "State is Required",
                          })}
                          onChange={handleStateChange}
                        >
                          {stateList.map((state) => (
                            <MenuItem key={state.value} value={state.value}>
                              {state.label}
                            </MenuItem>
                          ))}
                        </Select> : <TextField
                          id="state"
                          style={{ minWidth: "40%" }}
                          value={currentState}
                          {...register("state", {
                            required: "State is Required",
                          })}
                        />}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "16px",
                          padding: "16px 0",
                        }}
                      >
                        <InputLabel id="country-label">Country</InputLabel>

                        <Select
                          labelId="country-label"
                          id="country"
                          style={{ minWidth: "40%" }}
                          value={currentCountry}
                          {...register("country", {
                            required: true,
                          })}
                          onChange={handleCountryChange}
                        >
                          {COUNTRY_LIST.map((country) => (
                            <MenuItem key={country.value} value={country.value}>
                              {country.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>

                      <TextField
                        {...(errors.zip && {
                          helperText: errors.zip.message as string,
                        })}
                        {...(errors?.zip ? { error: true } : {})}
                        {...register("zip", {
                          required: "Zip/Postal Code is required"
                        })}
                        margin="dense"
                        label="Zip/Postal Code"
                        type="text"
                        fullWidth
                        variant="standard"
                        inputProps={{
                          maxLength: 6,
                          minLength: 5,
                        }}
                      />
                    </DialogContent>
                    <DialogActions>
                      <FormControlLabel
                        control={<Checkbox value="true" color="primary" />}
                        label="Also update master business record"
                        {...register("masterRecordUpdate")}
                      />
                      <Button onClick={handleClose}>Cancel</Button>
                      <Button type="submit">Save</Button>
                    </DialogActions>
                  </form>
                </FormProvider>
              </Dialog>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
