import React, { useState, useCallback, useEffect } from "react";
import { styled, alpha } from "@mui/material/styles";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  InputBase,
  Badge,
  MenuItem,
  Menu,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../Store";
import { logout } from "../../Actions/auth";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { cslist } from "../../Actions/dashboard";
import { IS_LOADING } from "../../Actions/types";
import BellIcon from "../../Assets/bellIcon.svg";
import NotifyCsAgent from "../../Components/NotifyCsAgent";
import { FollowOutBoundResponse, FollowUpTableCells, OutboundTableCells } from "../../Pages/CustomerInFunnelReport/Components/constants";
interface TableCellConfig {
  label: string;
  accessor: string;
  link?: boolean;
  transform?: (value: any, item: any) => string;
}
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "30ch",
    },
  },
}));

export const styles = {
  notificationsContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f5e2bd',
    padding: '10px 18px',   
  },
  notificationsContainerFollowUp: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f5c6bd',
    padding: '10px 18px',   
  },
  notificationHeader:{
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    alignItems: 'center',
    marginBottom : '16px'
  },

  leftSection: {
    display: 'flex',
    alignItems: 'center',
    notifText:{
      fontSize: '15px',
      fontWeight: '600',
      marginLeft: '16px',
    }
  },

  notificationTable: {
    marginTop: '20px',
    marginBottom: '26px',
  }
};

const CustomizedTableCell = styled(TableCell)(() => ({
  border: '1px solid white',
  fontWeight: '600',
  fontSize: '14px',
  backgroundColor: 'inherit',
}));

export default function Header({
  refIds,
  selectedRef,
  searchType,
  setSearchType,
  searchData,
  setSearchData,
  referenceId,
  setReferenceId,
  notificationsData,
  notificationsFollowUPData,
  removeClosedCase 
}: {
  refIds: string[];
  searchType: any;
  setSearchType: any;
  selectedRef: any;
  searchData: any;
  setSearchData: any;
  referenceId: any;
  setReferenceId: any;
  notificationsData: any;
  notificationsFollowUPData: any;
  removeClosedCase: any;
}) {
  const naviateTo = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [searchLabels, setSearchLables] = React.useState<null | HTMLElement>(
    null
  );
  const [expandNotifications, setExpandNotifications] = useState(false);
  const [expandFollowUpNotication ,setExpandFollowUpNotication] = useState(false);
  const [refId, setRefId] = useState("");
  const [searchValue, setSearchValue] = useState(searchData);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchEnum: any = {
    ref_no: "Reference",
    email: "Email",
    ein: "EIN",
    vin: "VIN",
    invoice: "Invoice",
    phone: "Phone Number",
    business_name: "Business Name",
    braintree: "Braintree ID"
  };

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isSearchLabelOpen = Boolean(searchLabels);
  const messages = useSelector((state: any) => state.message.message);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleSearchlabelsClose = () => {
    setSearchLables(null);
  };

  const handleSearchlabelsOpen = (event: React.MouseEvent<HTMLElement>) => {
    setSearchLables(event.currentTarget);
  };

  const selectSearchType = (type: string) => {
    setSearchType(type);
    handleSearchlabelsClose();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const { isLoggedIn } = useSelector((state: any) => state.auth);

  const logoutNow = useCallback(() => {
    naviateTo("/login");
    dispatch(logout()).then(() => {
      handleMenuClose();
    });
  }, [dispatch]);

  const handleChange = (event: SelectChangeEvent) => {
    setRefId(event.target.value as string);
    setReferenceId(event.target.value);
    selectedRef(event.target.value);
  };

  const handleSearchValChange = (e: any) => {
    setSearchData(e.target.value);
    setSearchValue(e.target.value);
    setRefId("");
    setReferenceId("");
  };

  const handleNotifications = () => {
    setExpandNotifications(!expandNotifications);
  };

  const handleFollowUpNotifications = () => {
    setExpandFollowUpNotication(!expandFollowUpNotication);
  }
  const [notificationsDataData1,setNotificationsDataData1] = useState([])
  const [notificationsFollowUPData1,setNotificationsFollowUPData1] = useState([])

  useEffect(() => {
    const filteredData = notificationsData?.filter((customer:any) => customer.customerInFunnelId !== notificationsDataData1);
    const filteredData2 = notificationsFollowUPData?.filter((customer:any) => customer.customerInFunnelId !== notificationsFollowUPData1);
    notificationsData = filteredData
    notificationsFollowUPData = filteredData2
    const { email, ref_no } = location.state || {};
    if (email || ref_no) {
      const searchType = email ? 'email' : 'ref_no';
      const searchValue = email || ref_no;
      setSearchType(searchType);
      setSearchData(searchValue);
      setSearchValue(searchValue);
      dispatch({ type: IS_LOADING });
      fetchCSData(searchType, searchValue);
      naviateTo(location.pathname, { replace: true, state: {} });
  }
}, [notificationsDataData1, notificationsFollowUPData1, location, naviateTo]);

  const searchByEmailOrRef = (value: any , searchtype: any) => {
    dispatch({ type: IS_LOADING });
    setSearchType(searchtype);
    setSearchData(value);
    setSearchValue(value);
    fetchCSData(searchtype, value);
  };

  if (!isLoggedIn) {
    return <Navigate to="/" />;
  }

  const searchHandler = () => {
    dispatch({ type: IS_LOADING });
    fetchCSData(searchType, searchValue);
  };
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={() => naviateTo("/reports")}>Reports</MenuItem>
      <MenuItem onClick={logoutNow}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
      <MenuItem onClick={() => naviateTo("/reports")}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Reports</p>
      </MenuItem>
      <MenuItem onClick={logoutNow}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  const fetchCSData = (type: any, value: any) => {
    dispatch(cslist(type, value));
  };
  
  useEffect(() => {
    if (messages && messages.length > 0) {
      if (referenceId === "") {
        selectedRef(messages[0].referenceNumber);
        setRefId(messages[0].referenceNumber);
      } else {
        selectedRef(referenceId);
        setRefId(referenceId);
      }
    }
    if (messages && messages.length === 0) {
      selectedRef("");
      setRefId("");
      return;
    }
  }, [messages]);

  const renderSearchMenu = (
    <Menu
      anchorEl={searchLabels}
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      open={isSearchLabelOpen}
      onClose={handleSearchlabelsClose}
    >
      <MenuItem
        onClick={(e) => {
          setRefId("");
          selectSearchType("email");
        }}
      >
        Email
      </MenuItem>
      <MenuItem
        onClick={() => {
          setRefId("");
          selectSearchType("vin");
        }}
      >
        VIN
      </MenuItem>
      <MenuItem
        onClick={() => {
          setRefId("");
          selectSearchType("ref_no");
        }}
      >
        Reference
      </MenuItem>
      <MenuItem
        onClick={() => {
          setRefId("");
          selectSearchType("ein");
        }}
      >
        EIN
      </MenuItem>
      <MenuItem
        onClick={() => {
          setRefId("");
          selectSearchType("invoice");
        }}
      >
        Invoice
      </MenuItem>
      <MenuItem
        onClick={() => {
          setRefId("");
          selectSearchType("phone");
        }}
      >
        Phone Number
      </MenuItem>
      <MenuItem
        onClick={() => {
          setRefId("");
          selectSearchType("business_name");
        }}
      >
        Business Name
      </MenuItem>
      <MenuItem
        onClick={() => {
          setRefId("");
          selectSearchType("braintree");
        }}
      >
        Braintree ID
      </MenuItem>
    </Menu>
  );  
  const commonTableCellsFollowUp: TableCellConfig[] = [
    { label: FollowUpTableCells.email, accessor: FollowOutBoundResponse.email , link: true },
    { label: FollowUpTableCells.mobileNumber, accessor: FollowOutBoundResponse.phone },
    { label: FollowUpTableCells.agentContacted, accessor: FollowOutBoundResponse.contactedToname },
    { label: `${FollowUpTableCells.wasConversion} ${FollowUpTableCells.conversion}`, accessor: FollowOutBoundResponse.isConverted, transform: (value: any) => (value ? 'Yes' : 'No') },
    { label: FollowUpTableCells.referenceNumber, accessor: FollowOutBoundResponse.refNo, link: true },
    { label: `${FollowUpTableCells.contacted} ${FollowUpTableCells.onIST}`, accessor: FollowOutBoundResponse.contactedAt },
    { label: FollowUpTableCells.AgenComment, accessor: FollowOutBoundResponse.agentLog },
    { label: `${FollowUpTableCells.followUp} ${FollowUpTableCells.requestedOn}`, accessor: FollowOutBoundResponse.followUpRequestedOnAtIst, transform: (value: any, item: any) => `${value} (${item.timezone})` },
  ];
  
  const commonTableCellsNotifications: TableCellConfig[] = [
    { label: OutboundTableCells.user, accessor: FollowOutBoundResponse.fullName },
    { label: FollowUpTableCells.email, accessor: FollowOutBoundResponse.email, link: true },
    { label: FollowUpTableCells.mobileNumber, accessor: FollowOutBoundResponse.phone },
    { label: OutboundTableCells.currPage, accessor: FollowOutBoundResponse.currentPage },
    { label: OutboundTableCells.prevPage, accessor: FollowOutBoundResponse.previousPage },
    { label: OutboundTableCells.timeInSession, accessor: FollowOutBoundResponse.timeSpent },
    { label: OutboundTableCells.filingInitiated, accessor: FollowOutBoundResponse.filingInitiatedAtIST },
    { label: OutboundTableCells.credits, accessor: FollowOutBoundResponse.userCredit, transform: (value: any) => `$ ${value}` },
  ];
  
  const renderTable = (data: any[], isFollowUp: boolean) => (
    <TableContainer sx={{ borderRadius: '4px', maxHeight: 300 }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow sx={{ backgroundColor: '#d9d9d9' }}>
            {(isFollowUp ? commonTableCellsFollowUp : commonTableCellsNotifications).map((cell, index) => (
              <CustomizedTableCell key={index}>{cell.label}</CustomizedTableCell>
            ))}
            <CustomizedTableCell>Action</CustomizedTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={index} sx={{ backgroundColor: '#f2f2f2' }}>
              {(isFollowUp ? commonTableCellsFollowUp : commonTableCellsNotifications).map((cell, cellIndex) => {
                const value = cell.accessor.split('.').reduce((acc: any, key: string) => acc && acc[key], item);
  
                return (
                  <CustomizedTableCell key={cellIndex}>
                    {cell.link ? (
                      cell.accessor === 'refNo' ? (
                        <Link style={{ color: 'inherit', cursor: 'pointer' }} onClick={() => searchByEmailOrRef(value, 'ref_no')}>
                          {cell.transform ? cell.transform(value, item) : value}
                        </Link>
                      ) : (
                        <Link style={{ color: 'inherit', cursor: 'pointer' }} onClick={() => searchByEmailOrRef(value, 'email')}>
                          {cell.transform ? cell.transform(value, item) : value}
                        </Link>
                      )
                    ) : (
                      cell.transform ? cell.transform(value, item) : value
                    )}
                  </CustomizedTableCell>
                );
              })}
              <CustomizedTableCell sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', flexWrap: 'wrap', minWidth: 'max-content' }}>
                <NotifyCsAgent
                  selectedRef={selectedRef.user}
                  customerInFunnelId={item.customerInFunnelId}
                  customerEmail={item.email}
                  customerCaseStatus={item.caseStatus}
                  setNotificationsData={setNotificationsDataData1}
                  isOutBoundCall={!isFollowUp}
                  removeClosedCase={removeClosedCase}
                />
              </CustomizedTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleSearchlabelsOpen}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            style={{ width: "150px" }}
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            {searchEnum[searchType]}
          </Typography>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              value={searchValue}
              onChange={handleSearchValChange}
            />
          </Search>
          <Button variant="contained" onClick={searchHandler}>
            Search
          </Button>
          {refIds.length ? (
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Ref Id</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  defaultValue="oooo"
                  value={refId}
                  label="Ref Id"
                  onChange={handleChange}
                >
                  {refIds.map((id: any) => (
                    <MenuItem key={id} value={id}>
                      {id}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          ) : (
            ""
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {/* <IconButton size="large" aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="error">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {renderSearchMenu}
      {notificationsFollowUPData && (
      <section style={styles.notificationsContainerFollowUp as React.CSSProperties}>
        <div style={styles.notificationHeader as React.CSSProperties}>
          <section style={styles.leftSection as React.CSSProperties}>
            <img src={BellIcon} alt="Notifications" />
            <span style={styles.leftSection.notifText as React.CSSProperties}>
              {FollowUpTableCells.youAre} {notificationsFollowUPData.length} {FollowUpTableCells.followUpAssigned}
            </span>
          </section>
          <Button
            variant={expandFollowUpNotication ? 'outlined' : 'contained'}
            style={{ backgroundColor: expandFollowUpNotication ? '#fff' : '', fontSize: '15px', padding: '6px 40px', textTransform: 'none' }}
            onClick={handleFollowUpNotifications}
          >
            {expandFollowUpNotication ? 'Hide' : 'View'}
          </Button>
        </div>
        {expandFollowUpNotication && renderTable(notificationsFollowUPData, true)}
      </section>
    )}

    {notificationsData && (
      <section style={styles.notificationsContainer as React.CSSProperties}>
        <div style={styles.notificationHeader as React.CSSProperties}>
          <section style={styles.leftSection as React.CSSProperties}>
            <img src={BellIcon} alt="Notifications" />
            <span style={styles.leftSection.notifText as React.CSSProperties}>
            {FollowUpTableCells.youAre} {notificationsData.length} {FollowUpTableCells.outboundCall}
            </span>
          </section>
          <Button
            variant={expandNotifications ? 'outlined' : 'contained'}
            style={{ backgroundColor: expandNotifications ? '#fff' : '', fontSize: '15px', padding: '6px 40px', textTransform: 'none' }}
            onClick={handleNotifications}
          >
            {expandNotifications ? 'Hide' : 'View'}
          </Button>
        </div>
        {expandNotifications && renderTable(notificationsData, false)}
      </section>
    )}
    </Box>
  );
}
