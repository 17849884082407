import React, { useEffect, useState } from 'react';
import { Grid, TextField, Box, Button, Checkbox, FormControlLabel, Container, Divider, Select, MenuItem, SelectChangeEvent, FormHelperText, Typography, IconButton, Stepper, Step, StepLabel, Tooltip, InputAdornment, Modal, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import Header from '../Header';
import SuspendFilingDialog from './SuspendFilingDialog';
import FilingActionPanel from '../FilingActionPanel';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import GlobalWrapper from '../../../Components/GlobalComponentWrapper/GlobalWrapper';
import mcsService from '../Services/mcs.service';
import { REGEX } from '../Constants/constants';
import { useNavigate } from 'react-router-dom';

interface FormData {
  [key: string]: string;
}

interface FormErrors {
  [key: string]: string;
}
export const styles = {
  tableContainer : {
      border: '1px solid #707070', borderRadius: '4px',margin: '0 auto',marginTop:'10px'
  },
  searchBar:{
    border: '1px solid #ccc',
    padding: '0 10px',
    borderRadius: '4px',
    backgroundColor: '#fff',
    width: '400px',
    height: '40px',
  },
  button:{
    height: '40px', textTransform: 'none'
  },
  textField : {
    fontSize:'14px',
    fontWeight:600 , 
    marginBottom:'10px',
}, 
 textFieldInside : {
  '& .MuiInputBase-input': {
    backgroundColor: 'white',
    height: '10px'
  },
  '& .MuiFormHelperText-root': {
    marginLeft:'4px'
  },
},
textFieldInsideDisable : {
  '& .MuiInputBase-input': {
    border: 'solid 1px #cdd4d9',
    backgroundColor: '#cdd4d9', 
    height: '10px'
  },
},
textFieldInsideSelect: {
  height: '40px',
  '& .MuiInputBase-root': {
    height: '40px',
  },
  '& .MuiInputBase-input': {
    height: '10px',
    padding: '10px 12px',
    backgroundColor: 'white',
  },
  borderColor: 'initial',
  '&.Mui-error': {
    borderColor: 'red',
  },
},

textFieldInsideSelectDisable:{
  height: '40px', 
  '& .MuiInputBase-root': {
    height: '40px',
  },
  '& .MuiInputBase-input': {
    height: '10px',
    padding: '10px 12px',
    border: 'solid 1px #cdd4d9',
    backgroundColor: '#cdd4d9', 
  },
},
textAreaBox: {
  backgroundColor: 'white',
  border: '0px solid #cdd4d9',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '1px',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#cdd4d9',
      borderWidth: '1px',
    },
    '&.Mui-error fieldset': {
      borderColor: '#d32f2f',
      borderWidth: '1px',
    },
  },
},


textAreaBoxDisable:{
  backgroundColor: '#cdd4d9', 
  border: '1px solid #cdd4d9',
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      border: 'solid 1px #cdd4d9',
      backgroundColor: '#cdd4d9', 
    },
  },
},
titleTag:{
  fontSize:'16px',
  fontWeight:600 , 
},
};

const FileMCS150 = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [sameLegalBusinessName, setSameLegalBusinessName] = useState(false);
  const [sameAsBusinessPhoneNum, setSameAsBusinessPhoneNum] = useState(false);
  const [sameAsBusinessFaxNum, setSameAsBusinessFaxNum] = useState(false);
  const [sameAsPrincipalBusiness, setSameAsPrincipalBusiness] = useState(false);
  const [isEditFiling, setIsEditFiling] = useState(false);
  const [getCurrentUserData, setGetCurrentUserData] = useState({})
  const [mappingKey, setMappingKey] = useState("");
  const [formKey, setFormKey] = useState("")
  const [isFilingClicked, setIsFilingClicked] = useState(false);
  const [suspendRemark, setSuspendRemark] = useState('');
  const [isFileAccepted, setIsFileAccepted] = useState(false);
  const [filingStauts, setFilingStatus] = useState("");
  const [dotNumber, setDotNumber] = useState("");
  const [currentStauts, setCurrentStatus] = useState(0);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [actionPanelData, setActionPanelData] = useState<Record<string, any>>({});
  const navigate = useNavigate();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [dotPin, setDotPin] = useState('')
  const [formData, setFormData] = useState<FormData>({
    usdot: dotNumber || '',
    ein: '',
    name: '',
    title: '',
    legalBusinessName: '',
    doingBusinessAs: '',
    streetAddressPB: '',
    cityPB: '',
    statePB: '',
    zipPB: '',
    countryPB: '',
    mailingCountry: '',
    businessPhoneNumber: '',
    businessMobilePhoneNumber: '',
    businessFaxNumber: '',
    emailAddress: '',
    emailAddressOptional: '',
    mailingStreetAddress: '',
    mailingCity: '',
    mailingState: '',
    mailingZip: '',
    mileage: '',
    mileageYear: '',
    officerName1: '',
    officerTitle1: '',
    officerName2: '',
    officerTitle2: '',
    notesAny: '',
    usdotpinform: dotPin || ''
  });


  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
  
    let filteredValue = value;
    if(name === 'usdot'){
      setDotNumber(value)
    }
  
    if (name === 'ein' || name === 'mileage') {
      filteredValue = value.replace(/\D/g, '');
    } 
    if (name === 'zipPB') {
      if (formData.countryPB === 'CA') {
        filteredValue = value.replace(/[^a-zA-Z0-9]/g, '');
      } else {
        filteredValue = value.replace(/\D/g, '');
      }
    }

    if (name === 'mailingZip') {
      if (formData.mailingCountry === 'CA') {
        filteredValue = value.replace(/[^a-zA-Z0-9]/g, '');
      } else {
        filteredValue = value.replace(/\D/g, '');
      }
    }
    
    if (name === 'businessPhoneNumber' || name === 'businessMobilePhoneNumber') {
      filteredValue = value.replace(/[^0-9]/g, '');
    }
    if (name === 'emailAddressOptional') {
      filteredValue = value.replace(/[^a-zA-Z0-9@._-]/g, '');
    }
    if (name === 'mailingState') {
      filteredValue = value.replace(/[^a-zA-Z-]/g, '');
    }
    if (name === 'statePB') {
      filteredValue = value.replace(/[^a-zA-Z-]/g, '');
    }
  
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: filteredValue,
      };


      if (sameAsPrincipalBusiness && ['streetAddressPB', 'cityPB', 'statePB', 'zipPB', 'countryPB'].includes(name)) {
        updatedData.mailingStreetAddress = updatedData.streetAddressPB;
        updatedData.mailingCity = updatedData.cityPB;
        updatedData.mailingState = updatedData.statePB;
        updatedData.mailingZip = updatedData.zipPB;
        updatedData.mailingCountry = updatedData.countryPB;
      }
  
      if (sameLegalBusinessName && name === 'legalBusinessName') {
        updatedData.doingBusinessAs = filteredValue;
      }
      if (sameAsBusinessPhoneNum && name === 'businessPhoneNumber') {
        updatedData.businessMobilePhoneNumber = filteredValue;
      }
      if (sameAsBusinessFaxNum && name === 'businessPhoneNumber') {
        updatedData.businessFaxNumber = filteredValue;
      }
  
      return updatedData;
    });
  
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  
    if (isFilingClicked) {
      const newErrors = { ...formErrors };
  
      const requiredFields = ['usdot', 'ein', 'businessPhoneNumber', 'businessMobilePhoneNumber', 'zipPB', 'mileage']; // Add other required fields here
      if (requiredFields.includes(name) && filteredValue.trim() === '') {
        newErrors[name] = 'This field is required';
      } else if (name === 'usdot' && !/^\d{5,12}$/.test(filteredValue)) {
        newErrors.usdot = 'USDOT Number must be between 5 and 12 digits and contain only numbers';
      } else if (name === 'ein' && !/^\d{9}$/.test(filteredValue)) {
        newErrors.ein = 'EIN must be a 9-digit number';
      } else if (name === 'businessPhoneNumber' && !REGEX.Regex.phone.test(filteredValue)) {
        newErrors.businessPhoneNumber = 'Business Phone Number must be a 10-digit number';
      } else if (name === 'businessMobilePhoneNumber' && !REGEX.Regex.phone.test(filteredValue)) {
        newErrors.businessMobilePhoneNumber = 'Mobile Phone Number must be a 10-digit number';
      } else if (name === 'zipPB') {
        if (formData.countryPB === 'CA') {
          if (!/^[a-zA-Z0-9]{6}$/.test(filteredValue)) {
            newErrors.zipPB = 'ZIP must be a 6-character alphanumeric';
          } else {
            newErrors.zipPB = '';
          }
        } else {
          if (!/^\d{5}$/.test(filteredValue)) {
            newErrors.zipPB = 'ZIP must be a 5-digit number';
          } else {
            newErrors.zipPB = '';
          }
        }
      } else if (name === 'mailingZip') {
        if (formData.mailingCountry === 'CA') {
          if (!/^[a-zA-Z0-9]{6}$/.test(filteredValue)) {
            newErrors.mailingZip = 'Mailing ZIP must be a 6-character alphanumeric';
          } else {
            newErrors.mailingZip = '';
          }
        } else {
          if (!/^\d{5}$/.test(filteredValue)) {
            newErrors.mailingZip = 'Mailing ZIP must be a 5-digit number';
          } else {
            newErrors.mailingZip = '';
          }
        }
      } else if (name === 'mileage' && !REGEX.Regex.milage.test(filteredValue)) {
        newErrors.mileage = 'Mileage must be a number';
      } else {
        newErrors[name] = ''; // Clear the error if validation passes
      }
  
      if (name === 'businessFaxNumber' && filteredValue && !/^\d{10}$/.test(filteredValue)) {
        newErrors.businessFaxNumber = 'Fax Number must be a 10-digit number';
      } else if (name === 'emailAddressOptional' && filteredValue && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(filteredValue)) {
        newErrors.emailAddressOptional = 'Please enter a valid email address';
      }
  
      setFormErrors(newErrors);
    }
  };
  


useEffect(() => {
  const params = new URLSearchParams(window.location.search);
  const mapping_key = params.get('mappingKey') || '';
  const dot_number = params.get('dotNumber') || '';
  getCustomerDetails(mapping_key,dot_number)
  setMappingKey(mapping_key);
  setDotNumber(dot_number)
}, []);

const getCustomerDetails = async (mapping_key:any,dotNo:any) => {
  setIsLoading(true)
  try {
    const resp =  await mcsService.getCustomerDetailsUsingMappingKey(mapping_key);
    if (resp?.status === 200) {
      setGetCurrentUserData(resp?.data);
      mapDataWithApiResponse(resp?.data,dotNo);
      setFilingStatus(resp?.data?.filing_status);
      setFormKey(resp?.data?.form_key);
      setActionPanelData(resp?.data)
      setIsLoading(false);
    } else if (resp?.status == 404 && resp?.message == "There is no record found with the given dot number") {
      setIsLoading(false);
    }
    else {
      setIsLoading(false)
      throw new Error('API request failed with status' + resp?.message);
    }
  
  } catch (err) {
      setIsLoading(false)
      alert(err);
  }
}

const mapDataWithApiResponse = (data:any,dotNo:any) =>{
setFormData({
  usdot: data?.dot_number ? data?.dot_number?.toString() : dotNo,
    ein: data?.ein || '',
    name: data?.company_official_authorized_name || '',
    title: data?.company_official_authorized_title || '',
    legalBusinessName: data?.legal_business_name || '',
    doingBusinessAs: data?.dba_name || '',
    streetAddressPB: data?.principal_business?.street || '',
    cityPB: data?.principal_business?.city || '',
    statePB: data?.principal_business?.state || '',
    zipPB: data?.principal_business?.zip || '',
    countryPB: data?.principal_business?.country || '',
    mailingCountry: data?.mailing_address?.country || '',
    businessPhoneNumber: data?.business_phone_number || '',
    businessMobilePhoneNumber: data?.different_busniess_phone_number || '',
    businessFaxNumber: data?.business_fax || '',
    emailAddress: data?.email_address || '',
    emailAddressOptional: data?.user_info?.email || '',
    mailingStreetAddress: data?.mailing_address?.street || '',
    mailingCity: data?.mailing_address?.city || '',
    mailingState: data?.mailing_address?.state || '',
    mailingZip: data?.mailing_address?.zip || '',
    mileage: data?.mcs150_mileage?.toString() || '',
    mileageYear: data?.mcs150_millage_year || '',
    officerName1: data?.sole_proprietor_name1 || '',
    officerTitle1: data?.sole_proprietor_title1 || '',
    officerName2: data?.sole_proprietor_name2 || '',
    officerTitle2: data?.sole_proprietor_title2 || '',
    notesAny: data?.notes || '',
  });
  setSameAsBusinessPhoneNum(data?.is_business_mobile_num_same || false);
  setSameAsBusinessFaxNum(data?.is_business_fax_number_same || false)
  setSameLegalBusinessName(data?.is_doing_business_name_same || false)
  setSameAsPrincipalBusiness(data?.is_mailing_address_same || false)
}
const handleSelectChange = (e: SelectChangeEvent<string>) => {
  const { name, value } = e.target;

  setFormData((prevFormData) => {
    const updatedData = {
      ...prevFormData,
      [name]: value,
    };

    if (sameAsPrincipalBusiness && ['countryPB'].includes(name)) {
      updatedData.mailingCountry = updatedData.countryPB;
    }
    return updatedData;
  });

  setFormErrors((prevFormErrors) => ({ ...prevFormErrors, [name]: '' }));
};

const continueFiling = (isSuspendFileClick=false) => {
  setIsFilingClicked(true);
  setIsFormSubmitted(true);
  if (validateForm(isSuspendFileClick)) {
    setIsLoading(true)
    let payload: Record<string, any> = {
      isDoingBusinessNameSame: sameLegalBusinessName,
      isMailingAddressSame: sameAsPrincipalBusiness,
      isBusinessFaxSame: sameAsBusinessFaxNum,
      isBusinessMobileNumSame: sameAsBusinessPhoneNum,
      mappingKey: mappingKey,
      dotNumber: formData?.usdot,
      ein: formData?.ein,
      companyOfficialAuthorizedName: formData?.name,
      companyOfficialAuthorizedTitle: formData?.title,
      legalBusinessName: formData?.legalBusinessName,
      dbaName: formData?.doingBusinessAs,
      principalBusiness: {
        street: formData?.streetAddressPB,
        city: formData?.cityPB,
        state: formData?.statePB,
        zip: formData?.zipPB,
        country: formData?.countryPB,
      },
      mailingAddress: {
        street: formData?.mailingStreetAddress,
        city: formData?.mailingCity,
        state: formData?.mailingState,
        zip: formData?.mailingZip,
        country: formData?.mailingCountry,
      },
      businessPhoneNumber: formData?.businessPhoneNumber,
      differentBusniessPhoneNumber: formData?.businessMobilePhoneNumber,
      emailAddress: formData?.emailAddressOptional,
      businessFax: formData?.businessFaxNumber,
      mcs150Mileage: formData?.mileage,
      mcs150MillageYear: formData?.mileageYear,
      soleProprietorName1: formData?.officerName1,
      soleProprietorName2: formData?.officerName2,
      soleProprietorTitle1: formData?.officerTitle1,
      soleProprietorTitle2: formData?.officerTitle2,
      filingStatus: isSuspendFileClick ? "SUSPENDED" : "PENDING",
      remarks:suspendRemark,
      notes:formData?.notesAny

    };

    // if (isSuspendFileClick) {
    //   payload['remarks'] = suspendRemark;
    // } else {
    //   payload['notes'] = formData?.notesAny;
    // }
    
    
    if(formKey){
      payload = {...payload,  formKey: formKey} as any;
    }
   
    return mcsService.saveCurrentFiling(payload)
    .then((response) => {
      if (response.status === 200) {  
        setFormKey(response?.data?.formKey)
        setIsLoading(false)
        // setGetCurrentUserData(resp?.data)
        if(isSuspendFileClick == true){
          navigate({pathname: '/emcs150/emcs-customers'});
        }
        // setIsEditFiling(!isEditFiling);
        getCustomerActionData(response?.data?.formKey);
      } else {
        setIsLoading(false)
        throw new Error('Error : ' + response?.data?.detail);
      }
    })
    .catch((err) => {
      setIsLoading(false)
      if (err.response && err.response.status === 400) {
        alert(err.response.data?.message);
      } else {
        alert('Something went wrong, Please try again');
      }
    });
  }
  setHasScrolled(false);
};

const getCustomerActionData = async (formKey:any) => {
  setIsLoading(true)
  try {
    const resp = await mcsService.getCustomerDetailsFormKey(formKey);
    if (resp?.status === 200) {
      setActionPanelData(resp?.data)
      setIsLoading(false)
    }
    else {
      setIsLoading(false)
      throw new Error('Error : ' + resp?.message);
    }
  
  } catch (err) {
      setIsLoading(false)
      alert(err);
  }
}

const validateForm = (isSuspendFileClick: any = false) => {
  if (isSuspendFileClick) {
    return true;
  }
  const newErrors = { ...formErrors };
  let hasError = false;

  // Required fields
  const requiredFields = [
    'usdot',
    'ein',
    'name',
    'title',
    'legalBusinessName',
    'streetAddressPB',
    'cityPB',
    'statePB',
    'zipPB',
    'countryPB',
    'businessPhoneNumber',
    'businessMobilePhoneNumber',
    'mileage',
    'mileageYear',
    'officerName1',
    'officerTitle1',
    'officerName2',
    'officerTitle2',
    'doingBusinessAs',
  ];

  requiredFields.forEach((field) => {
    if (!formData[field as keyof typeof formData]) {
      newErrors[field as keyof typeof formErrors] = 'This field is required';
      hasError = true;
    } else {
      newErrors[field as keyof typeof formErrors] = '';
    }
  });
  if (!REGEX.Regex.phone.test(formData.businessPhoneNumber)) {
    newErrors.businessPhoneNumber = 'Business Phone Number must be a 10-digit number';
    hasError = true;
  } else {
    newErrors.businessPhoneNumber = '';
  }

  if (!REGEX.Regex.phone.test(formData.businessMobilePhoneNumber)) {
    newErrors.businessMobilePhoneNumber = 'Mobile Phone Number must be a 10-digit number';
    hasError = true;
  } else {
    newErrors.businessMobilePhoneNumber = '';
  }


  if (formData.countryPB === 'CA') {
    if (!/^[a-zA-Z0-9]{6}$/.test(formData.zipPB)) {
      newErrors.zipPB = 'ZIP must be a 6-character alphanumeric';
      hasError = true;
    } else {
      newErrors.zipPB = '';
    }
  } else {
    if (!/^\d{5}$/.test(formData.zipPB)) {
      newErrors.zipPB = 'ZIP must be a 5-digit number';
      hasError = true;
    } else {
      newErrors.zipPB = '';
    }
  }

  if (!sameAsPrincipalBusiness) {
    if (formData.mailingCountry === 'CA') {
      if (!/^[a-zA-Z0-9]{6}$/.test(formData.mailingZip)) {
        newErrors.mailingZip = 'Mailing ZIP must be a 6-character alphanumeric';
        hasError = true;
      } else {
        newErrors.mailingZip = '';
      }
    } else {
      if (!/^\d{5}$/.test(formData.mailingZip)) {
        newErrors.mailingZip = 'Mailing ZIP must be a 5-digit number';
        hasError = true;
      } else {
        newErrors.mailingZip = '';
      }
    }

    if (!/^[a-zA-Z-]+$/.test(formData.mailingState)) {
      newErrors.mailingState = 'Please enter a valid Mailing State';
      hasError = true;
    } else {
      newErrors.mailingState = '';
    }

    if (!formData.mailingStreetAddress) {
      newErrors.mailingStreetAddress = 'This field is required';
      hasError = true;
    } else {
      newErrors.mailingStreetAddress = '';
    }

    if (!formData.mailingCity) {
      newErrors.mailingCity = 'This field is required';
      hasError = true;
    } else {
      newErrors.mailingCity = '';
    }

    if (!formData.mailingCountry) {
      newErrors.mailingCountry = 'This field is required'; 
      hasError = true;
    } else {
      newErrors.mailingCountry = '';
    }
  }

  if (formData.emailAddressOptional && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.emailAddressOptional)) {
    newErrors.emailAddressOptional = 'Please enter a valid email address';
    hasError = true;
  } else {
    newErrors.emailAddressOptional = '';
  }

  if (!/^[a-zA-Z-]+$/.test(formData.statePB)) {
    newErrors.statePB = 'Please enter a valid State';
    hasError = true;
  } else {
    newErrors.statePB = '';
  }

  if (!/^\d{5,12}$/.test(formData.usdot)) {
    newErrors.usdot = 'USDOT Number must be between 5 and 12 digits and contain only numbers';
    hasError = true;
  } else {
    newErrors.usdot = '';
  }

  if (!/^\d{9}$/.test(formData.ein)) {
    newErrors.ein = 'EIN must be a 9-digit number';
    hasError = true;
  } else {
    newErrors.ein = '';
  }

  const validMileageYears = ['2023', '2024', '2025'];
  const mileageYearValue = formData.mileageYear?.toString().trim();
  if (isFormSubmitted && !validMileageYears.includes(mileageYearValue)) {
    newErrors.mileageYear = 'Please select a valid Mileage Year';
    hasError = true;
  } else {
    newErrors.mileageYear = '';
  }

  setFormErrors(newErrors);
  return !hasError;
};

  useEffect(() => {
    if (!hasScrolled) {
      scrollToError();
    }
  }, [formErrors]);

  const scrollToError = () => {
    for (const key in formErrors) {
      if (formErrors[key]) {
        const errorElement = document.querySelector(`[name="${key}"]`);
        if (errorElement) {
          errorElement.scrollIntoView({ behavior: 'smooth' });
          setHasScrolled(true);
          break;
        }
      }
    }
  };
  
  
  useEffect(() => {
    if (formData.countryPB === 'CA' && formErrors.zipPB) {
      setFormErrors((prevErrors) => ({ ...prevErrors, zipPB: '' }));
    } else if (formData.countryPB !== 'CA' && formErrors.zipPB) {
      setFormErrors((prevErrors) => ({ ...prevErrors, zipPB: '' }));
    }
  }, [formData.countryPB]);

  useEffect(() => {
    if (formData.mailingCountry === 'CA' && formErrors.mailingZip) {
      setFormErrors((prevErrors) => ({ ...prevErrors, mailingZip: '' }));
    } else if (formData.mailingCountry !== 'CA' && formErrors.mailingZip) {
      setFormErrors((prevErrors) => ({ ...prevErrors, mailingZip: '' }));
    }
  }, [formData.mailingCountry]);
  

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const suspendCurrentFile = () => {
    continueFiling(true)
  }

  const handleSameAsBusinessPhoneChange = (e: any) => {
    const isChecked = e.target.checked;
    setSameAsBusinessPhoneNum(isChecked);
  
    if (isChecked) {
      handleTextFieldChange({
        target: {
          name: 'businessMobilePhoneNumber',
          value: formData.businessPhoneNumber,
        },
      } as React.ChangeEvent<HTMLInputElement>);
    } else {
      // Clear mobile phone number when unchecked
      handleTextFieldChange({
        target: {
          name: 'businessMobilePhoneNumber',
          value: '',
        },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };
  

  const handleDoingNameSame = (e: any) => {
    const isChecked = e.target.checked;
    setSameLegalBusinessName(isChecked);
  
    if (isChecked) {
      handleTextFieldChange({
        target: {
          name: 'doingBusinessAs',
          value: formData.legalBusinessName,
        },
      } as React.ChangeEvent<HTMLInputElement>);
    } else {
      handleTextFieldChange({
        target: {
          name: 'doingBusinessAs',
          value: '',
        },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };
  

  const handleSameFaxNumber = (e: any) => {
    const isChecked = e.target.checked;
    setSameAsBusinessFaxNum(isChecked);
  
    if (isChecked) {
      handleTextFieldChange({
        target: {
          name: 'businessFaxNumber',
          value: formData.businessPhoneNumber,
        },
      } as React.ChangeEvent<HTMLInputElement>);
    } else {
      handleTextFieldChange({
        target: {
          name: 'businessFaxNumber',
          value: '',
        },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const handleSameAsPrincipalAddress = (e: any) => {
    const isChecked = e.target.checked;
    setSameAsPrincipalBusiness(isChecked);
  
    if (isChecked) {
      handleBulkFieldChange({
        mailingStreetAddress: formData.streetAddressPB || '',
        mailingCity: formData.cityPB || '',
        mailingState: formData.statePB || '',
        mailingZip: formData.zipPB || '',
        mailingCountry: formData.countryPB || '',
      });
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        mailingStreetAddress: '',
        mailingCity: '',
        mailingState: '',
        mailingZip: '',
        mailingCountry: '',
      }));
    } 
    // else {
    //   handleBulkFieldChange({
    //     mailingStreetAddress: '',
    //     mailingCity: '',
    //     mailingState: '',
    //     mailingZip: '',
    //     mailingCountry: '',
    //   });
    // }
  };
  

  const handleBulkFieldChange = (fields: { [key: string]: any }) => {
    setFormData((prevData) => ({
      ...prevData,
      ...fields,
    }));
  
    if (isFilingClicked) {
      const newErrors = { ...formErrors };
  
      Object.keys(fields).forEach((name) => {
        const filteredValue = fields[name];
  
        if (filteredValue.trim() === '') {
          newErrors[name] = 'This field is required';
        } else if (name === 'usdot' && !/^USDOT\d{12}$/.test(filteredValue)) {
          newErrors.usdot = 'USDOT Number must be 5 letters followed by 12 digits';
        } else if (name === 'ein' && !/^\d{9}$/.test(filteredValue)) {
          newErrors.ein = 'EIN must be a 9-digit number';
        } else if (name === 'businessPhoneNumber' && !REGEX.Regex.phone.test(filteredValue)) {
          newErrors.businessPhoneNumber = 'Business Phone Number must be a 10-digit number';
        } else if (name === 'businessMobilePhoneNumber' && !REGEX.Regex.phone.test(filteredValue)) {
          newErrors.businessMobilePhoneNumber = 'Mobile Phone Number must be a 10-digit number';
        } else if (name === 'zipPB') {
          if (formData.countryPB === 'CA') {
            if (!/^[a-zA-Z0-9]{6}$/.test(filteredValue)) {
              newErrors.zipPB = 'ZIP must be a 6-character alphanumeric';
            } else {
              newErrors.zipPB = '';
            }
          } else {
            if (!/^\d{5}$/.test(filteredValue)) {
              newErrors.zipPB = 'ZIP must be a 5-digit number';
            } else {
              newErrors.zipPB = '';
            }
          }
        } else if (name === 'mailingZip') {
          if (formData.mailingCountry === 'CA') {
            if (!/^[a-zA-Z0-9]{6}$/.test(filteredValue)) {
              newErrors.mailingZip = 'Mailing ZIP must be a 6-character alphanumeric';
            } else {
              newErrors.mailingZip = '';
            }
          } else {
            if (!/^\d{5}$/.test(filteredValue)) {
              newErrors.mailingZip = 'Mailing ZIP must be a 5-digit number';
            } else {
              newErrors.mailingZip = '';
            }
          }
        } else if (name === 'emailAddressOptional' && filteredValue && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(filteredValue)) {
          newErrors.emailAddressOptional = 'Please enter a valid email address';
        } else {
          newErrors[name] = '';
        }
      });
  
      setFormErrors(newErrors);
    }
  };
  
  
  
  

return (
  <>
  <GlobalWrapper isLoading={isLoading}>
    <>
    <Header isLogoutCta={false} />
    <Container maxWidth={false} style={{padding:'0 40px'}}>
      <Box sx={{ flexGrow: 1, ml:3, mr:3, mb:3 }}>
        <div style={{ backgroundColor: '#edf2f8' }}>
          <div style={{margin:"0px 46px"}}>
            <Box sx={{ padding: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexGrow: 1 }}>
              <h2>Filing MCS150 for - {formData?.usdot ? formData?.usdot : dotNumber} </h2>
              { currentStauts < 7 && 
                  <SuspendFilingDialog
                  styles={styles}
                  mappingKey={mappingKey}
                  suspendCurrentFile={suspendCurrentFile}
                  setSuspendRemark={setSuspendRemark}
                  />
              }
              </Box>
              <Grid container spacing={2}>
                {/* USDOT and EIN */}
                <Grid item xs={6} md={3}>
                <div style={styles.textField}>USDOT Number</div>
                  <TextField
                    placeholder="Enter the USDOT Number"
                    name="usdot"
                    value={formData.usdot}
                    onChange={handleTextFieldChange}
                    fullWidth
                    error={!!formErrors.usdot}
                    helperText={formErrors.usdot}
                    inputProps={{
                      maxLength: 12,
                    }}
                    InputProps={{
                      readOnly: isEditFiling,
                      endAdornment: isFileAccepted && (
                        <Tooltip title="Copy">
                          <IconButton onClick={() => handleCopy(formData.usdot)}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                    sx={{
                      ...styles.textFieldInside,
                      ...(isEditFiling && styles.textFieldInsideDisable),
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                <div style={styles.textField}>EIN</div>
                  <TextField
                    placeholder="Enter the EIN"
                    name="ein"
                    value={formData.ein}
                    onChange={handleTextFieldChange}
                    fullWidth

                    error={!!formErrors.ein}
                    helperText={formErrors.ein}
                    InputProps={{
                      readOnly: isEditFiling,
                      inputProps: {
                        maxLength: 9,
                      },
                      endAdornment: isFileAccepted && (
                        <Tooltip title="Copy">
                          <IconButton onClick={() => handleCopy(formData.ein)}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                    sx={{
                      ...styles.textFieldInside,
                      ...(isEditFiling && styles.textFieldInsideDisable),
                    }}
                  />
                </Grid>
                {
                 currentStauts > 6 ? (
                  <Grid item xs={6} md={3}>
                <div style={styles.textField}>USDOT PIN</div>
                <TextField
                  placeholder="Enter the EIN"
                  name="usdotpinform"
                  value={dotPin}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    endAdornment: isFileAccepted && (
                      <Tooltip title="Copy">
                        <IconButton onClick={() => navigator.clipboard.writeText(dotPin)}>
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                  sx={{
                    ...styles.textFieldInside,
                    ...(isEditFiling && styles.textFieldInsideDisable),
                  }}
                />
              </Grid>
                 ) : 
                 (
                  <Grid item xs={6} md={3}></Grid>
                 )
              }
              </Grid>
  {/* Company Official */}
              <Divider sx={{mt:3, mb:2}} />
              <p style={styles.titleTag}>Company Official Authorized to Complete Forms</p>
              <Grid container spacing={2}>
                {/* <p>Company Official Authorized to Complete Forms</p> */}
                <Grid item xs={6} md={6}>
                <div style={styles.textField}>Name</div>
                  <TextField
                    placeholder="Enter Name"
                    name="name"
                    value={formData.name}
                    onChange={handleTextFieldChange}
                    fullWidth
                    error={!!formErrors.name}
                    helperText={formErrors.name}
                    InputProps={{
                      readOnly: isEditFiling,
                      endAdornment: isFileAccepted && (
                        <Tooltip title="Copy">
                          <IconButton onClick={() => handleCopy(formData.name)}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                    sx={{
                      ...styles.textFieldInside,
                      ...(isEditFiling && styles.textFieldInsideDisable),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <div style={styles.textField}>Title</div>
                  <TextField
                    placeholder="Enter Title"
                    name="title"
                    value={formData.title}
                    onChange={handleTextFieldChange}
                    fullWidth
                    error={!!formErrors.title}
                    helperText={formErrors.title}
                    InputProps={{
                      readOnly: isEditFiling,
                      endAdornment: isFileAccepted && (
                        <Tooltip title="Copy">
                          <IconButton onClick={() => handleCopy(formData.title)}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                    sx={{
                      ...styles.textFieldInside,
                      ...(isEditFiling && styles.textFieldInsideDisable),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <div style={styles.textField}>Legal Business Name</div>
                  <TextField
                    placeholder="Enter Legal Business Name"
                    name="legalBusinessName"
                    value={formData.legalBusinessName}
                    onChange={handleTextFieldChange}
                    fullWidth
                    error={!!formErrors.legalBusinessName}
                    helperText={formErrors.legalBusinessName}
                    InputProps={{
                      readOnly: isEditFiling,
                      endAdornment: isFileAccepted && (
                        <Tooltip title="Copy">
                          <IconButton onClick={() => handleCopy(formData.legalBusinessName)}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                    sx={{
                      ...styles.textFieldInside,
                      ...(isEditFiling && styles.textFieldInsideDisable),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <div style={styles.textField}>Doing Business As</div>
                  <TextField
                    placeholder="Enter Doing Business Name"
                    name="doingBusinessAs"
                    value={formData.doingBusinessAs}
                    onChange={handleTextFieldChange}
                    error={!!formErrors.doingBusinessAs}
                    helperText={formErrors.doingBusinessAs}
                    InputProps={{
                      readOnly: isEditFiling || sameLegalBusinessName,
                      endAdornment: isFileAccepted && (
                        <Tooltip title="Copy">
                          <IconButton onClick={() => handleCopy(formData.doingBusinessAs)}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                    fullWidth
                    sx={{
                      ...styles.textFieldInside,
                      ...((isEditFiling || sameLegalBusinessName) && styles.textFieldInsideDisable),
                    }}
                    disabled={sameLegalBusinessName}
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sameLegalBusinessName}
                        onChange={(e) => handleDoingNameSame(e)}
                        disabled={isEditFiling}
                      />
                    }
                    label="Legal business name and doing business is the same"
                  />
                </Grid>
              </Grid>
  {/* Principal Place of Business */}
              <Divider sx={{mt:3, mb:2}} />
              <p style={styles.titleTag}>Principal Place of Business</p>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                <div style={styles.textField}>Street Address</div>
                  <TextField
                    placeholder="Enter Street Address"
                    name="streetAddressPB"
                    value={formData.streetAddressPB}
                    onChange={handleTextFieldChange}
                    fullWidth
                    error={!!formErrors.streetAddressPB}
                    helperText={formErrors.streetAddressPB}
                    InputProps={{
                      readOnly: isEditFiling,
                      endAdornment: isFileAccepted && (
                        <Tooltip title="Copy">
                          <IconButton onClick={() => handleCopy(formData.streetAddressPB)}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                    sx={{
                      ...styles.textFieldInside,
                      ...(isEditFiling && styles.textFieldInsideDisable),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                <div style={styles.textField}>City</div>
                  <TextField
                    placeholder="Enter City"
                    name="cityPB"
                    value={formData.cityPB}
                    onChange={handleTextFieldChange}
                    fullWidth
                    error={!!formErrors.cityPB}
                    helperText={formErrors.cityPB}
                    InputProps={{
                      readOnly: isEditFiling,
                      endAdornment: isFileAccepted && (
                        <Tooltip title="Copy">
                          <IconButton onClick={() => handleCopy(formData.cityPB)}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                    sx={{
                      ...styles.textFieldInside,
                      ...(isEditFiling && styles.textFieldInsideDisable),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                <div style={styles.textField}>State</div>
                  <TextField
                    placeholder="Enter State"
                    name="statePB"
                    value={formData.statePB}
                    onChange={handleTextFieldChange}
                    fullWidth
                    error={!!formErrors.statePB}
                    helperText={formErrors.statePB}
                    InputProps={{
                      readOnly: isEditFiling,
                      endAdornment: isFileAccepted && (
                        <Tooltip title="Copy">
                          <IconButton onClick={() => handleCopy(formData.statePB)}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                    inputProps={{
                      maxLength: 5,
                    }}
                    sx={{
                      ...styles.textFieldInside,
                      ...(isEditFiling && styles.textFieldInsideDisable),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                <div style={styles.textField}>ZIP</div>
                  <TextField
                    placeholder="Enter ZIP"
                    name="zipPB"
                    value={formData.zipPB}
                    onChange={handleTextFieldChange}
                    fullWidth
                    error={!!formErrors.zipPB}
                    helperText={formErrors.zipPB}
                    InputProps={{
                      readOnly: isEditFiling,
                      endAdornment: isFileAccepted && (
                        <Tooltip title="Copy">
                          <IconButton onClick={() => handleCopy(formData.zipPB)}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                    inputProps={{
                      maxLength: formData.countryPB === 'CA' ? 6 : 5,
                    }}
                  
                    sx={{
                      ...styles.textFieldInside,
                      ...(isEditFiling && styles.textFieldInsideDisable),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                <div style={styles.textField}>Country</div>
                    <Select
                        name="countryPB"
                        value={formData.countryPB || ""}
                        onChange={handleSelectChange}
                        fullWidth
                        displayEmpty
                        disabled={isEditFiling}
                        error={!!formErrors.countryPB}
                        sx={{
                          ...styles.textFieldInsideSelect,
                          ...(isEditFiling && styles.textFieldInsideSelectDisable),
                        }}
                        endAdornment={isFileAccepted && (
                          <InputAdornment position="end">
                            <Tooltip title="Copy">
                              <IconButton onClick={() => handleCopy(formData.countryPB)}>
                                <ContentCopyIcon />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        )}
                      >
                        <MenuItem value="" disabled>
                          Select Country
                        </MenuItem>
                        <MenuItem value="US">United States of America</MenuItem>
                        <MenuItem value="CA">Canada</MenuItem>
                        <MenuItem value="MX">Mexico</MenuItem>
                      </Select>

                    {formErrors.countryPB && <FormHelperText style={{ color: '#d32f2f', marginTop: '4px' }}>{formErrors.countryPB}</FormHelperText>}
                </Grid> 
                <Grid item xs={12} sm={4}>
                <div style={styles.textField}>Email Address (Optional)</div>
                  <TextField
                    placeholder="Enter Email Address"
                    name="emailAddressOptional"
                    value={formData.emailAddressOptional}
                    onChange={handleTextFieldChange}
                    fullWidth
                    error={!!formErrors.emailAddressOptional}
                    helperText={formErrors.emailAddressOptional}
                    InputProps={{
                      readOnly: isEditFiling,
                      endAdornment: isFileAccepted && (
                        <Tooltip title="Copy">
                          <IconButton onClick={() => handleCopy(formData.emailAddressOptional)}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                    sx={{
                      ...styles.textFieldInside,
                      ...(isEditFiling && styles.textFieldInsideDisable),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                <div style={styles.textField}>Business Phone Number</div>
                  <TextField
                    placeholder="Enter Business Phone Number"
                    name="businessPhoneNumber"
                    value={formData.businessPhoneNumber}
                    onChange={handleTextFieldChange}
                    fullWidth
                    error={!!formErrors.businessPhoneNumber}
                    helperText={formErrors.businessPhoneNumber}
                    InputProps={{
                      readOnly: isEditFiling,
                      inputProps: {
                        maxLength: 10,
                      },
                      endAdornment: isFileAccepted && (
                        <Tooltip title="Copy">
                          <IconButton onClick={() => handleCopy(formData.businessPhoneNumber)}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                    sx={{
                      ...styles.textFieldInside,
                      ...(isEditFiling && styles.textFieldInsideDisable),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                <div style={styles.textField}>Business Mobile Phone Number</div>
                  <TextField
                    placeholder="Enter Business Mobile Phone Number"
                    name="businessMobilePhoneNumber"
                    value={formData.businessMobilePhoneNumber}
                    onChange={handleTextFieldChange}
                    fullWidth
                    error={!!formErrors.businessMobilePhoneNumber}
                    helperText={formErrors.businessMobilePhoneNumber}
                    InputProps={{
                      readOnly: isEditFiling || sameAsBusinessPhoneNum,
                      inputProps: {
                        maxLength: 10,
                      },
                      endAdornment: isFileAccepted && (
                        <Tooltip title="Copy">
                          <IconButton onClick={() => handleCopy(formData.businessMobilePhoneNumber)}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                    sx={{
                      ...styles.textFieldInside,
                      ...((isEditFiling || sameAsBusinessPhoneNum) && styles.textFieldInsideDisable),
                    }}
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sameAsBusinessPhoneNum}
                        onChange={(e) => handleSameAsBusinessPhoneChange(e)}
                        disabled={isEditFiling}
                      />
                    }
                    label="Same as business phone number"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                <div style={styles.textField}>Business Fax Number (Optional)</div>
                  <TextField
                    placeholder="Enter Fax Number"
                    name="businessFaxNumber"
                    value={formData.businessFaxNumber}
                    onChange={handleTextFieldChange}
                    fullWidth
                    error={!!formErrors.businessFaxNumber}
                    helperText={formErrors.businessFaxNumber}
                    InputProps={{
                      readOnly: isEditFiling || sameAsBusinessFaxNum,
                      inputProps: {
                        maxLength: 10,
                      },
                      endAdornment: isFileAccepted && (
                        <Tooltip title="Copy">
                          <IconButton onClick={() => handleCopy(formData.businessFaxNumber)}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                    sx={{
                      ...styles.textFieldInside,
                      ...((isEditFiling || sameAsBusinessFaxNum) && styles.textFieldInsideDisable),
                    }}
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sameAsBusinessFaxNum}
                        onChange={(e) => handleSameFaxNumber(e)}
                        disabled={isEditFiling}
                      />
                    }
                    label="Same as business phone number"
                  />
                </Grid>
              </Grid>

  {/* Principal Place of Business */}
            <Divider sx={{mt:3, mb:2}} />
            <p style={styles.titleTag}>Mailing Address</p>
            <FormControlLabel
                  control={
                    <Checkbox
                      checked={sameAsPrincipalBusiness}
                      onChange={(e) => handleSameAsPrincipalAddress(e)}
                      disabled={isEditFiling}
                    />
                  }
                  label="Same as principal place of business"
              />
            <Grid container spacing={2}>
           <Grid item xs={12} sm={4}>
              <div style={styles.textField}>Street Address</div>
              <TextField
                placeholder="Enter Street Address"
                name="mailingStreetAddress"
                value={formData.mailingStreetAddress}
                onChange={handleTextFieldChange}
                fullWidth
                error={!!formErrors.mailingStreetAddress}
                helperText={formErrors.mailingStreetAddress}
                InputProps={{
                  readOnly: isEditFiling || sameAsPrincipalBusiness,
                  endAdornment: isFileAccepted && (
                    <Tooltip title="Copy">
                      <IconButton onClick={() => handleCopy(formData.mailingStreetAddress)}>
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
                sx={{
                  ...styles.textFieldInside,
                  ...((isEditFiling || sameAsPrincipalBusiness) && styles.textFieldInsideDisable),
                }}
              />
            </Grid>

              <Grid item xs={12} sm={4}>
              <div style={styles.textField}>City</div>
                <TextField
                  placeholder="Enter City"
                  name="mailingCity"
                  value={formData.mailingCity}
                  onChange={handleTextFieldChange}
                  fullWidth
                  error={!!formErrors.mailingCity}
                  helperText={formErrors.mailingCity}
                  InputProps={{
                    readOnly: isEditFiling || sameAsPrincipalBusiness,
                    endAdornment: isFileAccepted && (
                      <Tooltip title="Copy">
                        <IconButton onClick={() => handleCopy(formData.mailingCity)}>
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                  sx={{
                    ...styles.textFieldInside,
                    ...((isEditFiling || sameAsPrincipalBusiness) && styles.textFieldInsideDisable),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
              <div style={styles.textField}>State</div>
                <TextField
                  placeholder="Enter State"
                  name="mailingState"
                  value={formData.mailingState}
                  onChange={handleTextFieldChange}
                  fullWidth
                  error={!!formErrors.mailingState}
                  helperText={formErrors.mailingState}
                  InputProps={{
                    readOnly: isEditFiling || sameAsPrincipalBusiness,
                    endAdornment: isFileAccepted && (
                      <Tooltip title="Copy">
                        <IconButton onClick={() => handleCopy(formData.mailingState)}>
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                  inputProps={{
                    maxLength: 5,
                  }}
                  sx={{
                    ...styles.textFieldInside,
                    ...((isEditFiling || sameAsPrincipalBusiness) && styles.textFieldInsideDisable),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
              <div style={styles.textField}>ZIP</div>
                <TextField
                  placeholder="Enter ZIP"
                  name="mailingZip"
                  value={formData.mailingZip}
                  onChange={handleTextFieldChange}
                  fullWidth
                  error={!!formErrors.mailingZip}
                  helperText={formErrors.mailingZip}
                  InputProps={{
                    readOnly: isEditFiling || sameAsPrincipalBusiness,
                    endAdornment: isFileAccepted && (
                      <Tooltip title="Copy">
                        <IconButton onClick={() => handleCopy(formData.mailingZip)}>
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                  inputProps={{
                    maxLength: formData.mailingCountry === 'CA' ? 6 : 5,
                  }}
                  sx={{
                    ...styles.textFieldInside,
                    ...((isEditFiling || sameAsPrincipalBusiness) && styles.textFieldInsideDisable),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <div style={styles.textField}>Country</div>
                <Select
                  name="mailingCountry"
                  value={formData.mailingCountry || ""}
                  onChange={handleSelectChange}
                  fullWidth
                  displayEmpty
                  error={!!formErrors.mailingCountry}
                  disabled={isEditFiling || sameAsPrincipalBusiness}
                  sx={{
                    ...styles.textFieldInsideSelect,
                    ...((isEditFiling || sameAsPrincipalBusiness) && styles.textFieldInsideSelectDisable),
                  }}
                  endAdornment={isFileAccepted && (
                    <InputAdornment position="end">
                      <Tooltip title="Copy">
                        <IconButton onClick={() => handleCopy(formData.mailingCountry)}>
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  )}
                >
                  <MenuItem value="" disabled>Select Country</MenuItem>
                  <MenuItem value="US">United States of America</MenuItem>
                  <MenuItem value="CA">Canada</MenuItem>
                  <MenuItem value="MX">Mexico</MenuItem>
                </Select>

                {formErrors.mailingCountry && (
                  <FormHelperText style={{ color: '#d32f2f', marginTop: '4px' }}>
                    {formErrors.mailingCountry}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>


            <Divider sx={{mt:3, mb:2}} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
              <div style={styles.textField}>Mileage</div>
                <TextField
                  placeholder="Enter Millage"
                  name="mileage"
                  value={formData.mileage}
                  onChange={handleTextFieldChange}
                  fullWidth
                  error={!!formErrors.mileage}
                  helperText={formErrors.mileage}
                  InputProps={{
                    readOnly: isEditFiling,
                    endAdornment: isFileAccepted && (
                      <Tooltip title="Copy">
                        <IconButton onClick={() => handleCopy(formData.mileage)}>
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                  sx={{
                    ...styles.textFieldInside,
                    ...(isEditFiling && styles.textFieldInsideDisable),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
              <div style={styles.textField}>Mileage Year</div>
                <Select
                  name="mileageYear"
                  value={formData.mileageYear || ""}
                  onChange={handleSelectChange}
                  fullWidth
                  displayEmpty
                  disabled={isEditFiling}
                  error={!!formErrors.mileageYear}
                  sx={{
                    ...styles.textFieldInsideSelect,
                    ...(isEditFiling && styles.textFieldInsideSelectDisable),
                  }}
                  endAdornment={isFileAccepted && (
                    <InputAdornment position="end">
                      <Tooltip title="Copy">
                        <IconButton onClick={() => handleCopy(formData.mileageYear)}>
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  )}
                >
                   <MenuItem value="" disabled>
                      Select Mileage Year
                    </MenuItem>
                  <MenuItem value="2023">2023</MenuItem>
                  <MenuItem value="2024">2024</MenuItem>
                  <MenuItem value="2025">2025</MenuItem>
                </Select>
                {(formErrors.mileageYear && isFormSubmitted) && ( <FormHelperText error>{formErrors.mileageYear}</FormHelperText> )}
              </Grid>
            </Grid>

  {/* Two Sole Proprietor, Officers */}
              <Divider sx={{mt:3, mb:2}} />
              <p style={styles.titleTag}>Two Sole Proprietor, Officers, or Partners and Their Titles</p>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                <div style={styles.textField}>Name</div>
                  <TextField
                    placeholder="Enter Name"
                    name="officerName1"
                    value={formData.officerName1}
                    onChange={handleTextFieldChange}
                    fullWidth
                    error={!!formErrors.officerName1}
                    helperText={formErrors.officerName1}
                    InputProps={{
                      readOnly: isEditFiling,
                      endAdornment: isFileAccepted && (
                        <Tooltip title="Copy">
                          <IconButton onClick={() => handleCopy(formData.officerName1)}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                    sx={{
                      ...styles.textFieldInside,
                      ...(isEditFiling && styles.textFieldInsideDisable),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <div style={styles.textField}>Title</div>
                  <TextField
                    placeholder="Enter Title"
                    name="officerTitle1"
                    value={formData.officerTitle1}
                    onChange={handleTextFieldChange}
                    fullWidth
                    error={!!formErrors.officerTitle1}
                    helperText={formErrors.officerTitle1}
                    InputProps={{
                      readOnly: isEditFiling,
                      endAdornment: isFileAccepted && (
                        <Tooltip title="Copy">
                          <IconButton onClick={() => handleCopy(formData.officerTitle1)}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                    sx={{
                      ...styles.textFieldInside,
                      ...(isEditFiling && styles.textFieldInsideDisable),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <div style={styles.textField}>Name</div>
                  <TextField
                    placeholder="Enter Name"
                    name="officerName2"
                    value={formData.officerName2}
                    onChange={handleTextFieldChange}
                    fullWidth
                    error={!!formErrors.officerName2}
                    helperText={formErrors.officerName2}
                    InputProps={{
                      readOnly: isEditFiling,
                      endAdornment: isFileAccepted && (
                        <Tooltip title="Copy">
                          <IconButton onClick={() => handleCopy(formData.officerName2)}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                    sx={{
                      ...styles.textFieldInside,
                      ...(isEditFiling && styles.textFieldInsideDisable),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <div style={styles.textField}>Title</div>
                  <TextField
                    placeholder="Enter Title"
                    name="officerTitle2"
                    value={formData.officerTitle2}
                    onChange={handleTextFieldChange}
                    fullWidth
                    error={!!formErrors.officerTitle2}
                    helperText={formErrors.officerTitle2}
                    InputProps={{
                      readOnly: isEditFiling,
                      endAdornment: isFileAccepted && (
                        <Tooltip title="Copy">
                          <IconButton onClick={() => handleCopy(formData.officerTitle2)}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                    sx={{
                      ...styles.textFieldInside,
                      ...(isEditFiling && styles.textFieldInsideDisable),
                    }}
                  />
                </Grid>
              </Grid>

  {/* Notes if any */}
              <Divider sx={{mt:3, mb:2}} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                <div style={styles.textField}>Notes (If any)</div>
                  <TextField
                    placeholder="Enter Notes"
                    name="notesAny"
                    value={formData.notesAny}
                    onChange={handleTextFieldChange}
                    fullWidth
                    multiline
                    rows={3}
                    error={!!formErrors.notesAny}
                    disabled={isEditFiling}
                    // helperText={formErrors.notesAny}
                    sx={{
                      ...styles.textAreaBox,
                      ...(isEditFiling && styles.textAreaBoxDisable),
                    }}
                    InputProps={{
                      endAdornment: isFileAccepted && (
                        <Tooltip title="Copy">
                          <IconButton onClick={() => handleCopy(formData.notesAny)}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                  />
                   {formErrors.notesAny && (
                  <div style={{ color: '#d32f2f', marginTop: '4px', fontSize:'0.75rem' }}>
                    {formErrors.notesAny}
                  </div>
              )}
                </Grid>
              </Grid>

              {/* Submit button */}
              <Box sx={{ marginTop: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
               {
                currentStauts < 5 && 
                (
                  isEditFiling ? 
                  <Button 
                    variant="contained" 
                    onClick={()=> setIsEditFiling(!isEditFiling)}  
                    sx={{
                      backgroundColor: '#016b89',
                      marginLeft: 2,
                      marginBottom:3,
                      width: '250px',
                      ...styles.button,
                    }}>Edit Filing
                  </Button>
                  :
                  <Button 
                    variant="contained" 
                    onClick={()=>continueFiling(false)}  
                    sx={{
                      backgroundColor: '#016b89',
                      marginLeft: 2,
                      marginBottom:3,
                      width: '250px',
                      ...styles.button,
                    }}>     Continue Filing
                  </Button>
                )
               } 
              </Box>
            </Box>
          </div>
        </div>
      </Box>
    </Container>


   {/* { (isEditFiling || isFileAccepted) &&  <FilingActionPanel formKey={formKey} mappingKey={mappingKey} getCurrentUserData={getCurrentUserData} setGetCurrentUserData={setGetCurrentUserData} setActionPanelData={setActionPanelData} actionPanelData={actionPanelData} fileMCSData={formData} setIsFileAccepted={setIsFileAccepted} setIsEditFiling={setIsEditFiling}  currentStauts={currentStauts} setCurrentStatus={setCurrentStatus}  /> } */}
   <FilingActionPanel formKey={formKey} mappingKey={mappingKey} getCurrentUserData={getCurrentUserData} setGetCurrentUserData={setGetCurrentUserData} setActionPanelData={setActionPanelData} actionPanelData={actionPanelData} fileMCSData={formData} setIsFileAccepted={setIsFileAccepted} isFileAccepted={isFileAccepted}  currentStauts={currentStauts} setCurrentStatus={setCurrentStatus} isEditFiling={isEditFiling} setIsEditFiling={setIsEditFiling} setDotPin={setDotPin}/>
     
   </>
  </GlobalWrapper>

{/* Seuspend Filing */}
   
  </>
  );
};

export default FileMCS150;
