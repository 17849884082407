import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
} from "@mui/material";
import { formatDate } from "../../Utils/Constants";

export default function PriorSoldVehicleDetails({
  vehicleResponse,
}: {
  vehicleResponse: any;
}) {
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>VIN</strong>
            </TableCell>
            <TableCell>
              <strong>Date Sold</strong>
            </TableCell>
            <TableCell>
              <strong>Buyer</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {vehicleResponse.form2290Model &&
            vehicleResponse.form2290Model.vehicleResponse &&
            vehicleResponse.form2290Model.vehicleResponse.priorSuspendedVehicles?.map(
              (row: any) => (
                <TableRow
                  key={row?.vin}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row?.vin}
                  </TableCell>
                  <TableCell align="left">
                    {formatDate(row?.effectiveDate)}
                  </TableCell>
                  <TableCell align="left">{row?.buyer}</TableCell>
                </TableRow>
              )
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
