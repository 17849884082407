import { AxiosResponse } from 'axios';
import { HttpApi } from '../api/HttpsApi';
import { CUSTOMER_IN_FUNNEL_LIST_ENV, StatusEnum } from '../Utils/Constants';

export const getAgentsList = () => {
  return HttpApi({
    url: 'api/payments/cs/agent/list',
    method: 'GET'
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};

export const getCustomerInFunnelReports = async (startDate: string, endDate: string ,param : string) => {
  try {
    const response = await HttpApi({
      url: `api/report/funnel/customers?case=${param}`,
      method: 'POST',
      data: {
        startDate,
        endDate
      }
    })
    return (response as AxiosResponse).data;
  } catch (e) {
    return {
      error: e
    };
  }
}

export const sendReportAsEmail = async (startDate: string, endDate: string ,param : string , emailList : any) => {
  const isClosedCase = param==StatusEnum.OPEN || param==StatusEnum.FOLLOW_UP? false : true;
  let body = {};
  if(isClosedCase) {
    body = {
      email : emailList,
      domain : CUSTOMER_IN_FUNNEL_LIST_ENV,
      status : StatusEnum.CLOSED ,
      startDate : startDate,
      endDate : endDate,
    };
  }
  else {  
    body = {
      email : emailList,
      domain : CUSTOMER_IN_FUNNEL_LIST_ENV,
      status :  param ,
    };
  }
  try {
    const response = await HttpApi({
      url: `api/report/funnel/customers/email`,
      method: 'POST',
      data: body
    })
    return (response as AxiosResponse).data;
  } catch (e) {
    return {
      error: e
    };
  }
}

export const downloadReportAsExcel = async (startDate: string, endDate: string ,param : string , emailList : any) => {
  const isClosedCase = param==StatusEnum.OPEN || param==StatusEnum.FOLLOW_UP? false : true;
  let body = {};
  if(isClosedCase) {
    body = {
      email : emailList,
      domain : CUSTOMER_IN_FUNNEL_LIST_ENV,
      status : StatusEnum.CLOSED ,
      startDate : startDate,
      endDate : endDate,
    };
  }
  else {  
    body = {
      email : emailList,
      domain : CUSTOMER_IN_FUNNEL_LIST_ENV,
      status :  param ,
    };
  }
  try {
    const response = await HttpApi({
      url: `api/report/funnel/customers/excel`,
      method: 'POST',
      data: body
    })
    return (response as AxiosResponse).data;
  } catch (e) {
    return {
      error: e
    };
  }
}

export const downloadFile = (response: any, fileName?: string) => {
  const a = document.createElement("a");
  a.href = response;
  a.download = response.split('/').pop() || "download.xls"; 
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const agentAssignment = async (id: any, userData: any ,executiveId : any) => {
  const body = {
    id: id,
    userKey: userData?.userKey,
    email: userData?.email,
    phone: userData?.mobileNumber,
    fullName: userData?.user,
    currentPage: userData?.currentPage || 'currentPage',
    previousPage: userData?.previousPage,
    timeSpent: userData?.timeSpent || '00:00',
    filingIntiatedAt: userData?.filingInitiated,
    filingType: userData?.filingType || "2290/8849",
    userCredit: userData?.efilingCredits || 0,
    isNewUser: userData?.isNewUser,
    currentUrl: userData?.currentUrl,
    previousUrl: userData?.previousUrl,
    executiveId:executiveId,
  };
  try {
    const response = await HttpApi({
      url: `api/report/funnel/assign/agent/customers`,
      method: 'POST',
      data: body
    })
    return (response as AxiosResponse).data;
  } catch (e) {
    return {
      error: e
    };
  }
}