import * as React from "react";
import { Button, Input, styled ,Card } from "@mui/material";
import store from "../../Store";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useState , useEffect} from "react";
import { DETAIL_TYPE } from "../../common/apis/const";
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import DashboardLinkService from "../../Services/dashboard-links.service";

const TabPanel = (props: { [x: string]: any; children: any; value: any; index: any; }) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default function EFilingCredits({ amount,setAmountPending,amountPending,setOpenCreditDetails,openCreditDetails }: { amount: any,setAmountPending:any, amountPending:any,setOpenCreditDetails:any,openCreditDetails:any }) {
  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
    setValue(newValue);
  };
  const Text = styled("div")(({ theme }) => ({
     fontSize: '12px',
      border: 'solid 1px #c05621',
      borderRadius:'6px',
      backgroundColor:'#faebcb',
      padding:'8px',
      width:'500px'
  }));

  const Value = styled("div")(({ theme }) => ({
    marginRight: "auto",
    marginLeft: "auto",
    width: "100%",
    fontSize:"14px !important",
  }));

  const label = styled("div")(({ theme }) => ({
   fontSize:"14px !important",
  }));
  
  const [filingDetail, setFilingDetail] = React.useState(amount);
  const [initialPageValue, setInitialPageValue] = useState(0);
  const filingType = DETAIL_TYPE.DetailType;
  const title =DETAIL_TYPE.titles;

  
  useEffect(() => {
    handleOpenCreditDetails();
    return () => {
      document.removeEventListener('updateTransactionTable', handleEvent);
    };
  },[value, initialPageValue]);
  
  const handleEvent = () => {
    setInitialPageValue(prevCount => prevCount+1)
  }
  const registerUpdateTableEvent = () => {
    document.addEventListener('updateTransactionTable', handleEvent);
  }

  const handleOpenCreditDetails = () => {
    registerUpdateTableEvent();
    return DashboardLinkService?.csCreditDetails(
      store.getState()?.message?.message[0]?.user?.email?.toLowerCase()
    )
      .then((data: any) => {
        setOpenCreditDetails(true);
        setAmountPending(data.data.amountPending);
        setFilingDetail(data.data);
      })
      .catch(() => {
        setOpenCreditDetails(false);
      });
    // setOpenCreditDetails(val)
  };

  const WarningIconSVG = () => {
    return (
      <svg data-name="Warning Icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path data-name="Path 40951" d="M0 0h20v20H0z" fill="none" />
        <path
          d="M10.411 5.657 16.686 16.5H4.136l6.275-10.843M2.695 15.665a1.666 1.666 0 0 0 1.442 2.5h12.55a1.666 1.666 0 0 0 1.442-2.5L11.853 4.824a1.666 1.666 0 0 0-2.883 0zm6.883-5.833V11.5a.833.833 0 1 0 1.667 0V9.832a.833.833 0 1 0-1.667 0zm0 4.167h1.667v1.667H9.578z"
          transform="translate(-.802 -1.493)"
          style={{ fill: '#9c4221' }} 
        />
      </svg>
    );
  };
  
  

  const DollarCircleSVG = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60.003" viewBox="0 0 60 60.003">
        <g id="no-money" transform="translate(-97.703 -98.807)">
          <path id="Path_97219" data-name="Path 97219" d="M472.511,218.906A2.511,2.511,0,0,1,470,216.394v-5.143a2.511,2.511,0,1,1,5.023,0v5.143A2.511,2.511,0,0,1,472.511,218.906Z" transform="translate(-344.723 -100.731)" fill="#dbdbdb"/>
          <path id="Path_97220" data-name="Path 97220" d="M472.511,679.986A2.511,2.511,0,0,1,470,677.474v-5.143a2.511,2.511,0,0,1,5.023,0v5.143A2.511,2.511,0,0,1,472.511,679.986Z" transform="translate(-344.723 -530.374)" fill="#dbdbdb"/>
          <path id="Path_97221" data-name="Path 97221" d="M354.02,280.133a5.265,5.265,0,0,1,2.229-3.588,10.108,10.108,0,0,1,5.514-1.905,11.886,11.886,0,0,1,1.327,0,9.916,9.916,0,0,1,4.147,1.385l3.6-3.6a14.751,14.751,0,0,0-7.46-2.8,16.747,16.747,0,0,0-1.89,0,15.153,15.153,0,0,0-8.275,2.921,10.2,10.2,0,0,0-4.185,7.1,9.308,9.308,0,0,0,1.953,6.6,12.013,12.013,0,0,0,3.167,2.87l3.7-3.7C355.3,284.256,353.819,282.231,354.02,280.133Z" transform="translate(-233.57 -158.646)" fill="#dbdbdb"/>
          <path id="Path_97222" data-name="Path 97222" d="M398.9,498.307a4.5,4.5,0,0,1,1.118,3.292,5.275,5.275,0,0,1-2.228,3.588,10.106,10.106,0,0,1-5.516,1.9,12.072,12.072,0,0,1-1.325,0,9.59,9.59,0,0,1-4.8-1.855L382.59,508.8a14.5,14.5,0,0,0,8.075,3.307c.318.018.643.028.964.028s.62-.008.927-.026a15.158,15.158,0,0,0,8.278-2.921,10.2,10.2,0,0,0,4.185-7.107,9.475,9.475,0,0,0-2.26-6.985,12.38,12.38,0,0,0-3.562-2.906l-3.788,3.789A8.136,8.136,0,0,1,398.9,498.307Z" transform="translate(-264.86 -365.447)" fill="#dbdbdb"/>
          <path id="Path_97223" data-name="Path 97223" d="M155.351,117.165A30,30,0,1,0,157.7,128.81a30,30,0,0,0-2.353-11.646Zm-27.561-12.815a24.353,24.353,0,0,1,14.977,5.122l-34.316,34.316a24.46,24.46,0,0,1,19.339-39.438Zm0,48.922a24.361,24.361,0,0,1-15.537-5.567l34.43-34.43a24.462,24.462,0,0,1-18.894,40Z" transform="translate(0 0)" fill="#dbdbdb"/>
        </g>
      </svg>
    );
  };

  const generateContent = (detailsArray: any[]) => {
    return (
      <Value>
        {Array.isArray(detailsArray) && detailsArray.length === 0 ? (
          <div>
            <div style={{textAlign:'center' , marginTop:'30px'}}>        
              <DollarCircleSVG />        
            </div>
             <div style={{fontSize:'18px' , marginTop:'15px' ,fontWeight:'bold', textAlign:'center'}}>Looks like there’s no e-Filing credit history here.</div>
            
          </div>
        ) : (
          <>
            <div>
              {detailsArray?.map((detail, index) => (
                <Card key={index} variant="outlined" style={{ margin: '10px 0' }}>
                  <CardContent>
                    {/* Display only 5 columns in the first row */}
                    <Grid container spacing={2} style={{ fontWeight: 'bold' , wordBreak:'break-word', whiteSpace: 'normal' }}>
                      <Grid item xs={1.5}>
                        {detail.type}
                      </Grid>
                      <Grid item xs={2.7} >
                      {(detail.type === filingType.used &&  !detail.refNo) ? `${title.refundedBY} ${detail.name}` : `${title.refNo}: ${detail.refNo}`}
                      </Grid>
                      <Grid item xs={3.4}>
                        {detail.type === filingType.credited ? `${title.addedBy} ${detail.name}` : null}
                      </Grid>
                      <Grid item xs={3.2}>
                        {detail.type === filingType.credited ? `${title.reason}` : null}
                      </Grid>
                      <Grid item xs={1.2}>
                        {detail.type === filingType.credited ? (
                            <span style={{ color: '#358f80' }}>+ ${detail.amount}</span>
                          ) : detail.type === filingType.used ? (
                            <span style={{ color: '#c53030' }}>- ${detail.amount}</span>
                          ) : (
                            <span style={{ color: '#989898' }}>- ${detail.amount}</span>
                          )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{wordBreak:'break-word', whiteSpace: 'normal' }}>
                      <Grid item xs={1.5}>
                        On {detail.createdDate}
                      </Grid>
                      <Grid item xs={2.7}>
                        {detail.type === filingType.credited ? `${title.expiredOn} ${detail.expiryDate}` :
                         (detail.type === filingType.used && !detail.refNo)?`${detail.email}` : detail.refNo}
                      </Grid>
                      <Grid item xs={3.4}>
                        {detail.type === filingType.credited ? detail.email : null}
                      </Grid>
                      <Grid item xs={3.2}>
                        {detail.type === filingType.credited ? detail.reason : null}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ))}
            </div>
          </>
        )}
      </Value>
    );
  };
  

  return (
    <>
      {filingDetail.tooltip && (  
        <Text>
          <div className="row gx-0 align-items-center" style={{ display: 'flex' }}>
            <div style={{ marginRight: '5px' }}><WarningIconSVG /></div>
            <span>Hurry! Your <strong>${filingDetail.tooltip.credit}</strong> worth e-Filing credits are set to expire in <strong>{filingDetail.tooltip.days} days.</strong></span>
          </div>
      </Text>      
      )}
      <div>
        <Box>
        <Tabs value={value}  onChange={handleChange} style={{ paddingLeft: "0px !important" }}>
            <Tab label="All" style={{ fontSize: '14px', fontWeight:'bold',textTransform: 'none', color: 'black',minWidth: '20px' }} />
            <Tab label="Credited" style={{ fontSize: '14px', fontWeight:'bold',textTransform: 'none', color: 'black',minWidth: '40px'  }} />
            <Tab label="Used" style={{ fontSize: '14px', fontWeight:'bold',textTransform: 'none' , color: 'black',minWidth: '40px' }} />
            <Tab label="Expired" style={{ fontSize: '14px', fontWeight:'bold',textTransform: 'none' , color: 'black',minWidth: '40px' }} />
        </Tabs>
          <div>
          <TabPanel value={value} index={0}>
            {generateContent(filingDetail.all)}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {/* Content for "Credited" tab */}
            {generateContent(filingDetail.credited)}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {/* Content for "Used" tab */}
            {generateContent(filingDetail.used)}
          </TabPanel>
          <TabPanel value={value} index={3}>
            {/* Content for "Expired" tab */}
            {generateContent(filingDetail.expired)}
          </TabPanel>
          </div>
        </Box>
      </div>
    </>
  );
}