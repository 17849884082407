import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
} from './types';

import AuthService from '../Services/auth.service';
import { AxiosResponse } from 'axios';

export const register = (params: any) => (dispatch: any) => {
  return AuthService.register(params).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: {
          loginError: false,
          message: (response as AxiosResponse).data.message,
        },
      });

      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: {
          loginError: true,
          message: 'Email already registered!',
          error
        },
      });

      return Promise.reject();
    }
  );
};

export const login = (username: any, password: any) => (dispatch: any) => {
  return AuthService.login(username, password).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: {
          loginError: true,
          message: 'Invalid credentials',
        },
      });

      return Promise.reject();
    }
  );
};

export const logout = () => (dispatch: any) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
  dispatch({
    type: SET_MESSAGE,
    payload: undefined,
  });
  return Promise.resolve();
};
