import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Link,
} from "@mui/material";
import DashboardLinkService from "../../Services/dashboard-links.service";

import { months } from "../../Utils/Constants";
export default function LossDetails({ form8849, attachment8849List }: any) {
  const handleDownloadFiles = (docType: string) => {
    return DashboardLinkService.cs8849Attachment(
      docType,
      form8849?.form8849?.formKey
    )
      .then((data: any) => {
        downloadFile(data);
      })
      .catch((error: any) => {
        console.log("download files", error);
      });
  };
  const downloadFile = (response: any) => {
    const file = new Blob([response], { type: "application/pdf" });
    const fileURL = window.URL.createObjectURL(file);
    const a = document.createElement("a");
    a.target = "_blank";
    document.body.appendChild(a);
    a.href = fileURL;
    a.click();
  };
  return (
    <TableContainer component={Paper}>
      <Table aria-label="Loss Details" size="small">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Loss Event
            </TableCell>
            <TableCell>{form8849 && form8849.lossType}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Date
            </TableCell>
            <TableCell>
              {form8849 &&
                new Date(form8849.dateOfOverPayment)
                  .toISOString()
                  .substring(0, 10)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Ending Month<small>of the year</small>
            </TableCell>
            <TableCell>
              {form8849 && months[form8849.form8849.taxYearEndMonth - 1]}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              First Used Month
            </TableCell>
            <TableCell>
              {form8849 && months[form8849.firstUsedMonth - 1]}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Taxable Gross Weight
            </TableCell>
            <TableCell>{form8849 && form8849.grossWeight}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Used for Logging
            </TableCell>
            <TableCell>
              {form8849 && (form8849.isLogging ? "Yes" : "No")}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Attached File<small>In support of the claim</small>
            </TableCell>
            <TableCell>
              {attachment8849List ? (
                <Link
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleDownloadFiles(attachment8849List[0]?.attachment)
                  }
                >
                  {attachment8849List[0]?.attachment}
                </Link>
              ) : (
                "-----"
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
