import React, { useEffect, useState } from 'react';
import { Backdrop, Button, CircularProgress, TextField } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { BUTTONS, ERRORS } from './constants';
import dashboardLinksService from '../../Services/dashboard-links.service';
import { REJECTED_STATUSES } from '../../common/apis/const';

const EditIRSExplain = ({ status , vin , vehicleType , formKey , filingStatus , onClose ,  clearStateAndRefetch }: { status : string ; vin : string; vehicleType : string ; formKey : string ;filingStatus : string ; onClose: () => void ;clearStateAndRefetch : any; }) => {
    const [IRSExplain ,  setIRSExplain] = useState<string>(status);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);
    const textFieldInside: SxProps<Theme> = {
        width: '346px',
        borderRadius: '4px',
        backgroundColor: '#fff',
        border: '1px solid #707070',
        '& .MuiOutlinedInput-root': {
          padding: '9px 11px', 
          '& textarea': {
            boxSizing: 'border-box', 
          },
          '& fieldset': {
            border: 'none', 
          },
        },
    };
    const buttonDiv: React.CSSProperties = {
        display: 'flex', 
        justifyContent: 'center', 
    }; 
    const button1: React.CSSProperties = {
        fontWeight: 500,
        height: '40px',
        width: '160px',
        margin: '26px 18px 26px 0px',
        fontSize: '15px',
        borderRadius: '4px',
        border: 'solid 1px #1876d1',
        marginBottom:  '26px',
        textTransform: 'none'
    }; 
    const button2: React.CSSProperties = {
        fontWeight: 500,
        height: '40px',
        width: '160px',
        margin: '26px 0px 26px 9px',
        fontSize: '15px',
        marginBottom: '26px',
        textTransform: 'none'
    }; 

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value.replace(/\s{2,}/g, ' '); 
        setIRSExplain(value);
      };
     
    const updateIRSExplain = () => {
        if (IRSExplain.trim() === '') {
            setError(true); 
        } 
        else {
            setError(false);
            setLoading(true)
            dashboardLinksService.updateExplainToIRS(vin,IRSExplain,vehicleType,formKey)
            .then((response: any) => {
                clearStateAndRefetch();
                setLoading(false); 
                onClose();

            })
            .catch((error:any) => {
                console.log(error)
                setLoading(false); 
            });
        }
    }

    useEffect(() => {
        setIsSaveDisabled(!(REJECTED_STATUSES.includes(filingStatus)));
        console.log(isSaveDisabled)
      }, []);
    
    return (
        <>
            {loading && (
                    <Backdrop
                        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={true}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
            <TextField
                name="explain"
                rows={2}
                multiline
                variant="outlined"
                value={IRSExplain}
                inputProps={{ maxLength: 9000, style: { fontSize: '14px' , height: '160px' , fontWeight: 500 } ,readOnly: isSaveDisabled }}
                onChange={handleInputChange}
                required
                error={error}
                sx={textFieldInside}
            //placeholder='hello'
            />
            {error && (
                <p style={{ color: 'red', marginTop: '8px' , fontSize:'12px' }}>{ERRORS.errorIRS}</p>
            )}
            <div style={buttonDiv}>
            <Button
                onClick={() => {
                    onClose();
                }}
                style={button1}
            >
                {BUTTONS.cancel}
            </Button>
                <Button
                    style={button2}
                    color="primary"
                    variant="contained"
                    onClick={() =>updateIRSExplain()}
                    disabled={isSaveDisabled}
                >
                    {BUTTONS.save}
                </Button>
        </div>
    </>
  );
};

export default EditIRSExplain;
