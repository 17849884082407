export const FOLLOW_UP_CONSTANTS= {
    detailsHeading:`The details of all the open follow-up requests by the customers.`,
    noData: `No open follow-up requests pending!`,
}

export const BUTTONS = {
    Search: `Search`,
    refreshToUpdate: `Refresh to Update`,
    exportExcel: ` Export to Excel`,
    senAsEmail: `Send as Email`,
    sendReportOnEmail: `Send Report on Email`,
    refresh : 'Refresh'
}

export const CASES = {
    open: `Customer In Funnel`,
    closed: `Closed Cases`,
    followUp: `Follow Up Requests`,
}

export const FollowUpTableCells = {
    email: `Email`,
    mobileNumber :`Mobile Number`,
    agentContacted: `Agent Contacted`,
    wasConversion: `Was`,
    conversion: `Conversion?`,
    referenceNumber: `Reference Number`,
    contacted: `Contacted`,
    onIST: `on (IST)`,
    AgenComment: `Agent's Comment`,
    followUp:`Follow Up`,
    requestedOn: `Requested On`,
    assignedFollow: `Assigned Follow`,
    upTo: `Up To`,
    agent: `Agent's`,
    status:`Status`,
    followUpAssigned: `follow-up calls assigned.`,
    youAre: `You have`,
    outboundCall  : `outbound call assigned.`
}

export const OutboundTableCells = {
    user : `User`,
    currPage : `Current Page`,
    prevPage : `Previous Page`,
    timeInSession : `Time in the Session`,
    filingInitiated : `Filing Initiated at (IST`,
    credits : `eFiling Credits`
}

export const FollowOutBoundResponse = {
    email : `email`,
    phone : `phone`,
    contactedToname : `contactedTo.name`,
    isConverted : `isConverted`,
    refNo : `refNo`,
    contactedAt : `contactedAt`,
    agentLog : `agentLog`,
    followUpRequestedOnAtIst : `followUpRequestedOnAtIst`,
    fullName : `fullName`,
    currentPage :   `currentPage`,
    previousPage : `previousPage`,
    timeSpent : `timeSpent`,
    filingInitiatedAtIST : `filingInitiatedAtIST`,
    userCredit : `userCredit`,
}

export const FUNNEL_ERRORS = {
    validAgent: `Please select a valid agent.`,
}

export const CASE_STATUS = {
    Awaiting: `Awaiting`
}

export enum NOTIFICATION_TYPE {
    Regular = `REGULAR`,
    FollowUp = `FOLLOW_UP`,
}