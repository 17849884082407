/* eslint-disable react/display-name */
import React from "react";
import { createPortal } from "react-dom";
import AppLoader from "./AppLoader";

interface GlobalWrapperInterface {
  isLoading?: boolean;
  children: React.ReactElement;
}

const GlobalWrapper = ({ children, isLoading }: GlobalWrapperInterface) => {
  if (isLoading) {
    return (
      <>
        {createPortal(<AppLoader />, document.body)}
        {children}
      </>
    );
  }
  return <>{children}</>;
};

export default GlobalWrapper;
