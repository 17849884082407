import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Grid } from '@mui/material';
import mcsService from '../Services/mcs.service';
import { useNavigate } from 'react-router-dom';

interface SuspendDialogProps {
  styles:any,
  mappingKey:any,
  suspendCurrentFile:() => void; 
  setSuspendRemark:any
}


const SuspendFilingDialog: React.FC<SuspendDialogProps> = ({styles,mappingKey,suspendCurrentFile, setSuspendRemark}) => {
  const [open, setOpen] = useState(false);
  const [suspendFileRemark, setSuspendFileRemark] = useState('');
  const [suspendFileError, setSuspendFileError] = useState('');
  const navigate = useNavigate();

  // Open the dialog
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSuspendFileError('');
    setSuspendFileRemark('');
    setOpen(false);
  };

  const submitSuspendedFile = () => {
    if (suspendFileRemark.trim() === '') {
      setSuspendFileError('Please enter a reason');
    } else {
      setSuspendFileError('');
      suspendCurrentFile(); // Call the suspendCurrentFile function passed from the parent
      handleClose();
    }
  };

  const handleNotInterested = () => {
    if (suspendFileRemark.trim() === '') {
      setSuspendFileError('Please enter a reason');
    } else {
      setSuspendFileError('');
      const payload = {
        mappingKey: mappingKey,
        preference: 'NOT_INTERESTED',
        remarks: suspendFileRemark,
      };
  
      mcsService.mcsSavePrefrence(payload)
      .then((response: any) => {
        if (response.status === 200) {
          handleClose();
          navigate({pathname: '/emcs150/emcs-customers'});
        } else {
          alert(response.message);
          // throw new Error('API request failed with status ' + response.status);
        }
      })
      .catch((error: any) => {
        alert('Something went wrong, Please try again');
      })  
    }
    
  };

  const handleSubmit = () => {
    if (suspendFileRemark.trim() === '') {
      setSuspendFileError('Please enter a reason');
    } else {
      setSuspendFileError('');
      console.log('Remarks submitted:', suspendFileRemark);
      setOpen(false);
    }
  };

  // Real-time validation as user types
  const handleInputChange = (e:any) => {
    const value = e.target.value;
    setSuspendFileRemark(value);
    setSuspendRemark(value)
  
    if (value.trim() === '') {
      setSuspendFileError('Please enter a reason');
    } else {
      setSuspendFileError('');
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        sx={{
          backgroundColor: '#ffffff',
          border: 'solid 2px #016b89',
          color:'#016b89',
          marginLeft: 2,
          ...styles.button,
          '&:hover': {
            backgroundColor: '#ffffff',
            border: 'solid 2px #016b89',
            color:'#016b89',
          },
        }}
        onClick={handleOpen}
      >
        Suspend Filing
      </Button>

      {/* Suspend Filing Dialog */}
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: '#edf2f8',
            padding: '20px',
          },
        }}
      >
        <DialogTitle sx={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'left' }}>
          Suspend Filing
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={styles.textField}>Reason</div>
              <TextField
                placeholder="Enter Reason"
                fullWidth
                multiline
                rows={3}
                value={suspendFileRemark}
                onChange={handleInputChange}
                error={!!suspendFileError}
                sx={styles.textAreaBox}
              />
              {/* Error message displayed outside the TextField */}
              {suspendFileError && (
                <div style={{ color: '#d32f2f', marginTop: '4px' }}>
                  {suspendFileError}
                </div>
              )}
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: 1 }}>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              width: '150px',
              color: '#397188',
              borderColor: '#397188',
              backgroundColor: 'white',
              textTransform: 'none',
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleNotInterested}
            sx={{
              width: '150px',
              backgroundColor: '#6c757d',
              textTransform: 'none',
            }}
          >
            Not Interested
          </Button>
          <Button
            variant="contained"
            onClick={submitSuspendedFile}
            sx={{
              width: '150px',
              backgroundColor: '#397188',
              textTransform: 'none',
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SuspendFilingDialog;
