import React, {useState} from "react";
import {AppBar, Box, Tab, Tabs, Toolbar} from "@mui/material";
import CustomerInFunnelContent from "./customerInFunnelContent";
import ClosedCases from "./ClosedCases";
import FollowUpRequests from "./FollowUpRequests";
import Container from "@mui/material/Container";
import { CASES } from "./Components/constants";

export default function CustomerInFunnelReport() {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (_event: any, newValue: React.SetStateAction<number>) => {
    setSelectedTab(newValue);
  };
  const toolbar: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    marginTop: '5px'
  };

  const tab1: React.CSSProperties = {
    width: "180px",
    minHeight: "40px",
    fontSize: "16px",
    borderRadius: '4px',
    fontWeight: 500,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#1876d1",
    backgroundColor: '#fff',
    textTransform: 'none',
  };

  const tab2: React.CSSProperties = {
    width: "180px",
    minHeight: "40px",
    fontSize: "16px",
    borderRadius: '4px',
    fontWeight: 500,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#fff",
    backgroundColor: '#105eaa',
    textTransform: 'none'
  };

  return (
    <div>
      <Box sx={{flexGrow: 1}}>
        <AppBar position="static">
          <Toolbar sx={toolbar}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              TabIndicatorProps={{
                style: {display: "none"}
              }}
            >
              <Tab
                label={CASES.open}
                sx={{...selectedTab === 0 ? tab1 : tab2, marginLeft: '50px'}}
              />
              <Tab
                label={CASES.followUp}
                sx={{...selectedTab === 1 ? tab1 : tab2, marginLeft: '20px'}}
              />
              <Tab
                label={CASES.closed}
                sx={{...selectedTab === 2 ? tab1 : tab2, marginLeft: '20px'}}
              />
            </Tabs>
          </Toolbar>
        </AppBar>
      </Box>
      <div>
        {selectedTab === 0 && (
          <div>
            <CustomerInFunnelContent/>
          </div>
        )}
        {selectedTab === 1 && (
          <Container maxWidth="xl">
            <FollowUpRequests/>
          </Container>
        )}
        {selectedTab === 2 && (
          <Container maxWidth="xl">
            <ClosedCases/>
          </Container>
        )}
      </div>
    </div>
  );
}
