import * as React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  DialogTitle,
  Select,
  Box,
  InputLabel,
  FormControl,
  MenuItem,
} from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { APPROVED, REJECTED } from "../../Utils/Constants";
import DashboardLinkService from "../../Services/dashboard-links.service";
import { useSelector } from 'react-redux';

function CustomToolbar() {
  const [open, setOpen] = React.useState(false);
  const [openManual, setOpenManual] = React.useState(false);
  const handleManualClose = () => {
    setOpenManual(false);
  };
  const handleManualSave = () => {
    return DashboardLinkService.csManualUpdate(manualRefund)
      .then(() => {
        setOpenManual(false);
      })
      .catch(() => {
        setOpenManual(false);
      });
  };

  const [manualRefund, setManualRefund] = React.useState<any>({
    referenceNumber: "",
    customerEmail: "",
    // formKey: "", 
    refundTransactionId: "",
    initiatedData: "",
    refundAmount: "",
    refundInvoice: "",
    currentStatus: "",
    refundDate: "",
    refundReference: "",
    refundReason: "",
    comments: "",
  });

  const openDialog = (e: any) => {
    if (e.target.innerText == "MANUAL REFUND") {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(!open);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <div onClick={(e) => openDialog(e)}>
        <Button onClick={() => setOpenManual(true)}>Manual Refund</Button>

        <Dialog open={openManual} onClose={handleClose}>
          <DialogTitle>Manual Refund</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="refNo"
              value={manualRefund.referenceNumber}
              onChange={(e) => {
                setManualRefund({
                  ...manualRefund,
                  referenceNumber: e.target.value,
                });
              }}
              label="refrence No"
              type="email"
              fullWidth
              variant="standard"
            />
            <TextField
              autoFocus
              margin="dense"
              id="customerEmail"
              value={manualRefund.customerEmail}
              onChange={(e) => {
                setManualRefund({
                  ...manualRefund,
                  customerEmail: e.target.value,
                });
              }}
              label="Customer Email"
              type="text"
              fullWidth
              variant="standard"
            />
            {/* <TextField
              autoFocus
              margin="dense"
              id="formKey"
              value={manualRefund.formKey}
              onChange={(e) => {
                setManualRefund({ ...manualRefund, formKey: e.target.value });
              }}
              label="Form Key"
              type="text"
              fullWidth
              variant="standard"
            /> */}
            <TextField
              autoFocus
              margin="dense"
              id="refundTransactionId"
              value={manualRefund.refundTransactionId}
              onChange={(e) => {
                setManualRefund({
                  ...manualRefund,
                  refundTransactionId: e.target.value,
                });
              }}
              label="Refund TransactionId"
              type="text"
              fullWidth
              variant="standard"
            />
            <TextField
              autoFocus
              margin="dense"
              id="amount"
              value={manualRefund.refundAmount}
              onChange={(e) => {
                setManualRefund({
                  ...manualRefund,
                  refundAmount: e.target.value,
                });
              }}
              label="Amount"
              type="text"
              fullWidth
              variant="standard"
            />
            <TextField
              autoFocus
              margin="dense"
              id="invoice"
              value={manualRefund.refundInvoice}
              onChange={(e) => {
                setManualRefund({
                  ...manualRefund,
                  refundInvoice: e.target.value,
                });
              }}
              label="Invoice"
              type="text"
              fullWidth
              variant="standard"
            />
            <TextField
              autoFocus
              margin="dense"
              id="currentStatus"
              value={manualRefund.currentStatus}
              onChange={(e) => {
                setManualRefund({
                  ...manualRefund,
                  currentStatus: e.target.value,
                });
              }}
              label="Current Status"
              type="text"
              fullWidth
              variant="standard"
            />
            <div
              style={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
                marginTop: "16px",
              }}
            >
              <div>Refund Reason</div>
              <Select
                style={{ width: "50%" }}
                value={manualRefund.refundReason}
                onChange={(e) => {
                  setManualRefund({
                    ...manualRefund,
                    refundReason: e.target.value,
                  });
                }}
              >
                {[
                  "Duplicate",
                  "EINBusiness mismatch",
                  "Multiple Submission",
                  "RIN/Invalid account",
                  "Multiple Invoice",
                  "Others",
                ].map((val, index) => (
                  <MenuItem key={index} value={val}>
                    {val}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <TextField
              autoFocus
              margin="dense"
              id="refundReference"
              value={manualRefund.refundReference}
              onChange={(e) => {
                setManualRefund({
                  ...manualRefund,
                  refundReference: e.target.value,
                });
              }}
              label="Refund Reference"
              type="text"
              fullWidth
              variant="standard"
            />
            <TextField
              autoFocus
              margin="dense"
              id="comments"
              value={manualRefund.category}
              onChange={(e) => {
                setManualRefund({ ...manualRefund, comments: e.target.value });
              }}
              label="comments"
              type="text"
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleManualClose}>Cancel</Button>
            <Button onClick={handleManualSave}>Save</Button>
          </DialogActions>
        </Dialog>
      </div>
    </GridToolbarContainer>
  );
}

const AdminRefundTable = ({
  adminPendingList,
  setUpdatedRefundData,
  handleAdminPending
}: {
  adminPendingList: any;
  setUpdatedRefundData: any;
  handleAdminPending: any;
}) => {
  const userRoles = useSelector((state: any) => state.auth.user.data.profile.roles);
  const [statusUpdateVal, setStatusUpdateVal] = React.useState({
    invoice_number: "",
    refund_id: "",
  });
  const [amount, setAmount] = React.useState("");
  const onEditCell = (params: any) => {
    setUpdatedRefundData((prev: any) => ({
      ...prev,
      formKey: params.row?.formKey || "",
      refundTransactionId: params.row?.refundTransactionId || "",
      customerEmail: params.row?.customerEmail || "",
      refundAmount: params.row?.refundAmount || "",
      refundReason: params.row?.refundReason || "",
      refundStatus: params.props.value == REJECTED ? 'Declined' : params?.props?.value || "",
      comments: params.row?.comments || "",
      refund_id: params.row?.refundId || "",
      invoice_number:params.row.refundInvoice || "",
    }))
    return new Promise<any>(() => {
      setStatusUpdateVal({
        invoice_number: params.row.refundInvoice,
        refund_id: params.row.refundId,
      });
      if (params.props.value == REJECTED) {
        params.row.refundStatus.description = "Rejected";
      } else if (params.props.value == APPROVED) {
        setOpenAmount(true);
        params.row.refundStatus.description = "Approved";
      } else if (params.props.value == "Pending") {
        params.row.refundStatus.description = "Pending";
      } else if (params.props.value == "Refunded") {
        params.row.refundStatus.description = "Refunded";
      }
    });
  };

  const columns: GridColDef[] = [
    { field: "refundId", headerName: "Refund ID", width: 150 },
    {
      field: "referenceNumber",
      headerName: "Reference",
      width: 150,
      renderCell: (params: any) => (
        <TextField
          placeholder="referenceNumber"
          variant="standard"
          InputProps={{
            style: {
              fontSize: "16px",
            },
            disableUnderline: true,
          }}
          defaultValue={params.row.referenceNumber}
          onChange={(e) => {
            setUpdatedRefundData((prev: any) => ({
              ...prev,
              referenceNumber: e.target.value,
              formKey: params.row.formKey,
              refundTransactionId: params.row.refundTransactionId,
              customerEmail: params.row.customerEmail,
              refundAmount: params.row.refundAmount,
              refundReason: params.row.refundReason,
              refundStatus: params.row.refundStatus.description,
              comments: params.row.comments,
              refund_id: params.row.refundId,
            }));
            params.api.updateRows([
              { ...params.row, referenceNumber: e.target.value },
            ]);
          }}
        />
      ),
    },
    {
      field: "customerEmail",
      headerName: "Customer Email",
      width: 250,
      renderCell: (params: any) => (
        <TextField
          placeholder="customerEmail"
          variant="standard"
          InputProps={{
            style: {
              fontSize: "16px",
            },
            disableUnderline: true,
          }}
          defaultValue={params.row.customerEmail}
          onChange={(e) => {
            setUpdatedRefundData((prev: any) => ({
              ...prev,
              referenceNumber: params.row.referenceNumber,
              formKey: params.row.formKey,
              refundTransactionId: params.row.refundTransactionId,
              refundAmount: params.row.refundAmount,
              refundReason: params.row.refundReason,
              refundStatus: params.row.refundStatus.description,
              comments: params.row.comments,
              refund_id: params.row.refundId,
              customerEmail: e.target.value,
            }));
            params.api.updateRows([
              { ...params.row, customerEmail: e.target.value },
            ]);
          }}
        />
      ),
    },
    {
      field: "CSE Name",
      headerName: "CSE Name",
      width: 150,
      valueGetter: (params: any) => {
        return params.row.executives.name;
      },
    },
    {
      field: "refundInvoice",
      headerName: "Refund Invoice",
      width: 200,
      renderCell: (params: any) => (
        <TextField
          placeholder="refundInvoice"
          variant="standard"
          InputProps={{
            style: {
              fontSize: "16px",
            },
            disableUnderline: true,
          }}
          defaultValue={params.row.refundInvoice}
          onChange={(e) => {
            setUpdatedRefundData((prev: any) => ({
              ...prev,
              referenceNumber: e.target.value,
              formKey: params.row.formKey,
              refundTransactionId: params.row.refundTransactionId,
              customerEmail: params.row.customerEmail,
              refundAmount: params.row.refundAmount,
              refundReason: params.row.refundReason,
              refundStatus: params.row.refundStatus.description,
              comments: params.row.comments,
              refund_id: params.row.refundId,
              refundInvoice: e.target.value,
            }));
            params.api.updateRows([
              { ...params.row, refundInvoice: e.target.value },
            ]);
          }}
        />
      ),
    },
    {
      field: "refundAmount",
      headerName: "Refund Amount",
      width: 150,
      renderCell: (params: any) => (
        <TextField
          placeholder="refundAmount"
          variant="standard"
          InputProps={{
            style: {
              fontSize: "16px",
            },
            disableUnderline: true,
          }}
          defaultValue={params.row.refundAmount}
          onChange={(e) => {
            setUpdatedRefundData((prev: any) => ({
              ...prev,
              referenceNumber: e.target.value,
              formKey: params.row.formKey,
              refundTransactionId: params.row.refundTransactionId,
              refundReason: params.row.refundReason,
              refundStatus: params.row.refundStatus.description,
              comments: params.row.comments,
              refund_id: params.row.refundId,
              refundAmount: e.target.value,
            }));
            params.api.updateRows([
              { ...params.row, refundAmount: e.target.value },
            ]);
          }}
        />
      ),
    },
    {
      field: "Current Status",
      headerName: "Current Status",
      width: 150,
      ...( userRoles.includes('admin') ?  {
        type: "singleSelect",
        editable: true,
        valueOptions: ["Pending", "Approved", "Rejected", "Refunded"],
        valueGetter: (params: any) => {
          return params.row.refundStatus !== null
            ? params.row.refundStatus.description
            : "";
        }
      } : {
        renderCell: (params: any) => <>{params.row.refundStatus.description}</>
      }),

      preProcessEditCellProps: onEditCell,
    },
    {
      field: "refundInitiatedDate",
      headerName: "Refund Initiated Date",
      width: 200,
      valueGetter: (params: any) => {
        const date = new Date(params.row.refundInitiateDate);
        return date.toISOString().substring(0, 10);
      },
    },
    {
      field: "refundDate",
      headerName: "Refund Date",
      width: 200,
      valueGetter: (params: any) => {
        if (params.row.refundDate) {
          const date = new Date(params.row.refundDate);
          return date.toISOString().substring(0, 10);
        }
      },
    },
    {
      field: "refundTransactionId",
      headerName: "Refund Reference Number",
      width: 200,
      renderCell: (params: any) => (
        <TextField
          placeholder="refundTransactionId"
          variant="standard"
          InputProps={{
            style: {
              fontSize: "16px",
            },
            disableUnderline: true,
          }}
          defaultValue={params.row.refundTransactionId}
          onChange={(e) => {
            setUpdatedRefundData((prev: any) => ({
              ...prev,
              referenceNumber: e.target.value,
              formKey: params.row.formKey,
              refundAmount: params.row.refundAmount,
              refundReason: params.row.refundReason,
              refundStatus: params.row.refundStatus.description,
              comments: params.row.comments,
              refund_id: params.row.refundId,
              customerEmail: params.row?.customerEmail || "",
              refundTransactionId: e.target.value,
            }));
            params.api.updateRows([
              { ...params.row, refundTransactionId: e.target.value },
            ]);
          }}
        />
      ),
    },
    {
      field: "refundReason",
      headerName: "Refund Reason",
      width: 150,
      type: "singleSelect",
      valueOptions: [
        "Duplicate",
        "EINBusiness mismatch",
        "Multiple Submission",
        "RIN/Invalid account",
        "Multiple Invoice",
        "Others",
      ],
      renderCell: (params: any) => (
        <Box sx={{ width: 100 }}>
          <FormControl variant="standard" fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={params.row.refundReason}
              label="Reason"
              onChange={(e) => {
                setUpdatedRefundData((prev: any) => ({
                  ...prev,
                  refundReason: e.target.value,
                }));
                params.api.updateRows([
                  { ...params.row, refundReason: e.target.value },
                ]);
              }}
            >
              {[
                "Duplicate",
                "EINBusiness mismatch",
                "Multiple Submission",
                "RIN/Invalid account",
                "Multiple Invoice",
                "Others",
              ].map((val, index) => (
                <MenuItem key={index} value={val}>
                  {val}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      ),
    },
    {
      field: "remarks",
      headerName: "Remarks",
      width: 150,
      renderCell: (params: any) => (
        <TextField
          placeholder="remarks"
          variant="standard"
          InputProps={{
            style: {
              fontSize: "16px",
            },
            disableUnderline: true,
          }}
          defaultValue={params.row.comments || ""}
          onChange={(e) => {
            setUpdatedRefundData((prev: any) => ({
              ...prev,
              comments: e.target.value,
            }));
            params.api.updateRows([
              { ...params.row, comments: e.target.value },
            ]);
          }}
        />
      ),
    },
  ];

  const [openAmount, setOpenAmount] = React.useState(false);

  const setOpenAmountClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpenAmount(false);
  };

  const setOpenAmountSave = () => {
    setOpenAmount(false);
    return DashboardLinkService.csStatusUpdate(
      {
        invoice_number: statusUpdateVal.invoice_number,
        refund_id: statusUpdateVal.refund_id,
        refund_amount: amount,
      },
      "Approved"
    ).then((res) => {
      if(res.status == 200){
        handleAdminPending();
      }
    });
  };

  const handleOpenAmount = () => {
    setOpenAmount(true);
  };

  return (
    <>
      <DataGrid
        getRowId={(row) => row.refundId}
        rows={adminPendingList}
        columns={columns}
        pageSize={15}
        rowsPerPageOptions={[5]}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar: CustomToolbar,
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 200 },
          },
        }}
      />
      <Dialog
        open={openAmount}
        onClose={() => handleOpenAmount}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent style={{ height: "100px" }}>
          <TextField
            autoFocus
            margin="dense"
            id="Enter Amount"
            value={amount}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
            label="Amount"
            required
            fullWidth
            variant="standard"
          />
          <Button onClick={setOpenAmountClose}>Cancel</Button>
          <Button onClick={setOpenAmountSave}>Save</Button>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};
export default AdminRefundTable;
