import {useState} from 'react';
import {Button, Grid, MenuItem, Select} from '@mui/material';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DISPOSITION_LIST} from '../../../Utils/Constants';
import dayjs from "dayjs";
import Agent from "../../../Utils/Models/Agent";
import { ClosedCase } from '../ClosedCases';

interface ClosedCasesFilterFormProps {
  onSearchPressed: (fromDate: Date, toDate: Date , clearFilters: boolean) => void;
  onFilterByConversion: (wasConversion: string) => void;
  onFilterByEmail: (email: string) => void;
  onFilterByDisposition: (disposition: string) => void;
  onFilterByAssignedTo: (assignedTo: string) => void;
  agentsList: Agent[];
  closedCases: ClosedCase[]
}

export default function ClosedCasesFilterForm({
                                                onSearchPressed,
                                                onFilterByConversion,
                                                onFilterByEmail,
                                                onFilterByDisposition,
                                                onFilterByAssignedTo,
                                                agentsList,
                                                closedCases
                                              }: ClosedCasesFilterFormProps) {

  // Form fields states
  const [wasConversion, setWasConversion] = useState<string>('none');
  const [assignedTo, setAssignedTo] = useState<string>('none');
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [disposition, setDisposition] = useState<string>('none');
  const [email, setEmail] = useState<string>('none');
  const [clearFilterCTADisable, setClearFilterCTADisable] = useState<boolean>(true)

  // Error states
  const [fromDateError, setFromDateError] = useState<boolean>(false);
  const [toDateError, setToDateError] = useState<boolean>(false);
  const [fromDateInvalidError, setFromDateInvalidError] = useState<boolean>(false);
  const [toDateInvalidError, setToDateInvalidError] = useState<boolean>(false);


  const btnStyle = {
    fontSize: '14px',
    height: '40px',
    marginTop: '16px',
    width: '140px',
    fontWeight: 'bold',
  };
  const fieldStyle = {
    height: '40px'
  };
  const lblStyle = {
    display: 'block',
    marginBottom: '4px',
    fontSize: '14px',
    fontWeight: 'bold'
  };
  const legenStyle = {
    fontWeight: '500',
    lineHeight: '1.25'
  };

let uniqueValues: string[] = [];
const agentsOptions = closedCases?.map((agent, index) => {
  if (agent.assignedTo && agent.assignedTo.trim() !== '' && !uniqueValues.includes(agent.assignedTo)) {
    uniqueValues.push(agent.assignedTo);
    return <MenuItem key={index} value={agent.assignedTo}>{agent.assignedTo}</MenuItem>;
  }
  return null;
}).filter(option => option !== null);

uniqueValues = [];
const dispositionOptions = closedCases.map((disp, index) => {
  if (disp.disposition && disp.disposition.trim() !== '' && !uniqueValues.includes(disp.disposition)) {
    uniqueValues.push(disp.disposition);
    return <MenuItem key={index} value={disp.disposition}>{disp.disposition}</MenuItem>;
  }
  return null;
}).filter(option => option !== null);

uniqueValues = [];
const emailOptions = closedCases.map((agent, index) => {
  if (agent.email && agent.email.trim() !== '' && !uniqueValues.includes(agent.email)) {
    uniqueValues.push(agent.email);
    return <MenuItem key={index} value={agent.email}>{agent.email}</MenuItem>;
  }
  return null;
}).filter(option => option !== null);

uniqueValues = [];
const wasConversionOptions = closedCases.map((wasConversion, index) => {
  if (!uniqueValues.includes(String(wasConversion.wasConversion))) {
    uniqueValues.push(String(wasConversion.wasConversion));
    return (
      <MenuItem key={index} value={wasConversion.wasConversion? "Yes" : "No"}>
         {wasConversion.wasConversion ? "Yes" : "No"}
      </MenuItem>
    );
  }
  return null;
}).filter(option => option !== null);

  function onClearFilterResults() {
    setEmail('none');
    setWasConversion('none');
    setDisposition('none');
    setAssignedTo('none');
    validateDateFields(true)
    setClearFilterCTADisable(true);
  }

  function validateDateFields(clearFilters:boolean) {
    if (fromDate === null) {
      setFromDateError(true);
    }
    if (toDate === null) {
      setToDateError(true);
    }
    if ((fromDate !== null && toDate !== null) && (fromDate > toDate)) {
      setFromDateError(false);
      setToDateError(false);
      setFromDateInvalidError(true);
      setToDateInvalidError(true);
    } 
    if (fromDate !== null && toDate !== null && !(fromDate > toDate)) {
      setFromDateInvalidError(false);
      setToDateInvalidError(false);
      setFromDateError(false);
      setToDateError(false);
      onSearchPressed(fromDate, toDate ,clearFilters);
    }
  }

  return (
    <div style={{marginLeft: '54px'}}>
      <Grid
        container
        alignItems="flex-end"
        justifyContent="flex-end"
        spacing={1}
        marginBottom={2}
        marginTop={1}
      >
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <p style={legenStyle}>The details of customers who were in funnel, and were converted by the CS agent assigned.</p>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2}>
          <label style={lblStyle}>From</label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              slotProps={{
                textField: {
                    size: 'small',
                    placeholder: 'Enter Date*',
                    helperText: fromDateError ? ('Please select the date') : fromDateInvalidError ? ('Please select a valid date range') : '',
                    error: fromDateError || fromDateInvalidError,                   
                  }  
              }}
              sx={{width: {xs: '100%', md: '100%', lg: '180px'}, '& .MuiFormHelperText-sizeSmall': {position: 'absolute', top: '36px', margin:'4px 0px', letterSpacing: '0px'}}}
              onChange={(date: Date | null) => setFromDate(dayjs(date).startOf('day').toDate())}
              disableFuture={true} 
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2}>
          <label style={lblStyle}>To</label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              slotProps={{
                textField: {
                    size: 'small',
                    placeholder: 'Enter Date*',
                    helperText: toDateError ? ('Please select the date') : toDateInvalidError ? ('Please select a valid date range') : '',
                    error: toDateError || toDateInvalidError
                  }               
              }}             
              sx={{width: {xs: '100%', md: '100%', lg: '180px'}, '& .MuiFormHelperText-sizeSmall': {position: 'absolute', top: '36px' ,margin:'4px 0px', letterSpacing: '0px'}}}
              onChange={(date: Date | null) => setToDate(dayjs(date).endOf('day').toDate())}
              disableFuture={true} 
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} lg={2}>
          <Button
            variant="contained"
            fullWidth
            style={{...btnStyle, textTransform: 'none'}}
            onClick={() => validateDateFields(false)}
          >
            Search
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="flex-end"
        justifyContent="flex-end"
        spacing={1}
      >
        <Grid item xs={12} md={6} lg={2}>
          <label style={lblStyle}>Email</label>
          <Select
            value={email}
            displayEmpty
            fullWidth
            style={fieldStyle}
            sx={{width: {lg: '180px'}, color: email === 'none' ? '#b3b3b3' : 'black'}}
            onChange={(e) => {
              setEmail(e.target.value as string);
              setClearFilterCTADisable(false);
              onFilterByEmail(e.target.value as string);
            }}
          >
            <MenuItem value={'none'} disabled>Select</MenuItem>
            {emailOptions}
          </Select>
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          <label style={lblStyle}>Assigned To</label>
          <Select
            value={assignedTo}
            displayEmpty
            fullWidth
            style={fieldStyle}
            sx={{width: {lg: '180px'}, color: assignedTo === 'none' ? '#b3b3b3' : 'black'}}
            onChange={(e) => {
              setAssignedTo(e.target.value as string);
              setClearFilterCTADisable(false);
              onFilterByAssignedTo(e.target.value as string);
            }}
          >
            <MenuItem value={'none'} disabled>Select</MenuItem>
            {agentsOptions}
          </Select>
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          <label style={lblStyle}>Was Conversion?</label>
          <Select
            value={wasConversion}
            displayEmpty
            fullWidth
            style={fieldStyle}
            sx={{width: {lg: '180px'}, color: wasConversion === 'none' ? '#b3b3b3' : 'black'}}
            onChange={(e) => {
              setWasConversion(e.target.value as string);
              setClearFilterCTADisable(false);
              onFilterByConversion(e.target.value as string);
            }}
          >
            <MenuItem value={'none'} disabled>Select</MenuItem>
              {wasConversionOptions}
          </Select>
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          <label style={lblStyle}>Disposition</label>
          <Select
            value={disposition}
            displayEmpty
            fullWidth
            style={fieldStyle}
            sx={{width: {lg: '180px'}, color: disposition === 'none' ? '#b3b3b3' : 'black'}}
            onChange={(e) => {
              setDisposition(e.target.value as string)
              setClearFilterCTADisable(false);
              onFilterByDisposition(e.target.value as string)
            }}
          >
            <MenuItem value={'none'} disabled>Select</MenuItem>
            {dispositionOptions}
          </Select>
        </Grid>
        <Grid item xs={12} lg={2} sx={{textAlign: 'left'}}>
          <Button
            variant="outlined"
            fullWidth
            style={btnStyle}
            sx={{width: {lg: '180px', textTransform: 'none'}}}
            onClick={() => onClearFilterResults()}
            disabled={clearFilterCTADisable}
          >
            Clear Filter
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

