import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, IconButton, DialogContent, Grid, TextField, DialogActions, FormControl, RadioGroup, FormControlLabel, Radio, FormHelperText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import mcsService from '../Services/mcs.service';

interface NotInterestedDialogProps {
  open: boolean;
  userClickedData: any;
  onSuccess: () => void;
  setIsLoading: (value: boolean) => void;
  styles: any;
  setNotInterested:any;
}

const NotInterestedDialog: React.FC<NotInterestedDialogProps> = ({
  open,
  userClickedData,
  onSuccess,
  setIsLoading,
  styles,
  setNotInterested,
}) => {
  const [remarksNI, setRemarksNI] = useState('');
  const [remarkErrorNI, setRemarkErrorNI] = useState('');
  const [addcustomerfield, setAddcustomerfield] = useState({
    customerType: ''
  });
  const [addcustomerErrorfield, setAddcustomerErrorfield] = useState({
    customerType: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddcustomerfield({
      ...addcustomerfield,
      [e.target.name]: e.target.value
    });
    setAddcustomerErrorfield({
      ...addcustomerErrorfield,
      customerType: ''
    });
  };
  const handleRemarksChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRemarksNI(e.target.value);

    if (e.target.value.trim()) {
      setRemarkErrorNI('');
    }
  };

  const handleNotInterestedSave = () => {
    let hasError = false;
    const newErrors: any = {};

    if (!addcustomerfield.customerType) {
      newErrors.customerType = 'Please select a customer type';
      hasError = true;
    }

    if (!remarksNI.trim()) {
      setRemarkErrorNI('Remarks cannot be empty');
      hasError = true;
    }

    if (hasError) {
      setAddcustomerErrorfield(newErrors);
      return;
    }

    setIsLoading(true);

    const payload = {
      mappingKey: userClickedData?.mappingKey,
      preference: 'NOT_INTERESTED',
      remarks: remarksNI,
      interactionType: addcustomerfield?.customerType
    };

    mcsService.mcsSavePrefrence(payload)
      .then((response) => {
        if (response.status === 200) {
          setRemarksNI('');
          setRemarkErrorNI('');
          handleNotInterestedClose()
          onSuccess(); 
        } else {
          throw new Error('API request failed with status ' + response.status);
        }
      })
      .catch(() => {
        alert('Something went wrong, Please try again');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleNotInterestedClose = async() => {
    setNotInterested(false);
    setRemarksNI('');
    setRemarkErrorNI('');
    setAddcustomerfield({ customerType: '' });
    setAddcustomerErrorfield({ customerType: '' });
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      sx={{
        '& .MuiDialog-paper': {
          width: '600px',
          backgroundColor: '#edf2f8',
        },
      }}
    >
      <DialogTitle sx={{fontWeight:"bold"}}>Mark Customer Not Interested</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleNotInterestedClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div style={styles.textField}>Remarks</div>
            <TextField
              placeholder="Add Remarks"
              fullWidth
              multiline
              rows={3}
              value={remarksNI}
              onChange={handleRemarksChange}
              error={!!remarkErrorNI}
              sx={styles.textAreaBox}
            />
            {remarkErrorNI && (
              <div style={{ color: 'red', marginTop: '4px' }}>
                {remarkErrorNI}
              </div>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} mt={2}>
          <div style={styles.textField}>Customer Interaction Type</div>
          <FormControl component="fieldset" error={!!addcustomerErrorfield.customerType}>
            <RadioGroup name="customerType" value={addcustomerfield.customerType} onChange={handleChange}>
                  <FormControlLabel value="INBOUND_EMCS" control={<Radio />} label="Inbound Call (from Customer)" />
                  <FormControlLabel value="OUTBOUND_EMCS" control={<Radio />} label="Outbound Call (to Customer)" />
            </RadioGroup>
            {addcustomerErrorfield.customerType && (
              <FormHelperText>{addcustomerErrorfield.customerType}</FormHelperText>
            )}
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ alignItems: 'center', justifyContent: 'center' }}>
        <Button onClick={handleNotInterestedSave} variant="contained" sx={{ backgroundColor: '#016b89', mb: 2, width: '125px', ...styles.button }}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotInterestedDialog;
