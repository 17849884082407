import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Dialog,
  DialogContent,
  TextField,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
} from "@mui/material";
import DashboardService from "../../Services/dashboard.service";
import EditIcon from "@mui/icons-material/Edit";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect } from "react";
import {
  COUNTRY_LIST,
  formatDate,
  US_STATES_LIST,
} from "../../Utils/Constants";
import { useAppDispatch } from "../../Store";
import { IS_LOADING } from "../../Actions/types";
import ChangePassword from "../../Pages/Dashboard/ChangePassword";

export default function Profile({
  user,
  ein,
  businessName,
  clearStateAndRefetch,
  selectedRefId,
}: any) {
  const methods = useForm({});
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [categoryData, setCategoryData] = useState({
    ptin: "",
    ein: "",
    firmsName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    zip: "",
    firmsPhone: "",
    stateT: ""
  });
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [radioVal, setRadioVal] = useState("no");
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [categoryView, setCategoryView] = useState(false);

  const [category, setCategory] = useState<any>();
  const [updateMaster, setUpdateMaster] = useState(false);
  const [changePassword, setChangePassword] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    console.log("user", user);
    if (user) {
      setCategory(user?.isTaxProfessional ? "paidPreparer" : "owner");
      setCategoryOpen(user?.isTaxProfessional);
    }
    setCategoryData((prev) => ({
      ...prev,
      ptin: user?.taxProfesssional?.ptin ? user?.taxProfesssional?.ptin.replace("P", "") : user?.taxProfesssional?.ptin,
      ein: user?.taxProfesssional?.firmEin,
      firmsName: user?.taxProfesssional?.firmName,
      addressLine1: user?.address?.addLine1,
      addressLine2: user?.address?.addLine2,
      city: user?.address?.city,
      zip: user?.address?.zip,
      firmsPhone: user?.taxProfesssional?.phone,
      stateT: user?.address?.state?.stateName
    }));
    setCountry(user?.address?.country?.countryCode);
    setState(user?.address?.state?.stateCode);
    setValue("email", user?.email);
    setValue("firstName", user?.firstName);
    setValue("lastName", user?.lastName);
    setRadioVal(user?.taxProfesssional?.isSelfEmployed ? "yes" : "no");
    console.log("-------------", radioVal);
  }, [user]);

  const onSubmit = (data: any) => {
    const taxProfessionalData = {
      ...categoryData,
      state,
      country,
      selfEmployed: radioVal === "yes",
      updateMaster: true,
      businessSubmitId: selectedRefId.businessSubmissionId,
      filingType:
        selectedRefId.filingType === "Form2290" ? "FORM_2290" : "FORM_8849",
    };
    dispatch({ type: IS_LOADING });
    return DashboardService.csUserUpdate(
      data,
      user,
      category,
      taxProfessionalData
    )
      .then(() => {
        clearStateAndRefetch();
        setOpen(false);
        setRadioVal("no");
        setUpdateMaster(false);
        setCategoryData({
          ptin: "",
          ein: "",
          firmsName: "",
          addressLine1: "",
          addressLine2: "",
          city: "",
          zip: "",
          firmsPhone: "",
          stateT: ""
        });
      })
      .catch((e) => {
        console.log("Catch error", e);
        setOpen(false);
      });
  };

  const submitCategoryDetails = (e: any) => {
    e.preventDefault();
    setCategoryView(false);
  };

  const textFieldChanger = (e: any) => {
    setCategoryData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const radioHanlder = (e: any) => {
    setRadioVal(e.target.defaultValue);
  };

  const closeModal = () => {
    setChangePassword(false);
  };
  console.log("category", category);
  return (
    <TableContainer component={Paper}>
      <Table aria-label="Profile Details" size="small">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Email
            </TableCell>
            <TableCell>{user && user.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Name
            </TableCell>
            <TableCell>
              {user && user.firstName} {user && user.lastName}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Phone Number
            </TableCell>
            <TableCell>
              {user && user.mobileNum}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Category
            </TableCell>
            <TableCell>
              <div
                style={{
                  display: "flex",
                  gap: "18px",
                }}
              >
                <div>
                  {category === "paidPreparer" && "Paid Preparer"}
                  {category === "owner" && "Owner"}
                </div>
                {category === "paidPreparer" && (
                  <div
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "end",
                      fontSize: "12px",
                    }}
                    onClick={() => setCategoryView(true)}
                  >
                    Show Details
                  </div>
                )}
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Registration Date
            </TableCell>
            <TableCell>
              {user && formatDate(user.creationDate)}
                <IconButton color="primary" aria-label="Edit">
                  <EditIcon onClick={handleOpen} />
                </IconButton>

              <Button size="small"></Button>
              <Dialog open={open} onClose={handleClose}>
                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle>User Info</DialogTitle>
                    <DialogContent>
                      <TextField
                        {...(errors.email && {
                          helperText: errors.email.message as string,
                        })}
                        {...(errors?.email ? { error: true } : {})}
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: "Please enter a valid email address",
                          },
                        })}
                        margin="dense"
                        label="Email Address"
                        type="text"
                        fullWidth
                        variant="standard"
                      />
                      <TextField
                        {...(errors.firstName && {
                          helperText: errors.firstName.message as string,
                        })}
                        {...(errors?.firstName ? { error: true } : {})}
                        {...register("firstName", {
                          required: "FirstName is required",
                        })}
                        margin="dense"
                        label="First Name"
                        type="text"
                        fullWidth
                        variant="standard"
                      />

                      <TextField
                        {...register("lastName")}
                        margin="dense"
                        label="Last Name"
                        type="text"
                        fullWidth
                        variant="standard"
                      />
                      <div style={{ display: "flex" }}>
                        <InputLabel id="category-label">Category</InputLabel>
                        <Select
                          labelId="category-label"
                          id="category"
                          value={category}
                          onChange={(e) => {
                            setCategory(e.target.value);
                            e.target.value === "paidPreparer" ?
                              setCategoryOpen(true) : setCategoryOpen(false);
                          }}
                        >
                          <MenuItem value={"paidPreparer"}>
                            Paid Preparer
                          </MenuItem>
                          <MenuItem value={"owner"}>Owner/Operator</MenuItem>
                        </Select>
                      </div>
                      {categoryOpen && (
                      <>
                        <TextField
                        margin="dense"
                        label="PTIN"
                        name="ptin"
                        value={categoryData?.ptin}
                        onChange={textFieldChanger}
                        type="text"
                        fullWidth
                        variant="standard"
                        inputProps={{ maxLength: 8 }}
                      />
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          Self Employed
                        </FormLabel>

                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="yes"
                          name="radio-buttons-group"
                        >
                          <div style={{ display: "flex" }}>
                            <FormControlLabel
                              value={"yes"}
                              control={<Radio />}
                              label="Yes"
                              checked={
                                typeof radioVal !== "undefined" &&
                                radioVal === "yes"
                              }
                              onClick={(e) => radioHanlder(e)}
                            />
                            <FormControlLabel
                              value={"no"}
                              control={<Radio />}
                              label="No"
                              checked={
                                typeof radioVal !== "undefined" &&
                                radioVal === "no"
                              }
                              onClick={(e) => radioHanlder(e)}
                            />
                          </div>
                        </RadioGroup>
                      </FormControl>
                      {radioVal !== "yes" ? (
                        <>
                          <TextField
                            margin="dense"
                            label="EIN"
                            name="ein"
                            value={categoryData?.ein}
                            onChange={textFieldChanger}
                            type="text"
                            fullWidth
                            inputProps={{ maxLength: 9 }}
                            variant="standard"
                          />
                          <TextField
                            margin="dense"
                            label="Firm Name"
                            name="firmsName"
                            value={categoryData?.firmsName}
                            onChange={textFieldChanger}
                            type="text"
                            fullWidth
                            variant="standard"
                          />
                          <TextField
                            margin="dense"
                            name="addressLine1"
                            value={categoryData?.addressLine1}
                            onChange={textFieldChanger}
                            label="Address Line 1"
                            type="text"
                            fullWidth
                            variant="standard"
                          />
                          <TextField
                            margin="dense"
                            name="addressLine2"
                            value={categoryData?.addressLine2}
                            onChange={textFieldChanger}
                            label="Address Line 2"
                            type="text"
                            fullWidth
                            variant="standard"
                          />
                          <TextField
                            margin="dense"
                            name="city"
                            value={categoryData?.city}
                            onChange={textFieldChanger}
                            label="City"
                            type="text"
                            fullWidth
                            variant="standard"
                          />
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "34px",
                              padding: "16px 0",
                            }}
                          >
                            <InputLabel id="stateName-label">State</InputLabel>
                            {country === 'US' ? <Select
                              labelId="stateName-label"
                              id="stateName"
                              style={{ minWidth: "40%" }}
                              value={state}
                              onChange={(e) => {
                                setState(e.target.value);
                              }}
                            >
                              {US_STATES_LIST.map((state) => (
                                <MenuItem key={state.label} value={state.value}>
                                  {state.label}
                                </MenuItem>
                              ))}
                              </Select> : <TextField
                              id="state_name"
                              name ="stateT"
                              style={{ minWidth: "40%" }}
                              value={categoryData?.stateT}
                              onChange={textFieldChanger}
                            />}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "16px",
                              padding: "16px 0",
                            }}
                          >
                            <InputLabel id="countryName-label">
                              Country
                            </InputLabel>
                            <Select
                              labelId="countryName-label"
                              id="countryName"
                              style={{ minWidth: "40%" }}
                              value={country}
                              onChange={(e) => {
                                setCountry(e.target.value);
                              }}
                            >
                              {COUNTRY_LIST.map((country) => (
                                <MenuItem
                                  key={country.value}
                                  value={country.value}
                                >
                                  {country.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                          <TextField
                            margin="dense"
                            name="zip"
                            value={categoryData?.zip}
                            onChange={textFieldChanger}
                            label="Zip/Postal Code"
                            type="text"
                            fullWidth
                            variant="standard"
                            inputProps={{ maxLength: 6 }}
                          />
                          <TextField
                            margin="dense"
                            label="Phone"
                            name="firmsPhone"
                            value={categoryData?.firmsPhone}
                            onChange={textFieldChanger}
                            type="number"
                            fullWidth
                            variant="standard"
                            inputProps={{ maxLength: 10 }}
                          />

                        </>
                      ) : null}
                           </>
                    )}

                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setChangePassword(true)}>
                        Change Password
                      </Button>
                      <Button onClick={handleClose}>Cancel</Button>
                      <Button type="submit">Save</Button>
                    </DialogActions>
                  </form>
                </FormProvider>
              </Dialog>
              <Dialog
                open={categoryView}
                onClose={() => {
                  setCategoryView(false);
                }}
              >
                <form onSubmit={submitCategoryDetails}>
                <fieldset disabled={true}>
                  <DialogTitle>Paid Preparer</DialogTitle>
                  <DialogContent>
                    <TextField
                      margin="dense"
                      label="PTIN"
                      name="ptin"
                      value={categoryData?.ptin}
                      onChange={textFieldChanger}
                      type="text"
                      fullWidth
                      variant="standard"
                      inputProps={{ maxLength: 8 }}
                    />
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">
                        Self Employed
                      </FormLabel>

                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="yes"
                        name="radio-buttons-group"
                      >
                        <div style={{ display: "flex" }}>
                          <FormControlLabel
                            value={"yes"}
                            control={<Radio />}
                            label="Yes"
                            checked={
                              typeof radioVal !== "undefined" &&
                              radioVal === "yes"
                            }
                          />
                          <FormControlLabel
                            value={"no"}
                            control={<Radio />}
                            label="No"
                            checked={
                              typeof radioVal !== "undefined" &&
                              radioVal === "no"
                            }
                          />
                        </div>
                      </RadioGroup>
                    </FormControl>
                    {radioVal !== "yes" ? (
                      <>
                        <TextField
                          margin="dense"
                          label="EIN"
                          name="ein"
                          value={categoryData?.ein}
                          onChange={textFieldChanger}
                          type="text"
                          fullWidth
                          inputProps={{ maxLength: 9 }}
                          variant="standard"
                        />
                        <TextField
                          margin="dense"
                          label="Firm Name"
                          name="firmsName"
                          value={categoryData?.firmsName}
                          onChange={textFieldChanger}
                          type="text"
                          fullWidth
                          variant="standard"
                        />
                        <TextField
                          margin="dense"
                          name="addressLine1"
                          value={categoryData?.addressLine1}
                          onChange={textFieldChanger}
                          label="Address Line 1"
                          type="text"
                          fullWidth
                          variant="standard"
                        />
                        <TextField
                          margin="dense"
                          name="addressLine2"
                          value={categoryData?.addressLine2}
                          onChange={textFieldChanger}
                          label="Address Line 2"
                          type="text"
                          fullWidth
                          variant="standard"
                        />
                        <TextField
                          margin="dense"
                          name="city"
                          value={categoryData?.city}
                          onChange={textFieldChanger}
                          label="City"
                          type="text"
                          fullWidth
                          variant="standard"
                        />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "34px",
                            padding: "16px 0",
                          }}
                        >
                          <InputLabel id="stateName-label">State</InputLabel>
                          {country === 'US' ? <Select
                            disabled={true}
                            labelId="stateName-label"
                            id="stateName"
                            style={{ minWidth: "40%" }}
                            value={state}
                            onChange={(e) => {
                              setState(e.target.value);
                            }}
                          >
                            {US_STATES_LIST.map((state) => (
                              <MenuItem key={state.label} value={state.label}>
                                {state.label}
                              </MenuItem>
                            ))}
                          </Select> : <TextField
                              id="state_name"
                              name ="stateT"
                              style={{ minWidth: "40%" }}
                              value={categoryData?.stateT}
                              onChange={textFieldChanger}
                            />}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "16px",
                            padding: "16px 0",
                          }}
                        >
                          <InputLabel id="countryName-label">
                            Country
                          </InputLabel>
                          <Select
                            disabled={true}
                            labelId="countryName-label"
                            id="countryName"
                            style={{ minWidth: "40%" }}
                            value={country}
                            onChange={(e) => {
                              setCountry(e.target.value);
                            }}
                          >
                            {COUNTRY_LIST.map((country) => (
                              <MenuItem
                                key={country.value}
                                value={country.value}
                              >
                                {country.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                        <TextField
                          margin="dense"
                          name="zip"
                          value={categoryData?.zip}
                          onChange={textFieldChanger}
                          label="Zip/Postal Code"
                          type="text"
                          fullWidth
                          variant="standard"
                          inputProps={{ maxLength: 6 }}
                        />
                        <TextField
                          margin="dense"
                          label="Phone"
                          name="firmsPhone"
                          value={categoryData?.firmsPhone}
                          onChange={textFieldChanger}
                          type="number"
                          fullWidth
                          variant="standard"
                          inputProps={{ maxLength: 10 }}
                        />
                      </>
                    ) : null}
                  </DialogContent>
                </fieldset>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setCategoryView(false);
                      }}
                    >
                      {"Close"}
                    </Button>
                  </DialogActions>

                </form>
              </Dialog>
              <ChangePassword
                open={changePassword}
                handleClose={closeModal}
                user={user}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
