import {
  CSLIST_SUCCESS,
  CSLIST_FAIL,
  SET_MESSAGE,
  UPDATE_MESSAGE,
} from "./types";

import DashboardService from "../Services/dashboard.service";

export const cslist = (type: any, value: any) => (dispatch: any) => {
  return DashboardService.csList(type, value).then(
    (response) => {
      dispatch({
        type: CSLIST_SUCCESS,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: response.data,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CSLIST_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: {
          isDasboardError: true,
          message: message,
        },
      });

      return Promise.reject();
    }
  );
};

export const cslistUpdate = (value: any) => (dispatch: any) => {
  return DashboardService.csListUpdate(value).then(
    (response) => {
      console.log("resolved: ", response);
    },
    (error) => {
      console.log("rejected: ", error);
    }
  );
};
