import React, { useEffect, useRef, useState } from 'react';
import { Grid, TextField, Box, Button, Container, Typography, IconButton, Stepper, Step, StepLabel, DialogTitle, Dialog, DialogContent, DialogActions } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import { REGEX } from '../Constants/constants';
import mcsService from '../Services/mcs.service';
import GlobalWrapper from '../../../Components/GlobalComponentWrapper/GlobalWrapper';
import SaveIcon from '@mui/icons-material/Save';
import Checked from "../../../Assets/eMCS/checked.svg";
import UploadEMCS150 from '../Common/UploadEMCS150';
import ResendInvoice from '../Common/ResendInvoice';
import axios, { CancelTokenSource } from 'axios';
import { useNavigate } from 'react-router-dom';

interface FilingAction {
  formKey:any,
  mappingKey:any,
  getCurrentUserData:any,
  setGetCurrentUserData:any,
  setActionPanelData:any,
  actionPanelData:Record<string, any>,
  fileMCSData:any,
  setIsFileAccepted:any
  isFileAccepted:any
  setIsEditFiling:any
  currentStauts:any 
  setCurrentStatus:any
  isEditFiling:any
  setDotPin:any
}

interface CtaActions {
  value?:any,
  status?:any,
  ctaName?:any;
  description?:any,
  ctaValue?:any
}

enum StepStatus {
  PENDING='PENDING',
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
  VERIFIED = 'VERIFIED',
  PENDING_PAYMENT='PENDING_PAYMENT',
  PAYMENT_COMPLETED = 'PAYMENT_COMPLETED',
  MARK_SUBMITTED = 'MARK_SUBMITTED',
  UPLOADED_EMCS = 'UPLOADED_EMCS',
  MARK_PROCESSING = 'MARK_PROCESSING',
  MARK_COMPLETED = 'MARK_COMPLETED'
}

type EditableFields = 'firstName' | 'lastName' | 'phone' | 'email';

export const styles = {
  button:{
    height: '40px', textTransform: 'none'
  },
  textField : {
    fontSize:'14px',
    fontWeight:600 , 
    marginBottom:'10px',
}, 
 textFieldInside : {
  '& .MuiInputBase-input': {
    backgroundColor: 'white',
    height: '10px'
  },
  '& .MuiFormHelperText-root': {
    marginLeft:'4px'
  },
},
textFieldInsideDisable : {
  '& .MuiInputBase-input': {
    border: 'solid 1px #cdd4d9',
    backgroundColor: '#cdd4d9', 
    height: '10px'
  },
},
stepper: {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginRight: '24px',
},
step: {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '16px',
},
stepIcon: {
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  marginRight: '8px',
},
userDetails: {
  borderRadius: '8px',
  width: '100%',
},
};

const FilingActionPanel: React.FC<FilingAction> = ({formKey, mappingKey, getCurrentUserData, setGetCurrentUserData, setActionPanelData, actionPanelData, fileMCSData, setIsFileAccepted, isFileAccepted, currentStauts,setCurrentStatus, isEditFiling, setIsEditFiling,setDotPin }) => {

  const steps = [
    { label: 'Step 1', description: 'Verify Filing'},
    { label: 'Step 2', description: 'Provide USDOT PIN'},
    { label: 'Step 3', description: 'Make Payment'},
  ];

  const submittedFilingSteps = [
    { label: 'Step 1', description: 'File & Submit on FMCSA' },
    { label: 'Step 2', description: 'Download & Upload eMCS' },
    // { label: 'Step 3', description: 'Check Status of filing' },
    { label: 'Step 3', description: 'Mark Filing Completed' },
  ];

  const fileActionItems =[
    { value:0, status: "NEW", description: "New", ctaName:'Send Verification Link', ctaValue:'SendVerificationLink' },
    { value:1, status: "NOT_INTERESTED", description: "Not Interested", ctaName:'Send Verification Link', ctaValue:'SendVerificationLink' },
    { value:2, status: "SUSPENDED", description: "Suspended", ctaName:'Send Verification Link', ctaValue:'SendVerificationLink' },
    { value:3, status: "PENDING", description: "Send Verification Link To The Customer", ctaName:'Send Verification Link', ctaValue:'SendVerificationLink' },
    { value:4, status: "PENDING_VERIFICATION", description: "Now ask customer to verify the pre-filled form", ctaName:'Send Verification Link', ctaValue:'SendVerificationLink' },
    { value:5, status: "VERIFIED", description: "Now ask customer to provide their USDOT PIN", ctaName:'Send Verification Link', ctaValue:'SendVerificationLink' },
    { value:6, status: "PENDING_PAYMENT", description: "Now ask customer to complete the service fee payment.", ctaName:'Send Payment Link', ctaValue:'SendPaymentLink' },
    { value:7, status: "PAYMENT_COMPLETED", description: "Filing is completed by the customer. Now, file on FMCSA.", ctaName:'Mark Submitted', ctaValue:'MarkSubmitted' },
    { value:8, status: "MARK_SUBMITTED", description: "Download & Upload the eMCS generated on FMCSA", ctaName:'Upload the eMCS', ctaValue:'UploadtheeMCS' },
    // { value:9, status: "UPLOADED_EMCS", description: "Check filing status on FMCSA. Mark if it is under review / processing.", ctaName:'Mark Processing', ctaValue:'MarkProcessing' },
    { value:10, status: "UPLOADED_EMCS", description: "Check filing status on FMCSA. Mark when it is completed.", ctaName:'Mark Completed', ctaValue:'MarkCompleted' },
    { value:11, status: "MARK_COMPLETED", description: "Filing is completed!", ctaName:'Send Verification Link', ctaValue:'SendVerificationLink' },
    { value:12, status: "FAILED", description: "Failed", ctaName:'Send Verification Link', ctaValue:'SendVerificationLink' }
  ]

  const hasBeenToggledRef = useRef(false);
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [isPaymentDone, setIsPaymentDone] = useState(false)
  const [filingCompletedModal, setFilingCompletedModal] = useState(false)
  const [filingStatusAPI, setFilingStatusAPI] = useState("");
  const [sendVerifyClickCount, setSendClickVerifyClickCount] = useState(0);
  const [sendPaymentClickCount, setSendPaymentClickCount] = useState(0);
  const [isUploadFileOpen, setIsUploadFileOpen] = useState(false);
  const [selectFile, setSelectFile] = useState<File | null>(null);
  const [selectFileError, setSelectFileError] = useState('');
  const [selectedFileSize, setSelectedFileSize] = useState('');
  const [ctaActions, setCtaActions] = useState<CtaActions | null>(null);
  const [cancelSource, setCancelSource] = useState<CancelTokenSource | null>(null);
  const [isFieldEditable, setIsFieldEditable] = useState({
    firstName: false,
    lastName: false,
    phone: false,
    email: false,
  });
  const [isEditing, setIsEditing] = useState({
    firstName: false,
    lastName: false,
    phone: false,
    email: false,
  });

  const [userDetailFormData, setUserDetailFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    usdotPin: '',
  });
  
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    setUserDetailFormData({
      firstName: getCurrentUserData?.user_info?.first_name || '',
      lastName: getCurrentUserData?.user_info?.last_name || '',
      phone: getCurrentUserData?.user_info?.phone_number || '',
      email: getCurrentUserData?.user_info?.email || '',
      usdotPin: getCurrentUserData?.user_info?.us_dot_pin || '',
    });
    setFilingStatusAPI(getCurrentUserData?.filing_status);
    setDotPin(getCurrentUserData?.user_info?.us_dot_pin)
  }, [getCurrentUserData]);
  
  useEffect(() => {
    validateUserDetailForm();
  }, [userDetailFormData]);
  
  useEffect(() => {
    if (isEditFiling && !hasBeenToggledRef.current) {

      if (!getCurrentUserData?.user_info?.first_name) {
        toggleEdit('firstName');
      }
      if (!getCurrentUserData?.user_info?.last_name) {
        toggleEdit('lastName');
      }
      if (!getCurrentUserData?.user_info?.phone_number) {
        toggleEdit('phone');
      }
      if (!getCurrentUserData?.user_info?.email) {
        toggleEdit('email');
      }
      hasBeenToggledRef.current = true;
    }
  }, [isEditFiling, getCurrentUserData]);
  
  

  const [userDetailFormErrors, setUserDetailFormErrors] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
  });

  const [verifyUserDetailFormErrors, setVerifyUserDetailFormErrors] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
  });

  const validateUserDetailForm = (fieldName?: keyof typeof userDetailFormData) => {
    const errors = { ...userDetailFormErrors };
    let isValid = true;
  
    const validateField = (name: keyof typeof userDetailFormData, value: string) => {
      if (value === null || value === undefined) return;
      
      switch (name) {
        case 'firstName':
          if (value.trim() === '') {
            errors.firstName = 'First Name is required';
            isValid = false;
          } else {
            errors.firstName = '';
          }
          break;
  
        case 'phone':
          if (value.trim() === '') {
            errors.phone = 'Phone number is required';
            isValid = false;
          } else if (!REGEX.Regex.phone.test(value)) {
            errors.phone = 'Phone number must be 10 digits';
            isValid = false;
          } else {
            errors.phone = '';
          }
          break;
  
        case 'email':
          if (value.trim() === '') {
            errors.email = 'Email is required';
            isValid = false;
          } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
            errors.email = 'Invalid email format';
            isValid = false;
          } else {
            errors.email = '';
          }
          break;
  
        default:
          break;
      }
    };
  
    if (fieldName) {
      // Validate the specific field being edited
      validateField(fieldName, userDetailFormData[fieldName]);
    } else {
      // Validate all fields
      (Object.keys(userDetailFormData) as Array<keyof typeof userDetailFormData>).forEach((key) => {
        validateField(key, userDetailFormData[key]);
      });
    }
  
    setUserDetailFormErrors(errors);
    return isValid;
  };
  

  
  
  const validateField = (name: string, value: string) => {
    const errors = { ...userDetailFormErrors };
  
    switch (name) {
      case 'firstName': {
        if(isEditing.firstName){
          errors.firstName = value.trim() === '' ? 'First Name is required' : '';
        }
        break;
      }
  
      case 'phone': {
        const phoneRegex = REGEX.Regex.phone;
        errors.phone = !phoneRegex.test(value) ? 'Phone number must be 10 digits' : '';
        break;
      }
  
      case 'email': {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        errors.email = !emailRegex.test(value) ? 'Invalid email format' : '';
        break;
      }
  
      default:
        break;
    }
  
    setUserDetailFormErrors(errors);
  };
  
  

  const handleUserDetailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserDetailFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    
    validateField(name, value);
  };
  
  

  useEffect(() => {
    filterByFilingStatus(getEffectiveFilingStatus());
    if (getEffectiveFilingStatus() === 'PAYMENT_COMPLETED') {
      setFilingCompletedModal(true);
    }
  }, [actionPanelData?.filing_status]);
  
  const filterByFilingStatus = (filingStatus: any) => {
    const effectiveFilingStatus = getEffectiveFilingStatus();
  
    const fileStatus = fileActionItems.find(item => item?.status === effectiveFilingStatus);
    if (fileStatus) {
      setCurrentStatus(fileStatus.value);
      setCtaActions(fileStatus);
    }
  
    if (fileStatus?.value !== undefined && fileStatus.value < 7) {
      const status = getActiveStepFromActionDescription(effectiveFilingStatus);
      setActiveStep(status);
      setIsPaymentDone(false);
      setIsFileAccepted(false);
    }
  
    if (fileStatus?.value !== undefined && fileStatus.value >= 7) {
      const statusCF = getActiveStepForCompleted(effectiveFilingStatus);
      setActiveStep(statusCF);
      setIsPaymentDone(true);
      setIsFileAccepted(true);
    }
    
    if(actionPanelData?.filing_status == 'SUSPENDED' && fileStatus?.value !== undefined && fileStatus.value <= 4){
      setIsEditFiling(false)
    } else{
      setIsEditFiling(fileStatus?.value !== undefined && fileStatus.value >= 3);
    }
  };
  
  
  

  
  const getActiveStepFromActionDescription = (description: string) => {
    const filingStatus = getEffectiveFilingStatus();
    switch (filingStatus) {
      case StepStatus.PENDING_VERIFICATION:
        return 0;
      case StepStatus.VERIFIED:
        return 1;
      // case StepStatus.PENDING_PAYMENT:
      //   return 1;
      case StepStatus.PENDING_PAYMENT:
        return 2;
      case StepStatus.PAYMENT_COMPLETED:
        return 3;
      default:
        return 0;
    }
  };
  

  const getActiveStepForCompleted = (description: string) => {
    const filingStatus = getEffectiveFilingStatus();
    switch (filingStatus) {
      case StepStatus.PAYMENT_COMPLETED:
        return 0;
      case StepStatus.MARK_SUBMITTED:
        return 1;
      case StepStatus.UPLOADED_EMCS:
        return 2;
      case StepStatus.MARK_PROCESSING:
        return 3;
      case StepStatus.MARK_COMPLETED:
        return 4;
      default:
        return 0;
    }
  };

  const checkIfFieldsAreEditable = () => {
    const isAnyFieldEditable = Object.entries(isEditing).some(
      ([field, isFieldEditing]) => field !== 'lastName' && isFieldEditing
    );
    if (isAnyFieldEditable) {
      const newFieldErrors = {
        firstName: isEditing.firstName ? 'Please save before proceeding.' : '',
        lastName: '',
        phone: isEditing.phone ? 'Please save before proceeding.' : '',
        email: isEditing.email ? 'Please save before proceeding.' : '',
      };
  
      setUserDetailFormErrors(newFieldErrors);
      return false;
    }
    setUserDetailFormErrors({
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
    });
  
    return true;
  };

  const sendVerificationLink = async (clickedCta:any) => {
    const canProceed = checkIfFieldsAreEditable();
    if (!canProceed) {
      return;
    }

    if (validateUserDetailForm()) {
      let param;
      if(clickedCta == 'SendVerificationLink'){
         param = sendVerifyClickCount === 0 ? 'Verification' : 'ResendVerification';
        setSendClickVerifyClickCount(sendVerifyClickCount + 1);
      }
      if(clickedCta == 'ResendInvoice'){
        param = 'Confirmation'
      }
      if(clickedCta == 'ResendConfirmation'){
        param = 'ResendConfirmation'
      }
     
      setIsLoading(true)
      try {
        const resp = await mcsService.sendVerification(formKey, param);
        if (resp?.status === 200) {
          setIsLoading(false)
          // if(param == 'Verification'){
            getCustomerActionData()
          // }
        }
        else {
          setIsLoading(false)
          throw new Error('API Error : ' + resp?.status);
        }
      
      } catch (err) {
          setIsLoading(false)
          alert('Something went wrong, Please try again');
      }
    }
  };

  const sendPaymentLink = async () => {
    const canProceed = checkIfFieldsAreEditable();
    if (!canProceed) {
      return;
    }
    if (validateUserDetailForm()) {
      const param = sendPaymentClickCount === 0 ? 'Payment' : 'ResendPayment';
      setSendPaymentClickCount(sendPaymentClickCount + 1);
      setIsLoading(true)
      try {
        const resp = await mcsService.mcsSendPayment(formKey, param);
        if (resp?.status === 200) {
          setIsLoading(false)
        }
        else {
          setIsLoading(false)
          throw new Error('API request failed with status ' + resp?.status);
        }
      
      } catch (err) {
          setIsLoading(false)
          alert('Something went wrong, Please try again');
      }
    }
  };

  const handleMarkSubmitted = async(value:any) =>{
    setIsLoading(true)
    try {
      const resp = await mcsService.mcsMarkSubmitted(formKey, value);
      if (resp?.status === 200) {
        setActionPanelData(resp?.data)
        filterByFilingStatus(resp?.data?.filing_status);
        setIsLoading(false)
      }
      else {
        setIsLoading(false)
        throw new Error('API Error : ' + resp?.message);
      }
    
    } catch (err) {
        setIsLoading(false)
        alert(err);
    }
  }

  const continueSubmittedFiling = ()=>{
    navigate('/emcs150/emcs-customers');
  }

  
  const getCustomerActionData = async (loadPage:any=false) => {
    loadPage && setIsLoading(true)
    try {
      const resp = await mcsService.getCustomerDetailsFormKey(formKey);
      if (resp?.status === 200) {
        setActionPanelData(resp?.data)
        filterByFilingStatus(resp?.data?.filing_status);
        setIsLoading(false)
        setGetCurrentUserData(resp?.data)
      }
      else {
        loadPage && setIsLoading(false)
        throw new Error('API Error ' + resp?.message);
      }
    
    } catch (err) {
      loadPage && setIsLoading(false)
        alert(err);
    }
  }

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval> | null = null;
  
    if (isEditFiling) {
      const source = axios.CancelToken.source();
      setCancelSource(source);
  
      const pollData = () => {
        getCustomerActionData().then(() => {
          filterByFilingStatus(actionPanelData?.filing_status);
        });
      };

      pollData();

      intervalId = setInterval(() => {
        pollData();
      }, 30000);
    }
  
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }

      if (cancelSource) {
        cancelSource.cancel('request canceled');
      }
    };
  }, [isEditFiling, actionPanelData?.filing_status]);
  

  const toggleEdit = (fieldName:EditableFields) => {
    setIsEditing((prev) => ({
      ...prev,
      [fieldName]: !prev[fieldName],
    }));
    setIsFieldEditable((prevState) => ({
      ...prevState,
      [fieldName]: !prevState[fieldName],
    }));
  };

  const saveChanges = async (field: any) => {
    const payload = {
      firstName: userDetailFormData?.firstName,
      lastName: userDetailFormData?.lastName,
      email: userDetailFormData?.email,
      phoneNumber: userDetailFormData?.phone,
      dotPin:userDetailFormData?.usdotPin,
      ein: fileMCSData?.ein,
      dotNumber: fileMCSData?.usdot,
    }
    if (validateUserDetailForm(field)) {
      setIsLoading(true)
      try {
        const resp = await mcsService.mcsSaveEditUserData(formKey, payload);
        if (resp?.status === 200) {
          // setActionPanelData(resp?.data);
          toggleEdit(field);
          setIsLoading(false)
        }
        else {
          setIsLoading(false)
          throw new Error('API request failed with status ' + resp?.message);
        }
      
      } catch (err) {
          setIsLoading(false)
          alert(err);
      }
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB > 25) {
        setSelectedFileSize('File size should be less than 25MB.');
        return; 
      }
      setSelectFile(file);
    }
  };

  const getEffectiveFilingStatus = () => {
    return actionPanelData?.filing_status === 'SUSPENDED'
      ? actionPanelData?.previous_status
      : actionPanelData?.filing_status;
  };

  
  // Handle file upload
  const handleUploadMCSFile = async() => {
    const formData = new FormData();
    formData.append('file', selectFile as Blob);
    if (selectFile) {
      setIsLoading(true)
      try {
        const resp = await mcsService.mcsUploadMCS(formKey, formData);
        if (resp?.status === 200) {
          setActionPanelData(resp?.data)
          filterByFilingStatus(resp?.data?.filing_status);
          setIsLoading(false)
        }
        else {
          setIsLoading(false)
          throw new Error('API Error :' + resp?.status);
        }
      
      } catch (err) {
          setIsLoading(false)
          alert('Something went wrong, Please try again');
      }
      closeUploadMCS();
    } else {
      setSelectFileError('Please choose a file first.')
    }
  };

  const closeUploadMCS = () => {setIsUploadFileOpen(false); setSelectFile(null);};

  const handleActionClick = (clickedValue: any) => {
    if (clickedValue.ctaValue === 'SendVerificationLink') {
      sendVerificationLink('SendVerificationLink');
    } else if (clickedValue.ctaValue === 'SendPaymentLink') {
      sendPaymentLink();
    } else if (clickedValue.ctaValue === 'MarkSubmitted') {
      handleMarkSubmitted('MARK_SUBMITTED');
    } else if (clickedValue.ctaValue === 'UploadtheeMCS') {
      setIsUploadFileOpen(true)
    } else if (clickedValue.ctaValue === 'MarkProcessing') {
      handleMarkSubmitted('MARK_PROCESSING');
    } else if (clickedValue.ctaValue === 'MarkCompleted') {
      handleMarkSubmitted('MARK_COMPLETED');
    } else {
      sendVerificationLink('SendVerificationLink');
    }
  };
  
return (
  <>
  { isEditFiling && 
   <GlobalWrapper isLoading={isLoading}>
    <Container maxWidth={false} style={{padding:'0 40px'}}>
      <Box sx={{ flexGrow: 1, ml:3, mr:3, mb:3 }}>
        <div style={{ backgroundColor: '#edf2f8' }}>
          <div style={{margin:"0px 46px", padding:"15px 0px"}}>
{/* action pannel */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '0 auto',
                backgroundColor: '#c0d2e5',
                border: '1px solid #8eaec9',
                borderRadius: '8px',
                marginTop:'10px'
              }}
            >
              <Typography variant="h6" sx={{padding:'10px'}}>
                <strong style={{fontWeight: '700', fontSize:'18px'}}>Action:</strong> <label style={{fontWeight: '600', fontSize:'16px'}}>{actionPanelData?.action_descriptions}</label> 
              </Typography>
              {
                currentStauts < 11 && (
                  <Box sx={{ display: 'flex', alignItems: 'center', padding:'10px' }}>
                    <Button
                      variant="contained"
                      onClick={() => handleActionClick(ctaActions)}
                      sx={{
                        backgroundColor: '#f05728',
                        color: '#fff',
                        textTransform: 'none',
                        padding: '8px 16px',
                        marginRight: '8px',
                      }}
                    >
                      {ctaActions?.ctaName}
                    </Button>
                    <Button variant="contained" sx={{ ml: 'auto', backgroundColor: '#016b89', height: '40px', textTransform: 'none' }} onClick={()=>getCustomerActionData(true)}>
                      <RefreshIcon />
                    </Button>
                  </Box>
                )
              }
            </Box>

{/* 1 progress steps File is in progress */}

            <Grid container spacing={2} sx={{mt:3}}>
                <Grid item xs={12} sm={3}>
                  <Box sx={{ maxWidth: 400 }}>
                    {
                      currentStauts < 7 ? (
                        <Stepper activeStep={getActiveStepFromActionDescription(actionPanelData?.filing_status)} orientation="vertical">
                        {steps.map((step, index) => (
                          <Step key={step.label}>
                            <StepLabel
                              sx={{
                                '& .MuiStepIcon-root': {
                                  color: activeStep > index ? 'green' : activeStep === index ? 'blue' : 'lightgray',
                                },
                                '& .MuiStepIcon-text': {
                                  color: 'white',
                                },
                                '& .MuiStepLabel-label': {
                                  fontWeight: activeStep === index ? 'bold' : 'normal',
                                  color: activeStep > index ? 'green' : activeStep === index ? 'blue' : 'lightgray',
                                },
                                '& .MuiStepConnector-root': {
                                  '& .MuiStepConnector-line': {
                                    borderColor: activeStep > index ? 'green' : 'lightgray',
                                    minHeight: '52px',
                                  },
                                },
                              }}
                            >
                              <Typography variant="body2">
                                <strong>STEP {index + 1}</strong>
                              </Typography>
                              <Typography variant="body1">{step.description}</Typography>
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                      ) : (
                        <Stepper activeStep={getActiveStepForCompleted(actionPanelData?.filing_status)} orientation="vertical">
                        {submittedFilingSteps.map((step, index) => (
                          <Step key={step.label}>
                            <StepLabel
                              sx={{
                                '& .MuiStepIcon-root': {
                                  color: activeStep > index ? 'green' : activeStep === index ? 'blue' : 'lightgray',
                                },
                                '& .MuiStepIcon-text': {
                                  color: 'white',
                                },
                                '& .MuiStepLabel-label': {
                                  fontWeight: activeStep === index ? 'bold' : 'normal',
                                  color: activeStep > index ? 'green' : activeStep === index ? 'blue' : 'lightgray',
                                },
                                '& .MuiStepConnector-root': {
                                  '& .MuiStepConnector-line': {
                                    borderColor: activeStep > index ? 'green' : 'lightgray',
                                    minHeight: '52px',
                                  },
                                },
                              }}
                            >
                              <Typography variant="body2">
                                <strong>STEP {index + 1}</strong>
                              </Typography>
                              <Typography variant="body1">{step.description}</Typography>
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                      )
                    }
                  </Box>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Box sx={styles.userDetails}>
                    <Typography variant="h6" sx={{ marginBottom: '18px', fontWeight: '600', fontSize:'16px' }}>
                      User Details
                    </Typography>

                    <Grid container spacing={2}>
                      {/* First Name */}
                      <Grid item xs={12} sm={6}>
                        <div style={styles.textField}>First Name</div>
                        <TextField
                          placeholder="First Name"
                          name="firstName"
                          value={userDetailFormData.firstName}
                          onChange={handleUserDetailChange}
                          fullWidth
                          error={!!userDetailFormErrors.firstName}
                          helperText={userDetailFormErrors.firstName}
                          sx={{
                            ...styles.textFieldInside, 
                            ...(!isFieldEditable.firstName && styles.textFieldInsideDisable), 
                          }}
                          InputProps={{
                            readOnly: !isFieldEditable.firstName,
                            endAdornment: (
                              <IconButton onClick={() => isEditing.firstName ? saveChanges('firstName') : toggleEdit('firstName')}>
                                {isEditing.firstName ? <SaveIcon /> : <EditIcon />}
                              </IconButton>
                            ),
                          }}
                        />
                      </Grid>

                      {/* Last Name */}
                      <Grid item xs={12} sm={6}>
                        <div style={styles.textField}>Last Name</div>
                        <TextField
                          placeholder="Last Name"
                          name="lastName"
                          value={userDetailFormData.lastName}
                          onChange={handleUserDetailChange}
                          fullWidth
                          error={!!userDetailFormErrors.lastName}
                          helperText={userDetailFormErrors.lastName}
                          sx={{
                            ...styles.textFieldInside, 
                            ...(!isFieldEditable.lastName && styles.textFieldInsideDisable), 
                          }}
                          InputProps={{
                            readOnly: !isFieldEditable.lastName, 
                            endAdornment: (
                              <IconButton onClick={() => isEditing.lastName ? saveChanges('lastName') : toggleEdit('lastName')}>
                                {isEditing.lastName ? <SaveIcon /> : <EditIcon />}
                              </IconButton>
                            ),
                          }}
                        />
                      </Grid>

                      {/* Phone */}
                      <Grid item xs={12} sm={6}>
                        <div style={styles.textField}>Phone</div>
                        <TextField
                          placeholder="Phone"
                          name="phone"
                          value={userDetailFormData.phone}
                          onChange={handleUserDetailChange}
                          fullWidth
                          error={!!userDetailFormErrors.phone}
                          helperText={userDetailFormErrors.phone}
                          sx={{
                            ...styles.textFieldInside,
                            ...(!isFieldEditable.phone && styles.textFieldInsideDisable),
                          }}
                          InputProps={{
                            readOnly: !isFieldEditable.phone, 
                            endAdornment:  (
                              <IconButton onClick={() => isEditing.phone ? saveChanges('phone') : toggleEdit('phone')}>
                                {isEditing.phone ? <SaveIcon /> : <EditIcon />}
                              </IconButton>
                            ),
                          }}
                          inputProps={{
                            maxLength: 10,
                          }}
                        />
                      </Grid>

                      {/* Email */}
                      <Grid item xs={12} sm={6}>
                        <div style={styles.textField}>Email</div>
                        <TextField
                          placeholder="Email"
                          name="email"
                          value={userDetailFormData.email}
                          onChange={handleUserDetailChange}
                          fullWidth
                          error={!!userDetailFormErrors.email}
                          helperText={userDetailFormErrors.email}
                          sx={{
                            ...styles.textFieldInside,
                            ...(!isFieldEditable.email && styles.textFieldInsideDisable), 
                          }}
                          InputProps={{
                            readOnly: !isFieldEditable.email,
                            endAdornment: (
                              <IconButton onClick={() => isEditing.email ? saveChanges('email') : toggleEdit('email')}>
                                {isEditing.email ? <SaveIcon /> : <EditIcon />}
                              </IconButton>
                            ),
                          }}
                        />
                      </Grid>
                      {
                        !isPaymentDone && (
                          <Grid item xs={12} sm={6}>
                          <div style={styles.textField}>USDOT PIN</div>
                          <TextField
                            placeholder="USDOT PIN"
                            value={userDetailFormData.usdotPin}
                            onChange={handleUserDetailChange}
                            fullWidth
                            sx={{
                              ...styles.textFieldInside,
                              // ...styles.textFieldInsideDisable,
                            }}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        )
                      }
                      {
                        currentStauts > 6 && (
                         <ResendInvoice formKey={formKey} sendVerificationLink={sendVerificationLink} setIsLoading={setIsLoading} currentStauts={currentStauts} />
                        )
                      }
                    </Grid>
                  </Box>
                </Grid>
            </Grid>
          </div>
        </div>
      </Box>
    </Container>
    </GlobalWrapper>
}

{/* completed filing popup */}
    {/* <button onClick={()=>setFilingCompletedModal(true)}>completed filing</button> */}
    <Dialog open={filingCompletedModal} maxWidth={false} 
      sx={{
        '& .MuiDialog-paper': {
          width: '600px',
          backgroundColor:'#edf2f8'
        },
      }}>
     
      <DialogContent>
      <Grid
        container
        spacing={2}
        style={{
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          marginTop: '5px',
        }}
      >

        <Grid item>
          <img src={Checked} alt="Completed" style={{ width: 30, height: 30 }} />
        </Grid>
        
        {/* Text */}
        <Grid item>
          <Typography variant="body1" align="center" style={{fontWeight:'bold', fontSize:"24px"}}>
            Filing Completed
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" align="center" style={{fontWeight:'bold', fontSize:"16px"}}>
           MCS Filing - <span style={{color:'#036a89', textDecoration:'underline', cursor:'pointer'}} onClick={()=>setFilingCompletedModal(false)}>{getCurrentUserData?.reference_id}</span>
          </Typography>
        </Grid>
        <p>You can find the complete form details using ‘View MCS’ on the dashboard.</p>
     </Grid>
      </DialogContent>

      <DialogActions sx={{ display:'flex', justifyContent:'center', mb:3 }}>
        <Button onClick={continueSubmittedFiling} variant="outlined" sx={{ ...styles.button,mr:3, width:'125px', border: 'solid 1px #036a89', color:'#036a89', backgroundColor:'#ffffff' }}>
          Cancel
        </Button>
        <Button onClick={()=>setFilingCompletedModal(false)} variant="contained" sx={{ backgroundColor: '#016b89',ml:3, marginLeft:"22px", width:'125px', ...styles.button }}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>

    <UploadEMCS150
        open={isUploadFileOpen}
        onClose={closeUploadMCS}
        handleFileChange={handleFileChange}
        selectFile={selectFile}
        handleUploadMCSFile={handleUploadMCSFile}
        selectFileError={selectFileError}
        selectedFileSize={selectedFileSize}
        usdot={getCurrentUserData?.dot_number}
      />
   
  </>
  );
};

export default FilingActionPanel;