import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField, Button, FormControl, RadioGroup, FormControlLabel, Radio, FormHelperText } from '@mui/material';
import mcsService from '../Services/mcs.service';
import { ADDNEWCS, REGEX } from '../Constants/constants';
import { useNavigate } from 'react-router-dom';

interface FormData {
  firstName: string;
  lastName: string;
  usdot: string;
  ein: string;
  email: string;
  mobilePhone: string;
  customerType?: string;
}

interface FormErrors {
  firstName?: string;
  // lastName?: string;
  usdot?: string;
  ein?: string;
  email?: string;
  mobilePhone?: string;
  customerType?: string;
}

interface AddCustomerDialogProps {
  open: boolean;
  onClose: () => void;
  styles: any;
  onCustomerAdded:() => void;
  setIsLoading:any;
  setAddedEmail:any;
  setSearchTerm:any;
  setIsRegisterNewUser:any;
  isRegisterNewUser:any;
  userClickedData:any
}

const AddCustomerDialog: React.FC<AddCustomerDialogProps> = ({ open, onClose, styles, onCustomerAdded, setIsLoading, setAddedEmail, setSearchTerm,setIsRegisterNewUser, isRegisterNewUser, userClickedData }) => {
  const [addcustomerfield, setAddCustomerField] = useState<FormData>({
    firstName: '',
    lastName: '',
    usdot: '',
    ein: '',
    email: '',
    mobilePhone: '',
    customerType:'',
  });

  const [addcustomerErrorfield, setAddCustomerErrorField] = useState<FormErrors>({
    firstName: '',
    // lastName: '',
    usdot: '',
    ein: '',
    email: '',
    mobilePhone: '',
    customerType:'',
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const navigate = useNavigate();
  useEffect(()=>{
    if(!isRegisterNewUser){
      setAddCustomerField({
        firstName: userClickedData?.firstName,
        lastName: userClickedData?.lastName,
        usdot: userClickedData?.usdot,
        ein: userClickedData?.ein,
        email: userClickedData?.email,
        mobilePhone: userClickedData?.phone,
      })
    }
  },[userClickedData])

  // Email validation
  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Mobile Phone validation
  const isValidMobilePhone = (mobilePhone: string) => {
    return REGEX.Regex.phone.test(mobilePhone);
  };

const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAddCustomerField({
        ...addcustomerfield,
        [name]: value,
    });

    // Real-time validation
    if (isSubmitted) {
        let validationError = '';

        switch (name) {
            case 'firstName':
            validationError = value.trim() ? '' : 'First Name is required';
            break;
            // case 'lastName':
            // validationError = value.trim() ? '' : 'Last Name is required';
            // break;
            case 'usdot':
            if (!value.trim()) {
                validationError = 'USDOT is required';
            } else if (!/^\d{5,12}$/.test(value)) {
                validationError = 'USDOT Number must be between 5 and 12 digits and contain only numbers';
            }
            break;
            case 'ein':
            if (!value.trim()) {
                validationError = 'EIN is required';
            } else if (!/^\d{9}$/.test(value)) {
                validationError = 'EIN must be a 9-digit number';
            }
            break;
            case 'email':
            if (!value.trim()) {
                validationError = 'Email is required';
            } else if (!isValidEmail(value)) {
                validationError = 'Email is not valid';
            }
            break;
            case 'mobilePhone':
            if (!value.trim()) {
                validationError = 'Mobile Phone is required';
            } else if (!isValidMobilePhone(value)) {
                validationError = 'Mobile Phone must be a 10-digit number';
            }
            break;
            case 'customerType':
              if (!isRegisterNewUser) {
                validationError = value.trim() ? '' : 'Customer Type is required';
              }
              break;
            default:
            break;
        }

        setAddCustomerErrorField((prevErrors) => ({
            ...prevErrors,
            [name]: validationError,
        }));
    }   
};

  // Validate form fields on submission
  const validateForm = () => {
    const newErrors: FormErrors = {};
    let hasError = false;

    if (!addcustomerfield.firstName) {
      newErrors.firstName = 'First Name is required';
      hasError = true;
    }

    // if (!addcustomerfield.lastName) {
    //   newErrors.lastName = 'Last Name is required';
    //   hasError = true;
    // }

    if (!addcustomerfield.usdot) {
      newErrors.usdot = 'USDOT is required';
      hasError = true;
    } else if (!/^\d{5,12}$/.test(addcustomerfield.usdot)) {
      newErrors.usdot = 'USDOT Number must be between 5 and 12 digits and contain only numbers';
      hasError = true;
    }

    if (!addcustomerfield.ein) {
      newErrors.ein = 'EIN is required';
      hasError = true;
    } else if (!/^\d{9}$/.test(addcustomerfield.ein)) {
      newErrors.ein = 'EIN must be a 9-digit number';
      hasError = true;
    }

    if (!addcustomerfield.email) {
      newErrors.email = 'Email is required';
      hasError = true;
    } else if (!isValidEmail(addcustomerfield.email)) {
      newErrors.email = 'Email is not valid';
      hasError = true;
    }

    if (!addcustomerfield.mobilePhone) {
      newErrors.mobilePhone = 'Mobile Phone is required';
      hasError = true;
    } else if (!isValidMobilePhone(addcustomerfield.mobilePhone)) {
      newErrors.mobilePhone = 'Mobile Phone must be a 10-digit number';
      hasError = true;
    }
    if (!addcustomerfield.customerType && !isRegisterNewUser) {
      newErrors.customerType = 'Please select a customer type';
      hasError = true;
    }

    setAddCustomerErrorField(newErrors);

    return !hasError;
  };

  const updateStateOnClick = () => {
    setIsLoading(true);
    const payload = {
      mappingKey: userClickedData?.mappingKey,
      preference: "INTERESTED",
      remarks: '',
      interactionType:addcustomerfield?.customerType,
      firstName: addcustomerfield?.firstName,
      lastName: addcustomerfield?.lastName,
      dotNumber: addcustomerfield?.usdot,
      ein: addcustomerfield?.ein,
      email: addcustomerfield?.email,
      phoneNumber: addcustomerfield?.mobilePhone
    }
    return mcsService.mcsSavePrefrence(payload)
    .then((response) => {
      if (response.status === 200) {
        setIsLoading(false)
        navigate({
          pathname: '/emcs150/file-mcs150',
          search: `?mappingKey=${userClickedData?.mappingKey}&dotNumber=${addcustomerfield?.usdot}`,
        });
      }
      else {
        setIsLoading(false)
        throw new Error('API Error : ' + response.status);
      }
    })
    .catch(() => {
      setIsLoading(false)
      alert('Something went wrong, Please try again');
    });
  };

  // Handle form submission
  const handleSubmit = async() => {
    setIsSubmitted(true);
    if (!validateForm()) {
      return
    }
    if(!isRegisterNewUser){
      updateStateOnClick()
      return;
    }
    setIsLoading(true)
      const payload = {
        firstName: addcustomerfield?.firstName,
        lastName: addcustomerfield?.lastName,
        dotNumber: addcustomerfield?.usdot,
        ein: addcustomerfield?.ein,
        email: addcustomerfield?.email,
        phoneNumber: addcustomerfield?.mobilePhone
      }
      return mcsService.mcsAddNewCustomer(payload)
      .then((response) => {
        if (response.status === 200) {
          clearData();
          onCustomerAdded();
          setAddedEmail(addcustomerfield?.email)
          setSearchTerm(addcustomerfield?.email)
          setIsLoading(false)
        } else {
          setIsLoading(false)
          throw new Error('API request failed with status ' + response?.data?.detail);
        }
      })
      .catch((err) => {
        setIsLoading(false)
        if (err.response && err.response.status === 400) {
          alert(err.response.data?.message);
        } else {
          alert('Something went wrong, Please try again');
        }
      });
    }    

  const clearData = () =>{
  onClose();
   setAddCustomerField({
      firstName: '',
      lastName: '',
      usdot: '',
      ein: '',
      email: '',
      mobilePhone: '',
   });
  }

  return (
    <Dialog open={open} maxWidth={false} 
      sx={{
        '& .MuiDialog-paper': {
          width: '600px',
          backgroundColor:'#edf2f8'
        },
      }}>
      <DialogTitle sx={{fontWeight:"bold"}}>{isRegisterNewUser ? 'Add Customer' : 'Start Filing'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <div style={styles.textField}>First Name</div>
              <TextField
                placeholder="Enter First Name"
                name="firstName"
                value={addcustomerfield.firstName}
                onChange={handleChange}
                fullWidth
                sx={styles.textFieldInside}
                error={!!addcustomerErrorfield.firstName}  
                helperText={addcustomerErrorfield.firstName}  
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <div style={styles.textField}>Last Name</div>
              <TextField
                placeholder="Enter Last Name"
                name="lastName"
                value={addcustomerfield.lastName}
                onChange={handleChange}
                fullWidth
                sx={styles.textFieldInside}
                // error={!!addcustomerErrorfield.lastName}
                // helperText={addcustomerErrorfield.lastName}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <div style={styles.textField}>USDOT Number</div>
              <TextField
                placeholder="Enter USDOT Number"
                name="usdot"
                value={addcustomerfield.usdot}
                onChange={handleChange}
                fullWidth
                sx={styles.textFieldInside}
                inputProps={{
                  maxLength: 12,
                }}
                error={!!addcustomerErrorfield.usdot}
                helperText={addcustomerErrorfield.usdot}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <div style={styles.textField}>EIN</div>
              <TextField
                placeholder="Enter EIN"
                name="ein"
                value={addcustomerfield.ein}
                onChange={handleChange}
                fullWidth
                sx={styles.textFieldInside}
                inputProps={{
                  maxLength: 9,
                }}
                error={!!addcustomerErrorfield.ein}
                helperText={addcustomerErrorfield.ein}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <div style={styles.textField}>Email</div>
              <TextField
                placeholder="Enter Email"
                name="email"
                value={addcustomerfield.email}
                onChange={handleChange}
                fullWidth
                sx={styles.textFieldInside}
                error={!!addcustomerErrorfield.email}
                helperText={addcustomerErrorfield.email}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <div style={styles.textField}>Mobile Phone</div>
              <TextField
                placeholder="Enter Mobile Phone Number"
                name="mobilePhone"
                value={addcustomerfield.mobilePhone}
                onChange={handleChange}
                fullWidth
                sx={styles.textFieldInside}
                inputProps={{
                  maxLength: 10,
                }}
                error={!!addcustomerErrorfield.mobilePhone}
                helperText={addcustomerErrorfield.mobilePhone}
              />
            </Grid>
            {
              !isRegisterNewUser && (
                <Grid item xs={12} sm={12}>
                <div style={styles.textField}>Customer Interaction Type</div>
                  <FormControl component="fieldset" error={!!addcustomerErrorfield.customerType}>
                    <RadioGroup name="customerType" value={addcustomerfield.customerType} onChange={handleChange}>
                          <FormControlLabel value="INBOUND_EMCS" control={<Radio />} label={ADDNEWCS.addNewCS.Inbound} />
                          <FormControlLabel value="OUTBOUND_EMCS" control={<Radio />} label={ADDNEWCS.addNewCS.Outbound} />
                    </RadioGroup>
                    {addcustomerErrorfield.customerType && (
                      <FormHelperText>{addcustomerErrorfield.customerType}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              )
            }
           
        </Grid>
      </DialogContent>

      <DialogActions sx={{ display:'flex', justifyContent:'center', mb:3 }}>
        <Button onClick={clearData} variant="outlined" sx={{ ...styles.button, width:'125px', border: 'solid 1px #036a89', color:'#036a89', backgroundColor:'#ffffff' }}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" sx={{ backgroundColor: '#016b89', marginLeft:"22px", width:'125px', ...styles.button }}>
          {isRegisterNewUser ? 'Add' : 'Start'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCustomerDialog;
