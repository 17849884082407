import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Dialog,
  DialogContent,
  TextField,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
  SelectChangeEvent,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import DashboardService from "../../Services/dashboard.service";
import EditIcon from "@mui/icons-material/Edit";
import {
  CANADA_STATES_LIST,
  COUNTRY_LIST,
  getTableValue,
  MEXICO_STATES_LIST,
  US_STATES_LIST,
} from "../../Utils/Constants";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../Store";
import { IS_LOADING } from "../../Actions/types";
export default function SoldDetails({
  form8849,
  clearStateAndRefetch,
  status,
}: any) {
  const methods = useForm({});
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const [errorState, setErrorState] = useState<any>({});

  const [stateList, setStateList] = useState(US_STATES_LIST);
  const [currentState, setCurrentState] = useState<any>();
  const [currentCountry, setCurrentCountry] = useState<any>();

  const handleOpen = () => {
    setErrorState({});
    setOpen(true);
  };
  const handleClose = () => {
    setErrorState({});
    setOpen(false);
  };

  const onSubmit = (data: any) => {
    dispatch({ type: IS_LOADING });
    return DashboardService.cSoldUpdate(data, form8849?.form8849?.formKey)
      .then(() => {
        clearStateAndRefetch();
        setOpen(false);
      })
      .catch((error) => {
        setErrorState((prev: any) => ({
          ...prev,
          ...error?.response?.data?.errors,
        }));
      });
  };

  const handleCountryChange = (e: SelectChangeEvent<unknown>) => {
    const country = e.target.value as string;
    setValue("country", country);
    setCurrentCountry(country);
    updateStateList(country);
  };
  const handleStateChange = (e: SelectChangeEvent<unknown>) => {
    const state = e.target.value as string;
    setValue("state", state);
    setCurrentState(state);
  };
  const updateStateList = (cn: string) => {
    switch (cn) {
      case "US":
        setStateList(US_STATES_LIST);
        break;
      case "MX":
        setStateList(MEXICO_STATES_LIST);
        break;
      case "CN":
        setStateList(CANADA_STATES_LIST);
        break;
    }
  };

  useEffect(() => {
    if (form8849?.soldTo) {
      setValue("soldTo", form8849?.soldTo);
    }

    if (form8849?.address?.country) {
      setValue("country", form8849?.address?.country);
      setCurrentCountry(form8849?.address?.country);
      updateStateList(form8849?.address?.country);
    }
    if (form8849?.address?.state) {
      setValue("state", form8849?.address?.state);
      setCurrentState(form8849?.address?.state);
    }
    if (form8849?.email) {
      setValue("email", form8849?.email);
    }
    if (form8849?.phone) {
      setValue("phone", form8849?.phone);
    }
    if (form8849?.address?.addLine1) {
      setValue("add1Modal", form8849?.address?.addLine1);
    }
    if (form8849?.address?.addLine2) {
      setValue("add2Modal", form8849?.address?.addLine2);
    }
    if (form8849?.address?.city) {
      setValue("cityModal", form8849?.address?.city);
    }
    if (form8849?.address?.zip) {
      setValue("zip", form8849?.address?.zip);
    }
  }, [form8849]);
  
  return (
    <TableContainer component={Paper}>
      <Table aria-label="Sold Details" size="small">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Sold To
            </TableCell>
            <TableCell>{form8849 && form8849.soldTo}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Email Address
            </TableCell>
            <TableCell>{form8849 && form8849.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Phone
            </TableCell>
            <TableCell>{form8849 && form8849.phone}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Address 1
            </TableCell>
            <TableCell>{form8849 && form8849?.address?.addLine1}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Address 2
            </TableCell>
            <TableCell>{form8849 && form8849?.address?.addLine2}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Country
            </TableCell>
            <TableCell>{form8849 && form8849?.address?.country}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              City
            </TableCell>
            <TableCell>{form8849 && form8849?.address?.city}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Zip Code
            </TableCell>
            <TableCell>{form8849 && form8849?.address?.zip}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              State
            </TableCell>
            <TableCell>
              {form8849 && form8849?.address?.state}
              {status !== "IRS Accepted" && status !== "Retrieved Schedule 1"  && status !== 'User Submitted' && (
                <IconButton color="primary" aria-label="Edit">
                  <EditIcon onClick={handleOpen} />
                </IconButton>
              )}
              <Button size="small"></Button>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit Sold Details</DialogTitle>
                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle>Business</DialogTitle>
                    <DialogContent>
                      <TextField
                        {...(errors.soldTo && {
                          helperText: errors.soldTo.message as string,
                        })}
                        {...(errors?.soldTo ? { error: true } : {})}
                        {...register("soldTo", {
                          required: "SoldTo is required",
                        })}
                        margin="dense"
                        label="Sold To"
                        type="text"
                        fullWidth
                        variant="standard"
                      />

                      <TextField
                        {...(errors.email && {
                          helperText: errors.email.message as string,
                        })}
                        {...(errors?.email ? { error: true } : {})}
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: "Please enter a valid email address",
                          },
                        })}
                        margin="dense"
                        label="Email address"
                        type="text"
                        fullWidth
                        variant="standard"
                      />

                      <TextField
                        {...(errors.phone && {
                          helperText: errors.phone.message as string,
                        })}
                        {...(errors?.phone ? { error: true } : {})}
                        {...register("phone", {
                          pattern: {
                            value: /^[1-9]{1}[0-9]{9}$/g,
                            message: "Please enter a valid phone number",
                          },
                        })}
                        margin="dense"
                        label="Phone No"
                        type="text"
                        fullWidth
                        variant="standard"
                      />

                      <TextField
                        {...(errors.add1Modal && {
                          helperText: errors.add1Modal.message as string,
                        })}
                        {...(errors?.add1Modal ? { error: true } : {})}
                        {...register("add1Modal")}
                        margin="dense"
                        label="Address1"
                        type="text"
                        fullWidth
                        variant="standard"
                      />

                      <TextField
                        {...(errors.add2Modal && {
                          helperText: errors.add2Modal.message as string,
                        })}
                        {...(errors?.add2Modal ? { error: true } : {})}
                        {...register("add2Modal")}
                        margin="dense"
                        label="Address2"
                        type="text"
                        fullWidth
                        variant="standard"
                      />

                      <TextField
                        {...(errors.cityModal && {
                          helperText: errors.cityModal.message as string,
                        })}
                        {...(errors?.cityModal ? { error: true } : {})}
                        {...register("cityModal", {
                          required: "City is required",
                        })}
                        margin="dense"
                        label="City"
                        type="text"
                        fullWidth
                        variant="standard"
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "34px",
                          padding: "16px 0",
                        }}
                      >
                        <InputLabel id="state-label">State</InputLabel>

                        { currentState === "US" ? <Select
                          labelId="state-label"
                          id="state"
                          style={{ minWidth: "40%" }}
                          value={currentState}
                          {...register("state", {
                            required: "State is Required",
                          })}
                          onChange={handleStateChange}
                        >
                          {stateList.map((state) => (
                            <MenuItem key={state.value} value={state.value}>
                              {state.label}
                            </MenuItem>
                          ))}
                        </Select> : <TextField
                          id="state"
                          style={{ minWidth: "40%" }}
                          value={currentState}
                          {...register("state", {
                            required: "State is Required",
                          })}/> }
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "16px",
                          padding: "16px 0",
                        }}
                      >
                        <InputLabel id="country-label">Country</InputLabel>

                        <Select
                          labelId="country-label"
                          id="country"
                          style={{ minWidth: "40%" }}
                          value={currentCountry}
                          {...register("country", {
                            required: "Country is required",
                          })}
                          onChange={handleCountryChange}
                        >
                          {COUNTRY_LIST.map((country) => (
                            <MenuItem key={country.value} value={country.value}>
                              {country.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>

                      <TextField
                        {...(errors.zip && {
                          helperText: errors.zip.message as string,
                        })}
                        {...(errors?.zip ? { error: true } : {})}
                        {...register("zip", {
                          required: "Zip/Postal Code is required",
                        })}
                        margin="dense"
                        label="Zip/Postal Code"
                        type="text"
                        fullWidth
                        variant="standard"
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Cancel</Button>
                      <Button type="submit">Save</Button>
                    </DialogActions>
                  </form>
                </FormProvider>
              </Dialog>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
