import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Dialog,
  DialogContent,
  TextField,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import { DASHES, maskString } from "../../Utils/Constants";
import DashboardService from "../../Services/dashboard.service";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

export default function RoutingDetails({
  paymentMethod,
  clearStateAndRefetch,
  selectedRefId,
}: any) {
  const [open, setOpen] = React.useState(false);
  const [errorState, setErrorState] = useState<any>({});
  const methods = useForm({});
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    setValue("routingNo", paymentMethod?.routingNo);
    setValue("accountNo", paymentMethod?.accountNo);
    setValue("accountType", paymentMethod?.accountType);
    setValue("phone", paymentMethod?.phoneNumber);
    setValue("irsPaymentMode", paymentMethod?.irsPaymentMode);
    setValue("remarks", paymentMethod?.remarks);
  }, [paymentMethod]);

  const handleOpen = () => {
    setErrorState({});
    setOpen(true);
  };

  const handleClose = () => {
    setErrorState({});
    setOpen(false);
  };

  const onSubmit = (data: any) => {
    const prepareData = {
      irsPaymentMode: "EFW",
      efw: {
        routingNo: data?.routingNo,
        accountType: data?.accountType,
        accountNo: data?.accountNo,
        phone: data?.phoneNumber,
        remarks: data?.remarks,
        amount: 0,
      },
    };
    const formKey = selectedRefId?.form2290Model?.formKey;
    return DashboardService.csUpdateRoutingDetails(formKey, prepareData)
      .then(() => {
        clearStateAndRefetch();
        setOpen(false);
      })
      .catch(() => {
        setOpen(false);
      });
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="Routing Details" size="small">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Routing No
            </TableCell>
            <TableCell>
              {paymentMethod?.routingNumber
                ? maskString(paymentMethod?.routingNumber, 4)
                : DASHES}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Account No
            </TableCell>
            <TableCell>
              {paymentMethod?.accountNumber
                ? maskString(paymentMethod?.accountNumber, 4)
                : DASHES}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Account Type
            </TableCell>
            <TableCell>{paymentMethod && paymentMethod.accountType}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Taxpayer Phone
            </TableCell>
            <TableCell>
              {paymentMethod && paymentMethod.phoneNumber}
              {selectedRefId?.status !== "Retrieved Schedule 1" && selectedRefId?.status !== 'User Submitted' && (
                <IconButton color="primary" aria-label="Edit">
                  <EditIcon onClick={handleOpen} />
                </IconButton>
              )}
              <Button size="small"></Button>
              <Dialog open={open} onClose={handleClose}>
                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle>Edit Routing Details</DialogTitle>
                    <DialogContent>
                      <TextField
                        {...(errors.routingNo && {
                          helperText: errors.routingNo.message as string,
                        })}
                        {...(errorState?.routingNo && {
                          helperText: errorState?.routingNo[0] as string,
                        })}
                        {...(errors?.routingNo || errorState?.routingNo
                          ? { error: true }
                          : {})}
                        {...register("routingNo", {
                          required: "Routing Number is required",
                        })}
                        margin="dense"
                        id="routingNo"
                        label="Routing No"
                        type="text"
                        fullWidth
                        variant="standard"
                        inputProps={{
                          maxLength: 9,
                        }}
                      />
                      <TextField
                        {...(errors.accountNo && {
                          helperText: errors.accountNo.message as string,
                        })}
                        {...(errorState?.accountNo && {
                          helperText: errorState?.accountNo[0] as string,
                        })}
                        {...(errors?.accountNo || errorState?.accountNo
                          ? { error: true }
                          : {})}
                        {...register("accountNo", {
                          required: "Account Number is required",
                        })}
                        margin="dense"
                        id="accountNo"
                        label="Account No"
                        type="text"
                        fullWidth
                        variant="standard"
                      />
                      <TextField
                        {...(errors.accountType && {
                          helperText: errors.accountType.message as string,
                        })}
                        {...(errorState?.accountType && {
                          helperText: errorState?.accountType[0] as string,
                        })}
                        {...(errors?.accountType || errorState?.accountType
                          ? { error: true }
                          : {})}
                        {...register("accountType")}
                        margin="dense"
                        id="accountType"
                        label="Account Type"
                        type="text"
                        fullWidth
                        variant="standard"
                      />
                      <TextField
                        {...(errors.phoneNumber && {
                          helperText: errors.phoneNumber.message as string,
                        })}
                        {...(errorState?.phoneNumber && {
                          helperText: errorState?.routingNo[0] as string,
                        })}
                        {...(errors?.phoneNumber || errorState?.phoneNumber
                          ? { error: true }
                          : {})}
                        {...register("phoneNumber", {
                          pattern: {
                            value: /^[1-9]{1}[0-9]{9}$/g,
                            message: "Please enter a valid phone number",
                          },
                        })}
                        margin="dense"
                        id="taxpayerPhone"
                        label="Taxpayer Phone"
                        type="text"
                        fullWidth
                        variant="standard"
                        inputProps={{
                          maxLength: 10,
                        }}
                      />
                      <TextField
                        {...(errors.remarks && {
                          helperText: errors.remarks.message as string,
                        })}
                        {...(errors?.remarks ? { error: true } : {})}
                        {...register("remarks", {
                          required: "Remark is required",
                        })}
                        margin="dense"
                        id="remark"
                        label="Remark"
                        type="text"
                        fullWidth
                        variant="standard"
                        multiline
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Cancel</Button>
                      <Button type="submit">Save</Button>
                    </DialogActions>
                  </form>
                </FormProvider>
              </Dialog>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
