import React, { useCallback } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home'; // Placeholder for logo
import { Card, CardMedia, Container } from '@mui/material';
import McsLogo from "../../../Assets/eMCS/e-mcs-150.svg";
import { useAppDispatch } from '../../../Store';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../Actions/auth';
import { useSelector } from 'react-redux';

interface HeaderProps {
  isLogoutCta: boolean;
}

const Header: React.FC<HeaderProps> = ({ isLogoutCta }) => {
  const [value, setValue] = React.useState(0);
  const dispatch = useAppDispatch();
  const naviateTo = useNavigate();

  const handleChange = (event:any, newValue:any) => {
    setValue(newValue);
  };

  const logoutNow = useCallback(() => {
    naviateTo("/login");
    dispatch(logout());
  }, [dispatch]);

  const { isLoggedIn } = useSelector((state: any) => state.auth);
// if (!isLoggedIn) {
//   return <Navigate to="/" />;
// }

  return (
    
    <Box sx={{ flexGrow: 1, backgroundColor:"#a4d9e8" }}>
      <Container maxWidth={false} style={{padding:'0 40px'}}>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <IconButton edge="start" color="inherit" aria-label="logo" sx={{ mr: 2 }}>
            <img src={McsLogo} alt="Notifications" style={{width:'120px'}} />
            </IconButton>
          </Box>

         { isLogoutCta && <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }}>
            <Button
              variant="outlined"
              sx={{
                borderColor: '#ec5928',
                color: 'white',
                backgroundColor: '#ec5928'
              }}
              onClick={logoutNow}
            >
              Logout
            </Button>
          </Box>}
        </Toolbar>
      </AppBar>
      </Container>
    </Box>
    
  );
}

export default Header;
