import { AxiosResponse } from "axios";
import { HttpApi } from "../api/HttpsApi";

const register = (params: any) => {
  return HttpApi({
    url: "api/auth/register",
    method: "POST",
    data: params,
  });
};

const login = (username: string, password: string) => {
  return HttpApi({
    url: "api/auth/login",
    method: "POST",
    data: {
      email: username,
      password: password,
    },
  }).then((response) => {
    if ((response as AxiosResponse).data.data.access_token) {
      localStorage.setItem(
        "user",
        JSON.stringify((response as AxiosResponse).data)
      );
      localStorage.setItem(
        "accessToken",
        (response as AxiosResponse).data.data.access_token
      );
      const expire =
        new Date().getTime() +
        (response as AxiosResponse).data.data.expires_in * 1000;
      localStorage.setItem("expirationTime", JSON.stringify(expire));
    }

    return (response as AxiosResponse).data;
  });
};

const logout = () => {
  localStorage.removeItem("user");
};

const csFetchUIToggles = () => {
  return HttpApi({
    url: "api/auth/ui-toggles",
    method: "GET",
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};

export default {
  register,
  login,
  logout,
  csFetchUIToggles,
};
