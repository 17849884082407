import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import DashboardLinkService from "../../Services/dashboard-links.service";
import store from "../../Store";

const SendFax = () => {
  const [faxNo, setFaxNo] = useState<string>("");
  const [faxSendSuccess, setFaxSendSuccess] = useState<boolean>(false);

  const sendFax = () => {
    const storeInfo = store.getState().message.message[0];
    const { referenceNumber, filingType } = storeInfo;
    return DashboardLinkService.csSendFax({
      referenceNumber,
      filingType,
      faxNo,
    })
      .then(() => {
        setFaxSendSuccess(true);
      })
      .catch(() => {
        setFaxSendSuccess(false);
      });
  };
  // const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   if (!e.target.files) {
  //     return;
  //   }

  //   // setFile(e.target.files[0]);

  //   // 🚩 do the file upload here normally...
  // };

  return (
    <div>
      {/* <div>Upload a file:</div>

      sendFax
      <button onClick={handleUploadClick}>
        {file ? `${file.name}` : 'Click to select'}
      </button> */}

      {/* 👇 Notice the `display: hidden` on the input */}
      {/* <label>Fax Number :</label> */}
      <TextField
        autoFocus
        margin="dense"
        id="faxno"
        value={faxNo}
        onChange={(e) => {
          setFaxNo(e.target.value);
        }}
        label="Fax Number"
        required
        fullWidth
        variant="standard"
      />

      <Button onClick={() => sendFax()}>Send Fax</Button>
      {faxSendSuccess && <span> Fax sent successfully</span>}
    </div>
  );
};

export default SendFax;
