import * as React from "react";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Header from "../../Layouts/Header";
import Profile from "../../Components/Profile";
import DasboardHeader from "../../Components/DasboardHeader";
import { Grid, Box, Typography } from "@mui/material";
import BusinessDetails from "../../Components/BusinessDetails";
import SigningAuthority from "../../Components/SigningAuthority";
import ThirdPartyDesignee from "../../Components/ThirdPartyDesignee";
import FilingType from "../../Components/FilingType";

import { useEffect, useState } from "react";
import RoutingDetails from "../../Components/RoutingDetails";
import TaxDetails from "../../Components/TaxDetails";
import LossDetails from "../../Components/LossDetails";
import SoldDetails from "../../Components/SoldDetails";
import FilingActions from "../../Components/FilingActions";
import store, { useAppDispatch } from "../../Store";
import FeeDetails from "../../Components/FeeDetails";
import TexableVehicleDetails from "../../Components/VehicleDetails/taxableVehicles";
import SuspendedVehicleDetails from "../../Components/VehicleDetails/suspendedVehicles";
import PriorSoldVehicleDetails from "../../Components/VehicleDetails/priorSoldVehicles";
import CreditVehicleDetails from "../../Components/VehicleDetails/creditVehicles";
import GrossWeightVehicleDetails from "../../Components/VehicleDetails/grossWeightVehicles";
import MileageExceed from "../../Components/VehicleDetails/mileageExceed";
import { useSelector } from "react-redux";
import { CLEAR_MESSAGE, CLEAR_ERROR_MESSAGE } from "../../Actions/types";
import { cslist } from "../../Actions/dashboard";
import { GlobalWrapper } from "../../Components/GlobalComponentWrapper";
import ClaimableVehicles from "../../Components/VehicleDetails/claimableVehicles";
import DashboardService from "../../Services/dashboard.service";
import notificationSound from '../../Assets/mixkit-positive-notification-951.mp3'
import notificationSoundFollowUp from '../../Assets/mixkit-bell-notification-933.mp3'
import { NOTIFICATION_TYPE } from "../CustomerInFunnelReport/Components/constants";
import CheckForMCS150 from "../../Components/CheckForEMCS150";
import mcsService from "../Emcs150/Services/mcs.service";
const searchValue: any = {
  email: "email",
  vin: "vin",
  ref_no: "referenceNumber",
  ein: "ein",
  invoice: "invoiceNumber",
};
export interface State extends SnackbarOrigin {
  open: boolean;
}
interface StoredLists {
  [key: string]: string | null;
}
export default function Dashboard() {
  const dispatch = useAppDispatch();
  const [selectedRefId, setselectedRefId] = useState<any>({});
  const [searchType, setSearchType] = useState("ref_no");
  const [searchData, setSearchData] = useState("");
  const [referenceId, setReferenceId] = useState("");
  const messages = useSelector((state: any) => state.message.message);
  const error = useSelector((state: any) => state.error);
  const isLoading = useSelector((state: any) => state.message.isLoading);
  const [notificationsData, setNotificationsData] = useState<any>(null);
  const [notificationsFollowUPData, setNotificationsFollowUPData] = useState<any>(null);
  const [viewBreakUpData, setViewBreakUpData] = useState(null);
  const [customerUSDOTdata, setCustomerUSDOTdata] = useState({});
  const [hideMCSsction, setHideMCSSection] = useState(false)

  const selectedRef = (refId: string) => {
    const res = messages.filter((el: any) => el.referenceNumber === refId)[0];
    res ? setselectedRefId(res) : setselectedRefId({});
    if(res){
      fetchUSDOTInfo(res)
    }
  };

  // useEffect(()=>{
  //   fetchUSDOTInfo('234364763')
  // },[])

  const fetchUSDOTInfo = async (res:any) =>{
    setHideMCSSection(false)
    const payload = {
      email:res?.user?.email,
      ein:res?.ein
    }
    try {
      const resp = await mcsService.mcsGetEformDashboardInfo(payload);
      if (resp?.status === 200) {
        setCustomerUSDOTdata(resp?.data)
      } else if(resp?.status === 404){
        setHideMCSSection(true)
      }
      else {
        throw new Error('API Error ' + resp?.status);
      }
    
    } catch (err) {
        alert('Something went wrong, Please try again for EMCS data');
    }
  }

  const clearStateAndRefetch = () => {
    dispatch({ type: CLEAR_MESSAGE });
    dispatch(cslist(searchType, searchData));
  };

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
  });

  const [notification, setNotification] = React.useState<any>({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
    type: "",
  });
  const { vertical, horizontal, open } = notification;

  const handleClose = () => {
    dispatch({
      type: CLEAR_ERROR_MESSAGE,
    });
    setNotification({ ...notification, open: false });
  };

  const playNotificationSound = (type : string) => {
    try{
      let theSound : any;
      if(type == NOTIFICATION_TYPE.Regular) {
        theSound = new Audio(notificationSound);
      }
      else {
        theSound = new Audio(notificationSoundFollowUp);
      }
      theSound.muted = false;
      theSound.play();
    }catch(e){
      console.log('error :', e)
    }
  }

  const arraysAreEqual = (arr1: any[], arr2: any[]): boolean => {
    const set1 = new Set(arr2);
    for (const item of arr1) {
      if (!set1.has(item)) return false;
    }
    return true;
  };

  const handlePlayNotificationSound = (response: any) => {
    const followUpCustomerIds: string[] = [];
    const regularCustomerIds: string[] = [];
    response?.data?.forEach((item: any) => {
      const customerId = item.customerInFunnelId.toString();
      if (item.caseCategory === NOTIFICATION_TYPE.FollowUp) {
        followUpCustomerIds.push(customerId);
      } else if (item.caseCategory === NOTIFICATION_TYPE.Regular) {
        regularCustomerIds.push(customerId);
      }
    });
    const storedLists: StoredLists = {
      OutboundCallAssignmentList: localStorage.getItem('OutboundCallAssignmentList'),
      FollowUpCallAssignmentList: localStorage.getItem('FollowUpCallAssignmentList')
    }; 
    const updateAndCheckList = (key: string, soundType: NOTIFICATION_TYPE.Regular | NOTIFICATION_TYPE.FollowUp, customerIds: string[]) => {
      const storedList = storedLists[key];
      const currentList = JSON.parse(storedList || '[]');
      // Play notification sound if the lists are different
      if (!arraysAreEqual(customerIds, currentList)) {
        playNotificationSound(soundType);
      }
      localStorage.setItem(key, JSON.stringify(customerIds));
    };
    // Update and check both lists
    updateAndCheckList('OutboundCallAssignmentList', NOTIFICATION_TYPE.Regular, regularCustomerIds);
    updateAndCheckList('FollowUpCallAssignmentList', NOTIFICATION_TYPE.FollowUp, followUpCustomerIds);
  };
  
  const removeClosedCase = (customerInFunnelId : any , isOutBoundCall : boolean) => {
    let notificationDataCloseCase: any[] = [];
    if (isOutBoundCall) {
      notificationDataCloseCase = notificationsData?.filter((customer:any) => customer.customerInFunnelId !== customerInFunnelId);
      setNotificationsData(notificationDataCloseCase.length> 0 ? notificationDataCloseCase : null)
    }
    else {
      notificationDataCloseCase = notificationsFollowUPData?.filter((customer:any) => customer.customerInFunnelId !== customerInFunnelId);
      setNotificationsFollowUPData(notificationDataCloseCase.length> 0 ? notificationDataCloseCase : null)
    }
  }

  const fetchData = async () => {
    try {
      const response = await DashboardService.getNotifications();
      if (!response.status) {
        throw new Error('Network response was not ok');
      }
      const followUpData: any[] = [];
      const regularData: any[] = []; 
      response.data.forEach((item: any) => {
          if (item.caseCategory === NOTIFICATION_TYPE.FollowUp) {
              followUpData.push(item);
          } else {
              regularData.push(item);
          }
      });        
      setNotificationsData(regularData.length > 0 ? regularData : null);
      setNotificationsFollowUPData(followUpData.length > 0 ? followUpData : null);
      handlePlayNotificationSound(response);
    } catch (error) {
      setNotificationsData(null);
      setNotificationsFollowUPData(null);
      localStorage.setItem('OutboundCallAssignmentList', JSON.stringify([]));
      localStorage.setItem('FollowUpCallAssignmentList', JSON.stringify([]));
    }
  };
  useEffect(() => {
    fetchData(); // Fetch data immediately on mount
    
    const interval = setInterval(() => {
      fetchData();
    }, 25000); // Interval in milliseconds (e.g., fetch data every 25 seconds)

    // Clean up the interval
    return () => clearInterval(interval);
  }, []); // Empty dependency array means this effect runs once after mount


  const getServiceFeeBreakUp = async () => {
    const formKey = selectedRefId && selectedRefId?.form2290Model && selectedRefId?.form2290Model?.formKey || selectedRefId && selectedRefId?.form8849 && selectedRefId?.form8849?.formKey;
    if(!formKey) return;
    try {
      const resp = await DashboardService.viewServiceFeeBreakUp(formKey);
      setViewBreakUpData(resp?.data);
    } catch (err) {
        console.log('Something went wrong, Please try again');
    }
  };

  useEffect(() => {
    if (messages === undefined) {
      setNotification({
        ...notification,
        open: true,
        message: "Error, Something went wrong",
        type: "error",
      });
    } else {
      if (messages !== "" && messages?.length === 0) {
        setNotification({
          ...notification,
          open: true,
          message: "No data available",
          type: "error",
        });
      }
    }
  }, [messages]);

  useEffect(() => {
    if (error.message && error.message !== "") {
      setNotification({
        ...notification,
        open: true,
        message: error.message,
        type: "error",
      });
    }
  }, [error]);

  return (
    <GlobalWrapper isLoading={isLoading}>
      <div>
        <Header
          refIds={
            messages
              ? messages.length === 1 && messages[0].referenceNumber === null
                ? []
                : messages.map((refObj: any) => refObj.referenceNumber)
              : []
          }
          selectedRef={selectedRef}
          searchType={searchType}
          searchData={searchData}
          setSearchType={setSearchType}
          setSearchData={setSearchData}
          referenceId={referenceId}
          setReferenceId={setReferenceId}
          notificationsData={notificationsData}
          notificationsFollowUPData={notificationsFollowUPData}
          removeClosedCase = {removeClosedCase}
        />

        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert
            onClose={handleClose}
            severity={notification.type}
            sx={{ width: "100%" }}
          >
            {notification.message}
          </Alert>
        </Snackbar>

        <Box my={3} mx={2} sx={{ flexGrow: 1 }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12}>
              <DasboardHeader 
                reference={selectedRefId} 
                clearStateAndRefetch={clearStateAndRefetch}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Profile
                user={selectedRefId.user}
                ein={selectedRefId.ein}
                clearStateAndRefetch={clearStateAndRefetch}
                businessName={selectedRefId.businessName}
                selectedRefId={selectedRefId}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FilingType
                reference={selectedRefId}
                clearStateAndRefetch={clearStateAndRefetch}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TaxDetails taxDetails={selectedRefId.form2290Model} />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <FeeDetails
                reference={selectedRefId}
                invoiceNumber={selectedRefId.invoiceNumber}
                feeDetails={selectedRefId.serviceFee}
                status={selectedRefId?.status}
                getServiceFeeBreakUp={getServiceFeeBreakUp}
                viewBreakUpData={viewBreakUpData}
                setViewBreakUpData={setViewBreakUpData}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <BusinessDetails
                reference={selectedRefId}
                clearStateAndRefetch={clearStateAndRefetch}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <LossDetails
                form8849={selectedRefId?.form8849}
                reference={selectedRefId}
                attachment8849List={selectedRefId?.attachment8849List}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <RoutingDetails
                selectedRefId={selectedRefId}
                paymentMethod={selectedRefId?.paymentMethod}
                clearStateAndRefetch={clearStateAndRefetch}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Grid mb={2} rowSpacing={2}>
                <SigningAuthority
                  saDetails={selectedRefId}
                  formType={
                    selectedRefId.filingType == "Form2290"
                      ? "FORM_2290"
                      : "FORM_8849"
                  }
                  businessSubmissionId={selectedRefId.businessSubmissionId}
                  clearStateAndRefetch={clearStateAndRefetch}
                />
              </Grid>
              <Grid container rowSpacing={1}>
                <ThirdPartyDesignee
                  tpd={selectedRefId}
                  formType={
                    selectedRefId.filingType === "Form2290"
                      ? "FORM_2290"
                      : "FORM_8849"
                  }
                  businessSubmissionId={selectedRefId.businessSubmissionId}
                  clearStateAndRefetch={clearStateAndRefetch}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SoldDetails
                form8849={selectedRefId?.form8849}
                clearStateAndRefetch={clearStateAndRefetch}
                status={selectedRefId?.status}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FilingActions
                reference={selectedRefId.referenceNumber}
                selectedRefId={selectedRefId}
              />
              { !hideMCSsction && <CheckForMCS150 customerUSDOTdata={customerUSDOTdata} ein={selectedRefId?.ein} setHideMCSSection={setHideMCSSection} />}
            </Grid>
            {selectedRefId?.form2290Model?.amendmentCategory &&
            selectedRefId?.form2290Model?.isAmendment &&
            selectedRefId?.form2290Model?.amendmentCategory !== "" &&
            selectedRefId?.form2290Model?.amendmentCategory !== "VINC" ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{ flex: "1 1 100%", background: "#EFEFEF" }}
                    variant='h6'
                    component='div'
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "0 16px",
                      }}
                    >
                      <div>Gross Weight Increase</div>
                      <div>
                        (
                        {
                          selectedRefId?.form2290Model?.vehicleResponse
                            ?.grossWeightIncreasedVehicles?.length
                        }
                        )
                      </div>
                    </div>
                  </Typography>
                  <GrossWeightVehicleDetails vehicleResponse={selectedRefId} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{ flex: "1 1 100%", background: "#EFEFEF" }}
                    variant='h6'
                    component='div'
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "0 16px",
                      }}
                    >
                      <div>Mileage Exceeded</div>
                      <div>
                        (
                        {
                          selectedRefId?.form2290Model?.vehicleResponse
                            ?.mileageExceededVehicles?.length
                        }
                        )
                      </div>
                    </div>
                  </Typography>
                  <MileageExceed
                    name='suspendedVehicles'
                    vehicleResponse={selectedRefId}
                  />
                </Grid>
              </>
            ) : selectedRefId?.form8849 && selectedRefId?.form8849 !== "" ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{ flex: "1 1 100%", background: "#EFEFEF" }}
                    variant='h6'
                    component='div'
                  >
                    Claimable Vehicles
                  </Typography>
                  <ClaimableVehicles vehicleResponse={selectedRefId} />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{ flex: "1 1 100%", background: "#EFEFEF" }}
                    variant='h6'
                    component='div'
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "0 16px",
                      }}
                    >
                      <div>Taxable Vehicles</div>
                      <div>
                        (
                        {
                          selectedRefId?.form2290Model?.vehicleResponse
                            ?.taxableVehicles?.length
                        }
                        )
                      </div>
                    </div>
                  </Typography>
                  <TexableVehicleDetails vehicleResponse={selectedRefId} clearStateAndRefetch={clearStateAndRefetch}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{ flex: "1 1 100%", background: "#EFEFEF" }}
                    variant='h6'
                    component='div'
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "0 16px",
                      }}
                    >
                      <div>Suspended Vehicles</div>
                      <div>
                        (
                        {
                          selectedRefId?.form2290Model?.vehicleResponse
                            ?.suspendedVehicles?.length
                        }
                        )
                      </div>
                    </div>
                  </Typography>
                  <SuspendedVehicleDetails vehicleResponse={selectedRefId} clearStateAndRefetch={clearStateAndRefetch} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{ flex: "1 1 100%", background: "#EFEFEF" }}
                    variant='h6'
                    component='div'
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "0 16px",
                      }}
                    >
                      <div>Prior Sold Vehicles</div>
                      <div>
                        (
                        {
                          selectedRefId?.form2290Model?.vehicleResponse
                            ?.priorSuspendedVehicles?.length
                        }
                        )
                      </div>
                    </div>
                  </Typography>
                  <PriorSoldVehicleDetails vehicleResponse={selectedRefId} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{ flex: "1 1 100%", background: "#EFEFEF" }}
                    variant='h6'
                    component='div'
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "0 16px",
                      }}
                    >
                      <div>Credit Vehicles</div>
                      <div>
                        (
                        {
                          selectedRefId?.form2290Model?.vehicleResponse
                            ?.creditVehicles?.length
                        }
                        )
                      </div>
                    </div>
                  </Typography>
                  <CreditVehicleDetails vehicleResponse={selectedRefId} clearStateAndRefetch={clearStateAndRefetch}/>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </div>
    </GlobalWrapper>
  );
}
