import React, {useEffect, useState} from "react";
import {styled, Box, Typography, Button, Backdrop, CircularProgress} from "@mui/material";
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined';
import {getAgentsList} from "../../Services/reports.service";
import dashboardLinksService from '../../Services/dashboard-links.service';
import FollowUpRequestsTable from "./Components/followUpRequestsTable";
import { agentListmapping } from "../../Utils/Constants/commonCustomerInFunnelFunction";
import { BUTTONS, FOLLOW_UP_CONSTANTS } from "./Components/constants";

export interface UserData {
    user: string;
    email: string;
    mobileNumber: string;
    isConverted: boolean;
    filingInitiated: Date;
    contactedAt : string;
    requestedAt : string;
    agentContacted : string;
    refNo : string;
    agentLog : string;
    status: string;
    assignedTo: string;
    userKey: string;
    id: number;
    executiveId : number;
    currentPage: string;
    previousPage: string;
    timeSpent: string;
    filingIntiatedAt: string;
    filingType: string;
    userCredit: string;
    isNewUser: string;
    currentUrl: string;
    previousUrl: string;
    duration: string;
    timezone: string;
}

export const styles = {
    svg: {
        width: '100px',
        height: '100px',
        marginBottom: '20px',
    },
    header : {
        display: 'flex', 
        fontWeight: 500,   
        justifyContent: 'space-between', 
        alignItems: 'center', 
        marginBottom: '30px'
    },
    refreshButton: {
        width: '120px',
        height: '40px',
        borderRadius: '4px',
        border: 'solid 1px #1876d1',
        backgroundColor: '#fff',
        fontSize: "16px",  
        fontWeight: 500,   
        color: "#1876d1",
        textTransform: 'none'
    },
    margin: {
        margin: '24px 66px 24px 50px'
    }
}

export default function FollowUpRequests() {
  const [userData, setUserData] = useState<UserData[]>([]);
  const [agentListEmail, setAgentListEmail] = useState<string[]>([]); 
  const [agentNameToIdMap, setAgentNameToIdMap] = useState<{ [key: string]: string | number }>({}); 
  const [agentList, setAgentList] = useState<string[]>([]); 
  const [loading, setLoading] = useState<boolean>(false);
  const [agentSelectedIndex, setAgentSelectedIndex] = useState<{ [key: string]: number | null }>({});

  const CustomizedEmptyBox = styled(Box)(() => ({
    border: '1px solid #707070',
    borderRadius: '4px',
    height: 450,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#f4f4f4',
  }));

  const fetchAPICustomerInFunnelReports=() => {
    setLoading(true); 
    dashboardLinksService.getCustomerInFunnelListOpen(true)
        .then((response) => {           
            userDataAdd(response.data);
            setLoading(false); 
        })
        .catch((error) => {
            setUserData([]);
            setLoading(false); 
        });
  }

  const userDataAdd = (data: any) => {
    const agentIndexMapping: { [key: string]: number | null } = {};
    const userDataArray: UserData[] = data.map((item: any) => {
      agentIndexMapping[item.email] = item?.id ?? null;
      return {
        user: item?.fullName,
        email: item?.email,
        mobileNumber: item?.phone,
        status: item.caseStatus,
        assignedTo: item?.executivesList && item.executivesList.length > 0 ? item.executivesList[0]?.name : null,
        userKey: item?.userKey,
        id: item?.id,
        duration: item?.assignedCaseRemainingSeconds,
        isConverted: item?.isConverted,
        contactedAt: item?.contactedAt,
        agentContacted: item?.agentContacted ? item?.agentContacted.name : null,
        refNo: item?.refNo,
        requestedAt: item?.followUpRequestedOnAtIst,
        agentLog: item?.agentLog,
        executiveId: item?.executiveId,
        currentPage: item?.currentPage,
        previousPage: item?.previousPage,
        timeSpent: item?.timeSpent,
        filingIntiatedAt: item?.filingInitiated,
        filingType: item?.filingType,
        userCredit: item?.efilingCredits,
        isNewUser: item?.isNewUser,
        currentUrl: item?.currentUrl,
        previousUrl: item?.previousUrl,
        timezone: item?.timezone,
      };
    });
    setUserData(userDataArray);
    setAgentSelectedIndex(agentIndexMapping);
  };

  const handleRefresh = () => {
    fetchAPICustomerInFunnelReports();
  }

  useEffect(() => {
    fetchAPICustomerInFunnelReports();
    getAgentsList().then((response) => {
        const { sortedList, emailList, nameToIdMap } = agentListmapping(response);
        setAgentList(sortedList);
        setAgentListEmail(emailList);
        setAgentNameToIdMap(nameToIdMap);
    });
  }, []);

  return <>
    {loading && (
        <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )}
    <div style={styles.margin}>
    <div style={styles.header}>
        <div>{FOLLOW_UP_CONSTANTS.detailsHeading}</div>
        <Button variant="outlined" onClick={handleRefresh} sx={styles.refreshButton as React.CSSProperties}>{BUTTONS.refresh}</Button>
    </div>
    {userData.length === 0 ? (
      <CustomizedEmptyBox mt={3}>
        <DoNotDisturbAltOutlinedIcon sx={{color: '#c7c5c5', fontSize: 130}}/>
        <Typography mt={1}>{FOLLOW_UP_CONSTANTS.noData}</Typography>
      </CustomizedEmptyBox>
    ) : (
        <FollowUpRequestsTable 
          closedCases={userData}
          agentList={agentList}
          agentListEmail={agentListEmail}
          agentNameToIdMap = {agentNameToIdMap}
          agentSelectedIndex = {agentSelectedIndex}
          setAgentSelectedIndex = {setAgentSelectedIndex}
          handleRefresh={handleRefresh}
        />
    )}
    </div>
  </>;
}
