import * as React from 'react';
import {useEffect, useState} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  styled,
  Stack,
  Button,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Backdrop,
  CircularProgress
} from '@mui/material';
import dayjs from "dayjs";
import {ClosedCase} from "../ClosedCases";
import {FirstPage as FirstPageIcon, KeyboardArrowLeft, KeyboardArrowRight, LastPage as LastPageIcon} from '@mui/icons-material';
import SendAsEmail from './sendAsEmail';
import Agent from '../../../Utils/Models/Agent';
import { downloadFile, downloadReportAsExcel } from '../../../Services/reports.service';

export const styles = {
  paginationIcons: {
    borderRadius: '1px',
    backgroundColor: '#bddbf5',
    width: '28px',
    height: '28px',
    marginRight: '4px',
    color: '#1876d1'
  },
  sorting : {
    position: 'relative',cursor: 'pointer', minWidth:'78px'
  } as any,
  sortIcons : {
    position: 'absolute', right: '0px', top: '50%', marginLeft:'2px', transform: 'translate(-50%, -50%)', lineHeight: '0.8'
  } as any,
}
export default function ClosedCasesTable({closedCases ,agentsList ,fromDate , toDate}: { closedCases: ClosedCase[],agentsList: Agent[]; fromDate: string; toDate: string; }) {
  const ROWS_PER_PAGE = 10;
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState<keyof ClosedCase | undefined>(undefined);
  const [order, setOrder] = useState<'asc' | 'desc' | undefined>(undefined);
  const [sendEmail, setSendEmail] = React.useState(false);
  const [agentEmailList, setAgentEmailList] = useState<string[]>([]);  

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const emptyRows =
    ROWS_PER_PAGE - Math.min(ROWS_PER_PAGE, closedCases.length - page * ROWS_PER_PAGE);

  const handleSort = (property: keyof ClosedCase) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const parseDate = (dateString: string | null) => {
    if (!dateString) {
      return null;
    }
    const parts = dateString.split(/[\s/,:]+/);
    const [day, month, year, hour, minute, second] = parts.map(part => parseInt(part, 10));
    return new Date(year, month - 1, day, hour, minute, second);
  };
  
  const sortedRows = orderBy ? closedCases.slice().sort((a, b) => {
    const dateA = parseDate(a[orderBy] as string);
    const dateB = parseDate(b[orderBy] as string);
  
    if (dateA && dateB) {
      return (dateA.getTime() - dateB.getTime()) * (order === 'asc' ? 1 : -1);
    } else if (!dateA) {
      return order === 'asc' ? 1 : -1;
    } else {
      return order === 'asc' ? -1 : 1;
    }
  }) : closedCases;
    

  const CustomizedTableCell = styled(TableCell)(() => ({
    border: '1px solid white',
    fontWeight: 'bold',
    fontSize: '14px'
  }));

  const handleCloseSMS = () =>{
    setSendEmail(false);
  }
  
  const downloadReport = async () => {
    setLoading(true);
    const response = await downloadReportAsExcel(fromDate, toDate, 'CLOSED', []);
    if(response.data) {
      downloadFile(response.data)
      setLoading(false)
    }
    else {
      setLoading(false)
    }
  }

  useEffect(()=>{
    const listEmail: string[] = [];
    agentsList.forEach((item: { id: string | number; name: string; email: string }) => {
      listEmail.push(item.email.toLowerCase())
    });
    listEmail.sort();
    setAgentEmailList(listEmail);
  }, []); 

  return (
    <>
    {loading && (
      <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
      >
          <CircularProgress color="inherit" />
      </Backdrop>
    )}
    <Box marginTop={3} marginBottom={3}>
      <TableContainer sx={{border: '1px solid #707070', borderRadius: '4px'}}>
        <Table>
          <TableHead sx={{backgroundColor: '#d9d9d9'}}>
            <TableRow>
              <CustomizedTableCell>User</CustomizedTableCell>
              <CustomizedTableCell>Email</CustomizedTableCell>
              <CustomizedTableCell>Mobile Number</CustomizedTableCell>
              <CustomizedTableCell>Assigned To</CustomizedTableCell>
              <CustomizedTableCell>Was Conversion?</CustomizedTableCell>
              <CustomizedTableCell>Reference Number</CustomizedTableCell>
              <CustomizedTableCell
                  style={styles.sorting}
                  onClick={() => handleSort('filingInitiated')}
              >
                  Filing Initiated<br />at (IST)
                  <span style={styles.sortIcons}>
                      ▲ <br />▼
                  </span>
              </CustomizedTableCell>
              <CustomizedTableCell
                  style={styles.sorting}
                      onClick={() => handleSort('filingCompleted')}
                  >
                  Filing Completed at (IST)
                  <span style={styles.sortIcons}>
                      ▲ <br />▼
                  </span>
              </CustomizedTableCell>
              <CustomizedTableCell>Disposition</CustomizedTableCell>
              <CustomizedTableCell>Agent&apos;s Log</CustomizedTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows
              .slice(page * ROWS_PER_PAGE, page * ROWS_PER_PAGE + ROWS_PER_PAGE)
              .map((closedCase: ClosedCase) => (
                <TableRow
                  key={closedCase.id}
                  sx={{backgroundColor: '#f4f4f4'}}
                >
                  <CustomizedTableCell sx={{border: '1px solid white'}}>{closedCase.user}</CustomizedTableCell>
                  <CustomizedTableCell>{closedCase.email}</CustomizedTableCell>
                  <CustomizedTableCell>{closedCase.mobileNumber}</CustomizedTableCell>
                  <CustomizedTableCell>{closedCase.assignedTo}</CustomizedTableCell>
                  <CustomizedTableCell>{closedCase.wasConversion ? 'Yes' : 'No'}</CustomizedTableCell>
                  <CustomizedTableCell>{closedCase.referenceNumber}</CustomizedTableCell>
                  <CustomizedTableCell>{closedCase.filingInitiated?.toString()}</CustomizedTableCell>
                  <CustomizedTableCell>{closedCase.filingCompleted?.toString()}</CustomizedTableCell>
                  <CustomizedTableCell>{closedCase.disposition}</CustomizedTableCell>
                  <CustomizedTableCell>{closedCase.agentsLog}</CustomizedTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{marginTop: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center',}}>
        <div style={{margin: '0px 50px'}}></div>
        <Stack spacing={3} direction="row" justifyContent="center">
          <Button variant="contained" sx={{textTransform: 'none', width: '160px'}} onClick={() => downloadReport()}>Export to Excel</Button>
          <Button variant="contained" sx={{textTransform: 'none', width: '160px'}}  onClick={() => setSendEmail(true)}>Send as Email</Button>
        </Stack>
        <TablePagination
          component="div"
          count={closedCases.length}
          rowsPerPage={ROWS_PER_PAGE}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[]}
          ActionsComponent={(subProps) => {
            const {onPageChange, page} = subProps;
            const currentPage = page + 1; 
            const totalPages = Math.ceil(closedCases.length / ROWS_PER_PAGE);
            return (
              <div style={{display: 'flex', alignItems: 'center'}}>
                <IconButton style={styles.paginationIcons} onClick={() => onPageChange(null, 0)} disabled={page === 0} aria-label="first page">
                  <FirstPageIcon/>
                </IconButton>
                <IconButton style={styles.paginationIcons} onClick={() => onPageChange(null, page - 1)} disabled={page === 0} aria-label="previous page">
                  <KeyboardArrowLeft/>
                </IconButton>
                <span style={{whiteSpace: 'nowrap', margin: '0 8px'}}>{currentPage} of {totalPages}</span>
                <IconButton style={styles.paginationIcons} onClick={() => onPageChange(null, page + 1)} disabled={page >= Math.ceil(closedCases.length / ROWS_PER_PAGE) - 1}
                            aria-label="next page">
                  <KeyboardArrowRight/>
                </IconButton>
                <IconButton style={styles.paginationIcons} onClick={() => onPageChange(null, Math.ceil(closedCases.length / ROWS_PER_PAGE) - 1)}
                            disabled={page >= Math.ceil(closedCases.length / ROWS_PER_PAGE) - 1} aria-label="last page">
                  <LastPageIcon/>
                </IconButton>
              </div>
            );
          }}
          labelDisplayedRows={() => null}
        />
        <Dialog 
          open={sendEmail}
          onClose={() => setSendEmail(false)}>
          <div  style={{ width: '500px'}}>
            <DialogTitle sx={{ textAlign: 'center',fontSize: '21px', fontFamily: 'unset', marginTop:'4px' }}>Send Report on Email</DialogTitle>
            <DialogContent>
            <DialogContent style={{padding:'0px 0px 0px 0px'}}>
                  <SendAsEmail
                    emailIds ={agentEmailList}
                    onClose={handleCloseSMS}
                    fromDate={fromDate}
                    toDate={toDate}
                    status="CLOSED"
                  />
            </DialogContent>
            </DialogContent>
          </div>
      </Dialog>
      </div>
    </Box>
    </>
  );
}
