export const LOCALSTORAGE_GET_ACCESSTOKEN = () =>
  localStorage.getItem("accessToken");

export const REJECTED_STATUSES = [
  "Declined & Rejected",
  "IRS Rejected",
  "Silvermine Blocked",
  "Block & Initiate Credit",
  "Block & Initiate Refund",
  "Silvermine Rejected",
  "Silvermine Submitted",
  "Not Submitted",
]

export class DETAIL_TYPE {

  static DetailType ={
    used:"Used",
    credited:"Credited",
    expired:"Expired"
  }

  static titles ={
    refundedBY:"Debited by",
    refNo:"Reference Number",
    addedBy:"Added by",
    expiredOn:"Expires on",
    reason:"Reason"
  }

}

export class REFERENCE_STATUS  {
  static status = {
    SR : 'Silvermine Rejected' ,
    SB : 'Silvermine Blocked' ,
    US : 'User Submitted' ,
    PS : 'Pre Filing Submitted'             
  }
}
