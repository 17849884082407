import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { login } from "../../Actions/auth";
import { useAppDispatch } from "../../Store";
import { Alert, AlertTitle, Card } from "@mui/material";
import Google from "../../Assets/Google.svg";

import { Navigate } from "react-router-dom";
import { useSocialLogin } from "./useSocialLogin";
import authService from "../../Services/auth.service";
function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="/">
        Customer Support
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function Login() {
  const [successful, setSuccessful] = useState(false);
  const { googleLogin } = useSocialLogin();
  const { message } = useSelector((state: any) => {
    return state.message;
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchTogglesApi = () => {
      authService.csFetchUIToggles()
        .then((response) => {
          if (response.data) {
            const isStandalonePrefiling =response.data?.standalonePrefiling?.enabled;
            localStorage.setItem( "isStandAlonePrefiling",isStandalonePrefiling);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    localStorage.setItem('OutboundCallAssignmentList', JSON.stringify([]));
    fetchTogglesApi();
  }, []);

  const formIsValid = (fieldValues = formValues) => {
    const isValid =
      fieldValues.email &&
      fieldValues.password &&
      Object.values(errors).every((x) => x === "");

    return isValid;
  };

  const { isLoggedIn } = useSelector((state: any) => state.auth);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formIsValid()) {
      setSuccessful(false);

      // form.current.validateAll();
      const data = new FormData(e.currentTarget);

      dispatch(login(data.get("email"), data.get("password")))
        .then(() => {
          setSuccessful(true);
        })
        .catch((error) => {
          setSuccessful(false);
        });
    }
  };

  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({} as any);
  const validate: any = (fieldValues = formValues) => {
    const temp: any = { ...errors };

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : "This field is required.";
      if (fieldValues.email)
        temp.email = /@silverminegroup.com\s*$/.test(fieldValues.email)
          ? ""
          : "Email is not valid.";
    }

    if ("password" in fieldValues)
      temp.password = fieldValues.password ? "" : "This field is required.";

    setErrors({
      ...temp,
    });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    validate({ [name]: value });
  };

  if (isLoggedIn) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Card
          sx={{
            width: "500px",
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "24px 56px 32px 56px",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          {message && (
            <Alert
              sx={{
                width: "100%",
              }}
              severity={!message.loginError ? "success" : "error"}
            >
              <AlertTitle>
                {!message.loginError ? "Success" : "Error"}
              </AlertTitle>
              {message.loginError.message}{" "}
              {message.loginError && <>{message.message}</>}
            </Alert>
          )}
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChange}
              onBlur={handleChange}
              {...(errors["email"] && {
                error: true,
                helperText: errors["email"],
              })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleChange}
              onBlur={handleChange}
              {...(errors["password"] && {
                error: true,
                helperText: errors["password"],
              })}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/register" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
          {/* <Box>
            <Button
              variant="outlined"
              onClick={() => googleLogin()}
              startIcon={<img height={24} src={Google} />}
            >
              Google
            </Button>
          </Box> */}
        </Card>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
