import { AxiosResponse } from "axios";
import { HttpApi } from "../api/HttpsApi";
import store from "../Store";

const csList = (type: any, value: any) => {
  return HttpApi({
    url: "api/report/filing/list",
    method: "GET",
    params: { type: type, value: value },
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

const csListUpdate = (value: any, reference?: any) => {
  const body = {
    businessSubmittedId: reference?.businessSubmissionId,
    name: value?.businessModal,
    ein: value?.einModal,
    isAddressChanged: value.checkValue === "true" ? "true" : "false",
    formType:
      reference?.filingType?.toUpperCase() === "FORM2290"
        ? "FORM_2290"
        : "FORM_8849",
    remarks: "test",
    address: {
      addLine1: value.add1Modal,
      addLine2: value.add2Modal,
      zip: value.zip,
      city: value.cityModal,
      state: value.state,
      country: value.country,
    },
  };

  const masterBodyData = {
    businessKey: reference?.businessKey,
    userId: reference?.user?.userKey,
    name: value?.businessModal,
    ein: value?.einModal,
    isAddressChanged: value.checkValue === "true" ? "true" : "false",
    totalEmp: "11",
    address: {
      addLine1: value.add1Modal,
      addLine2: value.add2Modal,
      zip: value.zip,
      city: value.cityModal,
      state: value.state,
      country: value.country,
    },
  };

  if (value?.masterRecordUpdate === "true") {
    return HttpApi({
      url: "api/filing/cs/business/update",
      method: "PUT",
      data: body,
    })
      .then(() => {
        HttpApi({
          url: "api/business/cs/business/update",
          method: "PUT",
          data: masterBodyData,
        })
          .then((response) => (response as AxiosResponse).data)
          .catch((error) => Promise.reject(error));
      })
      .catch((error) => Promise.reject(error));
  } else {
    return HttpApi({
      url: "api/filing/cs/business/update",
      method: "PUT",
      data: body,
    })
      .then((response) => (response as AxiosResponse).data)
      .catch((error) => Promise.reject(error));
  }
};

const csListSignUpdate = (value: any) => {
  const body = {
    name: value.saName,
    title: value.saTitle,
    phone: value.saPhone,
    pin: value.saPin,
    businessSubmittedId: value.businessSubmissionId,
    formType: value.formType,
    remarks: value.remarks || "test",
  };
  console.log("body", value);

  const masterBody = {
    name: value.saName,
    title: value.saTitle,
    phone: value.saPhone,
    pin: value.saPin,
    Id: value.businessSubmissionId,
    businessKey: store.getState()?.message?.message[0]?.businessKey,
  };

  if (value?.masterRecordUpdate === true) {
    return HttpApi({
      url: "api/filing/cs/sign/auth/update",
      method: "PUT",
      data: body,
    })
      .then(() => {
        HttpApi({
          url: "api/business/cs/signing/auth/update",
          method: "PUT",
          data: masterBody,
        })
          .then((response) => (response as AxiosResponse).data)
          .catch((error) => Promise.reject(error));
      })
      .catch((error) => Promise.reject(error));
  } else {
    return HttpApi({
      url: "api/filing/cs/sign/auth/update",
      method: "PUT",
      data: body,
    })
      .then((response) => (response as AxiosResponse).data)
      .catch((error) => Promise.reject(error));
  }
};

const csListTPDUpdate = (value: any) => {
  const body = {
    name: value.tpdDesignee,
    phone: value.tpdPhone,
    pin: value.tpdPin,
    businessSubmittedId: value.businessSubmissionId,
    formType: value.formType,
    remarks: value.remarks || "test",
  };

  const masterBody = {
    name: value.tpdDesignee,
    phone: value.tpdPhone,
    pin: value.tpdPin,
    businessKey: store.getState()?.message?.message[0]?.businessKey,
  };

  if (value?.masterRecordUpdate === true) {
    return HttpApi({
      url: "api/filing/cs/third/party/update",
      method: "PUT",
      data: body,
    })
      .then(() => {
        HttpApi({
          url: "api/business/cs/third/party/update",
          method: "PUT",
          data: masterBody,
        })
          .then((response) => (response as AxiosResponse).data)
          .catch((error) => Promise.reject(error));
      })
      .catch((error) => Promise.reject(error));
  } else {
    return HttpApi({
      url: "api/filing/cs/third/party/update",
      method: "PUT",
      data: body,
    })
      .then((response) => (response as AxiosResponse).data)
      .catch((error) => Promise.reject(error));
  }
};

const csAuthUpdate = (value: any) => {
  const bodyObj = store
    .getState()
    .message.message.filter(
      (el: any) => el.businessSubmissionId == value.businessSubmittedId
    );
  const body = {
    firstName: bodyObj[0].firstName,
    lastName: bodyObj[0].lastName,
    email: bodyObj[0].email,
    password: bodyObj[0].password,
    phoneNumber: bodyObj[0].phone,
    role: bodyObj[0].role,
  };

  return HttpApi({
    url: "api/filing/cs/sign/auth/update",
    method: "PUT",
    data: body,
  })
    .then((response) => (response as AxiosResponse).data)
    .catch((error) => console.log(error));
};

const csUserUpdate = (
  value: any,
  user: any,
  category: string,
  taxProfessionalData: any
) => {
  console.log("user:-", value, user, category, taxProfessionalData);

  const bodyWithoutTaxProf = {
    userKey: user?.userKey,
    firstName: value?.firstName,
    lastName: value?.lastName,
    email: value?.email,
    userType: "USER",
    pageUrl: typeof window !== 'undefined' ? window.location.pathname : ''
  };

  let bodyWithTaxProf: any = {
    userKey: user?.userKey,
    firstName: value?.firstName,
    lastName: value?.lastName,
    email: value?.email,
    userType: "TAX_PROFESSIONAL",
    taxProfessionalDetails: {
      selfEmployed: taxProfessionalData?.selfEmployed,
      ptin: "P" + taxProfessionalData?.ptin,
      taxProfessionalEmploymentDetails: {
        ein: taxProfessionalData?.ein,
        firmsName: taxProfessionalData?.firmsName,
        addressLine1: taxProfessionalData?.addressLine1,
        addressLine2: taxProfessionalData?.addressLine2,
        zip: taxProfessionalData?.zip,
        city: taxProfessionalData?.city,
        state: taxProfessionalData?.country === 'US' ? taxProfessionalData?.state : taxProfessionalData?.stateT,
        country: taxProfessionalData?.country,
        firmsPhone: taxProfessionalData?.firmsPhone
      }
    },
    pageUrl: typeof window !== 'undefined' ? window.location.pathname : ''

  };

  const updateBusiness: any = {
    businessSubmittedId: taxProfessionalData?.businessSubmitId,
    formType: taxProfessionalData?.filingType,
    remarks: "test",
    ptin: "P" + taxProfessionalData?.ptin,
  };

  if (!bodyWithTaxProf.selfEmployed) {
    bodyWithTaxProf = {
      ...bodyWithTaxProf,
      taxProfessionalDetails: {
        selfEmployed: taxProfessionalData?.selfEmployed,
        ptin: "P" + taxProfessionalData?.ptin,
        taxProfessionalEmploymentDetails: {
          ein: taxProfessionalData?.ein,
          firmsName: taxProfessionalData?.firmsName,
          addressLine1: taxProfessionalData?.addressLine1,
          addressLine2: taxProfessionalData?.addressLine2,
          zip: taxProfessionalData?.zip,
          city: taxProfessionalData?.city,
          state: taxProfessionalData?.country === 'US' ? taxProfessionalData?.state : taxProfessionalData?.stateT,
          country: taxProfessionalData?.country,
          firmsPhone: taxProfessionalData?.firmsPhone
        }
      },
    };
    updateBusiness.taxProfessionalDetails = {
      selfEmployed: taxProfessionalData?.selfEmployed,
      ptin: "P" + taxProfessionalData?.ptin,
      taxProfessionalEmploymentDetails: {
        selfEmployed: taxProfessionalData?.selfEmployed,
        ptin: "P" + taxProfessionalData?.ptin,
        taxProfessionalEmploymentDetails: {
          ein: taxProfessionalData?.ein,
          firmsName: taxProfessionalData?.firmsName,
          addressLine1: taxProfessionalData?.addressLine1,
          addressLine2: taxProfessionalData?.addressLine2,
          zip: taxProfessionalData?.zip,
          city: taxProfessionalData?.city,
          state: taxProfessionalData?.country === 'US' ? taxProfessionalData?.state : taxProfessionalData?.stateT,
          country: taxProfessionalData?.country,
          firmsPhone: taxProfessionalData?.firmsPhone
        }
      }
    };
  }
  if(taxProfessionalData?.selfEmployed) {
    delete bodyWithTaxProf.taxProfessionalDetails?.taxProfessionalEmploymentDetails;
    delete updateBusiness.taxProfessionalDetails.taxProfessionalEmploymentDetails;
  }
  if (taxProfessionalData?.updateMaster || category === "owner") {
    console.log("category: ", category, category === "paidPreparer" ? bodyWithTaxProf : bodyWithoutTaxProf);
    return HttpApi({
      url: "api/auth/cs/user/update",
      method: "PUT",
      data: category === "paidPreparer" ? bodyWithTaxProf : bodyWithoutTaxProf,
    })
      .then((response) => {
        if (category === "paidPreparer") {
          return HttpApi({
            url: "api/filing/cs/tax/professional",
            method: "PUT",
            data: updateBusiness,
          })
            .then((response) => {
              (response as AxiosResponse).data;
            })
            .catch((error) => console.log(error));
        }
        (response as AxiosResponse).data;
      })
      .catch((error) => console.log(error));
  } else {
    console.log("category - ", category, updateBusiness)
    return HttpApi({
      url: "api/filing/cs/tax/professional",
      method: "PUT",
      data: updateBusiness,
    })
      .then((response) => (response as AxiosResponse).data)
      .catch((error) => {
        console.log(error);
        return error;
      });
  }
};

const csFormUpdate = (
  radioVal: any,
  taxperiod: string,
  usedMonth: any,
  reference: any
) => {
  const body = {
    formKey: reference?.form2290Model?.formKey,
    businessId: reference?.form2290Model?.businessId,
    businessName: reference?.form2290Model?.businessName,
    taxPeriod: taxperiod,
    firstUsedMonth: usedMonth,
    isFinalReturn: radioVal === "no" ? false : true,
    isTaxable: "true",
    isSuspended: "true",
    isCredit: "true",
    isPriorYearSold: "true",
    isAmendment: reference?.form2290Model?.isAmendment,
  };

  return HttpApi({
    url: `api/filing/cs/update-filing/${reference?.referenceNumber}`,
    method: "PUT",
    data: body,
  })
    .then((response) => (response as AxiosResponse).data)
    .catch((error) => console.log(error));
};

const cSoldUpdate = (value: any, formKey: string) => {
  const body = {
    form8849_key: formKey,
    sold_to: value.soldTo,
    email: value.email,
    phone_number: value.phone,
    address_line1: value.add1Modal,
    address_line2: value.add2Modal,
    city: value.cityModal,
    state: value.state,
    country: value.country,
    zip_code: value.zip,
  };

  return HttpApi({
    url: "api/filing/cs/update8849/sold",
    method: "PUT",
    data: body,
  })
    .then((response) => (response as AxiosResponse).data)
    .catch((error) => console.log(error));
};

const csReTransmit = (value: any) => {
  const { filingType, referenceNumber } = value;
  return HttpApi({
    url: `api/filing/cs/retransmit-${filingType}/${referenceNumber}`,
    method: "POST",
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};

const csUpdateRoutingDetails = (formkey: any, payload: any) => {
  return HttpApi({
    url: `api/filing/update-payment-method/${formkey}`,
    method: "POST",
    data: payload,
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch((error) => Promise.reject(error));
};

const csReportDetails = (apiurl?: any, payload?: any) => {
  return HttpApi({
    url: apiurl,
    method: "POST",
    data: payload,
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      alert('Something went wrong, Please try again')
      return error;
    });
};

const csReportGet = (apiurl?: any) => {
  return HttpApi({
    url: apiurl,
    method: "GET",
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      console.log(error);
      alert('Something went wrong, Please try again')
    });
};

const csChangePassword = (payload: any) => {
  return HttpApi({
    url: "api/auth/cs/change-password",
    method: "POST",
    data: payload,
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};

const getNotifications = () => {
  return HttpApi({
    url: "api/report/funnel/customers/executive/case",
    method: "GET",
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};

const csCloseDashboardCase = (payload: any) => {
  return HttpApi({
    url: "api/report/funnel/customers/executive/case",
    method: "PUT",
    data: payload,
  })
};

const csDisposition = () => {
  return HttpApi({
    url: "api/report/funnel/customers/executive/case/disposition",
    method: "GET",
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      console.log(error);
      alert('Something went wrong, Please try again')
    });
};

const viewServiceFeeBreakUp = (formKey: any) => {
  return HttpApi({
    url: `api/report/user/service-fee-breakdown?form-key=${formKey}`,
    method: "GET",
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};

export default {
  csList,
  cSoldUpdate,
  csListUpdate,
  csListSignUpdate,
  csListTPDUpdate,
  csAuthUpdate,
  csUserUpdate,
  csFormUpdate,
  csReTransmit,
  csUpdateRoutingDetails,
  csReportDetails,
  csReportGet,
  csChangePassword,
  csCloseDashboardCase,
  csDisposition,
  getNotifications,
  viewServiceFeeBreakUp,
};
