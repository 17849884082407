import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useGoogleLogin } from "@react-oauth/google";
import { HttpApi } from "../../api/HttpsApi";
import { AxiosResponse } from "axios";
import { useDispatch } from "react-redux";
import { LOGIN_FAIL, LOGIN_SUCCESS, SET_MESSAGE } from "../../Actions/types";

export const useSocialLogin = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<AxiosResponse | undefined>();
  const dispatch = useDispatch();
  const callToSocialLoginApi = async (token: string, provider: string) => {
    await HttpApi({
      url: "api/auth/social-login",
      method: "POST",
      data: {
        token,
        provider,
      },
    })
      .then((data: any) => {
        if (data.data.access_token) {
          if (data.data.access_token) {
            localStorage.setItem("user", JSON.stringify(data));
            localStorage.setItem("accessToken", data.access_token);
            dispatch({
              type: LOGIN_SUCCESS,
              payload: { user: data },
            });
          }
        }
        setData(data);
      })
      .catch((error) => {
        dispatch({
          type: LOGIN_FAIL,
        });

        if (error.response.status === 403) {
          dispatch({
            type: SET_MESSAGE,
            payload: {
              loginError: true,
              message: "Unregistered Email!",
            },
          });
        }
      });
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      callToSocialLoginApi(tokenResponse?.access_token, "google");
    },
  });

  useEffect(() => {
    const token = data?.data?.access_token;
    if (token) {
      localStorage.setItem("accessToken", token);
      navigate("/dashboard");
    }
  }, [data]);

  return { googleLogin };
};
