import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export const StyledCircularProgress = styled(CircularProgress)``;

export const StyledLoaderOverlay = styled(Box)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  z-index: 99999;
  opacity: 0.6;
  place-content: center;
  pointer-events: none;
  background-color: rgba(235, 236, 240, 0.9);
`;
