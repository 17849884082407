import { agentAssignment } from "../../Services/reports.service";

export const agentListmapping = (response: any) => {
    const list: string[] = [];
    const emailList: string[] = [];
    const nameToIdMap: { [key: string]: string | number } = {};
  
    response?.data?.forEach((item: { id: string | number; name: string; email: string }) => {
        list.push(item.name.toLowerCase());
        emailList.push(item.email.toLowerCase());
        nameToIdMap[item.name.toLowerCase()] = item.id;
    });
  
    list.sort();
    emailList.sort();
    const sortedList = list.map(name => name.charAt(0).toUpperCase() + name.slice(1));
  
    return { sortedList, emailList, nameToIdMap };
  };
  
export const handleAgentSelectionChange = async (
    event: React.SyntheticEvent,
    newValue: string | null,
    id: number,
    userDataItem: any,
    agentNameToIdMap: { [key: string]: string | number },
    agentSelectedIndex: { [key: string]: number | null },
    setAgentSelections: React.Dispatch<React.SetStateAction<{ [key: number]: string }>>,
    handleRefresh: () => void
  ) => {
    const value = newValue || ''; // Handle case where newValue is null
  
    // Update local state
    setAgentSelections(prevState => ({
        ...prevState,
        [id]: value,
    }));
  
    const previousId = agentSelectedIndex[userDataItem?.email] ||  null;
    const executiveId = agentNameToIdMap[value.toLowerCase()] || null;
  
    try {
        const response = await agentAssignment(previousId, userDataItem, executiveId);
        if (response.data) {
            setAgentSelections({});
            handleRefresh();
        }
    } catch (error) {
        console.error(error);
    }
};