export const CONSTANTS= {
    emailPlaceholder:"Enter the customer’s email address",
    otpPlaceholder:'Enter the OTP received by the customer',
    numberPlaceholder:"Enter the customer’s mobile number",
    verifyOTPNumber:"Verify the customer’s number using the OTP!",
    verifiedNumber:"Customer’s number verified! Proceed with sending the SMS.",  
    verifyOTPEmail:"Verify the customer’s email address using the OTP!",
    verifiedEmail:"Customer’s email verified! Proceed with sending the Email.",
    mobile:'Mobile Number',
    otp:'OTP',
    email:'Email Address',
    alternateEmail:'Alternate Email',
    verified:'Verified'
}

export const BUTTONS ={
    send:'Send OTP',
    sendSMS:'Send SMS',
    sendEmail:'Send Email',
    verify:'Verify OTP',
    cancel:'Cancel',
    alternateEmail:'Send to Alternate Email',
    exportExcel:'Export to Excel',
}

export const ERRORMSG = {
    emailEmpty:'Please enter email',
    numberEmpty:'Please enter phone number',
    emailInvalid:'Please enter valid email',
    numberInvalid: 'Please enter a valid phone number',
    otpEmpty:'Please enter OTP',
    otpInvalid:'Please enter a valid OTP',
    emailNotSent:`Action failed! Please use ‘Export To Excel’ to continue.`,
    emailValidationList: 'Please select a email address.'
}