import axios, { AxiosRequestConfig } from "axios";
import { LOCALSTORAGE_GET_ACCESSTOKEN } from "../common/apis/const";
import { API_ENDPOINT_MCS } from "../Utils/Constants";

export const HttpsApiMCS = async ({
  url,
  method = "GET",
  data = null,
  headers,
  params,
  extraConfig,
}: {
  url: string;
  method: "POST" | "PUT" | "DELETE" | "GET";
  data?: any;
  headers?: any;
  params?: any;
  extraConfig?: any;
}) => {
  if (!url) {
    return;
  }
  if (headers) {
    headers["Content-Type"] = "application/json";
  }

  const option = {
    method,
    data,
    headers,
    params,
    ...extraConfig
  };

  axios.interceptors.request.use((config: any) => {
    const url = config.url;
    const token = LOCALSTORAGE_GET_ACCESSTOKEN();
    const expirationTime = localStorage.getItem("expirationTime");
    const curentTime = new Date().getTime();
    const expiredTime = expirationTime && JSON.parse(expirationTime);
    if (expirationTime && token) {
      if (curentTime > expiredTime) {
        localStorage.clear();
        window.location.href = "/login";
        return;
      }
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
    } else {
      if (!url.includes("login") && !url.includes("register") && !url.includes("ui-toggles"))  {
        window.location.href = "/login"; 
      }
    }
    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      }
      return Promise.reject(err);
    }
  );

  return await axios(API_ENDPOINT_MCS + url, { ...option });
};
