export const REGEX = {
    Regex: class {
      static phone = /^((?!(0))[0-9]{10})$/;
      static email = /^[a-zA-Z0-9@._-]+$/;
      static milage = /^\d+$/;
    }
}

export const COUNTRY = {
  Regex: class {
    static CA = 'CA';
    static US = 'US';
    static milage = 'MX';
  }
}

export const ADDNEWCS = {
  addNewCS: class {
    static Inbound = 'Inbound Call (from Customer)';
    static Outbound = 'Outbound Call (to Customer)';
  }
}
