import React, { useEffect, useState } from 'react';
import { Grid, TextField, Box, Button, Container, Typography, IconButton, Stepper, Step, StepLabel, DialogTitle, Dialog, DialogContent, DialogActions } from '@mui/material';
import mcsService from '../Services/mcs.service';

interface FilingAction {
  formKey:any,
  sendVerificationLink:(value:any)=>void
  setIsLoading:(value:boolean)=>void
  currentStauts:any
}

export const styles = {
  button:{
    height: '50px', textTransform: 'none'
  },
  viewMcs:{
    color:'#016b89', textDecoration:'underline', fontSize:'16px', fontWeight:'bold', cursor:'pointer'
  }

};

const ResendInvoice: React.FC<FilingAction> = ({formKey,sendVerificationLink, setIsLoading,currentStauts}) => {
  
  const viewMCS = async() =>{
    setIsLoading(true)
    try {
      const resp = await mcsService.mcsViewMCS150(formKey);
      if (resp?.status === 200) {
        window.open(resp?.data?.submission_reference_url)
        setIsLoading(false)
      }
      else {
        setIsLoading(false)
        throw new Error('API Error : ' + resp?.status);
      }
    
    } catch (err) {
        setIsLoading(false)
        alert('Something went wrong, Please try again');
    }
  }
  
return (
  <>
  {
    currentStauts > 9 &&  (
      <Grid item xs={12} sm={12}>
      <p style={styles.viewMcs} onClick={viewMCS}>View eMCS150</p>
    </Grid>
    )
  }
    <Grid item xs={12} sm={6}>
        <Button 
        variant="contained"  
        onClick={()=>sendVerificationLink('ResendInvoice')}
        sx={{
            backgroundColor: '#016b89',
            marginBottom:3,
            width: '100%',
            ...styles.button,
        }}>   Resend Invoice
        </Button>
    </Grid>
    <Grid item xs={12} sm={6}>
        <Button 
        variant="contained"  
        onClick={()=>sendVerificationLink('ResendConfirmation')}
        sx={{
            backgroundColor: '#016b89',
            marginBottom:3,
            width: '100%',
            ...styles.button,
        }}>  Resend Confirmation
        </Button>
    </Grid>
  </>
)
}

export default ResendInvoice;