import { AxiosResponse } from "axios";
import { HttpsApiMCS } from "../../../api/HttpsApiMCS";

const mcsSavePrefrence = (payload: any) => {
  return HttpsApiMCS({
    url: "api/emcs-filing/customers/save-preference",
    method: "POST",
    data: payload,
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      throw error;
    });
};

const mcsGetEformDashboardInfo = (payload: any) => {
  return HttpsApiMCS({
    url: 'api/emcs-filing/customers/emcs-detail',
    method: "POST",
    data: payload,
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      throw error;
    });
};


const getEmcsData = (searchTerm:any="", page:any=0, direction:any='asc', filingStatus:any='all') => {
  return HttpsApiMCS({
    url: "api/emcs-filing/customers/search",
    method: "GET",
    params: {
      query: searchTerm,
      page: page - 1,
      size: 10,
      sort: "biennialUpdateDue",
      direction: direction,
      filingStatus: filingStatus
    },
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};

const getCustomerDetailsFormKey = (formKey:any) => {
  return HttpsApiMCS({
    url: `api/emcs-filing/${formKey}`,
    method: "GET",
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};

const getCustomerDetailsUsingMappingKey = (mappingKey:any) => {
  return HttpsApiMCS({
    url: `api/emcs-filing/fmcsa-details/${mappingKey}`,
    method: "GET",
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};

const saveCurrentFiling = (payload: any) => {
  return HttpsApiMCS({
    url: "api/emcs-filing/save",
    method: "POST",
    data: payload,
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};

const mcsAddNewCustomer = (payload: any) => {
  return HttpsApiMCS({
    url: "api/emcs-filing/customers/register",
    method: "POST",
    data: payload,
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};

const sendVerification = (formKey:any, urlParam:any) => {
  return HttpsApiMCS({
    url: `api/emcs-filing/trigger-email/${formKey}/${urlParam}`,
    method: "POST",
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};

const mcsSaveEditUserData = (formKey:any, payload:any) => {
  return HttpsApiMCS({
    url: `api/emcs-filing/customers/update-form/${formKey}`,
    method: "PUT",
    data: payload,
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};

const mcsSendPayment = (formKey:any, urlParas:any) => {
  return HttpsApiMCS({
    url: `api/emcs-filing/trigger-email/${formKey}/${urlParas}`,
    method: "POST",
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};

const mcsMarkSubmitted = (formKey:any, value:any) => {
  return HttpsApiMCS({
    url: `api/emcs-filing/${formKey}/${value}`,
    method: "PUT",
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};

const mcsUploadMCS = (formKey:any, file:any) => {
  return HttpsApiMCS({
    url: `api/emcs-filing/upload-fmcsa/${formKey}`,
    method: "PUT",
    data: file,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};

const mcsViewMCS150 = (formKey:any) => {
  return HttpsApiMCS({
    url: `api/emcs-filing/download?formKey=${formKey}`,
    method: "GET",
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};



export default {
  mcsSavePrefrence,
  mcsAddNewCustomer,
  getCustomerDetailsUsingMappingKey,
  getEmcsData,
  getCustomerDetailsFormKey,
  saveCurrentFiling,
  sendVerification,
  mcsGetEformDashboardInfo,
  mcsSaveEditUserData,
  mcsSendPayment,
  mcsMarkSubmitted,
  mcsUploadMCS,
  mcsViewMCS150
};
