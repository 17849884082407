import { AxiosResponse } from "axios";
import { HttpApi } from "../api/HttpsApi";
import { CUSTOMER_IN_FUNNEL_LIST_ENV } from "../Utils/Constants";

const csAdminRefundPendingList = () => {
  return HttpApi({
    url: "api/payments/cs/list-refund/p",
    method: "GET",
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};

const csNotSubmittedFormsList = (value: any) => {
  return HttpApi({
    url: `api/filing/cs/not-submitted-forms/${value}`,
    method: "GET",
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};

const csPhoneNumberVerified = (value:string) =>{
  const body = {
    type:"email",
    values:value
  };
  return HttpApi({
    url: `api/auth/cs/user-keys`,
    method: "POST",
    data: body,
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      throw new Error(error);
    });
};

const csSendOTP = (userKey:string ,number:string ,countryCode:string) =>{
  const body = {
    mobileNumber:number,
    region:countryCode
  };
  return HttpApi({ 
    url: `api/auth/cs/mobile/send-otp/${encodeURIComponent(userKey)}`,
    method: "POST",
    data: body,
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      throw new Error(error);
    });
};
 
const verifyOTP = (userKey:string ,number:string ,otp:string) =>{
  const pageUrl = typeof window !== 'undefined' ? window.location.pathname : '';
  const body = {
    otp:otp,
    mobileNumber:number,
    pageUrl:pageUrl
  };
  return HttpApi({
    url: `api/auth/cs/mobile/verify/${encodeURIComponent(userKey)}`,
    method: "POST",
    data: body,
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      throw new Error(error.response.data.errors);
    });
};

const csSendOTPEmail = (email:string) =>{
  return HttpApi({ 
    url: `api/auth/cs/send-email-otp/${email}`,
    method: "POST",
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      throw new Error(error.response.data.errors);
    });
};
 
const verifyOTPEmail = (email:string ,otp:string) =>{
  const body = {
    otp:otp,
    email:email
  };
  return HttpApi({
    url: `api/auth/cs/verify-email-otp`,
    method: "POST",
    data: body,
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      throw new Error(error.response.data.errors);
    });
};


const sendSMS = ( refNo:string)=>{
  return HttpApi({
    url: `api/filing/cs/doc/sms/${refNo}`,
    method: "POST",
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      throw new Error(error.response.data.errors);
    });
}

 const getCountryCodes = () =>{
  return HttpApi({
    url: `api/user/country-code`,
    method: "GET",
  })
    .then((response) => {
      console.log(response)
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      throw new Error(error.response.data.errors);
    });
}
 

const csCreditDetails = (value: string) => {
  return HttpApi({
    url: `api/payments/credits/list/${value}`,
    method: "GET",
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};

const csAddDetails = (value: any) => {
  return HttpApi({
    url: `api/payments/credits/admin/add`,
    method: "POST",
    data: value,
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};

const csSendFax = (value: any) => {
  const body = {
    ref_no: value.referenceNumber,
    fax: value.faxNo,
    type: value.filingType == "Form2290" ? "2290" : "8849",
  };
  return HttpApi({
    url: "api/report/filing/send-fax-async",
    method: "POST",
    data: body,
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};

const csDownloadSchedule1 = (value: any) => {
  return HttpApi({
    url: "api/filing/auth/schedule1",
    method: "GET",
    params: { form_key: value },
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};

const csDownloadFiles = (value: string, docType: string) => {
  return HttpApi({
    url: "api/filing/cs/reference",
    method: "GET",
    params: { ref_no: value, doc: docType },
    extraConfig: {
      responseType: "blob",
    },
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};

const csDownloadRefCopy = (value: string) => {
  return HttpApi({
    url: "api/irs-submission/2290/ref/copy",
    method: "GET",
    params: { refNo: value},
    extraConfig: {
      responseType: "blob",
    },
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};

const piDownload = (referenceNo: string) => {
  return HttpApi({
    url: `api/payments/cs/invoice/${referenceNo}`,
    method: "GET",
  }).then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
}

export const csStatusUpdate = (value: any, status: string) => {
  const params: any = {
    refundId: value.refund_id,
    invoiceNumber: value.invoice_number,
  };
  let apiStatus = "";
  if (status == "Approved") {
    apiStatus = "api/payments/refund/approve";
    params["refund_amount"] = value.refund_amount;
  } else {
    apiStatus = "api/payments/refund/reject";
  }
  return HttpApi({
    url: apiStatus,
    method: "POST",
    params,
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};

export const csManualUpdate = (value: any) => {
  console.log('value ::', value)
  return HttpApi({
    url: "api/payments/cs/initiate",
    method: "POST",
    data: value,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};

export const csRefundUpdate = (value: any, refund_id: any) => {
  return HttpApi({
    url: `api/payments/refund/update/${refund_id}`,
    method: "POST",
    data: value,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};

const updateExplainToIRS = (vin:string , explanation:string ,vehicleType:string , formKey:string ) => {
  const body = {
    vin: vin,
    explanation: explanation,
    vehicleType: vehicleType,
    formKey: formKey
  };
  return HttpApi({
    url: "api/filing/cs/update/irs-explanation",
    method: "PUT",
    data: body,
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      throw new Error(error.response.data.errors);
    });
};

const csSendScheduleEmail = (value: any) => {
  const body = {
    refNo: value.referenceNumber,
    email: value.email,
    formType: value.filingType == "Form2290" ? "FORM_2290" : "FORM_8849",
  };
  return HttpApi({
    url: "api/filing/cs/email/file",
    method: "POST",
    data: body,
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      throw new Error(error.response.data.errors);
    });
};

const getAgentList = () => {
  return HttpApi({
    url:'api/payments/cs/agent/list ',
    method: "GET",
  })
    .then((response) =>{
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      throw new Error(error.response.data.errors);
    });
}

const getCustomerFunnelList = (param: any) => {
  return HttpApi({
    url: `api/report/funnel/customers?case=${param}`,
    method: "POST",
    data :{}
  })
    .then((response) =>{
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      throw new Error(error.response.data.errors);
    });
}

const getCustomerInFunnelListOpen = (isFollowUp:boolean)  => {
  const categoryParam = isFollowUp ? '&category=FOLLOW_UP' : '';
  const url = `api/report/funnel/list/customers?env=${CUSTOMER_IN_FUNNEL_LIST_ENV}${categoryParam}`;
  return HttpApi({
    url: url,
    method: "POST",
  })
    .then((response) =>{
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      throw new Error(error.response.data.errors);
    });
}

const addDuplicateCustomers = (listOfDuplicateUser:any) => {
  return HttpApi({
    url:`api/report/funnel/customer/duplicate`,
    method:"POST",
    data:listOfDuplicateUser
  })
  .then((response) =>{
    return (response as AxiosResponse).data;
  })
  .catch(function (error) {
    throw new Error(error.response.data.errors);
  });
} 

const cs8849Attachment = (docType: string, formKey: string) => {
  return HttpApi({
    url: `api/filing/submission/claim/${formKey}`,
    method: "GET",
    params: { claim_name: docType },
    extraConfig: {
      responseType: "blob",
    },
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};

const csStopProcessing = (referenceNumber: any) => {
  return HttpApi({
    url: `api/filing/cs/stop-process/${referenceNumber}`,
    method: "POST",
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};

const csBlockRefundAndCredit = (referenceNumber: any, status: any, rejReason: any) => {
  return HttpApi({
    url: `api/filing/cs/${referenceNumber}/update-status/${status}?reasonCode=${rejReason}`,
    method: "POST",
  })
    .then((response) => {
      return (response as AxiosResponse).data;
    })
    .catch(function (error) {
      return error;
    });
};
export default {
  csAdminRefundPendingList,
  csNotSubmittedFormsList,
  csCreditDetails,
  csSendFax,
  csDownloadSchedule1,
  csDownloadFiles,
  csDownloadRefCopy,
  csStatusUpdate,
  csManualUpdate,
  csRefundUpdate,
  csAddDetails,
  csSendScheduleEmail,
  cs8849Attachment,
  csStopProcessing,
  csBlockRefundAndCredit,
  piDownload,
  csPhoneNumberVerified,
  csSendOTP,
  verifyOTP,
  sendSMS,
  csSendOTPEmail,
  verifyOTPEmail,
  getCountryCodes,
  getAgentList,
  getCustomerFunnelList,
  getCustomerInFunnelListOpen,
  updateExplainToIRS,
  addDuplicateCustomers
};
